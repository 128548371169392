import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
  Image,
  Font,
} from "@react-pdf/renderer";
import logo from "../../logo-succession-now.png";
import RobotoLight from "../../assets/font/Roboto-Light.ttf";
import RobotoRegular from "../../assets/font/Roboto-Regular.ttf";
import RobotoMedium from "../../assets/font/Roboto-Medium.ttf";
import RobotoBold from "../../assets/font/Roboto-Bold.ttf";
import moment from "moment/moment";
import { sumTotal } from "../../helpers";
import { useTranslation } from "react-i18next";

Font.register({ family: "RobotoLight", src: RobotoLight });
Font.register({ family: "RobotoRegular", src: RobotoRegular });
Font.register({ family: "RobotoMedium", src: RobotoMedium });
Font.register({ family: "RobotoBold", src: RobotoBold });

const SuccessionPlanAllReport = ({
  successors,
  heading,
  readinessHeading,
  concernHeadings,
  predictorsHeading,
  conclusion,
  planApprove,
  name,
  date,
  trimmedDataURL,
  user,
  companyLogo,
}) => {
  const { i18n, t } = useTranslation();
  const profileHeading = [
    {
      name: t("years_of_senior_management_experience"),
    },
    {
      name: t("years_with_cu_or_company"),
    },
    {
      name: t("education"),
    },
    {
      name: t("performance"),
    },
    {
      name: t("loyalty"),
    },
    {
      name: t("desire_to_advance"),
    },
    {
      name: t("retirement_window"),
    },
    {
      name: t("additional_comments"),
    },
  ];

  const styles = StyleSheet.create({
    page: {
      padding: 20,
      fontFamily: "RobotoRegular",
      lineHeight: 1.2,
      fontSize: 9,
      color: "#334155",
    },
    tableHeading: {
      fontSize: 12,
      width: "20%",
      maxWidth: "100%",
      padding: 8,
      backgroundColor: "#0f172a",
      color: "#ffffff",
    },
    tableData: {
      fontSize: 10,
      width: "20%",
      maxWidth: "100%",
      padding: 8,
    },

    image: {
      width: 150,
      height: 30,
      objectFit: "contain",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    tableOtherData: {
      fontSize: 10,
      color: "#334155",
    },
    imageSignature: {
      width: 100,
      height: 60,
      objectFit: "contain",
    },
    section: { textAlign: "center" },
    profileTableHeading: {
      fontSize: 8,
      width: "14%",
      paddingHorizontal: 4,
      paddingVertical: 8,
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    profileTableHeadingText: {
      width: "100%",
      fontFamily: "RobotoRegular",
      fontSize: 8,
      lineHeight: 1,
    },
    profileSubText: {
      fontSize: 8,
      marginTop: 2,
    },
    profileTableData: {
      fontSize: 9,
      width: "14%",
      padding: 4,
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    profileTableText: {
      fontSize: 7,
    },
    tablePart: {
      display: "flex",
      flexDirection: "row",
      overflow: "hidden",
    },
    developmentTableHeading: {
      fontSize: 8,
      width: "33.33%",
      paddingHorizontal: 8,
      paddingVertical: 8,
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    developmentTableData: {
      fontSize: 9,
      width: "33.33%",
      padding: 4,
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    developmentTableText: {
      fontSize: 7,
    },
    developmentTableHeadingText: {
      width: "100%",
      fontFamily: "RobotoRegular",
      fontSize: 10,
      lineHeight: 1,
    },
    readinessTableHeading: {
      fontSize: 10,
      width: "25%",
      padding: "8px 4px",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    readinessTableHeadingText: {
      width: "100%",
      fontFamily: "RobotoRegular",
      fontSize: 10,
      lineHeight: 1,
    },
    readinessTableData: {
      fontSize: 10,
      width: "25%",
      padding: 4,
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    readinessTableText: {
      fontSize: 9,
    },
    conclusionTableHeading: {
      fontSize: 12,
      width: "20%",
      padding: 5,
    },
    conclusionTableData: {
      fontSize: 10,
      width: "20%",
      fontSize: 10,
      padding: 5,
    },
    conclusionTableOtherData: {
      fontSize: 10,
    },
  });

  return (
    <>
      <Page
        size="A4"
        orientation="landscape"
        style={[
          styles.page,
          {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
          },
        ]}
      >
        <View style={[styles.section, { color: "white" }]}>
          <Text>Cover Page</Text>
        </View>
        <View
          style={{
            paddingBottom: 20,
            width: "100%",
            height: "90%",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <View
            style={{
              position: "absolute",
              right: 0,
              top: 0,
              width: 120,
              height: 24,
            }}
          >
            <Image style={{ objectFit: "contain" }} src={logo} />
          </View>
          <View
            style={{
              paddingBottom: 20,
              margin: "0 auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <View
              style={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                fontSize: 16,
              }}
            >
              <Image
                style={[
                  {
                    width: "400",
                    height: "40",
                    objectFit: "contain",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  },
                ]}
                src={companyLogo}
                // src='https://successionnow.iosx.in/logo-succession-now.png'
              />
            </View>
            <View
              style={[
                styles.tableOther,
                { display: "flex", flexDirection: "row", marginTop: 15 },
              ]}
            >
              <Text
                style={[
                  styles.tableOtherData,
                  {
                    display: "flex",
                    flexDirection: "row",
                    fontFamily: "RobotoBold",
                    fontSize: 20,
                    textTransform: "capitalize",
                  },
                ]}
              >
                {user?.company_name}
              </Text>
            </View>
            <View
              style={[
                styles.tableOther,
                { display: "flex", flexDirection: "row", marginTop: 15 },
              ]}
            >
              <Text
                style={[
                  styles.tableOtherData,
                  {
                    display: "flex",
                    flexDirection: "row",
                    fontFamily: "RobotoMedium",
                    fontSize: 16,
                    textTransform: "capitalize",
                  },
                ]}
              >
                Succession Plan Summary for{" "}
                {successors && successors.length > 0
                  ? successors[0]?.pos_details[0]?.name
                  : ""}
              </Text>
            </View>
          </View>
          <View
            style={[
              {
                position: "absolute",
                bottom: "0",
                display: "flex",
                flexDirection: "row",
                textAlign: "center",
                justifyContent: "center",
                marginTop: "auto",
              },
            ]}
          >
            <View
              style={[
                styles.tableOther,
                {
                  display: "flex",
                  flexDirection: "row",
                  marginTop: 5,
                  textAlign: "center",
                  justifyContent: "center",
                  marginRight: 12,
                },
              ]}
            >
              <Text
                style={[
                  {
                    display: "flex",
                    flexDirection: "row",
                    fontFamily: "RobotoMedium",
                    fontSize: 10,
                    textAlign: "center",
                    fontSize: 10,
                  },
                ]}
              >
                Created by :
              </Text>
              <Text style={{ fontWeight: 700, fontSize: 10, marginLeft: 2 }}>
                {successors && successors.length > 0 ? successors[0]?.name : ""}{" "}
                (
                {successors && successors.length > 0
                  ? successors[0]?.position
                  : ""}
                )
              </Text>
            </View>
            <View
              style={[
                styles.tableOther,
                { display: "flex", flexDirection: "row", marginTop: 5 },
              ]}
            >
              <Text
                style={[
                  styles.tableOtherData,
                  {
                    display: "flex",
                    flexDirection: "row",
                    fontFamily: "RobotoMedium",
                    fontSize: 10,
                  },
                ]}
              >
                Date:
              </Text>
              <Text style={{ fontWeight: 700, fontSize: 10, marginLeft: 2 }}>
                {successors && successors.length > 0
                  ? successors[0]?.report_date
                    ? moment(new Date(successors[0]?.report_date)).format(
                        "MM/DD/YYYY"
                      )
                    : moment(new Date()).format("MM/DD/YYYY")
                  : moment(new Date()).format("MM/DD/YYYY")}
              </Text>
            </View>
          </View>
        </View>
      </Page>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <View style={[styles.section, { color: "white" }]}>
          <Text>Successors | Step 1 </Text>
        </View>
        <View
          style={{
            paddingBottom: 20,
            width: "100%",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <View
            style={[
              styles.tableOther,
              { display: "flex", flexDirection: "row", marginTop: 15 },
            ]}
          >
            <Text
              style={[
                styles.tableOtherData,
                {
                  display: "flex",
                  flexDirection: "row",
                  fontFamily: "RobotoMedium",
                  fontSize: 16,
                  textTransform: "capitalize",
                },
              ]}
            >
              Succession Plan Summary for{" "}
              {successors && successors.length > 0
                ? successors[0]?.pos_details[0]?.name
                : ""}
            </Text>
          </View>
        </View>
        {successors &&
          successors.length > 0 &&
          successors.map((row, key) => {
            return (
              <>
                {row.successors_details &&
                  row.successors_details.length > 0 && (
                    <View
                      style={[
                        styles.tableOther,
                        {
                          display: "flex",
                          flexDirection: "row",
                          columnGap: 20,
                          marginTop: 5,
                          marginBottom: 5,
                          textAlign: "center",
                          justifyContent: "center",
                        },
                      ]}
                      key={key}
                    >
                      <Text
                        style={[
                          styles.tableOtherData,
                          {
                            display: "flex",
                            flexDirection: "row",
                            fontWeight: 600,
                            fontSize: 14,
                            textAlign: "center",
                          },
                        ]}
                      >
                        {t("succession_candidates_for")} {row.position}
                      </Text>
                    </View>
                  )}
                {row.successors_details &&
                  row.successors_details.length > 0 && (
                    <View
                      style={{
                        border: "1px solid #e2e8f0",
                        borderRadius: 6,
                        overflow: "hidden",
                        marginBottom: 30,
                      }}
                      key={key}
                    >
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          backgroundColor: "#f8fafc",
                          overflow: "hidden",
                        }}
                      >
                        <Text
                          style={[
                            styles.tableHeading,
                            { fontFamily: "RobotoMedium", fontSize: 10 },
                          ]}
                        >
                          &nbsp;
                        </Text>
                        {row.successors_details.map((item, index) => (
                          <View
                            key={key + "-" + index}
                            style={[
                              styles.tableHeading,
                              {
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                              },
                            ]}
                          >
                            <Text
                              style={{
                                width: "100%",
                                fontFamily: "RobotoMedium",
                                fontSize: 10,
                              }}
                            >
                              {item.name}
                            </Text>
                            <Text style={{ fontSize: 8, marginTop: 2 }}>
                              {item?.position_details
                                ? item?.position_details[0]?.short_name
                                : "N/A"}
                            </Text>
                          </View>
                        ))}
                      </View>
                      {profileHeading.map((items, index) => (
                        <View
                          key={key + "-" + index}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            borderTop: "1px solid #e2e8f0",
                          }}
                        >
                          <View
                            style={[
                              styles.tableData,
                              {
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                              },
                            ]}
                          >
                            <Text
                              style={[
                                {
                                  fontWeight: 500,
                                  width: "100%",
                                  fontSize: 10,
                                  lineHeight: 1,
                                },
                              ]}
                            >
                              {items.name}
                            </Text>
                          </View>

                          {row.successors_details.map((item, index2) => (
                            <View
                              key={key + "-" + index + "_" + index2}
                              style={[
                                styles.tableData,
                                {
                                  display: "flex",
                                  flexDirection: "row",
                                  flexWrap: "wrap",
                                },
                              ]}
                            >
                              {index === 0 && (
                                <Text style={{ fontSize: 9 }}>
                                  {item?.experience_details &&
                                  item?.experience_details.length > 0
                                    ? item?.experience_details[0]?.name
                                    : "N/A"}
                                </Text>
                              )}
                              {index === 1 && (
                                <Text style={{ fontSize: 9 }}>
                                  {item?.cu_details &&
                                  item?.cu_details.length > 0
                                    ? item?.cu_details[0]?.name
                                    : "N/A"}
                                </Text>
                              )}
                              {index === 2 && (
                                <Text style={{ fontSize: 9 }}>
                                  {item?.education_details &&
                                  item?.education_details.length > 0
                                    ? item?.education_details[0]?.name
                                    : "N/A"}
                                </Text>
                              )}
                              {index === 3 && (
                                <Text style={{ fontSize: 9 }}>
                                  {item?.performance_details &&
                                  item?.performance_details.length > 0
                                    ? item?.performance_details[0]?.name +
                                      " " +
                                      (item?.performance_details[0].number
                                        ? "(" +
                                          item?.performance_details[0].number +
                                          ")"
                                        : "")
                                    : "N/A"}
                                </Text>
                              )}
                              {index === 4 &&
                                (item?.loyalty_details.length > 0 &&
                                item?.loyalty_details[0].isPass ? (
                                  <Text style={{ fontSize: 9 }}>
                                    {item?.performance_details &&
                                    item?.performance_details.length > 0
                                      ? item?.performance_details[0]?.name +
                                        " " +
                                        (item?.performance_details[0].number
                                          ? "(" +
                                            item?.performance_details[0]
                                              .number +
                                            ")"
                                          : "")
                                      : "N/A"}
                                  </Text>
                                ) : (
                                  <Text style={{ fontSize: 9, color: "red" }}>
                                    {Array.isArray(item?.loyalty_details) &&
                                    item?.loyalty_details.length > 0
                                      ? item?.loyalty_details[0]?.isPass
                                        ? "Pass: "
                                        : "Fail: "
                                      : ""}
                                    {item?.loyalty_details &&
                                    item?.loyalty_details.length > 0
                                      ? item?.loyalty_details[0]?.name
                                      : "N/A"}
                                  </Text>
                                ))}
                              {index === 5 &&
                                (item.desire_details.length > 0 &&
                                item?.desire_details[0].isPass ? (
                                  <Text style={{ fontSize: 9 }}>
                                    {Array.isArray(item?.desire_details) &&
                                    item?.desire_details.length > 0
                                      ? item?.desire_details[0]?.isPass
                                        ? "Pass: "
                                        : "Fail: "
                                      : ""}
                                    {item?.desire_details &&
                                    item?.desire_details.length > 0
                                      ? item?.desire_details[0]?.name
                                      : "N/A"}
                                  </Text>
                                ) : (
                                  <Text style={{ fontSize: 9, color: "red" }}>
                                    {Array.isArray(item?.desire_details) &&
                                    item?.desire_details.length > 0
                                      ? item?.desire_details[0]?.isPass
                                        ? "Pass: "
                                        : "Fail: "
                                      : ""}
                                    {item?.desire_details &&
                                    item?.desire_details.length > 0
                                      ? item?.desire_details[0]?.name
                                      : "N/A"}
                                  </Text>
                                ))}
                              {index === 6 &&
                                (item.retirement_details.length > 0 &&
                                item?.retirement_details[0].isPass ? (
                                  <Text style={{ fontSize: 9 }}>
                                    {Array.isArray(item?.retirement_details) &&
                                    item?.retirement_details.length > 0
                                      ? item?.retirement_details[0]?.isPass
                                        ? "Pass: "
                                        : "Fail: "
                                      : ""}
                                    {item?.retirement_details &&
                                    item?.retirement_details.length > 0
                                      ? item?.retirement_details[0]?.name
                                      : "N/A"}
                                  </Text>
                                ) : (
                                  <Text style={{ fontSize: 9, color: "red" }}>
                                    {Array.isArray(item?.retirement_details) &&
                                    item?.retirement_details.length > 0
                                      ? item?.retirement_details[0]?.isPass
                                        ? "Pass: "
                                        : "Fail: "
                                      : ""}
                                    {item?.retirement_details &&
                                    item?.retirement_details.length > 0
                                      ? item?.retirement_details[0]?.name
                                      : "N/A"}
                                  </Text>
                                ))}
                              {index === 7 && (
                                <Text style={{ fontSize: 9 }}>
                                  {item?.additional_comments
                                    ? item?.additional_comments
                                    : ""}
                                </Text>
                              )}
                            </View>
                          ))}
                        </View>
                      ))}
                    </View>
                  )}
              </>
            );
          })}
      </Page>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <View style={[styles.section, { color: "white" }]}>
          <Text>Concern | Step 2</Text>
        </View>
        <View
          style={{
            paddingBottom: 20,
            width: "100%",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <View
            style={[
              styles.tableOther,
              { display: "flex", flexDirection: "row", marginTop: 15 },
            ]}
          >
            <Text
              style={[
                styles.tableOtherData,
                {
                  display: "flex",
                  flexDirection: "row",
                  fontFamily: "RobotoMedium",
                  fontSize: 16,
                  textTransform: "capitalize",
                },
              ]}
            >
              Succession Plan Summary for{" "}
              {successors && successors.length > 0
                ? successors[0]?.pos_details[0]?.name
                : ""}
            </Text>
          </View>
        </View>
        {successors &&
          successors.length > 0 &&
          successors.map((row, key) => {
            return (
              <>
                {row.successors_details && (
                  <View
                    style={[
                      styles.tableOther,
                      {
                        display: "flex",
                        flexDirection: "row",
                        columnGap: 20,
                        marginTop: 5,
                        marginBottom: 5,
                        textAlign: "center",
                        justifyContent: "center",
                      },
                    ]}
                    key={key}
                  >
                    <Text
                      style={[
                        styles.tableOtherData,
                        {
                          display: "flex",
                          flexDirection: "row",
                          fontWeight: 600,
                          fontSize: 14,
                          textAlign: "center",
                        },
                      ]}
                    >
                      Conditions of Concern
                    </Text>
                  </View>
                )}
                {row.successors_details &&
                  row.successors_details.length > 0 && (
                    <View
                      style={{
                        border: "1px solid #e2e8f0",
                        borderRadius: 6,
                        overflow: "hidden",
                        marginBottom: 30,
                      }}
                      key={key}
                    >
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          backgroundColor: "#f8fafc",
                          overflow: "hidden",
                        }}
                      >
                        <Text
                          style={[
                            styles.tableHeading,
                            { fontFamily: "RobotoMedium", fontSize: 10 },
                          ]}
                        >
                          &nbsp;
                        </Text>
                        {row.successors_details.map((item, index) => (
                          <View
                            key={index}
                            style={[
                              styles.tableHeading,
                              {
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                              },
                            ]}
                          >
                            <Text
                              style={{
                                width: "100%",
                                fontFamily: "RobotoMedium",
                                fontSize: 10,
                              }}
                            >
                              {item.name}
                            </Text>
                            <Text style={{ fontSize: 8, marginTop: 2 }}>
                              {item?.position_details
                                ? item?.position_details[0]?.short_name
                                : "N/A"}
                            </Text>
                          </View>
                        ))}
                      </View>
                      {concernHeadings.map((items, index) => (
                        <View
                          key={key + "-" + index}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            borderTop: "1px solid #e2e8f0",
                          }}
                        >
                          <View
                            style={[
                              styles.tableData,
                              {
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                              },
                            ]}
                          >
                            <Text
                              style={[
                                {
                                  fontWeight: 500,
                                  width: "100%",
                                  fontSize: 10,
                                  lineHeight: 1,
                                },
                              ]}
                            >
                              {items?.heading_name
                                ? items?.heading_name
                                : items?.name}
                            </Text>
                            <Text
                              style={[
                                {
                                  fontSize: 8,
                                  fontFamily: "RobotoMedium",
                                  marginTop: 2,
                                },
                              ]}
                            >
                              {items.heading_description
                                ? "(" + items?.heading_description + ")"
                                : "(" + items?.description + ")"}
                            </Text>
                          </View>
                          {row.successors_details.map((item, index2) => {
                            let h_idx = -1;
                            let opt_id = "";
                            let op_idx = -1;
                            if (item?.concerns) {
                              h_idx = item?.concerns.findIndex(
                                (p) => p.heading_id === items?._id
                              );
                              opt_id = item?.concerns[h_idx]?.option_id;
                              op_idx = items?.options.findIndex(
                                (op) => op._id === opt_id
                              );
                            }
                            let point =
                              index === h_idx &&
                              items?.options[op_idx]?._id === opt_id
                                ? items?.options[op_idx]?.point
                                : "";
                            let name =
                              index === h_idx &&
                              items?.options[op_idx]?._id === opt_id
                                ? items?.options[op_idx]?.name
                                : "";
                            return (
                              <View
                                key={index + "_" + index2}
                                style={[
                                  styles.tableData,
                                  {
                                    display: "flex",
                                    flexDirection: "row",
                                    flexWrap: "wrap",
                                  },
                                ]}
                              >
                                <Text style={{ fontSize: 9 }}>
                                  {Number(point).toFixed(1) + " " + name}
                                </Text>
                              </View>
                            );
                          })}
                        </View>
                      ))}
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          borderTop: "1px solid #e2e8f0",
                        }}
                      >
                        <View
                          style={[
                            styles.tableData,
                            {
                              display: "flex",
                              flexDirection: "row",
                              flexWrap: "wrap",
                              backgroundColor: "#f8fafc",
                            },
                          ]}
                        >
                          <Text
                            style={[
                              {
                                fontWeight: 500,
                                width: "100%",
                                fontSize: 10,
                                lineHeight: 1,
                              },
                            ]}
                          >
                            Overall Score
                          </Text>
                          <Text
                            style={[
                              {
                                fontSize: 8,
                                fontFamily: "RobotoMedium",
                                marginTop: 2,
                              },
                            ]}
                          >
                            (1-5 Scale; 5 = Best)
                          </Text>
                        </View>
                        {row.successors_details.map((item, index2) => {
                          return (
                            <>
                              <View
                                key={index2}
                                style={[
                                  styles.tableData,
                                  {
                                    display: "flex",
                                    flexDirection: "row",
                                    flexWrap: "wrap",
                                    backgroundColor: "#f8fafc",
                                  },
                                ]}
                              >
                                <Text style={{ fontSize: 9, fontWeight: 700 }}>
                                  {item?.overall_score
                                    ? Number(item?.overall_score).toFixed(2)
                                    : 0.0}
                                </Text>
                              </View>
                            </>
                          );
                        })}
                      </View>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          borderTop: "1px solid #e2e8f0",
                        }}
                      >
                        <View
                          style={[
                            styles.tableData,
                            {
                              display: "flex",
                              flexDirection: "row",
                              flexWrap: "wrap",
                            },
                          ]}
                        >
                          <Text
                            style={[
                              {
                                fontWeight: 500,
                                width: "100%",
                                fontSize: 10,
                                lineHeight: 1,
                              },
                            ]}
                          >
                            Additional Comments
                          </Text>
                          <Text
                            style={[
                              {
                                fontSize: 8,
                                fontFamily: "RobotoMedium",
                                marginTop: 2,
                              },
                            ]}
                          ></Text>
                        </View>
                        {row.successors_details.map((item, index2) => {
                          return (
                            <>
                              <View
                                key={index2}
                                style={[
                                  styles.tableData,
                                  {
                                    display: "flex",
                                    flexDirection: "row",
                                    flexWrap: "wrap",
                                  },
                                ]}
                              >
                                <Text style={{ fontSize: 9 }}>
                                  {item?.concern_additional_comments
                                    ? item?.concern_additional_comments
                                    : ""}
                                </Text>
                              </View>
                            </>
                          );
                        })}
                      </View>
                    </View>
                  )}
              </>
            );
          })}
      </Page>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <View style={[styles.section, { color: "white" }]}>
          <Text>Predictors | Step 3</Text>
        </View>
        <View
          style={{
            paddingBottom: 20,
            width: "100%",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <View
            style={[
              styles.tableOther,
              { display: "flex", flexDirection: "row", marginTop: 15 },
            ]}
          >
            <Text
              style={[
                styles.tableOtherData,
                {
                  display: "flex",
                  flexDirection: "row",
                  fontFamily: "RobotoMedium",
                  fontSize: 16,
                  textTransform: "capitalize",
                },
              ]}
            >
              Succession Plan Summary for{" "}
              {successors && successors.length > 0
                ? successors[0]?.pos_details[0]?.name
                : ""}
            </Text>
          </View>
        </View>
        {successors &&
          successors.length > 0 &&
          successors.map((row, key) => {
            return (
              <>
                {row.successors_details && (
                  <View
                    style={[
                      styles.tableOther,
                      {
                        display: "flex",
                        flexDirection: "row",
                        columnGap: 20,
                        marginTop: 5,
                        marginBottom: 5,
                        textAlign: "center",
                        justifyContent: "center",
                      },
                    ]}
                    key={key}
                  >
                    <Text
                      style={[
                        styles.tableOtherData,
                        {
                          display: "flex",
                          flexDirection: "row",
                          fontWeight: 600,
                          fontSize: 14,
                          textAlign: "center",
                        },
                      ]}
                    >
                      Potential Predictors
                    </Text>
                  </View>
                )}
                {row.successors_details &&
                  row.successors_details.length > 0 && (
                    <View
                      style={{
                        border: "1px solid #e2e8f0",
                        borderRadius: 6,
                        overflow: "hidden",
                        marginBottom: 30,
                      }}
                      key={key}
                    >
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          backgroundColor: "#f8fafc",
                          overflow: "hidden",
                        }}
                      >
                        <Text
                          style={[
                            styles.tableHeading,
                            { fontFamily: "RobotoMedium", fontSize: 10 },
                          ]}
                        >
                          &nbsp;
                        </Text>
                        {row.successors_details.map((item, index) => (
                          <View
                            key={index}
                            style={[
                              styles.tableHeading,
                              {
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                              },
                            ]}
                          >
                            <Text
                              style={{
                                width: "100%",
                                fontFamily: "RobotoMedium",
                                fontSize: 10,
                              }}
                            >
                              {item.name}
                            </Text>
                            <Text style={{ fontSize: 8, marginTop: 2 }}>
                              {item?.position_details
                                ? item?.position_details[0]?.short_name
                                : "N/A"}
                            </Text>
                          </View>
                        ))}
                      </View>
                      {predictorsHeading.map((items, index) => (
                        <View
                          key={index}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            borderTop: "1px solid #e2e8f0",
                          }}
                        >
                          <View
                            style={[
                              styles.tableData,
                              {
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                              },
                            ]}
                          >
                            <Text
                              style={[
                                {
                                  fontWeight: 500,
                                  width: "100%",
                                  fontSize: 10,
                                  lineHeight: 1,
                                },
                              ]}
                            >
                              {items?.heading_name
                                ? items?.heading_name
                                : items?.name}
                            </Text>
                            <Text
                              style={[
                                {
                                  fontSize: 8,
                                  fontFamily: "RobotoMedium",
                                  marginTop: 2,
                                },
                              ]}
                            >
                              {items.heading_description
                                ? "(" + items?.heading_description + ")"
                                : "(" + items?.description + ")"}
                            </Text>
                            <Text
                              style={[
                                {
                                  fontSize: 8,
                                  fontFamily: "RobotoMedium",
                                  marginTop: 2,
                                  color: "red",
                                },
                              ]}
                            >
                              Weight:{" "}
                              {items.company_weight
                                ? items?.company_weight + "%"
                                : items?.weight + "%"}
                            </Text>
                          </View>
                          {row.successors_details.map((item, index2) => {
                            let h_idx = -1;
                            let opt_id = "";
                            let op_idx = -1;
                            if (item?.predictors) {
                              h_idx = item?.predictors.findIndex(
                                (p) => p.heading_id === items?._id
                              );
                              opt_id = item?.predictors[h_idx]?.option_id;
                              op_idx = items?.options.findIndex(
                                (op) => op._id === opt_id
                              );
                            }
                            let name =
                              index === h_idx &&
                              items?.options[op_idx]?._id === opt_id
                                ? items?.options[op_idx]?.name
                                : "";
                            return (
                              <View
                                key={index + "_" + index2}
                                style={[
                                  styles.tableData,
                                  {
                                    display: "flex",
                                    flexDirection: "row",
                                    flexWrap: "wrap",
                                  },
                                ]}
                              >
                                <Text style={{ fontSize: 9 }}>{name}</Text>
                              </View>
                            );
                          })}
                        </View>
                      ))}
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          borderTop: "1px solid #e2e8f0",
                        }}
                      >
                        <View
                          style={[
                            styles.tableData,
                            {
                              display: "flex",
                              flexDirection: "row",
                              flexWrap: "wrap",
                              backgroundColor: "#f8fafc",
                            },
                          ]}
                        >
                          <Text
                            style={[
                              {
                                fontWeight: 500,
                                width: "100%",
                                fontSize: 10,
                                lineHeight: 1,
                              },
                            ]}
                          >
                            Overall Score
                          </Text>
                          <Text
                            style={[
                              {
                                fontSize: 8,
                                fontFamily: "RobotoMedium",
                                marginTop: 2,
                              },
                            ]}
                          >
                            Weight:{" "}
                            {sumTotal(
                              predictorsHeading,
                              "weight",
                              "company_weight"
                            )}
                            %
                          </Text>
                        </View>
                        {row.successors_details.map((item, index2) => {
                          return (
                            <>
                              <View
                                key={index2}
                                style={[
                                  styles.tableData,
                                  {
                                    display: "flex",
                                    flexDirection: "row",
                                    flexWrap: "wrap",
                                    backgroundColor: "#f8fafc",
                                  },
                                ]}
                              >
                                <Text style={{ fontSize: 9, fontWeight: 700 }}>
                                  {item?.overall_score
                                    ? Number(
                                        item?.overall_weighted_score
                                      ).toFixed(2)
                                    : 0.0}
                                </Text>
                              </View>
                            </>
                          );
                        })}
                      </View>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          borderTop: "1px solid #e2e8f0",
                        }}
                      >
                        <View
                          style={[
                            styles.tableData,
                            {
                              display: "flex",
                              flexDirection: "row",
                              flexWrap: "wrap",
                            },
                          ]}
                        >
                          <Text
                            style={[
                              {
                                fontWeight: 500,
                                width: "100%",
                                fontSize: 10,
                                lineHeight: 1,
                              },
                            ]}
                          >
                            Additional Comments
                          </Text>
                          <Text
                            style={[
                              {
                                fontSize: 8,
                                fontFamily: "RobotoMedium",
                                marginTop: 2,
                              },
                            ]}
                          ></Text>
                        </View>
                        {row.successors_details.map((item, index2) => {
                          return (
                            <>
                              <View
                                key={index2}
                                style={[
                                  styles.tableData,
                                  {
                                    display: "flex",
                                    flexDirection: "row",
                                    flexWrap: "wrap",
                                  },
                                ]}
                              >
                                <Text style={{ fontSize: 9 }}>
                                  {item?.predictor_additional_comments
                                    ? item?.predictor_additional_comments
                                    : ""}
                                </Text>
                              </View>
                            </>
                          );
                        })}
                      </View>
                    </View>
                  )}
              </>
            );
          })}
      </Page>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <View style={[styles.section, { color: "white" }]}>
          <Text>Scorecard | Step 4</Text>
        </View>
        <View
          style={{
            paddingBottom: 20,
            width: "100%",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <View
            style={[
              styles.tableOther,
              { display: "flex", flexDirection: "row", marginTop: 15 },
            ]}
          >
            <Text
              style={[
                styles.tableOtherData,
                {
                  display: "flex",
                  flexDirection: "row",
                  fontFamily: "RobotoMedium",
                  fontSize: 16,
                  textTransform: "capitalize",
                },
              ]}
            >
              Succession Plan Summary for{" "}
              {successors && successors.length > 0
                ? successors[0]?.pos_details[0]?.name
                : ""}
            </Text>
          </View>
        </View>
        {successors &&
          successors.length > 0 &&
          successors.map((row, key) => {
            return (
              <>
                {row.successors_details && (
                  <View
                    style={[
                      styles.tableOther,
                      {
                        display: "flex",
                        flexDirection: "row",
                        columnGap: 20,
                        marginTop: 5,
                        marginBottom: 5,
                        textAlign: "center",
                        justifyContent: "center",
                      },
                    ]}
                    key={key}
                  >
                    <Text
                      style={[
                        styles.tableOtherData,
                        {
                          display: "flex",
                          flexDirection: "row",
                          fontWeight: 600,
                          fontSize: 14,
                          textAlign: "center",
                        },
                      ]}
                    >
                      Succession Plan Scorecard
                    </Text>
                  </View>
                )}
                {row.successors_details &&
                  row.successors_details.length > 0 && (
                    <View
                      style={{
                        border: "1px solid #e2e8f0",
                        borderRadius: 6,
                        overflow: "hidden",
                        marginBottom: 30,
                      }}
                      key={key}
                    >
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          backgroundColor: "#f8fafc",
                          overflow: "hidden",
                        }}
                      >
                        <Text
                          style={[
                            styles.tableHeading,
                            { fontFamily: "RobotoMedium", fontSize: 10 },
                          ]}
                        >
                          &nbsp;
                        </Text>
                        {row.successors_details.map((item, index) => (
                          <View
                            key={index}
                            style={[
                              styles.tableHeading,
                              {
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                              },
                            ]}
                          >
                            <Text
                              style={{
                                width: "100%",
                                fontFamily: "RobotoMedium",
                                fontSize: 10,
                              }}
                            >
                              {item.name}
                            </Text>
                            <Text style={{ fontSize: 8, marginTop: 2 }}>
                              {item?.position_details
                                ? item?.position_details[0]?.short_name
                                : "N/A"}
                            </Text>
                          </View>
                        ))}
                      </View>
                      {heading.map((items, index) => (
                        <View
                          key={index}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            borderTop: "1px solid #e2e8f0",
                          }}
                        >
                          <View
                            style={[
                              styles.tableData,
                              {
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                              },
                            ]}
                          >
                            <Text
                              style={[
                                {
                                  fontWeight: 500,
                                  width: "100%",
                                  fontSize: 10,
                                  lineHeight: 1,
                                },
                              ]}
                            >
                              {items.name}
                            </Text>
                            <Text
                              style={[
                                {
                                  fontSize: 8,
                                  fontFamily: "RobotoMedium",
                                  marginTop: 2,
                                  color: "red",
                                },
                              ]}
                            >
                              {items.option}
                            </Text>
                          </View>

                          {row.successors_details.map((item, index2) => (
                            <View
                              key={index + "_" + index2}
                              style={[
                                styles.tableData,
                                {
                                  display: "flex",
                                  flexDirection: "row",
                                  flexWrap: "wrap",
                                },
                              ]}
                            >
                              {index === 0 && (
                                <Text style={{ fontSize: 9 }}>
                                  {item?.experience_details &&
                                  item?.experience_details.length > 0
                                    ? item?.experience_details[0]?.name
                                    : "N/A"}
                                </Text>
                              )}
                              {index === 1 && (
                                <Text style={{ fontSize: 9 }}>
                                  {item?.education_details &&
                                  item?.education_details.length > 0
                                    ? item?.education_details[0]?.name
                                    : "N/A"}
                                </Text>
                              )}
                              {index === 2 && (
                                <Text style={{ fontSize: 9 }}>
                                  {item?.performance_details &&
                                  item?.performance_details.length > 0
                                    ? item?.performance_details[0]?.name +
                                      " " +
                                      (item?.performance_details[0].number
                                        ? "(" +
                                          item?.performance_details[0].number +
                                          ")"
                                        : "")
                                    : "N/A"}
                                </Text>
                              )}
                              {index === 3 &&
                                (item?.loyalty_details.length > 0 &&
                                item?.loyalty_details[0].isPass ? (
                                  <Text style={{ fontSize: 9 }}>
                                    {Array.isArray(item?.loyalty_details) &&
                                    item?.loyalty_details.length > 0
                                      ? item?.loyalty_details[0]?.isPass
                                        ? "Pass: "
                                        : "Fail: "
                                      : ""}
                                    {item?.loyalty_details &&
                                    item?.loyalty_details.length > 0
                                      ? item?.loyalty_details[0]?.name
                                      : "N/A"}
                                  </Text>
                                ) : (
                                  <Text style={{ fontSize: 9, color: "red" }}>
                                    {Array.isArray(item?.loyalty_details) &&
                                    item?.loyalty_details.length > 0
                                      ? item?.loyalty_details[0]?.isPass
                                        ? "Pass: "
                                        : "Fail: "
                                      : ""}
                                    {item?.loyalty_details &&
                                    item?.loyalty_details.length > 0
                                      ? item?.loyalty_details[0]?.name
                                      : "N/A"}
                                  </Text>
                                ))}
                              {index === 4 && (
                                <Text style={{ fontSize: 9 }}>
                                  {item?.cu_details &&
                                  item?.cu_details.length > 0
                                    ? item?.cu_details[0]?.name
                                    : "N/A"}
                                </Text>
                              )}
                              {index === 5 &&
                                (item.desire_details.length > 0 &&
                                item?.desire_details[0].isPass ? (
                                  <Text style={{ fontSize: 9 }}>
                                    {Array.isArray(item?.desire_details) &&
                                    item?.desire_details.length > 0
                                      ? item?.desire_details[0]?.isPass
                                        ? "Pass: "
                                        : "Fail: "
                                      : ""}
                                    {item?.desire_details &&
                                    item?.desire_details.length > 0
                                      ? item?.desire_details[0]?.name
                                      : "N/A"}
                                  </Text>
                                ) : (
                                  <Text style={{ fontSize: 9, color: "red" }}>
                                    {Array.isArray(item?.desire_details) &&
                                    item?.desire_details.length > 0
                                      ? item?.desire_details[0]?.isPass
                                        ? "Pass: "
                                        : "Fail: "
                                      : ""}
                                    {item?.desire_details &&
                                    item?.desire_details.length > 0
                                      ? item?.desire_details[0]?.name
                                      : "N/A"}
                                  </Text>
                                ))}
                              {index === 6 &&
                                (item.retirement_details.length > 0 &&
                                item?.retirement_details[0].isPass ? (
                                  <Text style={{ fontSize: 9 }}>
                                    {Array.isArray(item?.retirement_details) &&
                                    item?.retirement_details.length > 0
                                      ? item?.retirement_details[0]?.isPass
                                        ? "Pass: "
                                        : "Fail: "
                                      : ""}
                                    {item?.retirement_details &&
                                    item?.retirement_details.length > 0
                                      ? item?.retirement_details[0]?.name
                                      : "N/A"}
                                  </Text>
                                ) : (
                                  <Text style={{ fontSize: 9, color: "red" }}>
                                    {Array.isArray(item?.retirement_details) &&
                                    item?.retirement_details.length > 0
                                      ? item?.retirement_details[0]?.isPass
                                        ? "Pass: "
                                        : "Fail: "
                                      : ""}
                                    {item?.retirement_details &&
                                    item?.retirement_details.length > 0
                                      ? item?.retirement_details[0]?.name
                                      : "N/A"}
                                  </Text>
                                ))}
                              {index === 7 && (
                                <Text style={{ fontSize: 9 }}>
                                  {item?.overall_score
                                    ? item?.overall_score
                                    : 0}
                                </Text>
                              )}
                              {index === 8 && (
                                <Text style={{ fontSize: 9 }}>
                                  {item?.overall_weighted_score
                                    ? item?.overall_weighted_score
                                    : 0}
                                </Text>
                              )}
                              {index === 9 && (
                                <Text style={{ fontSize: 9 }}>
                                  {Number(item?.overallWeightScore)
                                    ? Number(item?.overallWeightScore).toFixed(
                                        2
                                      )
                                    : 0.0}
                                </Text>
                              )}
                              {index === 10 && (
                                <Text style={{ fontSize: 9 }}>
                                  {item?.timeframe_details &&
                                    item?.timeframe_details.length > 0 &&
                                    item?.timeframe_details[0]?.name}
                                  {"\n"}
                                  {item?.competencyfits_details &&
                                    item?.competencyfits_details.length > 0 &&
                                    item?.competencyfits_details[0]?.name}
                                  {"\n"}
                                  {item?.advancement_additional_comments &&
                                    item?.advancement_additional_comments}
                                </Text>
                              )}
                            </View>
                          ))}
                        </View>
                      ))}
                    </View>
                  )}
              </>
            );
          })}
      </Page>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <View style={[styles.section, { color: "white" }]}>
          <Text>Development Plan | Step 6</Text>
        </View>
        <View
          style={{
            paddingBottom: 20,
            width: "100%",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <View
            style={[
              styles.tableOther,
              { display: "flex", flexDirection: "row", marginTop: 15 },
            ]}
          >
            <Text
              style={[
                styles.tableOtherData,
                {
                  display: "flex",
                  flexDirection: "row",
                  fontFamily: "RobotoMedium",
                  fontSize: 16,
                  textTransform: "capitalize",
                },
              ]}
            >
              Succession Plan Summary for{" "}
              {successors && successors.length > 0
                ? successors[0]?.pos_details[0]?.name
                : ""}
            </Text>
          </View>
        </View>
        <View
          style={[
            styles.tableOther,
            {
              display: "flex",
              flexDirection: "row",
              columnGap: 20,
              marginTop: 5,
              marginBottom: 5,
              textAlign: "center",
              justifyContent: "center",
            },
          ]}
        >
          <Text
            style={[
              styles.tableOtherData,
              {
                display: "flex",
                flexDirection: "row",
                fontWeight: 600,
                fontSize: 14,
                textAlign: "center",
              },
            ]}
          >
            {t("development_plan")}
          </Text>
        </View>
        {successors &&
          successors.length > 0 &&
          successors.map((value, idx) => {
            return (
              <>
                <View style={[{ marginTop: 10, marginBottom: 10 }]}>
                  {value.successors_details &&
                    value.successors_details.length > 0 &&
                    value.successors_details.map((row, key) => (
                      <>
                        <View
                          style={[
                            styles.tableOther,
                            {
                              display: "flex",
                              flexDirection: "row",
                              columnGap: 20,
                              marginTop: 5,
                              marginBottom: 5,
                            },
                          ]}
                          key={idx}
                        >
                          <Text
                            style={[
                              styles.tableOtherData,
                              {
                                display: "flex",
                                flexDirection: "row",
                                fontFamily: "RobotoMedium",
                                fontSize: 12,
                              },
                            ]}
                          >
                            {row.name} (
                            {row?.position_details
                              ? row?.position_details[0]?.name
                              : "N/A"}
                            )
                          </Text>
                        </View>
                        <View
                          style={{
                            border: "1px solid #e2e8f0",
                            borderRadius: 6,
                            overflow: "hidden",
                          }}
                          key={key}
                        >
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              backgroundColor: "#0f172a",
                              color: "#ffffff",
                              overflow: "hidden",
                            }}
                          >
                            <View style={[styles.developmentTableHeading, {}]}>
                              <Text
                                style={[
                                  styles.developmentTableText,
                                  { fontSize: 10 },
                                ]}
                              >
                                Needed Skills & Abilities
                              </Text>
                            </View>
                            <View style={[styles.developmentTableHeading, {}]}>
                              <Text
                                style={[
                                  styles.developmentTableText,
                                  { fontSize: 10 },
                                ]}
                              >
                                Needed Experience or Seasoning
                              </Text>
                            </View>
                            <View style={[styles.developmentTableHeading, {}]}>
                              <Text
                                style={[
                                  styles.developmentTableText,
                                  { fontSize: 10 },
                                ]}
                              >
                                Needed Education
                              </Text>
                            </View>
                          </View>
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              borderTop: "1px solid #e2e8f0",
                            }}
                          >
                            {row.plan_skills && row.plan_skills.length > 0 && (
                              <View
                                style={[
                                  styles.developmentTableData,
                                  {
                                    flexDirection: "column",
                                    padding: 8,
                                    marginTop: "8px",
                                  },
                                ]}
                                key={key}
                              >
                                {row.plan_skills.map(
                                  (skill_item, skill_index) => (
                                    <View
                                      style={
                                        skill_index === 0
                                          ? { display: "flex", fontSize: 9 }
                                          : {
                                              display: "flex",
                                              borderTop: "1px solid #e2e8f0",
                                              marginTop: "8px",
                                              paddingTop: "8px",
                                            }
                                      }
                                      key={key + "_" + skill_index}
                                    >
                                      <Text
                                        key={key + "_" + skill_index}
                                        style={{ fontSize: 9, marginTop: 2 }}
                                      >
                                        {skill_item}
                                      </Text>
                                    </View>
                                  )
                                )}
                              </View>
                            )}
                            {row.plan_experiences &&
                              row.plan_experiences.length > 0 && (
                                <View
                                  style={[
                                    styles.developmentTableData,
                                    { flexDirection: "column", padding: 8 },
                                  ]}
                                  key={key}
                                >
                                  {row.plan_experiences.map(
                                    (experience_item, experience_index) => (
                                      <View
                                        style={{ display: "flex", fontSize: 9 }}
                                        key={key + "_" + experience_index}
                                      >
                                        <Text
                                          key={key + "_" + experience_index}
                                          style={
                                            experience_index === 0
                                              ? { display: "flex", fontSize: 9 }
                                              : {
                                                  display: "flex",
                                                  borderTop:
                                                    "1px solid #e2e8f0",
                                                  marginTop: "8px",
                                                  paddingTop: "8px",
                                                }
                                          }
                                        >
                                          {experience_item}
                                        </Text>
                                      </View>
                                    )
                                  )}
                                </View>
                              )}
                            {row.plan_educations &&
                              row.plan_educations.length > 0 && (
                                <View
                                  style={[
                                    styles.developmentTableData,
                                    { flexDirection: "column", padding: 8 },
                                  ]}
                                  key={key}
                                >
                                  {row.plan_educations.map(
                                    (education_item, education_index) => (
                                      <View
                                        style={{ display: "flex", fontSize: 9 }}
                                        key={key + "_" + education_index}
                                      >
                                        <Text
                                          key={key + "_" + education_index}
                                          style={
                                            education_index === 0
                                              ? { display: "flex", fontSize: 9 }
                                              : {
                                                  display: "flex",
                                                  borderTop:
                                                    "1px solid #e2e8f0",
                                                  marginTop: "8px",
                                                  paddingTop: "8px",
                                                }
                                          }
                                        >
                                          {education_item}
                                        </Text>
                                      </View>
                                    )
                                  )}
                                </View>
                              )}
                          </View>
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              borderTop: "1px solid #e2e8f0",
                            }}
                          >
                            <View
                              style={[
                                styles.developmentTableData,
                                { flexDirection: "column", padding: 8 },
                              ]}
                            >
                              <View
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  fontSize: 9,
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 9,
                                    fontFamily: "RobotoMedium",
                                  }}
                                >
                                  {" "}
                                  Timeframe:
                                </Text>
                                <Text style={{ fontSize: 9 }}>
                                  {" "}
                                  {row?.plan_skills_timeframe_details &&
                                  row?.plan_skills_timeframe_details.length > 0
                                    ? row?.plan_skills_timeframe_details[0]
                                        ?.name
                                    : "N/A"}
                                </Text>
                              </View>
                            </View>
                            <View
                              style={[
                                styles.developmentTableData,
                                { flexDirection: "column", padding: 8 },
                              ]}
                            >
                              <View
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  fontSize: 9,
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 9,
                                    fontFamily: "RobotoMedium",
                                  }}
                                >
                                  {" "}
                                  Timeframe:
                                </Text>
                                <Text style={{ fontSize: 9 }}>
                                  {" "}
                                  {row?.plan_experiences_timeframe_details &&
                                  row?.plan_experiences_timeframe_details
                                    .length > 0
                                    ? row?.plan_experiences_timeframe_details[0]
                                        ?.name
                                    : "N/A"}
                                </Text>
                              </View>
                            </View>
                            <View
                              style={[
                                styles.developmentTableData,
                                { flexDirection: "column", padding: 8 },
                              ]}
                            >
                              <View
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  fontSize: 9,
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 9,
                                    fontFamily: "RobotoMedium",
                                  }}
                                >
                                  {" "}
                                  Timeframe:
                                </Text>
                                <Text style={{ fontSize: 9 }}>
                                  {" "}
                                  {row?.plan_educations_timeframe_details &&
                                  row?.plan_educations_timeframe_details
                                    .length > 0
                                    ? row?.plan_educations_timeframe_details[0]
                                        ?.name
                                    : "N/A"}
                                </Text>
                              </View>
                            </View>
                          </View>
                        </View>
                      </>
                    ))}
                </View>
              </>
            );
          })}
      </Page>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <View style={[styles.section, { color: "white" }]}>
          <Text>Advance Readiness | Step 7</Text>
        </View>
        <View
          style={{
            paddingBottom: 20,
            width: "100%",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <View
            style={[
              styles.tableOther,
              { display: "flex", flexDirection: "row", marginTop: 15 },
            ]}
          >
            <Text
              style={[
                styles.tableOtherData,
                {
                  display: "flex",
                  flexDirection: "row",
                  fontFamily: "RobotoMedium",
                  fontSize: 16,
                  textTransform: "capitalize",
                },
              ]}
            >
              Succession Plan Summary for{" "}
              {successors && successors.length > 0
                ? successors[0]?.pos_details[0]?.name
                : ""}
            </Text>
          </View>
        </View>
        {successors &&
          successors.length > 0 &&
          successors.map((row, key) => {
            return (
              <>
                <View style={[{ marginBottom: 2, marginTop: 2 }]}></View>
                {row.successors_details &&
                  row.successors_details.length > 0 && (
                    <>
                      <View
                        style={[
                          styles.tableOther,
                          {
                            display: "flex",
                            flexDirection: "row",
                            columnGap: 20,
                            marginTop: 5,
                            marginBottom: 5,
                            textAlign: "center",
                            justifyContent: "center",
                          },
                        ]}
                        key={key}
                      >
                        <Text
                          style={[
                            styles.tableOtherData,
                            {
                              display: "flex",
                              flexDirection: "row",
                              fontWeight: 600,
                              fontSize: 14,
                              textAlign: "center",
                            },
                          ]}
                        >
                          Position Competency and Readiness for {row.position}
                        </Text>
                      </View>
                      <View
                        style={[
                          styles.tableOther,
                          {
                            display: "flex",
                            flexDirection: "row",
                            columnGap: 20,
                            marginTop: 5,
                            marginBottom: 5,
                            textAlign: "center",
                            justifyContent: "center",
                          },
                        ]}
                        key={key}
                      >
                        <Text
                          style={[
                            styles.tableOtherData,
                            {
                              display: "flex",
                              flexDirection: "row",
                              fontWeight: 600,
                              fontSize: 10,
                              textAlign: "center",
                            },
                          ]}
                        >
                          {row.position_competencies}
                        </Text>
                      </View>
                    </>
                  )}
                {row.successors_details &&
                  row.successors_details.length > 0 && (
                    <View
                      style={{
                        border: "1px solid #e2e8f0",
                        borderRadius: 6,
                        overflow: "hidden",
                        marginBottom: 30,
                      }}
                      key={key}
                    >
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          backgroundColor: "#f8fafc",
                          overflow: "hidden",
                        }}
                      >
                        <Text
                          style={[
                            styles.tableHeading,
                            { fontFamily: "RobotoMedium", fontSize: 10 },
                          ]}
                        >
                          &nbsp;
                        </Text>
                        {row.successors_details.map((item, index) => (
                          <View
                            key={index}
                            style={[
                              styles.tableHeading,
                              {
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                              },
                            ]}
                          >
                            <Text
                              style={{
                                width: "100%",
                                fontFamily: "RobotoMedium",
                                fontSize: 10,
                              }}
                            >
                              {item.name}
                            </Text>
                            <Text style={{ fontSize: 8, marginTop: 2 }}>
                              {item?.position_details
                                ? item?.position_details[0]?.short_name
                                : "N/A"}
                            </Text>
                          </View>
                        ))}
                      </View>
                      {readinessHeading.map((items, index) => (
                        <View
                          key={index}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            borderTop: "1px solid #e2e8f0",
                          }}
                        >
                          <View
                            style={[
                              styles.tableData,
                              {
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                              },
                            ]}
                          >
                            <Text
                              style={[
                                {
                                  fontWeight: 500,
                                  width: "100%",
                                  fontSize: 10,
                                  lineHeight: 1,
                                },
                              ]}
                            >
                              {items?.name}
                            </Text>
                          </View>

                          {row.successors_details.map((item, index2) => (
                            <View
                              key={index + "_" + index2}
                              style={[
                                styles.tableData,
                                {
                                  display: "flex",
                                  flexDirection: "row",
                                  flexWrap: "wrap",
                                },
                              ]}
                            >
                              {index === 0 && (
                                <Text style={{ fontSize: 9 }}>
                                  {item?.timeframe_details &&
                                    item?.timeframe_details.length > 0 &&
                                    item?.timeframe_details[0]?.name}
                                </Text>
                              )}
                              {index === 1 && (
                                <Text style={{ fontSize: 9 }}>
                                  {item?.competencyfits_details &&
                                    item?.competencyfits_details.length > 0 &&
                                    item?.competencyfits_details[0]?.name}
                                </Text>
                              )}
                              {index === 2 && (
                                <Text style={{ fontSize: 9 }}>
                                  {item?.cultural_details &&
                                    item?.cultural_details.length > 0 &&
                                    item?.cultural_details[0]?.name}
                                </Text>
                              )}
                              {index === 3 && (
                                <Text style={{ fontSize: 9 }}>
                                  {item?.advancement_additional_comments}
                                </Text>
                              )}
                              {index === 4 &&
                              item?.desireResult &&
                              item?.desireResult === 2 ? (
                                <Text style={{ fontSize: 9, color: "red" }}>
                                  {item?.desire_details[0]?.name}
                                </Text>
                              ) : (
                                <Text style={{ fontSize: 9, color: "red" }}>
                                  ""
                                </Text>
                              )}
                              {index === 4 &&
                              item?.loyaltyResult &&
                              item?.loyaltyResult === 2 ? (
                                <Text style={{ fontSize: 9, color: "red" }}>
                                  {item?.loyalty_details[0]?.name}
                                </Text>
                              ) : (
                                <Text style={{ fontSize: 9, color: "red" }}>
                                  ""
                                </Text>
                              )}
                              {index === 4 &&
                              item?.RetirementResult &&
                              item?.RetirementResult === 2 ? (
                                <Text style={{ fontSize: 9, color: "red" }}>
                                  {item?.retirement_details[0]?.name}
                                </Text>
                              ) : (
                                <Text style={{ fontSize: 9, color: "red" }}>
                                  ""
                                </Text>
                              )}
                            </View>
                          ))}
                        </View>
                      ))}
                    </View>
                  )}
              </>
            );
          })}
      </Page>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <View style={[styles.section, { color: "white" }]}>
          <Text>Conclusion | Step 8</Text>
        </View>
        <View
          style={{
            paddingBottom: 20,
            width: "100%",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <View
            style={[
              styles.tableOther,
              { display: "flex", flexDirection: "row", marginTop: 15 },
            ]}
          >
            <Text
              style={[
                styles.tableOtherData,
                {
                  display: "flex",
                  flexDirection: "row",
                  fontFamily: "RobotoMedium",
                  fontSize: 16,
                  textTransform: "capitalize",
                },
              ]}
            >
              Succession Plan Summary for{" "}
              {successors && successors.length > 0
                ? successors[0]?.pos_details[0]?.name
                : ""}
            </Text>
          </View>
        </View>
        <View
          style={[
            styles.tableOther,
            {
              display: "flex",
              flexDirection: "row",
              columnGap: 20,
              marginTop: 5,
              marginBottom: 5,
              textAlign: "center",
              justifyContent: "center",
            },
          ]}
        >
          <Text
            style={[
              styles.tableOtherData,
              {
                display: "flex",
                flexDirection: "row",
                fontWeight: 600,
                fontSize: 14,
                textAlign: "center",
              },
            ]}
          >
            Conclusion for {user?.title}{" "}
          </Text>
        </View>
        <View
          style={{
            border: "1px solid #e2e8f0",
            borderRadius: 6,
            overflow: "hidden",
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              backgroundColor: "#0f172a",
              color: "#ffffff",
              overflow: "hidden",
            }}
          >
            <Text style={[styles.conclusionTableHeading, { fontWeight: 600 }]}>
              Choices
            </Text>
            <View
              style={[
                styles.conclusionTableHeading,
                {
                  fontWeight: 600,
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                },
              ]}
            >
              <Text style={{ fontWeight: 600, width: "100%" }}>
                Time Horizon{" "}
              </Text>
              <Text style={{ fontSize: 8, width: "100%" }}>(If 0-1 Year)</Text>
            </View>
            <View
              style={[
                styles.conclusionTableHeading,
                {
                  fontWeight: 600,
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                },
              ]}
            >
              <Text style={{ fontWeight: 600, width: "100%" }}>
                Time Horizon{" "}
              </Text>
              <Text style={{ fontSize: 8, width: "100%" }}>(If 1-3 Year)</Text>
            </View>
            <View
              style={[
                styles.conclusionTableHeading,
                {
                  fontWeight: 600,
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                },
              ]}
            >
              <Text style={{ fontWeight: 600, width: "100%" }}>
                Time Horizon{" "}
              </Text>
              <Text style={{ fontSize: 8, width: "100%" }}>(If 3-5 Year)</Text>
            </View>
            <View
              style={[
                styles.conclusionTableHeading,
                {
                  fontWeight: 600,
                  display: "flex",
                  backgroundColor: "#ef4444",
                  flexDirection: "row",
                  color: "white",
                  flexWrap: "wrap",
                },
              ]}
            >
              <Text style={{ fontWeight: 600 }}>
                Emergency or Interim Replacement
              </Text>
            </View>
          </View>

          {conclusion &&
            conclusion.length > 0 &&
            conclusion.map((item, index) => {
              let time_horizon_zero_to_one_record =
                successors.length > 0 &&
                successors[0]?.successors_details?.filter(
                  (rv) => rv._id === item?.time_horizon_zero_to_one
                );
              let time_horizon_zero_to_one_name = "";
              let time_horizon_zero_to_one_score = 0;
              if (time_horizon_zero_to_one_record.length > 0) {
                time_horizon_zero_to_one_name =
                  successors.length > 0 &&
                  successors[0]?.successors_details?.find(
                    (rv) => rv._id === item?.time_horizon_zero_to_one
                  )?.name;
                time_horizon_zero_to_one_score =
                  successors.length > 0 &&
                  successors[0]?.successors_details?.find(
                    (rv) => rv._id === item?.time_horizon_zero_to_one
                  )?.overallWeightScore;
              }

              let time_horizon_one_to_three_record =
                successors.length > 0 &&
                successors[0]?.successors_details?.filter(
                  (rv) => rv._id === item?.time_horizon_one_to_three
                );
              let time_horizon_one_to_three_name = "";
              let time_horizon_one_to_three_score = "";
              if (time_horizon_one_to_three_record.length > 0) {
                time_horizon_one_to_three_name =
                  successors.length > 0 &&
                  successors[0]?.successors_details?.find(
                    (rv) => rv._id === item?.time_horizon_one_to_three
                  )?.name;
                time_horizon_one_to_three_score =
                  successors.length > 0 &&
                  successors[0]?.successors_details?.find(
                    (rv) => rv._id === item?.time_horizon_one_to_three
                  )?.overallWeightScore;
              }

              let time_horizon_three_to_five_record =
                successors.length > 0 &&
                successors[0]?.successors_details?.filter(
                  (rv) => rv._id === item?.time_horizon_three_to_five
                );
              let time_horizon_three_to_five_name = "";
              let time_horizon_three_to_five_score = "";
              if (time_horizon_three_to_five_record.length > 0) {
                time_horizon_three_to_five_name =
                  successors.length > 0 &&
                  successors[0]?.successors_details?.find(
                    (rv) => rv._id === item?.time_horizon_three_to_five
                  )?.name;
                time_horizon_three_to_five_score =
                  successors.length > 0 &&
                  successors[0]?.successors_details?.find(
                    (rv) => rv._id === item?.time_horizon_three_to_five
                  )?.overallWeightScore;
              }

              let replacement_record =
                successors.length > 0 &&
                successors[0]?.successors_details?.filter(
                  (rv) => rv._id === item?.replacement
                );
              let replacement_name = "";
              let replacement_score = "";
              if (replacement_record.length > 0) {
                replacement_name =
                  successors.length > 0 &&
                  successors[0]?.successors_details?.find(
                    (rv) => rv._id === item?.replacement
                  )?.name;
                replacement_score =
                  successors.length > 0 &&
                  successors[0]?.successors_details?.find(
                    (rv) => rv._id === item?.replacement
                  )?.overallWeightScore;
              }
              return (
                <View
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    borderTop: "1px solid #e2e8f0",
                  }}
                >
                  <Text
                    style={[styles.conclusionTableData, { fontWeight: 600 }]}
                  >
                    {item.name}
                  </Text>
                  <View
                    style={[
                      styles.conclusionTableData,
                      {
                        fontWeight: 600,
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                      },
                    ]}
                  >
                    <Text style={{ fontWeight: 600 }}>
                      {item.isComment
                        ? item?.comment_zero_to_one
                        : time_horizon_zero_to_one_record.length > 0
                        ? time_horizon_zero_to_one_name +
                          " (" +
                          (time_horizon_zero_to_one_score > 0
                            ? time_horizon_zero_to_one_score
                            : 0) +
                          ")"
                        : item?.time_horizon_zero_to_one_text}
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.conclusionTableData,
                      {
                        fontWeight: 600,
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                      },
                    ]}
                  >
                    <Text style={{ fontWeight: 600 }}>
                      {item.isComment
                        ? item?.comment_one_to_three
                        : time_horizon_one_to_three_record.length > 0
                        ? time_horizon_one_to_three_name +
                          " (" +
                          (time_horizon_one_to_three_score > 0
                            ? time_horizon_one_to_three_score
                            : 0) +
                          ")"
                        : item?.time_horizon_one_to_three_text}
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.conclusionTableData,
                      {
                        fontWeight: 600,
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                      },
                    ]}
                  >
                    <Text style={{ fontWeight: 600 }}>
                      {item.isComment
                        ? item?.comment_three_to_five
                        : time_horizon_three_to_five_record.length > 0
                        ? time_horizon_three_to_five_name +
                          " (" +
                          (time_horizon_three_to_five_score > 0
                            ? time_horizon_three_to_five_score
                            : 0) +
                          ")"
                        : item?.time_horizon_three_to_five_text}
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.conclusionTableData,
                      {
                        fontWeight: 600,
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                      },
                    ]}
                  >
                    <Text style={{ fontWeight: 600 }}>
                      {item.isComment
                        ? item?.comment_replacement
                        : replacement_record.length > 0
                        ? replacement_name +
                          " (" +
                          (replacement_score > 0 ? replacement_score : 0) +
                          ")"
                        : item?.replacement_text}
                    </Text>
                  </View>
                </View>
              );
            })}
        </View>
        <View style={{ marginTop: 10 }}>
          <View
            style={[
              styles.tableOther,
              { display: "flex", flexDirection: "row", marginTop: 10 },
            ]}
          >
            <Text
              style={[
                styles.conclusionTableOtherData,
                { display: "flex", flexDirection: "row", fontWeight: 500 },
              ]}
            >
              Date:
            </Text>
            <Text style={{ fontWeight: 700, fontSize: 12, marginLeft: 10 }}>
              {date ? moment(new Date(date)).format("YYYY/MM/DD") : ""}
            </Text>
          </View>
          {trimmedDataURL && (
            <View
              style={[
                styles.tableOther,
                { display: "flex", flexDirection: "row", marginTop: 10 },
              ]}
            >
              <Text
                style={[
                  styles.conclusionTableOtherData,
                  { display: "flex", flexDirection: "row", fontWeight: 500 },
                ]}
              >
                Signature:
              </Text>
              <Text style={{ fontWeight: 700, fontSize: 12, marginLeft: 10 }}>
                <Image style={styles.imageSignature} src={trimmedDataURL} />
              </Text>
            </View>
          )}
        </View>
      </Page>
    </>
  );
};

export default SuccessionPlanAllReport;

import React, { useState, useEffect, useCallback } from "react";
import { setTitle } from "../helpers/MetaTag";
import { useSelector } from "react-redux";

import { postData } from "../services/api";
import { useTranslation } from "react-i18next";
import { PDFDownloadLink } from "@react-pdf/renderer";
import BoardRetirementReportPdf from "./pdf/BoardRetirementReportPdf";
import { API_URL } from "../config/host";

const BoardRetirementReport = () => {
  setTitle(
    "Succession Planning Software for your Business with Succession Now | Retirement Report"
  );
  const { t } = useTranslation();
  const user = useSelector((state) => state.auth?.user);
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [boardMambers, setBoardMambers] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(0);

  const getDownload = (e) => {
    e.preventDefault();
    if (e.target.tagName.toLowerCase() === "a") {
      window.open(e.target.href, "_blank");
    }
  };

  const loadList = useCallback(async () => {
    setLoading(true);
    try {
      const result = await postData("board-member/list", {
        sortQuery: { created_at: 1 },
        outside_candidate: 2,
      });
      if (result.data) {
        const verifiedBoardMember =
          result?.data &&
          result.data?.length > 0 &&
          result.data?.filter((item) => item?.parent_id);
        setBoardMambers(
          result.data?.sort((a, b) => a.first_name.localeCompare(b.first_name))
        );
      }
      setTotalDataCount(result.count);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  }, [offset]);

  useEffect(() => {
    loadList();
  }, [loadList]);

  // console.log(boardMambers, "boardMambersboardMambersboardMambers");

  return (
    <>
      <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-4">
        <div>
          <div className="text-xl md:text-3xl text-slate-800 font-bold">
            {t("Board Retirement Report") + " - " + user?.company_name}
          </div>
        </div>
        <div className="sm:ml-auto flex justify-end items-center">
          <button
            type={"button"}
            className={
              "flex justify-center items-center gap-2 bg-teal-500 text-white rounded text-base sm:text-lg h-8 sm:h-10 py-0 px-2 sm:px-3 transition-all duration-200 cursor-pointer"
            }
            onClick={(e) => getDownload(e)}
          >
            <i className={"fa-fw fa-light fa-download"}></i>
            <div className={"text-xs sm:text-sm font-medium whitespace-nowrap"}>
              <PDFDownloadLink
                document={
                  <BoardRetirementReportPdf
                    user={user}
                    companyLogo={API_URL + user?.image}
                    boardMambers={boardMambers}
                  />
                }
                fileName="profile_report.pdf"
              >
                {({ blob, url, loading, error }) =>
                  loading ? t("loading_document") : t("download_pdf")
                }
              </PDFDownloadLink>
            </div>
          </button>
        </div>
      </div>
      <div className="relative flex justify-start items-center mb-4 space-x-2">
        {/* <div className="relative z-20">
          <DropdownCheckbox data={positions} functions={positionSelect} />
        </div> */}
        {/* <div className="relative z-20">
          <DropdownCheckbox data={users} functions={userSelect} />
        </div> */}
      </div>
      <div className="w-full overflow-auto scroll-smooth scrollbar">
        <table className="min-w-max w-full table-auto">
          <tbody className="text-slate-700 text-sm font-light bg-white">
            <tr className="bg-slate-100">
              <td className="py-3 px-2" colspan="11"></td>
            </tr>
            <tr className="text-white font-bold text-sm leading-none border-b border-slate-200">
              <th
                className="py-4 px-2 text-left text-sm max-w-[240px] z-10 bg-slate-900 rounded-tl-md"
                colspan="3"
              >
                Board Retirement Window
              </th>
              <th
                className="py-4 px-2 text-left max-w-[240px] z-10 bg-slate-900 rounded-tr-md"
                colspan="8"
              >
                Year
              </th>
            </tr>
            {boardMambers &&
              Array?.isArray(boardMambers) &&
              boardMambers?.length > 0 &&
              boardMambers?.map((item, index) => (
                <>
                  <tr className="border-b border-slate-200" key={index}>
                    <td
                      className="py-3 px-2 font-semibold text-sm text-left max-w-[240px] align-top"
                      colspan="3"
                    >
                      {item?.association_retirement_information === 1
                        ? item?.first_name + " " + item?.last_name
                        : "Anonymous"}
                      <span className="block text-xs font-bold leading-tight text-teal-500">
                        {item?.position_details?.name || "N/A"}
                      </span>
                    </td>
                    <td
                      className="py-3 px-2 text-left text-sm max-w-[240px] "
                      colspan="8"
                    >
                      {item?.retirement_window && item?.retirement_window > 1
                        ? item?.retirement_window + " Years"
                        : item?.retirement_window + " Year"}
                    </td>
                  </tr>
                </>
              ))}
            <tr className="bg-slate-100">
              <td className="py-3 px-2" colspan="11"></td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="w-full overflow-auto scroll-smooth scrollbar">
        <table className="min-w-max w-full table-auto">
          <tbody className="text-slate-700 text-sm font-light bg-white">
            <tr className="bg-slate-100">
              <td className="py-3 px-2" colspan="11"></td>
            </tr>
            <tr className="text-white font-bold text-sm leading-none border-b border-slate-200">
              <th className="py-4 px-2 text-left max-w-[240px] bg-slate-900 rounded-tl-md sm:rounded-none">
                Name
              </th>
              <th className="py-4 px-2 text-left max-w-[240px] bg-slate-900 rounded-tl-md sm:rounded-none">
                Primary Area of Expertise
              </th>
              <th className="py-4 px-2 text-left max-w-[240px] bg-slate-900">
                Primary Area of Expertise 2
              </th>
              <th className="py-4 px-2 text-left max-w-[240px] bg-slate-900">
                Primary Area of Expertise 3
              </th>
              <th className="py-4 px-2 text-left max-w-[240px] bg-slate-900 rounded-tr-md sm:rounded-none">
                Primary Area of Expertise 4
              </th>
            </tr>

            {boardMambers &&
              Array?.isArray(boardMambers) &&
              boardMambers?.length > 0 &&
              boardMambers?.map((item, index) => (
                <>
                  <tr className="border-b border-slate-200" key={index}>
                    <td className="py-3 px-2 font-semibold text-sm text-left max-w-[240px] align-top">
                      {item?.association_retirement_information === 1
                        ? item?.first_name + " " + item?.last_name
                        : "Anonymous"}
                      <span className="block text-sm font-bold leading-tight text-teal-500">
                        {item?.position_details?.name || "N/A"}
                      </span>
                    </td>
                    <td className="py-3 px-2 text-left text-sm max-w-[240px] ">
                      <div className="">
                        {item?.primary_expertise_details?.name || "N/A"}
                      </div>

                      <small className="text-dark-teal-600">
                        {item?.primary_experience
                          ? item?.primary_experience > 1
                            ? item?.primary_experience + " Years"
                            : item?.primary_experience + " Year"
                          : ""}
                      </small>
                    </td>
                    <td className="py-3 px-2 text-left text-sm max-w-[240px] ">
                      <div className="">
                        {item?.primary_expertise_two_details?.name || "N/A"}
                      </div>

                      <small className="text-dark-teal-600">
                        {item?.primary_experience_two
                          ? item?.primary_experience_two > 1
                            ? item?.primary_experience_two + " Years"
                            : item?.primary_experience_two + " Year"
                          : ""}
                      </small>
                    </td>
                    <td className="py-3 px-2 text-left text-sm max-w-[240px] ">
                      <div className="">
                        {item?.primary_expertise_three_details?.name || "N/A"}
                      </div>

                      <small className="text-dark-teal-600">
                        {item?.primary_experience_three
                          ? item?.primary_experience_three > 1
                            ? item?.primary_experience_three + " Years"
                            : item?.primary_experience_three + " Year"
                          : ""}
                      </small>
                    </td>
                    <td className="py-3 px-2 text-left text-sm max-w-[240px] ">
                      <div className="">
                        {item?.primary_expertise_four_details?.name || "N/A"}
                      </div>

                      <small className="text-dark-teal-600">
                        {item?.primary_experience_four
                          ? item?.primary_experience_four > 1
                            ? item?.primary_experience_four + " Years"
                            : item?.primary_experience_four + " Year"
                          : ""}
                      </small>
                    </td>
                  </tr>
                </>
              ))}
            <tr className="bg-slate-100">
              <td className="py-3 px-2" colspan="11"></td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default BoardRetirementReport;

import React, { useState, useEffect, Fragment } from "react";
import toastr from "toastr";
import { useNavigate, useParams } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { postData } from "../../services/api";
import Input from "../form/Input";
import Button from "../form/Button";
import { setTitle } from "../../helpers/MetaTag";
import Select from "../form/Select";
import Textarea from "../form/Textarea";

const AddEditPlanFeaturePopup = ({
  isOpen,
  setIsOpen = () => {},
  data,
  loadList = () => {},
  id,
}) => {
  if (id) {
    setTitle(
      "Succession Planning Software for your Business with Succession Now | Edit Plan Feature"
    );
  } else {
    setTitle(
      "Succession Planning Software for your Business with Succession Now | Add Plan Feature"
    );
  }
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const [details, setDetails] = useState({});
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [fieldtype, setFieldtype] = useState("");
  const [headingtype, setHeadingtype] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [isManage, setIsManage] = useState(false);

  const createAlias = (text) => {
    let result = text.toLowerCase().replace(/\s+/g, "-");
    setCode(result);
  };

  useEffect(() => {
    // Plan  feature details
    const loadDetails = async () => {
      try {
        const result = await postData("plan-feature/details", {
          id: id,
        });
        if (result.data) {
          setDetails(result.data);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadDetails();
  }, [id]);

  useEffect(() => {
    setName(details?.name);
    setFieldtype(details?.fieldType);
    setCode(details?.code);
    setShortDescription(details?.short_description);
    setHeadingtype(details?.headingType);
  }, [details]);

  const onSubmit = async (e) => {
    setIsManage(true);
    try {
      let path = "";
      let payload = "";
      if (id) {
        path = "plan-feature/update";
        payload = {
          id: id,
          name: name,
          fieldType: fieldtype,
          shortDescription: shortDescription,
          headingType: headingtype,
        };
      } else {
        path = "plan-feature/create";
        payload = {
          name: name,
          code: code,
          fieldType: fieldtype,
          shortDescription: shortDescription,
          headingType: headingtype,
        };
      }
      const result = await postData(path, payload);
      if (result.status && result.status === 200) {
        toastr.success(result.message);
        setIsManage(false);
        loadList();
        setIsOpen({ open: false, _id: null });
        // return navigate("/plan-features");
      } else {
        toastr.error(result.message);
        setIsManage(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setIsManage(false);
    }
  };
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-3xl transform rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900 text-center"
                >
                  {id ? t("edit_plan_feature") : t("add_plan_feature")}
                </Dialog.Title>
                <div className="absolute top-2 right-2 z-10">
                  <Button
                    buttonLabelClasses=""
                    buttonType={"button"}
                    buttonIcon={"fa-light fa-times"}
                    buttonIconPosition={"left"}
                    buttonLabel={""}
                    buttonClasses={
                      "!bg-transparent !text-slate-700 !w-10 !h-10 !p-0"
                    }
                    functions={() => {
                      setIsOpen({ _id: null, open: false });
                      setName("");
                      setCode("");
                      setFieldtype("");
                      setHeadingtype("");
                      setShortDescription("");
                    }}
                    buttonHasLink={false}
                  />
                </div>
                <div className="relative mt-4 border bg-white border-gray-200 p-4  rounded-xl">
                  <div className="relative grid grid-cols-2 gap-4">
                    <div className="col-span-12 lg:col-span-9 flex flex-col">
                      <div className="space-y-4">
                        <div
                          className={`grid ${
                            id ? "sm:grid-cols-1" : "sm:grid-cols-2"
                          } gap-4`}
                        >
                          <div className="relative w-full">
                            <Input
                              label={t("name")}
                              labelClasses={"!text-xs"}
                              inputType={"text"}
                              inputPlaceholder={t("enter_name")}
                              inputValue={name}
                              setInput={(value) => {
                                setName(value);
                                createAlias(value);
                              }}
                              requiredDisplay={true}
                            />
                          </div>
                          {!id && (
                            <div className="relative w-full">
                              <Input
                                label={t("code")}
                                labelClasses={"!text-xs"}
                                inputType={"text"}
                                inputPlaceholder={t("enter_code")}
                                inputValue={code}
                                setInput={setCode}
                                requiredDisplay={true}
                              />
                            </div>
                          )}
                        </div>
                        <div className="grid sm:grid-cols-2 gap-4">
                          <div className="relative w-full">
                            <Select
                              label={t("field_type")}
                              labelClasses={"!text-xs"}
                              xPlacement={"left"}
                              selectedValue={fieldtype}
                              dropdownClass={"!w-60"}
                              dropdownData={[
                                {
                                  _id: "",
                                  label: t("select_field_type"),
                                  value: "",
                                },
                                {
                                  _id: "input",
                                  label: t("input"),
                                  value: "input",
                                },
                                {
                                  _id: "checkbox",
                                  label: t("checkbox"),
                                  value: "checkbox",
                                },
                              ]}
                              getSelectedValue={(e) => setFieldtype(e._id)}
                              requiredDisplay={true}
                            />
                          </div>
                          <div className="relative w-full">
                            <Select
                              label={t("heading_type")}
                              labelClasses={"!text-xs"}
                              xPlacement={"left"}
                              selectedValue={headingtype}
                              dropdownClass={"!w-60"}
                              dropdownData={[
                                {
                                  _id: "",
                                  label: t("select_heading_type"),
                                  value: "",
                                },
                                {
                                  _id: "key-features",
                                  label: t("key_features"),
                                  value: "key-features",
                                },
                                {
                                  _id: "number-of-plans",
                                  label: t("number_of_plans"),
                                  value: "number-of-plans",
                                },
                                {
                                  _id: "additional-tools",
                                  label: t("additional_tools"),
                                  value: "additional-tools",
                                },
                              ]}
                              getSelectedValue={(e) => setHeadingtype(e._id)}
                              requiredDisplay={true}
                            />
                          </div>
                        </div>
                        <div className="relative w-full">
                          <Textarea
                            label={t("tooltip_content")}
                            labelClasses={"!text-xs"}
                            inputValue={shortDescription}
                            inputName={"enter_tooltip_content"}
                            inputPlaceholder={t("enter_tooltip_content")}
                            setTextarea={setShortDescription}
                          />
                        </div>
                      </div>

                      <div className="flex justify-end gap-4 mt-5">
                        <div className="flex items-center justify-end gap-4">
                          <Button
                            buttonClasses={
                              "bg-white border border-dark-teal-500 !text-dark-teal-500 ml-2"
                            }
                            buttonLabelClasses="text-dark-teal-500 "
                            buttonType={"button"}
                            buttonIcon={"fa-solid fa-xmark"}
                            buttonIconPosition={"left"}
                            buttonLabel={t("cancel")}
                            functions={() => {
                              setIsOpen({ _id: null, open: false });
                              setName("");
                              setCode("");
                              setFieldtype("");
                              setHeadingtype("");
                              setShortDescription("");
                            }}
                            buttonHasLink={false}
                          />
                          <Button
                            buttonClasses=""
                            buttonLabelClasses=""
                            buttonType={"button"}
                            buttonIcon={
                              isManage
                                ? "fa-light fa-spinner fa-spin"
                                : "fa-light fa-floppy-disk"
                            }
                            buttonIconPosition={"left"}
                            buttonLabel={t("save")}
                            functions={onSubmit}
                            buttonHasLink={false}
                            buttonDisabled={isManage}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AddEditPlanFeaturePopup;

import React, { useEffect, useRef, useState } from "react";

const Textarea = ({
  label,
  labelClasses,
  requiredDisplay = false,
  inputClasses,
  inputPlaceholder,
  inputValue,
  inputName,
  errorType,
  isDisable = false,
  isAddBullet = false,
  setTextarea = () => {},
  onBlur = () => {},
  ...props
}) => {
  const bullet = "\u2022";
  const bulletWithSpace = `${bullet} `;
  const textAr = useRef(null);

  const setTextVal = (event) => {
    if (textAr && isAddBullet) {
      if (event.which === 13) {
        if (
          textAr.current.value !== "" &&
          textAr.current.value !== undefined &&
          textAr.current.value !== bullet
        ) {
          const newTxtVal = textAr.current.value.split("\n");
          const newTxtArr = [];
          for (let index = 0; index < newTxtVal.length; index++) {
            if (newTxtVal[index].charAt(0) !== bullet) {
              newTxtArr[index] = `${bulletWithSpace}${newTxtVal[index]}`;
            } else {
              newTxtArr[index] = `${newTxtVal[index]}`;
            }
          }
          const textNew = newTxtArr.join("\n");
          textAr.current.value = `${textNew}`;
        } else {
          textAr.current.value += `${bulletWithSpace}`;
          var txtval = textAr.current.value;
          if (txtval.substr(txtval.length - 1) === "\n") {
            textAr.current.value = txtval.substring(0, txtval.length - 1);
          }
        }
      }
      if (textAr.current.value[0] !== bullet) {
        if (textAr.current.value[0] !== undefined) {
          textAr.current.value = `${bulletWithSpace}${textAr.current.value}`;
        }
      }
    }
  };

  return (
    <>
      {label && (
        <div
          className={"text-sm font-medium text-slate-500 mb-1 " + labelClasses}
        >
          {label}
          {requiredDisplay && (
            <span className="text-[8px] text-red-500 ml-0.5">
              <i className="fa-solid fa-asterisk"></i>
            </span>
          )}
        </div>
      )}
      <div className="relative flex">
        <textarea
          ref={textAr}
          disabled={isDisable}
          className={
            "w-full h-40 rounded bg-slate-50 border border-slate-200 px-3 text-sm text-slate-600 !ring-0 !outline-0 focus:border-gray-400 transition-all duration-200 resize-none overflow-auto scroll-smooth scrollbar " +
            inputClasses
          }
          placeholder={inputPlaceholder}
          name={inputName}
          value={inputValue}
          onChange={(e) => setTextarea(e.target.value)}
          onKeyUp={setTextVal}
          onBlur={(e) => onBlur(e.target.value)}
        ></textarea>
      </div>
      {errorType && errorType === "error" && (
        <div className="text-xs text-red-600 mt-1">
          <i className="fa-regular fa-fw fa-square-exclamation text-sm mr-1"></i>
          This field is required
        </div>
      )}
      {errorType && errorType === "warning" && (
        <div className="text-xs text-amber-600 mt-1">
          <i className="fa-regular fa-fw fa-triangle-exclamation text-sm mr-1"></i>
          This field has a warning
        </div>
      )}
      {errorType && errorType === "success" && (
        <div className="text-xs text-green-600 mt-1">
          <i className="fa-regular fa-fw fa-circle-check text-sm mr-1"></i>
          Verified
        </div>
      )}
    </>
  );
};

export default Textarea;

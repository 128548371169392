import React, { useState, useEffect, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Button from "../form/Button";
import Checkbox from "../form/Checkbox";
import NoDataFound from "../NoDataFound";
import Searching from "../Searching";

import { postData } from "../../services/api";
import toastr from "toastr";
import { useTranslation } from "react-i18next";
import Loader from "../loader/Loader";

const ManageRolePopup = ({
  isOpen,
  setIsOpen = () => {},
  data,
  getActionValue = () => {},
}) => {
  const { i18n, t } = useTranslation();
  const role = localStorage.getItem("role");
  const [processing, setProcessing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userRole, setUserRole] = useState([]);
  const [rolePermission, setRolePermission] = useState([]);
  const closeModal = () => {
    setIsOpen(false);
  };

  const onSubmit = async () => {
    try {
      setLoading(true);
      const result = await postData("usertype/manage-role", {
        id: data?._id,
        role: userRole,
      });
      if (result.status && result.status === 200) {
        toastr.success(result.message);
        getActionValue({
          button_type: "edit",
          row_id: data?._id,
          current_status: "",
        });
        setIsOpen(false);
        setLoading(false);
      } else {
        toastr.error(result.message);
        setLoading(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    setUserRole(data?.role);
    async function RolePermissionData() {
      setProcessing(true);
      try {
        const results = await postData("role/list", {
          sortQuery: { ordering: 1 },
          status: 1,
          codes: [
            "sales-staff",
            "appointment-setter",
            "sales-admin",
            "consultant",
            "content-manager",
          ],
        });
        if (results.data) {
          setRolePermission(
            results.data.map((item, key) => {
              let matchCode = data?.role.filter((itm) => itm === item?._id);
              if (matchCode && matchCode.length > 0) {
                return {
                  _id: item?._id,
                  title: item?.name,
                  code: item?.code,
                  isEnabled: true,
                };
              } else {
                return {
                  _id: item?._id,
                  title: item?.name,
                  code: item?.code,
                  isEnabled: false,
                };
              }
            })
          );
          setProcessing(false);
        } else {
          console.log("role list message", results.message);
          setProcessing(false);
        }
      } catch (error) {
        console.log("Error role list catch", error.message);
        setProcessing(false);
      }
    }
    RolePermissionData();
  }, [data]);

  const updateHandler = (_id, event) => {
    if (event) {
      setUserRole((userRole) => [...userRole, _id]);
      setRolePermission(
        rolePermission.map((item, key) => {
          if (item?._id === _id) {
            return {
              ...item,
              isEnabled: true,
            };
          } else {
            return item;
          }
        })
      );
    } else {
      setUserRole((userPermission) =>
        userPermission.filter((item) => item !== _id)
      );
      setRolePermission(
        rolePermission.map((item, key) => {
          if (item?._id === _id) {
            return {
              ...item,
              isEnabled: false,
            };
          } else {
            return item;
          }
        })
      );
    }
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-xl transform rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 text-center"
                  >
                    {t("manage_role")}
                  </Dialog.Title>
                  <div className="absolute top-2 right-2 z-10">
                    <Button
                      buttonLabelClasses=""
                      buttonType={"button"}
                      buttonIcon={"fa-light fa-times"}
                      buttonIconPosition={"left"}
                      buttonLabel={""}
                      buttonClasses={
                        "!bg-transparent !text-slate-700 !w-10 !h-10 !p-0"
                      }
                      functions={closeModal}
                      buttonHasLink={false}
                    />
                  </div>
                  <div className="relative mt-4 w-full">
                    {rolePermission.length > 0 ? (
                      <div className="flex flex-wrap gap-4">
                        {rolePermission.map((item, index) => (
                          <div
                            key={index}
                            className="w-full sm:w-[calc(50%-0.5rem)] flex items-center justify-between p-4 border rounded-lg shadow-sm hover:bg-gray-100 transition duration-200"
                          >
                            <Checkbox
                              checkboxLableClass="text-sm font-medium text-gray-700"
                              checkboxInputClass="h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-300"
                              checkboxName={item?.code}
                              isChecked={item?.isEnabled}
                              checkboxLabel={item?.title}
                              checkboxValue={item?._id}
                              getCheckboxValue={(_id, event) =>
                                updateHandler(_id, event.target.checked)
                              }
                            />
                          </div>
                        ))}
                      </div>
                    ) : processing ? (
                      <div className="flex justify-center py-2">
                        {/* <Searching label={t("searching")} /> */}
                        <Loader />
                      </div>
                    ) : (
                      <div className="flex justify-center py-2">
                        <NoDataFound label={t("no_data_found")} />
                      </div>
                    )}
                  </div>

                  <div className="relative flex justify-center mb-3 mt-6 gap-4">
                    <Button
                      buttonType={"button"}
                      buttonIcon={"fa-light fa-times"}
                      buttonIconPosition={"left"}
                      buttonLabel={t("cancel")}
                      buttonClasses={
                        "bg-white border border-dark-teal-500 !text-dark-teal-500 ml-2"
                      }
                      buttonLabelClasses="text-dark-teal-500 "
                      functions={closeModal}
                      buttonHasLink={false}
                    />
                    <Button
                      buttonClasses="!bg-teal-500"
                      buttonLabelClasses=""
                      buttonType={"button"}
                      buttonIcon={
                        loading
                          ? "fa-light fa-spinner fa-spin"
                          : "fa-light fa-check"
                      }
                      buttonIconPosition={"left"}
                      buttonLabel={t("confirm")}
                      functions={onSubmit}
                      buttonHasLink={false}
                      buttonDisabled={loading}
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ManageRolePopup;

import {useState,useEffect} from 'react';
import CreateRoutes from './routes';
import { useTranslation } from "react-i18next";
import { NetworkStatusProvider } from './Context/NetworkStatusContext';
import moment from 'moment-timezone';
moment.tz.setDefault("America/Denver");

function App() {
  const { i18n } = useTranslation();
  useEffect(() => {
    const storedLanguage = sessionStorage.getItem('sessionLang');
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);

  // useEffect(() => {
  //   const handleUserActivity = () => {
  //     checkTokenExpiry();
  //   };

  //   window.addEventListener('mousemove', handleUserActivity);
  //   window.addEventListener('keydown', handleUserActivity);

  //   return () => {
  //     window.removeEventListener('mousemove', handleUserActivity);
  //     window.removeEventListener('keydown', handleUserActivity);
  //   };
  // }, [checkTokenExpiry]);

  return (
    <>
      <NetworkStatusProvider>
        <CreateRoutes />
      </NetworkStatusProvider>
    </>
  );
}

export default App;

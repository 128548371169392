import React, { useEffect, useState } from "react";
import Button from "../../form/Button";
import { useTranslation } from "react-i18next";
import Textarea from "../../form/Textarea";
import toastr from "toastr";
import { postData } from "../../../services/api";

const ProviderApiKeySetting = ({ details }) => {
  const { t } = useTranslation();
  const [data, setData] = useState({});
  const [loaded, setLoaded] = useState(false);

  const providerName = ["ninebox", "orgchart"];

  useEffect(() => {
    if (details?._id) {
      const transformedData =
        details &&
        Array.isArray(details?.providerkeys) &&
        details?.providerkeys?.length > 0 &&
        details?.providerkeys.reduce((acc, item) => {
          acc[item.code] = item.providerKey || "";
          return acc;
        }, {});
      setData(transformedData);
    }
  }, [details]);

  const getMaskedKey = (secretKey) => {
    const visibleStart = 10;
    const visibleEnd = 10;
  
    if (secretKey.length <= visibleStart + visibleEnd) {
      return secretKey;
    }
  
    const start = secretKey.slice(0, visibleStart);
    const end = secretKey.slice(-visibleEnd);
    const masked = "*".repeat(secretKey.length - visibleStart - visibleEnd);
  
    return `${start}${masked}${end}`;
  };
  
  const handleCopyKey = (key) => {
    navigator.clipboard.writeText(key).then(() => {
      toastr.success("Secret Key copied to clipboard!");
    });
  };

  const onSubmit = async () => {
    setLoaded(true);
    try {
      const path = "setting/apikey-update";
      const payload = providerName.map((provider) => ({
        code: provider,
        providerKey: data[provider] || "",
      }));

      const res = await postData(path, { providerkeys: payload });
      if (res.status && res.status === 200) {
        toastr.success(res.message);
      } else {
        toastr.error(res.message);
      }
    } catch (err) {
      console.log(err.messege);
    }

    setLoaded(false);
  };

  return (
    <div>
      <div className="relative flex justify-between items-center mb-3 flex-wrap sm:flex-nowrap gap-y-4 ">
        <div className="text-2xl font-bold">Provider Key Setting</div>
      </div>
      <div className="grid grid-cols-12 gap-6 p-6 bg-gray-50 rounded-lg shadow-md">
        <div className="col-span-12 flex flex-col space-y-6">
          <div className="grid grid-cols-2 gap-6 overflow-auto scroll-smooth scrollbar">
            {providerName &&
              Array.isArray(providerName) &&
              providerName?.length > 0 &&
              providerName?.map((provider, index) => (
                <div
                  key={index}
                  className="relative w-full space-y-2 border border-dark-teal-600 shadow-lg p-3 rounded-lg bg-teal-50"
                >
                  <Textarea
                    key={index}
                    label={`${provider}`}
                    labelClasses="!text-xl !font-bold !text-teal-700 !capitalize"
                    inputClasses="h-24 border rounded-lg p-2 text-gray-800 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                    inputPlaceholder={`Generate Secret Key for ${provider}`}
                    inputValue={data[provider] ? getMaskedKey(data[provider]) : ""}
                    setTextarea={(value) =>
                      setData((prevData) => ({
                        ...prevData,
                        [provider]: value,
                      }))
                    }
                    // requiredDisplay={true}
                  />
                  <Button
                    buttonType="button"
                    buttonLabel="Copy"
                    buttonIconPosition={"left"}
                    buttonIcon={"fa-regular fa-copy"}
                    buttonClasses="!bg-blue-500 text-white"
                    functions={() => handleCopyKey(data[provider])}
                    buttonHasLink={false}
                  />
                </div>
              ))}
          </div>

          <div className="flex justify-end gap-4 mt-5">
            <Button
              buttonClasses=""
              buttonLabelClasses=""
              buttonType={"button"}
                buttonIcon={
                  loaded
                    ? "fa-light fa-spinner fa-spin"
                    : "fa-light fa-floppy-disk"
                }
              buttonIconPosition={"left"}
              buttonLabel={t("save")}
              functions={onSubmit}
              buttonHasLink={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProviderApiKeySetting;

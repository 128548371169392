import React, { useState, useEffect, useRef } from "react";
import { setTitle } from "../../helpers/MetaTag";
import Select from "../../components/form/Select";
import Button from "../../components/form/Button";
import ReportTab from "../../shared/ReportTab";
import Table from "../../components/elements/table/Table";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { reorder, currencyFormat , capitalizeString} from "../../helpers";
import toastr from "toastr";
import moment from "moment";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
import "flatpickr/dist/themes/material_blue.css";
import "flatpickr/dist/l10n/default";
import { useSelector } from "react-redux";

import { postData } from "../../services/api";
import { PDFDownloadLink } from "@react-pdf/renderer";
import DashboardReport from "../pdf/DashboardReport";
import CommissionReport from "../pdf/CommissionReport";
import AvgCommissionReport from "../pdf/AvgCommissionReport";
import ExpensesPopup from "../../components/popup/ExpensesPopup";
import { useTranslation } from "react-i18next";
import Pagination from "../../components/Common/Pagination";

const Reports = () => {
  setTitle(
    "Succession Planning Software for your Business with Succession Now | Reports"
  );
  const { i18n, t } = useTranslation();
  const role = localStorage.getItem("role");
  const user = useSelector((state) => state.auth?.user);
  const type = new URLSearchParams(window.location.search).get("type");
  const subTypes = new URLSearchParams(window.location.search).get("sub_type");
  const flatPicker = useRef(null);
  const [selectedDates, setSelectedDates] = useState([]);
  const [subtype, setSubtype] = useState("");
  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [sortKey, setSortKey] = useState("ordering");
  const [sortValue, setSortValue] = useState(1);
  const [salesId, setSalesId] = useState("");
  const [consultantId, setConsultantId] = useState("");
  const [appointmentSetterId, setAppointmentSetterId] = useState("");
  const [date, setDate] = useState("");
  const [sales, setSales] = useState([]);
  const [consultants, setConsultants] = useState([]);
  const [appointmentSetter, setAppointmentSetter] = useState([]);
  const [countData, setCountData] = useState(0);
  const [results, setResults] = useState([]);
  const [changeValue, setChangeValue] = useState({});
  const [expenseType, setExpenseType] = useState("");
  const [expenseTypes, setExpenseTypes] = useState([]);

  const [expensesData, setExpensesData] = useState({});
  const [actionValues, setActionValues] = useState({});
  const [enabledExpensesPopup, setEnabledExpensesPopup] = useState(false);
  const [expenesInfo, setExpensesInfo] = useState({});
  const [netRevenew, setNetRevenew] = useState([]);
  const [revenewResult, setRevenewResult] = useState([]);
  const [expensesResult, setExpensesResult] = useState([]);
  const [totalRevenew, setTotalRevenew] = useState(0);
  const [totalExpenses, setTotalExpenses] = useState(0);
  const [totalCommission, setTotalCommission] = useState(0);
  const [isUpdate, setIsUpdate] = useState(false);
  const [checkboxValue, setCheckboxValue] = useState({});
  const [paymentStatus, setPaymentStatus] = useState("");

  const [avgResult, setAvgResult] = useState([]);
  const [netRevenewCommissionResult, setNetRevenewCommissionResult] = useState([]);
  const [code, setCode] = useState("sales-staff");
  const [companies, setCompanies] = useState([]);
  const [companyId, setCompanyId] = useState("");
  const [commissionFinalResult, setCommisionFinalResult] = useState([]);
  const [avgCommissionFinalResult, setAvgCommissionFinalResult] = useState([]);

  const [noOfPage, setNoOfPage] = useState(0);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [startDataCount, setStartDataCount] = useState(0);
  const [endDataCount, setEndDataCount] = useState(0);

  let heading = "";
  if (type && type === "sales") {
    heading = t("sales");
  } else if (type && type === "cancellation") {
    heading = t("cancellation");
  } else if (type && type === "revenue") {
    heading = t("revenue");
  } else if (type && type === "succession-plan") {
    heading = t("plans");
  } else if (type && type === "demos") {
    heading = t("demos");
  } else if (type && type === "renewals") {
    heading = t("renewals");
  } else if (type && type === "referrals") {
    heading = t("referrals");
  } else if (type && type === "appointments") {
    heading = t("appointments");
  } else if (type && type === "expenses") {
    heading = t("expenses");
  } else if (type && type === "commissions") {
    heading = t("commissions");
  } else if (type && type === "net-revenue") {
    heading = t("net_revenue");
  } else if (type && type === "lead-tracking") {
    heading = t("lead_tracking");
  } else if (type && type === "avg-commission") {
    heading = t("commissions");
  } else if (type && type === "avg-sales") {
    heading = t("sales_report");
  }

  const extraData = [
    role &&
      role === "master-admin" && {
        _id: 1,
        label: t("edit"),
        icon: "fa-pen-to-square",
        link: "/",
        type: "button",
        standout: false,
        buttonType: "edit-expenses-info",
        isVisabled: 1,
        ordering: 1,
      },
  ];

  const staffArray = [
    {
      code: "sales-staff",
      label: "Sales Person",
    },
    {
      code: "consultant",
      label: "Consultant",
    },
    {
      code: "appointment-setter",
      label: "Appointment Setter",
    },
    {
      code: "sales-admin",
      label: "Sales Admin",
    },
  ];  

  const titleCase = (str) => {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(" ");
  };

  const handleReady = (selectedDates, dateStr, instance) => {
    const clearButton = document.createElement("div");
    clearButton.className = "clear-button";
    clearButton.textContent = "Clear";
    clearButton.addEventListener("click", () => {
      instance.clear();
    });
    instance.calendarContainer.appendChild(clearButton);
  };

  useEffect(() => {
    if (avgResult.length > 0 && type === "avg-sales") {
      var result = [];
      avgResult.reduce(function (res, value) {
        if (!res[value._id]) {
          res[value._id] = {
            _id: value._id,
            name: value.name,
            total: value.total,
            price: value.price,
            qty: 0,
          };
          result.push(res[value._id]);
        } else {
          res[value._id].name = value.name;
          res[value._id].total += value.total;
          res[value._id].price += value.price;
        }
        res[value._id].qty += value.qty;
        return res;
      }, {});
      setCountData(result?.length);
      setResults(
        result
          .map((value) => {
            return {
              _id: value?._id,
              rowData: [
                {
                  _id: 1,
                  width: 15,
                  type: "text",
                  data: value?.name,
                  isColor: "",
                },
                {
                  _id: 2,
                  width: 15,
                  type: "text",
                  data: value?.qty ? value?.qty : 0,
                  isColor: "",
                },
                {
                  _id: 3,
                  width: 15,
                  type: "text",
                  data: "$" + Number(value?.total).toFixed(2),
                  isColor: "",
                },
                {
                  _id: 4,
                  width: 20,
                  type: "text",
                  data: "$" + Number(value?.price).toFixed(2),
                  isColor: "",
                },
              ],
            };
          })
          .filter((item) => item?._id)
      );
    }
  }, [avgResult, type]);

  useEffect(() => {
    if (actionValues && actionValues.button_type === "edit-expenses-info") {
      setEnabledExpensesPopup(true);
      const index = expensesData.findIndex(
        (item) => item._id === actionValues?.row_id
      );
      setExpensesInfo({ ...expensesData[index] });
    } else if (actionValues && actionValues.button_type === "delete") {
      const loadDeleteRecord = async () => {
        setIsUpdate(false);
        try {
          const statusData = await postData("expenses/delete", {
            id: actionValues?.row_id,
          });
          if (statusData.data) {
            const index = results.findIndex(
              (item) => item._id === actionValues?.row_id
            );
            if (index !== -1) {
              results.splice(index, 1);
              setResults(
                results.map((it) => {
                  return it;
                })
              );
            }
            const index1 = expensesData.findIndex(
              (item) => item._id === actionValues?.row_id
            );
            if (index1 !== -1) {
              expensesData.splice(index, 1);
              setExpensesData(
                expensesData.map((it) => {
                  return it;
                })
              );
            }
            setIsUpdate(true);
          } else {
            toastr.error(statusData.message);
          }
        } catch (error) {
          toastr.error(error.message);
        }
      };
      loadDeleteRecord();
    }
  }, [actionValues]);

  useEffect(() => {
    if (checkboxValue && checkboxValue.isEnabled) {
      const loadCommissionPay = async () => {
        setIsUpdate(false);
        try {
          const payData = await postData("commission/pay", {
            company_id: checkboxValue?.id,
            staff_id: checkboxValue?.checkboxId,
            staff_code: checkboxValue?.code,
          });
          if (payData.data) {
            toastr.success(payData.message);
            setIsUpdate(true);
          } else {
            toastr.error(payData.message);
          }
        } catch (error) {
          toastr.error(error.message);
        }
      };
      loadCommissionPay();
    }
  }, [checkboxValue]);

  useEffect(() => {
    const loadStaffes = async () => {
      try {
        const result = await postData("usertype/list", {
          sortQuery: { ordering: 1 },
          isStaff: 1,
          //code : ['consultant','sales-staff','appointment-setter'],
          status: 1,
        });
        if (result.status === 200) {
          let sales = [];
          let consultants = [];
          let appointmentSetter = [];
          result.data.map((value) => {
            sales.push(value);
            consultants.push(value);
            appointmentSetter.push(value);
            // if(value.role_details && value.role_details[0].code === 'sales-staff'){
            //   sales.push(value)
            // }else if(value.role_details && value.role_details[0].code === 'consultant'){
            //   consultants.push(value)
            // }else if(value.role_details && value.role_details[0].code === 'appointment-setter'){
            //   appointmentSetter.push(value)
            // }
          });
          setSales(sales);
          setConsultants(consultants);
          setAppointmentSetter(appointmentSetter);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadStaffes();

    async function loadExpensesType() {
      try {
        const result = await postData("filter-item/list", {
          sortQuery: { created_at: 1 },
          status: 1,
          type: "expense-type",
        });
        if (result.data) {
          setExpenseTypes(result.data);
        } else {
          console.log("Position list message", result.message);
        }
      } catch (error) {
        console.log("Error position list catch", error.message);
      }
    }
    if (type && type === "expenses") {
      loadExpensesType();
    }

    async function companyData() {
      try {
        const result = await postData("usertype/list", {
          code: "customer-admin",
          status: 1,
        });
        if (result.data) {
          setCompanies(result.data);
        } else {
          console.log("companies list message", result.message);
        }
      } catch (error) {
        console.log("Error companies list catch", error.message);
      }
    }
    if (type && type === "renewals") {
      companyData();
    }
  }, [type]);

  useEffect(() => {
    setSubtype(subTypes);
  }, [subTypes, changeValue]);

  useEffect(() => {
    const loadSubscriptionList = async () => {
      setLoading(true);
      try {
        const result = await postData("subscription/list", {
          // limit:limit,
          // offset: offset,
          sortQuery: { [sortKey]: sortValue },
          salesId: salesId,
          consultantId: consultantId,
          appointmentSetterId: appointmentSetterId,
          date: date,
          accountType: "business",
          type: "all",
        });
        if (result.status === 200) {
          let cmCancel = 0;
          let lmCancel = 0;
          let cyCancel = 0;
          let lyCancel = 0;
          let totalC = 0;
          let totalSale = 0;
          let cmSale = 0;
          let lmSale = 0;
          let cySale = 0;
          let lySale = 0;
          let totalResult = [];
          let cmResult = [];
          let lmResult = [];
          let cyResult = [];
          let lyResult = [];
          let totalCancelResult = [];
          let cmCancelResult = [];
          let lmCancelResult = [];
          let cyCancelResult = [];
          let lyCancelResult = [];

          result.data.map((value, key) => {
            let salesPersion = "";
            let consultantPersion = "";
            let appointmentSetterPersion = "";
            let sales =
              value?.user_details.length > 0 &&
              value?.user_details[0].staffs &&
              value?.user_details[0].staffs.filter(
                (item) => item.code === "sales-staff"
              );
            if (sales && sales.length > 0) {
              let salesId = sales[0].staff_id;
              let salesPersionArray = value?.staff_details.filter(
                (itm) => itm._id === salesId
              );
              if (salesPersionArray && salesPersionArray.length > 0) {
                salesPersion =
                  salesPersionArray[0].first_name +
                  " " +
                  salesPersionArray[0].last_name;
              }
            }
            let consultant =
              value?.user_details.length > 0 &&
              value?.user_details[0].staffs &&
              value?.user_details[0].staffs.filter(
                (item) => item.code === "consultant"
              );
            if (consultant && consultant.length > 0) {
              let consultantId = consultant[0].staff_id;
              let consultantPersionArray = value?.staff_details.filter(
                (itm) => itm._id === consultantId
              );
              if (consultantPersionArray && consultantPersionArray.length > 0) {
                consultantPersion =
                  consultantPersionArray[0].first_name +
                  " " +
                  consultantPersionArray[0].last_name;
              }
            }
            let appointmentSetter =
              value?.user_details.length > 0 &&
              value?.user_details[0].staffs &&
              value?.user_details[0].staffs.filter(
                (item) => item.code === "appointment-setter"
              );
            if (appointmentSetter && appointmentSetter.length > 0) {
              let appointmentSetterId = appointmentSetter[0].staff_id;
              let appointmentSetterPersionArray = value?.staff_details.filter(
                (itm) => itm._id === appointmentSetterId
              );
              if (
                appointmentSetterPersionArray &&
                appointmentSetterPersionArray.length > 0
              ) {
                appointmentSetterPersion =
                  appointmentSetterPersionArray[0].first_name +
                  " " +
                  appointmentSetterPersionArray[0].last_name;
              }
            }
            if (
              moment(new Date()).format("YYYY-MM") ===
              moment(new Date(value?.created_at)).format("YYYY-MM")
            ) {
              if (value?.status === 2) {
                cmCancel = cmCancel + 1;
                cmCancelResult.push({
                  _id: value?._id,
                  rowData: [
                    {
                      _id: 1,
                      width: 15,
                      type: "text",
                      data: value?.company_name,
                      isColor: "",
                    },
                    {
                      _id: 2,
                      width: 15,
                      type: "text",
                      data: "$" + value?.price.toFixed(2),
                      isColor: "",
                    },
                    {
                      _id: 3,
                      width: 20,
                      type: "text",
                      data: salesPersion ? salesPersion : t("none"),
                      isColor: "",
                    },
                    {
                      _id: 4,
                      width: 20,
                      type: "text",
                      data: consultantPersion ? consultantPersion : t("none"),
                      isColor: "",
                    },
                    {
                      _id: 5,
                      width: 20,
                      type: "text",
                      data: appointmentSetterPersion
                        ? appointmentSetterPersion
                        : t("none"),
                      isColor: "",
                    },
                    {
                      _id: 6,
                      width: 15,
                      type: "status",
                      data: value?.number_of_succession_plan,
                      isColor: "",
                    },
                  ],
                });
              }
              if (
                value?.status === 1 &&
                (salesPersion || consultantPersion || appointmentSetterPersion)
              ) {
                cmSale = cmSale + 1;
                cmResult.push({
                  _id: value?._id,
                  rowData: [
                    {
                      _id: 1,
                      width: 15,
                      type: "link",
                      data: value?.company_name,
                      isColor: "",
                      value: "/company-details/" + value?.company_id,
                    },
                    {
                      _id: 2,
                      width: 15,
                      type: "text",
                      data: "$" + value?.original_price.toFixed(2),
                      isColor: "",
                    },
                    {
                      _id: 3,
                      width: 20,
                      type: "text",
                      data: salesPersion ? salesPersion : t("none"),
                      isColor: "",
                    },
                    {
                      _id: 4,
                      width: 20,
                      type: "text",
                      data: consultantPersion ? consultantPersion : t("none"),
                      isColor: "",
                    },
                    {
                      _id: 5,
                      width: 20,
                      type: "text",
                      data: appointmentSetterPersion
                        ? appointmentSetterPersion
                        : t("none"),
                      isColor: "",
                    },
                    {
                      _id: 6,
                      width: 15,
                      type: "status",
                      statusLabel:
                        value?.status && value?.status === 1
                          ? t("active")
                          : value?.status && value?.status === 2
                          ? t("canceled")
                          : t("pending"),
                      statusType:
                        value?.status && value?.status === 1
                          ? "success"
                          : value?.status && value?.status === 2
                          ? "danger"
                          : "info",
                      isColor: "",
                    },
                  ],
                });
              }
            }
            if (
              moment(new Date()).subtract(1, "month").format("YYYY-MM") ===
              moment(new Date(value?.created_at)).format("YYYY-MM")
            ) {
              if (value?.status === 2) {
                lmCancel = lmCancel + 1;
                lmCancelResult.push({
                  _id: value?._id,
                  rowData: [
                    {
                      _id: 1,
                      width: 15,
                      type: "text",
                      data: value?.company_name,
                      isColor: "",
                    },
                    {
                      _id: 2,
                      width: 15,
                      type: "text",
                      data: "$" + value?.price.toFixed(2),
                      isColor: "",
                    },
                    {
                      _id: 3,
                      width: 20,
                      type: "text",
                      data: salesPersion ? salesPersion : t("none"),
                      isColor: "",
                    },
                    {
                      _id: 4,
                      width: 20,
                      type: "text",
                      data: consultantPersion ? consultantPersion : t("none"),
                      isColor: "",
                    },
                    {
                      _id: 5,
                      width: 20,
                      type: "text",
                      data: appointmentSetterPersion
                        ? appointmentSetterPersion
                        : t("none"),
                      isColor: "",
                    },
                    {
                      _id: 6,
                      width: 15,
                      type: "status",
                      data: value?.number_of_succession_plan,
                      isColor: "",
                    },
                  ],
                });
              }
              if (
                value?.status === 1 &&
                (salesPersion || consultantPersion || appointmentSetterPersion)
              ) {
                lmSale = lmSale + 1;
                lmResult.push({
                  _id: value?._id,
                  rowData: [
                    {
                      _id: 1,
                      width: 15,
                      type: "link",
                      data: value?.company_name,
                      isColor: "",
                      value: "/company-details/" + value?.company_id,
                    },
                    {
                      _id: 2,
                      width: 15,
                      type: "text",
                      data: "$" + value?.original_price.toFixed(2),
                      isColor: "",
                    },
                    {
                      _id: 3,
                      width: 20,
                      type: "text",
                      data: salesPersion ? salesPersion : t("none"),
                      isColor: "",
                    },
                    {
                      _id: 4,
                      width: 20,
                      type: "text",
                      data: consultantPersion ? consultantPersion : t("none"),
                      isColor: "",
                    },
                    {
                      _id: 5,
                      width: 20,
                      type: "text",
                      data: appointmentSetterPersion
                        ? appointmentSetterPersion
                        : t("none"),
                      isColor: "",
                    },
                    {
                      _id: 6,
                      width: 15,
                      type: "status",
                      statusLabel:
                        value?.status && value?.status === 1
                          ? t("active")
                          : value?.status && value?.status === 2
                          ? t("canceled")
                          : t("pending"),
                      statusType:
                        value?.status && value?.status === 1
                          ? "success"
                          : value?.status && value?.status === 2
                          ? "danger"
                          : "info",
                      isColor: "",
                    },
                  ],
                });
              }
            }
            if (
              moment(new Date()).format("YYYY") ===
              moment(new Date(value?.created_at)).format("YYYY")
            ) {
              if (value?.status === 2) {
                cyCancel = cyCancel + 1;
                cyCancelResult.push({
                  _id: value?._id,
                  rowData: [
                    {
                      _id: 1,
                      width: 15,
                      type: "text",
                      data: value?.company_name,
                      isColor: "",
                    },
                    {
                      _id: 2,
                      width: 15,
                      type: "text",
                      data: "$" + value?.price.toFixed(2),
                      isColor: "",
                    },
                    {
                      _id: 3,
                      width: 20,
                      type: "text",
                      data: salesPersion ? salesPersion : t("none"),
                      isColor: "",
                    },
                    {
                      _id: 4,
                      width: 20,
                      type: "text",
                      data: consultantPersion ? consultantPersion : t("none"),
                      isColor: "",
                    },
                    {
                      _id: 5,
                      width: 20,
                      type: "text",
                      data: appointmentSetterPersion
                        ? appointmentSetterPersion
                        : t("none"),
                      isColor: "",
                    },
                    {
                      _id: 6,
                      width: 15,
                      type: "status",
                      data: value?.number_of_succession_plan,
                      isColor: "",
                    },
                  ],
                });
              }
              if (
                value?.status === 1 &&
                (salesPersion || consultantPersion || appointmentSetterPersion)
              ) {
                cySale = cySale + 1;
                cyResult.push({
                  _id: value?._id,
                  rowData: [
                    {
                      _id: 1,
                      width: 15,
                      type: "link",
                      data: value?.company_name,
                      isColor: "",
                      value: "/company-details/" + value?.company_id,
                    },
                    {
                      _id: 2,
                      width: 15,
                      type: "text",
                      data: "$" + value?.original_price.toFixed(2),
                      isColor: "",
                    },
                    {
                      _id: 3,
                      width: 20,
                      type: "text",
                      data: salesPersion ? salesPersion : t("none"),
                      isColor: "",
                    },
                    {
                      _id: 4,
                      width: 20,
                      type: "text",
                      data: consultantPersion ? consultantPersion : t("none"),
                      isColor: "",
                    },
                    {
                      _id: 5,
                      width: 20,
                      type: "text",
                      data: appointmentSetterPersion
                        ? appointmentSetterPersion
                        : t("none"),
                      isColor: "",
                    },
                    {
                      _id: 6,
                      width: 15,
                      type: "status",
                      statusLabel:
                        value?.status && value?.status === 1
                          ? t("active")
                          : value?.status && value?.status === 2
                          ? t("canceled")
                          : t("pending"),
                      statusType:
                        value?.status && value?.status === 1
                          ? "success"
                          : value?.status && value?.status === 2
                          ? "danger"
                          : "info",
                      isColor: "",
                    },
                  ],
                });
              }
            }
            if (
              moment(new Date()).subtract(1, "year").format("YYYY") ===
              moment(new Date(value?.created_at)).format("YYYY")
            ) {
              if (value?.status === 2) {
                lyCancel = lyCancel + 1;
                lyCancelResult.push({
                  _id: value?._id,
                  rowData: [
                    {
                      _id: 1,
                      width: 15,
                      type: "text",
                      data: value?.company_name,
                      isColor: "",
                    },
                    {
                      _id: 2,
                      width: 15,
                      type: "text",
                      data: "$" + value?.original_price.toFixed(2),
                      isColor: "",
                    },
                    {
                      _id: 3,
                      width: 20,
                      type: "text",
                      data: salesPersion ? salesPersion : t("none"),
                      isColor: "",
                    },
                    {
                      _id: 4,
                      width: 20,
                      type: "text",
                      data: consultantPersion ? consultantPersion : t("none"),
                      isColor: "",
                    },
                    {
                      _id: 5,
                      width: 20,
                      type: "text",
                      data: appointmentSetterPersion
                        ? appointmentSetterPersion
                        : t("none"),
                      isColor: "",
                    },
                    {
                      _id: 6,
                      width: 15,
                      type: "status",
                      data: value?.number_of_succession_plan,
                      isColor: "",
                    },
                  ],
                });
              }
              if (
                value?.status === 1 &&
                (salesPersion || consultantPersion || appointmentSetterPersion)
              ) {
                lySale = lySale + 1;
                lyResult.push({
                  _id: value?._id,
                  rowData: [
                    {
                      _id: 1,
                      width: 15,
                      type: "link",
                      data: value?.company_name,
                      isColor: "",
                      value: "/company-details/" + value?.company_id,
                    },
                    {
                      _id: 2,
                      width: 15,
                      type: "text",
                      data: "$" + value?.original_price.toFixed(2),
                      isColor: "",
                    },
                    {
                      _id: 3,
                      width: 20,
                      type: "text",
                      data: salesPersion ? salesPersion : t("none"),
                      isColor: "",
                    },
                    {
                      _id: 4,
                      width: 20,
                      type: "text",
                      data: consultantPersion ? consultantPersion : t("none"),
                      isColor: "",
                    },
                    {
                      _id: 5,
                      width: 20,
                      type: "text",
                      data: appointmentSetterPersion
                        ? appointmentSetterPersion
                        : t("none"),
                      isColor: "",
                    },
                    {
                      _id: 6,
                      width: 15,
                      type: "status",
                      statusLabel:
                        value?.status && value?.status === 1
                          ? t("active")
                          : value?.status && value?.status === 2
                          ? t("canceled")
                          : t("pending"),
                      statusType:
                        value?.status && value?.status === 1
                          ? "success"
                          : value?.status && value?.status === 2
                          ? "danger"
                          : "info",
                      isColor: "",
                    },
                  ],
                });
              }
            }
            if (value?.status === 2) {
              totalC = totalC + 1;
              totalCancelResult.push({
                _id: value?._id,
                rowData: [
                  {
                    _id: 1,
                    width: 15,
                    type: "text",
                    data: value?.company_name,
                    isColor: "",
                  },
                  {
                    _id: 2,
                    width: 15,
                    type: "text",
                    data: "$" + value?.price.toFixed(2),
                    isColor: "",
                  },
                  {
                    _id: 3,
                    width: 20,
                    type: "text",
                    data: salesPersion ? salesPersion : t("none"),
                    isColor: "",
                  },
                  {
                    _id: 4,
                    width: 20,
                    type: "text",
                    data: consultantPersion ? consultantPersion : t("none"),
                    isColor: "",
                  },
                  {
                    _id: 5,
                    width: 20,
                    type: "text",
                    data: appointmentSetterPersion
                      ? appointmentSetterPersion
                      : t("none"),
                    isColor: "",
                  },
                  {
                    _id: 6,
                    width: 15,
                    type: "status",
                    data: value?.number_of_succession_plan,
                    isColor: "",
                  },
                ],
              });
            }
            if (
              value?.status === 1 &&
              (salesPersion || consultantPersion || appointmentSetterPersion)
            ) {
              totalSale = totalSale + 1;
              totalResult.push({
                _id: value?._id,
                rowData: [
                  {
                    _id: 1,
                    width: 15,
                    type: "link",
                    data: value?.company_name,
                    isColor: "",
                    value: "/company-details/" + value?.company_id,
                  },
                  {
                    _id: 2,
                    width: 15,
                    type: "text",
                    data: "$" + value?.original_price.toFixed(2),
                    isColor: "",
                  },
                  {
                    _id: 3,
                    width: 20,
                    type: "text",
                    data: salesPersion ? salesPersion : t("none"),
                    isColor: "",
                  },
                  {
                    _id: 4,
                    width: 20,
                    type: "text",
                    data: consultantPersion ? consultantPersion : t("none"),
                    isColor: "",
                  },
                  {
                    _id: 5,
                    width: 20,
                    type: "text",
                    data: appointmentSetterPersion
                      ? appointmentSetterPersion
                      : t("none"),
                    isColor: "",
                  },
                  {
                    _id: 6,
                    width: 15,
                    type: "status",
                    statusLabel:
                      value?.status && value?.status === 1
                        ? t("active")
                        : value?.status && value?.status === 2
                        ? t("canceled")
                        : t("pending"),
                    statusType:
                      value?.status && value?.status === 1
                        ? "success"
                        : value?.status && value?.status === 2
                        ? "danger"
                        : "info",
                    isColor: "",
                  },
                ],
              });
            }
          });
          if (type && type === "sales") {
            if (subTypes && subTypes === "total") {
              setCountData(totalSale);
              setResults(totalResult);
            } else if (subTypes && subTypes === "current-year") {
              setCountData(cySale);
              setResults(cyResult);
            } else if (subTypes && subTypes === "previous-year") {
              setCountData(lySale);
              setResults(lyResult);
            } else if (subTypes && subTypes === "current-month") {
              setCountData(cmSale);
              setResults(cmResult);
            } else if (subTypes && subTypes === "previous-month") {
              setCountData(lmSale);
              setResults(lmResult);
            }
          } else if (type && type === "cancellation") {
            if (subTypes && subTypes === "total") {
              setCountData(totalC);
              setResults(totalCancelResult);
            } else if (subTypes && subTypes === "current-year") {
              setCountData(cyCancel);
              setResults(cyCancelResult);
            } else if (subTypes && subTypes === "previous-year") {
              setCountData(lyCancel);
              setResults(lyCancelResult);
            } else if (subTypes && subTypes === "current-month") {
              setCountData(cmCancel);
              setResults(cmCancelResult);
            } else if (subTypes && subTypes === "previous-month") {
              setCountData(lmCancel);
              setResults(lmCancelResult);
            }
          }
        }
        setLoading(false);
        setChangeValue({});
      } catch (err) {
        console.log(err.message);
        setLoading(false);
        setChangeValue({});
      }
    };

    const loadDashboardList = async () => {
      setLoading(true);
      try {
        const result = await postData("dashboard/list", {
          sortQuery: { [sortKey]: sortValue },
          salesId: salesId,
          consultantId: consultantId,
          appointmentSetterId: appointmentSetterId,
          date: date,
        });
        if (result.data) {
          let price = 0;
          let cmPrice = 0;
          let lmPrice = 0;
          let cyPrice = 0;
          let lyPrice = 0;
          let totalResult = [];
          let cmResult = [];
          let lmResult = [];
          let cyResult = [];
          let lyResult = [];
          let totalNetRevenew = [];
          let cmNetRevenew = [];
          let lmNetRevenew = [];
          let cyNetRevenew = [];
          let lyNetRevenew = [];
          result.data.map((value, key) => {
            price = price + Number(value?.price);
            let salesPersion = "";
            let consultantPersion = "";
            let appointmentSetterPersion = "";
            let sales =
              value?.user_details.length > 0 &&
              value?.user_details[0].staffs &&
              value?.user_details[0].staffs.filter(
                (item) => item.code === "sales-staff"
              );
            if (sales && sales.length > 0) {
              let salesId = sales[0].staff_id;
              let salesPersionArray = value?.staff_details.filter(
                (itm) => itm._id === salesId
              );
              if (salesPersionArray && salesPersionArray.length > 0) {
                salesPersion =
                  salesPersionArray[0].first_name +
                  " " +
                  salesPersionArray[0].last_name;
              }
            }
            let consultant =
              value?.user_details.length > 0 &&
              value?.user_details[0].staffs &&
              value?.user_details[0].staffs.filter(
                (item) => item.code === "consultant"
              );
            if (consultant && consultant.length > 0) {
              let consultantId = consultant[0].staff_id;
              let consultantPersionArray = value?.staff_details.filter(
                (itm) => itm._id === consultantId
              );
              if (consultantPersionArray && consultantPersionArray.length > 0) {
                consultantPersion =
                  consultantPersionArray[0].first_name +
                  " " +
                  consultantPersionArray[0].last_name;
              }
            }
            let appointmentSetter =
              value?.user_details.length > 0 &&
              value?.user_details[0].staffs &&
              value?.user_details[0].staffs.filter(
                (item) => item.code === "appointment-setter"
              );
            if (appointmentSetter && appointmentSetter.length > 0) {
              let appointmentSetterId = appointmentSetter[0].staff_id;
              let appointmentSetterPersionArray = value?.staff_details.filter(
                (itm) => itm._id === appointmentSetterId
              );
              if (
                appointmentSetterPersionArray &&
                appointmentSetterPersionArray.length > 0
              ) {
                appointmentSetterPersion =
                  appointmentSetterPersionArray[0].first_name +
                  " " +
                  appointmentSetterPersionArray[0].last_name;
              }
            }

            totalResult.push({
              _id: value?._id,
              rowData: [
                {
                  _id: 1,
                  width: 15,
                  type: "text",
                  data: value?.company_name,
                  isColor: "",
                },
                {
                  _id: 2,
                  width: 15,
                  type: "text",
                  data:
                    "$" +
                    (value.price ? Number(value?.price).toFixed(2) : "0.00"),
                  isColor: "",
                },
                {
                  _id: 3,
                  width: 20,
                  type: "text",
                  data: salesPersion ? salesPersion : t("none"),
                  isColor: "",
                },
                {
                  _id: 4,
                  width: 20,
                  type: "text",
                  data: consultantPersion ? consultantPersion : t("none"),
                  isColor: "",
                },
                {
                  _id: 5,
                  width: 20,
                  type: "text",
                  data: appointmentSetterPersion
                    ? appointmentSetterPersion
                    : t("none"),
                  isColor: "",
                },
                {
                  _id: 6,
                  width: 15,
                  type: "status",
                  statusLabel:
                    value?.status && value?.status === 1
                      ? t("active")
                      : value?.status && value?.status === 2
                      ? t("canceled")
                      : t("pending"),
                  statusType:
                    value?.status && value?.status === 1
                      ? "success"
                      : value?.status && value?.status === 2
                      ? "danger"
                      : "info",
                  isColor: "",
                },
              ],
            });
            totalNetRevenew.push({
              ...value,
              date: value?.created_at,
              type: "Revenue",
            });
            if (
              moment(new Date()).format("YYYY-MM") ===
              moment(new Date(value?.created_at)).format("YYYY-MM")
            ) {
              cmPrice = cmPrice + Number(value?.price);
              cmResult.push({
                _id: value?._id,
                rowData: [
                  {
                    _id: 1,
                    width: 15,
                    type: "text",
                    data: value?.company_name,
                    isColor: "",
                  },
                  {
                    _id: 2,
                    width: 15,
                    type: "text",
                    data:
                      "$" +
                      (value.price ? Number(value?.price).toFixed(2) : "0.00"),
                    isColor: "",
                  },
                  {
                    _id: 3,
                    width: 20,
                    type: "text",
                    data: salesPersion ? salesPersion : t("none"),
                    isColor: "",
                  },
                  {
                    _id: 4,
                    width: 20,
                    type: "text",
                    data: consultantPersion ? consultantPersion : t("none"),
                    isColor: "",
                  },
                  {
                    _id: 5,
                    width: 20,
                    type: "text",
                    data: appointmentSetterPersion
                      ? appointmentSetterPersion
                      : t("none"),
                    isColor: "",
                  },
                  {
                    _id: 6,
                    width: 15,
                    type: "status",
                    statusLabel:
                      value?.status && value?.status === 1
                        ? t("active")
                        : value?.status && value?.status === 2
                        ? t("canceled")
                        : t("pending"),
                    statusType:
                      value?.status && value?.status === 1
                        ? "success"
                        : value?.status && value?.status === 2
                        ? "danger"
                        : "info",
                    isColor: "",
                  },
                ],
              });
              cmNetRevenew.push({
                ...value,
                date: value?.created_at,
                type: "Revenue",
              });
            }
            if (
              moment(new Date()).subtract(1, "month").format("YYYY-MM") ===
              moment(new Date(value?.created_at)).format("YYYY-MM")
            ) {
              lmPrice = lmPrice + Number(value?.price);
              lmResult.push({
                _id: value?._id,
                rowData: [
                  {
                    _id: 1,
                    width: 15,
                    type: "text",
                    data: value?.company_name,
                    isColor: "",
                  },
                  {
                    _id: 2,
                    width: 15,
                    type: "text",
                    data:
                      "$" +
                      (value.price ? Number(value?.price).toFixed(2) : "0.00"),
                    isColor: "",
                  },
                  {
                    _id: 3,
                    width: 20,
                    type: "text",
                    data: salesPersion ? salesPersion : t("none"),
                    isColor: "",
                  },
                  {
                    _id: 4,
                    width: 20,
                    type: "text",
                    data: consultantPersion ? consultantPersion : t("none"),
                    isColor: "",
                  },
                  {
                    _id: 5,
                    width: 20,
                    type: "text",
                    data: appointmentSetterPersion
                      ? appointmentSetterPersion
                      : t("none"),
                    isColor: "",
                  },
                  {
                    _id: 6,
                    width: 15,
                    type: "status",
                    statusLabel:
                      value?.status && value?.status === 1
                        ? t("active")
                        : value?.status && value?.status === 2
                        ? t("canceled")
                        : t("pending"),
                    statusType:
                      value?.status && value?.status === 1
                        ? "success"
                        : value?.status && value?.status === 2
                        ? "danger"
                        : "info",
                    isColor: "",
                  },
                ],
              });
              lmNetRevenew.push({
                ...value,
                date: value?.created_at,
                type: "Revenue",
              });
            }
            if (
              moment(new Date()).format("YYYY") ===
              moment(new Date(value?.created_at)).format("YYYY")
            ) {
              cyPrice = cyPrice + Number(value?.price);
              cyResult.push({
                _id: value?._id,
                rowData: [
                  {
                    _id: 1,
                    width: 15,
                    type: "text",
                    data: value?.company_name,
                    isColor: "",
                  },
                  {
                    _id: 2,
                    width: 15,
                    type: "text",
                    data:
                      "$" +
                      (value.price ? Number(value?.price).toFixed(2) : "0.00"),
                    isColor: "",
                  },
                  {
                    _id: 3,
                    width: 20,
                    type: "text",
                    data: salesPersion ? salesPersion : t("none"),
                    isColor: "",
                  },
                  {
                    _id: 4,
                    width: 20,
                    type: "text",
                    data: consultantPersion ? consultantPersion : t("none"),
                    isColor: "",
                  },
                  {
                    _id: 5,
                    width: 20,
                    type: "text",
                    data: appointmentSetterPersion
                      ? appointmentSetterPersion
                      : t("none"),
                    isColor: "",
                  },
                  {
                    _id: 6,
                    width: 15,
                    type: "status",
                    statusLabel:
                      value?.status && value?.status === 1
                        ? t("active")
                        : value?.status && value?.status === 2
                        ? t("canceled")
                        : t("pending"),
                    statusType:
                      value?.status && value?.status === 1
                        ? "success"
                        : value?.status && value?.status === 2
                        ? "danger"
                        : "info",
                    isColor: "",
                  },
                ],
              });
              cyNetRevenew.push({
                ...value,
                date: value?.created_at,
                type: "Revenue",
              });
            }
            if (
              moment(new Date()).subtract(1, "year").format("YYYY") ===
              moment(new Date(value?.created_at)).format("YYYY")
            ) {
              lyPrice = cyPrice + Number(value?.price);
              lyResult.push({
                _id: value?._id,
                rowData: [
                  {
                    _id: 1,
                    width: 15,
                    type: "text",
                    data: value?.company_name,
                    isColor: "",
                  },
                  {
                    _id: 2,
                    width: 15,
                    type: "text",
                    data:
                      "$" +
                      (value.price ? Number(value?.price).toFixed(2) : "0.00"),
                    isColor: "",
                  },
                  {
                    _id: 3,
                    width: 20,
                    type: "text",
                    data: salesPersion ? salesPersion : t("none"),
                    isColor: "",
                  },
                  {
                    _id: 4,
                    width: 20,
                    type: "text",
                    data: consultantPersion ? consultantPersion : t("none"),
                    isColor: "",
                  },
                  {
                    _id: 5,
                    width: 20,
                    type: "text",
                    data: appointmentSetterPersion
                      ? appointmentSetterPersion
                      : t("none"),
                    isColor: "",
                  },
                  {
                    _id: 6,
                    width: 15,
                    type: "status",
                    statusLabel:
                      value?.status && value?.status === 1
                        ? t("active")
                        : value?.status && value?.status === 2
                        ? t("canceled")
                        : t("pending"),
                    statusType:
                      value?.status && value?.status === 1
                        ? "success"
                        : value?.status && value?.status === 2
                        ? "danger"
                        : "info",
                    isColor: "",
                  },
                ],
              });
              lyNetRevenew.push({
                ...value,
                date: value?.created_at,
                type: "Revenue",
              });
            }
          });
          if (type && type === "net-revenue") {
            if (subTypes && subTypes === "total") {
              setRevenewResult(totalNetRevenew);
            } else if (subTypes && subTypes === "current-year") {
              setRevenewResult(cyNetRevenew);
            } else if (subTypes && subTypes === "previous-year") {
              setRevenewResult(lyNetRevenew);
            } else if (subTypes && subTypes === "current-month") {
              setRevenewResult(cmNetRevenew);
            } else if (subTypes && subTypes === "previous-month") {
              setRevenewResult(lmNetRevenew);
            }
          } else {
            if (subTypes && subTypes === "total") {
              setCountData(Number(price).toFixed(2));
              setResults(totalResult);
            } else if (subTypes && subTypes === "current-year") {
              setCountData(Number(cyPrice).toFixed(2));
              setResults(cyResult);
            } else if (subTypes && subTypes === "previous-year") {
              setCountData(Number(lyPrice).toFixed(2));
              setResults(lyResult);
            } else if (subTypes && subTypes === "current-month") {
              setCountData(Number(cmPrice).toFixed(2));
              setResults(cmResult);
            } else if (subTypes && subTypes === "previous-month") {
              setCountData(Number(lmPrice).toFixed(2));
              setResults(lmResult);
            }
          }
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err.message);
      }
    };

    const loadPlanList = async () => {
      setLoading(true);
      try {
        const result = await postData("usertype/list", {
          code: "customer-admin",
          accountType: "business",
          useFor: "succession-plan",
        });
        if (result.status === 200) {
          let cmSuccessionPlan = 0;
          let lmSuccessionPlan = 0;
          let cySuccessionPlan = 0;
          let lySuccessionPlan = 0;
          let totalResult = [];
          let cmResult = [];
          let lmResult = [];
          let cyResult = [];
          let lyResult = [];
          result.data.map((value, key) => {
            if (
              moment(new Date()).format("YYYY-MM") ===
              moment(new Date(value?.created_at)).format("YYYY-MM")
            ) {
              cmSuccessionPlan = cmSuccessionPlan + 1;
              cmResult.push({
                _id: value?._id,
                rowData: [
                  {
                    _id: 1,
                    width: 15,
                    type: "text",
                    data: value?.company_name,
                    isColor: "",
                  },
                  {
                    _id: 2,
                    width: 20,
                    type: "text",
                    data: Number(value?.conclusion_info.length),
                    isColor: "",
                  },
                  {
                    _id: 3,
                    width: 20,
                    type: "text",
                    data:
                      Number(value?.individual_info.length) -
                      Number(value?.conclusion_info.length),
                    isColor: "",
                  },
                  {
                    _id: 4,
                    width: 15,
                    type: "text",
                    data:
                      "$" +
                      (value.code_info
                        ? Number(value?.code_info?.price).toFixed(2)
                        : value.plan_info
                        ? Number(value?.plan_info?.price).toFixed(2)
                        : value?.subscription_details[0]?.price),
                    isColor: "",
                  },
                  {
                    _id: 5,
                    width: 15,
                    type: "status",
                    statusLabel:
                      value?.status && value?.status === 1
                        ? t("active")
                        : value?.status && value?.status === 2
                        ? t("canceled")
                        : t("pending"),
                    statusType:
                      value?.status && value?.status === 1
                        ? "success"
                        : value?.status && value?.status === 2
                        ? "danger"
                        : "info",
                    isColor: "",
                  },
                ],
              });
            }
            if (
              moment(new Date()).subtract(1, "month").format("YYYY-MM") ===
              moment(new Date(value?.created_at)).format("YYYY-MM")
            ) {
              lmSuccessionPlan = lmSuccessionPlan + 1;
              lmResult.push({
                _id: value?._id,
                rowData: [
                  {
                    _id: 1,
                    width: 15,
                    type: "text",
                    data: value?.company_name,
                    isColor: "",
                  },
                  {
                    _id: 2,
                    width: 20,
                    type: "text",
                    data: Number(value?.conclusion_info.length),
                    isColor: "",
                  },
                  {
                    _id: 3,
                    width: 20,
                    type: "text",
                    data:
                      Number(value?.individual_info.length) -
                      Number(value?.conclusion_info.length),
                    isColor: "",
                  },
                  {
                    _id: 4,
                    width: 15,
                    type: "text",
                    data:
                      "$" +
                      (value.code_info
                        ? Number(value?.code_info?.price).toFixed(2)
                        : value.plan_info
                        ? Number(value?.plan_info?.price).toFixed(2)
                        : Number(value?.subscription_details[0]?.price).toFixed(
                            2
                          )),
                    isColor: "",
                  },
                  {
                    _id: 5,
                    width: 15,
                    type: "status",
                    statusLabel:
                      value?.status && value?.status === 1
                        ? t("active")
                        : value?.status && value?.status === 2
                        ? t("canceled")
                        : t("pending"),
                    statusType:
                      value?.status && value?.status === 1
                        ? "success"
                        : value?.status && value?.status === 2
                        ? "danger"
                        : "info",
                    isColor: "",
                  },
                ],
              });
            }
            if (
              moment(new Date()).format("YYYY") ===
              moment(new Date(value?.created_at)).format("YYYY")
            ) {
              cySuccessionPlan = cySuccessionPlan + 1;
              cyResult.push({
                _id: value?._id,
                rowData: [
                  {
                    _id: 1,
                    width: 15,
                    type: "text",
                    data: value?.company_name,
                    isColor: "",
                  },
                  {
                    _id: 2,
                    width: 20,
                    type: "text",
                    data: Number(value?.conclusion_info.length),
                    isColor: "",
                  },
                  {
                    _id: 3,
                    width: 20,
                    type: "text",
                    data:
                      Number(value?.individual_info.length) -
                      Number(value?.conclusion_info.length),
                    isColor: "",
                  },
                  {
                    _id: 4,
                    width: 15,
                    type: "text",
                    data:
                      "$" +
                      (value.code_info
                        ? Number(value?.code_info?.price).toFixed(2)
                        : value.plan_info
                        ? Number(value?.plan_info?.price).toFixed(2)
                        : Number(value?.subscription_details[0]?.price).toFixed(
                            2
                          )),
                    isColor: "",
                  },
                  {
                    _id: 5,
                    width: 15,
                    type: "status",
                    statusLabel:
                      value?.status && value?.status === 1
                        ? t("active")
                        : value?.status && value?.status === 2
                        ? t("canceled")
                        : t("pending"),
                    statusType:
                      value?.status && value?.status === 1
                        ? "success"
                        : value?.status && value?.status === 2
                        ? "danger"
                        : "info",
                    isColor: "",
                  },
                ],
              });
            }
            if (
              moment(new Date()).subtract(1, "year").format("YYYY") ===
              moment(new Date(value?.created_at)).format("YYYY")
            ) {
              lySuccessionPlan = lySuccessionPlan + 1;
              lyResult.push({
                _id: value?._id,
                rowData: [
                  {
                    _id: 1,
                    width: 15,
                    type: "text",
                    data: value?.company_name,
                    isColor: "",
                  },
                  {
                    _id: 2,
                    width: 20,
                    type: "text",
                    data: Number(value?.conclusion_info.length),
                    isColor: "",
                  },
                  {
                    _id: 3,
                    width: 20,
                    type: "text",
                    data:
                      Number(value?.individual_info.length) -
                      Number(value?.conclusion_info.length),
                    isColor: "",
                  },
                  {
                    _id: 4,
                    width: 15,
                    type: "text",
                    data:
                      "$" +
                      (value.code_info
                        ? Number(value?.code_info?.price).toFixed(2)
                        : value.plan_info
                        ? Number(value?.plan_info?.price).toFixed(2)
                        : Number(value?.subscription_details[0]?.price).toFixed(
                            2
                          )),
                    isColor: "",
                  },
                  {
                    _id: 5,
                    width: 15,
                    type: "status",
                    statusLabel:
                      value?.status && value?.status === 1
                        ? t("active")
                        : value?.status && value?.status === 2
                        ? t("canceled")
                        : t("pending"),
                    statusType:
                      value?.status && value?.status === 1
                        ? "success"
                        : value?.status && value?.status === 2
                        ? "danger"
                        : "info",
                    isColor: "",
                  },
                ],
              });
            }
            totalResult.push({
              _id: value?._id,
              rowData: [
                {
                  _id: 1,
                  width: 15,
                  type: "text",
                  data: value?.company_name,
                  isColor: "",
                },
                {
                  _id: 2,
                  width: 20,
                  type: "text",
                  data: Number(value?.conclusion_info.length),
                  isColor: "",
                },
                {
                  _id: 3,
                  width: 20,
                  type: "text",
                  data:
                    Number(value?.individual_info.length) -
                    Number(value?.conclusion_info.length),
                  isColor: "",
                },
                {
                  _id: 4,
                  width: 15,
                  type: "text",
                  data:
                    "$" +
                    (value.code_info
                      ? Number(value?.code_info?.price).toFixed(2)
                      : value.plan_info
                      ? Number(value?.plan_info?.price).toFixed(2)
                      : Number(value?.subscription_details[0]?.price).toFixed(
                          2
                        )),
                  isColor: "",
                },
                {
                  _id: 5,
                  width: 15,
                  type: "status",
                  statusLabel:
                    value?.status && value?.status === 1
                      ? t("active")
                      : value?.status && value?.status === 2
                      ? t("canceled")
                      : t("pending"),
                  statusType:
                    value?.status && value?.status === 1
                      ? "success"
                      : value?.status && value?.status === 2
                      ? "danger"
                      : "info",
                  isColor: "",
                },
              ],
            });
          });
          if (subTypes && subTypes === "total") {
            setCountData(result.count);
            setResults(totalResult);
          } else if (subTypes && subTypes === "current-year") {
            setCountData(cySuccessionPlan);
            setResults(cyResult);
          } else if (subTypes && subTypes === "previous-year") {
            setCountData(lySuccessionPlan);
            setResults(lyResult);
          } else if (subTypes && subTypes === "current-month") {
            setCountData(cmSuccessionPlan);
            setResults(cmResult);
          } else if (subTypes && subTypes === "previous-month") {
            setCountData(lmSuccessionPlan);
            setResults(lmResult);
          }
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err.message);
      }
    };

    const loadExpensesList = async () => {
      setLoading(true);
      try {
        const result = await postData("expenses/list", {
          sortQuery: { created_at: -1 },
          type: expenseType,
          date: date,
        });
        if (result.status === 200) {
          setExpensesData(result.data);
          let cmExpenses = 0;
          let lmExpenses = 0;
          let cyExpenses = 0;
          let lyExpenses = 0;
          let totalResult = [];
          let cmResult = [];
          let lmResult = [];
          let cyResult = [];
          let lyResult = [];
          let totalNetRevenew = [];
          let cmNetRevenew = [];
          let lmNetRevenew = [];
          let cyNetRevenew = [];
          let lyNetRevenew = [];
          result.data.map((value, key) => {
            let details = '';
            if (value.isCommission === 1) {
              let staffCode = "";
              if (value?.staff_code && value?.staff_code === "sales-staff") {
                staffCode = t("sales_person");
              } else if (
                value?.staff_code &&
                value?.staff_code === "consultant"
              ) {
                staffCode = t("consultant");
              } else if (
                value?.staff_code &&
                value?.staff_code === "appointment-setter"
              ) {
                staffCode = t("appointment_setter");
              } else if (
                value?.staff_code &&
                value?.staff_code === "sales-admin"
              ) {
                staffCode = t("sales_admin");
              }
              details =
                t("commission_has_been_given_to") +
                " " +
                value?.staff_name +
                " " +
                t("as_a") +
                " " +
                staffCode;
            }
            totalResult.push({
              _id: value?._id,
              rowData: [
                {
                  _id: 1,
                  width: 15,
                  type: "text",
                  data: (value?.isCommission ===3)?value?.type_name:t('commission'),
                  isColor: "",
                },
                {
                  _id: 2,
                  width: 15,
                  type: "text",
                  data: "$" + Number(value?.amount).toFixed(2),
                  isColor: "",
                },
                {
                  _id: 3,
                  width: 20,
                  type: "text",
                  data:
                    value && value?.date
                      ? moment(new Date(value?.date)).format("Do MMM YYYY")
                      : moment(new Date(value?.created_at)).format("Do MMM YYYY"),
                  isColor: "",
                },
                {
                  _id: 4,
                  width: 20,
                  type: "text",
                  data: (value?.isCommission ===3)?value?.comments:details,
                  isColor: "",
                },
                {
                  ...(value?.isCommission ===3)?
                  {
                    _id: 5,
                    width: 10,
                    type: "action",
                    data: [{ "edit-expenses-info": 1 }],
                    isColor: "",
                  }:''
                }                
              ],
            });
            totalNetRevenew.push({ ...value, type: "Expenses" });
            if (
              moment(new Date()).format("YYYY-MM") ===
              moment(new Date(value?.created_at)).format("YYYY-MM")
            ) {
              cmExpenses = cmExpenses + 1;
              cmResult.push({
                _id: value?._id,
                rowData: [
                  {
                    _id: 1,
                    width: 15,
                    type: "text",
                    data: (value?.isCommission ===3)?value?.type_name:t('commission'),
                    isColor: "",
                  },
                  {
                    _id: 2,
                    width: 15,
                    type: "text",
                    data: "$" + Number(value?.amount).toFixed(2),
                    isColor: "",
                  },
                  {
                    _id: 3,
                    width: 20,
                    type: "text",
                    data:
                      value && value?.date
                        ? moment(new Date(value?.date)).format("Do MMM YYYY")
                        : moment(new Date(value?.created_at)).format("Do MMM YYYY"),
                    isColor: "",
                  },
                  {
                    _id: 4,
                    width: 20,
                    type: "text",
                    data: (value?.isCommission ===3)?value?.comments:details,
                    isColor: "",
                  },
                  {
                    ...(value?.isCommission ===3)?
                    {
                      _id: 5,
                      width: 10,
                      type: "action",
                      data: [{ "edit-expenses-info": 1 }],
                      isColor: "",
                    }:''
                  }                ],
              });
              cmNetRevenew.push({ ...value, type: "Expenses" });
            }
            if (
              moment(new Date()).subtract(1, "month").format("YYYY-MM") ===
              moment(new Date(value?.created_at)).format("YYYY-MM")
            ) {
              lmExpenses = lmExpenses + 1;
              lmResult.push({
                _id: value?._id,
                rowData: [
                  {
                    _id: 1,
                    width: 15,
                    type: "text",
                    data: (value?.isCommission ===3)?value?.type_name:t('commission'),
                    isColor: "",
                  },
                  {
                    _id: 2,
                    width: 15,
                    type: "text",
                    data: "$" + Number(value?.amount).toFixed(2),
                    isColor: "",
                  },
                  {
                    _id: 3,
                    width: 20,
                    type: "text",
                    data:
                      value && value?.date
                        ? moment(new Date(value?.date)).format("Do MMM YYYY")
                        : moment(new Date(value?.created_at)).format("Do MMM YYYY"),
                    isColor: "",
                  },
                  {
                    _id: 4,
                    width: 20,
                    type: "text",
                    data: (value?.isCommission ===3)?value?.comments:details,
                    isColor: "",
                  },
                  {
                    ...(value?.isCommission ===3)?
                    {
                      _id: 5,
                      width: 10,
                      type: "action",
                      data: [{ "edit-expenses-info": 1 }],
                      isColor: "",
                    }:''
                  }
                ],
              });
              lmNetRevenew.push({ ...value, type: "Expenses" });
            }
            if (
              moment(new Date()).format("YYYY") ===
              moment(new Date(value?.created_at)).format("YYYY")
            ) {
              cyExpenses = cyExpenses + 1;
              cyResult.push({
                _id: value?._id,
                rowData: [
                  {
                    _id: 1,
                    width: 15,
                    type: "text",
                    data: (value?.isCommission ===3)?value?.type_name:t('commission'),
                    isColor: "",
                  },
                  {
                    _id: 2,
                    width: 15,
                    type: "text",
                    data: "$" + Number(value?.amount).toFixed(2),
                    isColor: "",
                  },
                  {
                    _id: 3,
                    width: 20,
                    type: "text",
                    data:
                      value && value?.date
                        ? moment(new Date(value?.date)).format("Do MMM YYYY")
                        : moment(new Date(value?.created_at)).format("Do MMM YYYY"),
                    isColor: "",
                  },
                  {
                    _id: 4,
                    width: 20,
                    type: "text",
                    data: (value?.isCommission ===3)?value?.comments:details,
                    isColor: "",
                  },
                  {
                    ...(value?.isCommission ===3)?
                    {
                      _id: 5,
                      width: 10,
                      type: "action",
                      data: [{ "edit-expenses-info": 1 }],
                      isColor: "",
                    }:''
                  }
                ],
              });
              cyNetRevenew.push({ ...value, type: "Expenses" });
            }
            if (
              moment(new Date()).subtract(1, "year").format("YYYY") ===
              moment(new Date(value?.created_at)).format("YYYY")
            ) {
              lyExpenses = lyExpenses + 1;
              lyResult.push({
                _id: value?._id,
                rowData: [
                  {
                    _id: 1,
                    width: 15,
                    type: "text",
                    data: (value?.isCommission ===3)?value?.type_name:t('commission'),
                    isColor: "",
                  },
                  {
                    _id: 2,
                    width: 15,
                    type: "text",
                    data: "$" + Number(value?.amount).toFixed(2),
                    isColor: "",
                  },
                  {
                    _id: 3,
                    width: 20,
                    type: "text",
                    data:
                      value && value?.date
                        ? moment(new Date(value?.date)).format("Do MMM YYYY")
                        : moment(new Date(value?.created_at)).format("Do MMM YYYY"),
                    isColor: "",
                  },
                  {
                    _id: 4,
                    width: 20,
                    type: "text",
                    data: (value?.isCommission ===3)?value?.comments:details,
                    isColor: "",
                  },
                  {
                    ...(value?.isCommission ===3)?
                    {
                      _id: 5,
                      width: 10,
                      type: "action",
                      data: [{ "edit-expenses-info": 1 }],
                      isColor: "",
                    }:''
                  }
                ],
              });
              lyNetRevenew.push({ ...value, type: "Expenses" });
            }
          });
          if (type && type === "net-revenue") {
            if (subTypes && subTypes === "total") {
              setExpensesResult(totalNetRevenew);
            } else if (subTypes && subTypes === "current-year") {
              setExpensesResult(cyNetRevenew);
            } else if (subTypes && subTypes === "previous-year") {
              setExpensesResult(lyNetRevenew);
            } else if (subTypes && subTypes === "current-month") {
              setExpensesResult(cmNetRevenew);
            } else if (subTypes && subTypes === "previous-month") {
              setExpensesResult(lmNetRevenew);
            }
          } else {
            if (subTypes && subTypes === "total") {
              setCountData(result.count);
              setResults(totalResult);
            } else if (subTypes && subTypes === "current-year") {
              setCountData(cyExpenses);
              setResults(cyResult);
            } else if (subTypes && subTypes === "previous-year") {
              setCountData(lyExpenses);
              setResults(lyResult);
            } else if (subTypes && subTypes === "current-month") {
              setCountData(cmExpenses);
              setResults(cmResult);
            } else if (subTypes && subTypes === "previous-month") {
              setCountData(lmExpenses);
              setResults(lmResult);
            }
          }
        }
        setLoading(false);
        setChangeValue({});
      } catch (err) {
        setLoading(false);
        console.log(err.message);
        setChangeValue({});
      }
    };

    const loadCommissionsList = async () => {
      setLoading(true);
      try {
        const result = await postData("commission/list", {
          code: ["customer-admin"],
          sortQuery: { ["created_at"]: -1 },
          limit: 100,
          offset: offset,
          salesId: salesId,
          consultantId: consultantId,
          appointmentSetterId: appointmentSetterId,
          date: date,
          payment_status: paymentStatus,
        });
        if (result.status === 200) {
          setCommisionFinalResult(result.data);
          let cmExpenses = 0;
          let lmExpenses = 0;
          let cyExpenses = 0;
          let lyExpenses = 0;
          let totalResult = [];
          let cmResult = [];
          let lmResult = [];
          let cyResult = [];
          let lyResult = [];
          let totalAvgResult = [];
          let cmAvgResult = [];
          let lmAvgResult = [];
          let cyAvgResult = [];
          let lyAvgResult = [];
          let totalNRCResult = [];
          let cmNRCResult = [];
          let lmNRCResult = [];
          let cyNRCResult = [];
          let lyNRCResult = [];
          result.data.map((value, key) => {
            let salesId = "";
            let salesPersion = "";
            let salesCommission = 0;
            let consultantId = 0;
            let salesPrice = 0;
            let consultantPersion = "";
            let consultantCommission = 0;
            let consultantPrice = 0;
            let appointmentSetterId = "";
            let appointmentSetterPersion = "";
            let appointmentSetterCommission = 0;
            let appointmentSetterPrice = 0;
            let salesAdminId = "";
            let salesAdminPersion = "";
            let salesAdminPrice = 0;
            let salesAdminCommission = 0;
            let sales =
              value?.staffs.length > 0 &&
              value?.staffs.filter((item) => item.code === "sales-staff");
            if (sales && sales.length > 0) {
              salesId = sales[0].staff_id;
              let salesPersionArray = value?.staff_details.filter(
                (itm) => itm._id === salesId
              );
              if (salesPersionArray && salesPersionArray.length > 0) {
                let finalSalesCommission =
                  salesPersionArray[0]?.commission?.filter(
                    (sale) => sale.code === "sales-staff"
                  )[0]?.commission;
                salesPersion =
                  salesPersionArray[0].first_name +
                  " " +
                  salesPersionArray[0].last_name;
                salesCommission = sales[0].commission
                  ? sales[0].commission
                  : finalSalesCommission;
                salesPrice =
                  (Number(value?.original_price) * Number(salesCommission)) /
                  100;
              }
            }
            let consultant =
              value?.staffs.length > 0 &&
              value?.staffs.filter((item) => item.code === "consultant");
            if (consultant && consultant.length > 0) {
              consultantId = consultant[0].staff_id;
              let consultantPersionArray = value?.staff_details.filter(
                (itm) => itm._id === consultantId
              );
              if (consultantPersionArray && consultantPersionArray.length > 0) {
                let finalConsultantCommission =
                  consultantPersionArray[0]?.commission?.filter(
                    (consult) => consult.code === "consultant"
                  )[0]?.commission;
                consultantPersion =
                  consultantPersionArray[0].first_name +
                  " " +
                  consultantPersionArray[0].last_name;
                consultantCommission = consultant[0].commission
                  ? consultant[0].commission
                  : finalConsultantCommission;
                consultantPrice =
                  (Number(value?.original_price) *
                    Number(consultantCommission)) /
                  100;
              }
            }
            let appointmentSetter =
              value?.staffs.length > 0 &&
              value?.staffs.filter(
                (item) => item.code === "appointment-setter"
              );
            if (appointmentSetter && appointmentSetter.length > 0) {
              appointmentSetterId = appointmentSetter[0].staff_id;
              let appointmentSetterPersionArray = value?.staff_details.filter(
                (itm) => itm._id === appointmentSetterId
              );
              if (
                appointmentSetterPersionArray &&
                appointmentSetterPersionArray.length > 0
              ) {
                let finalAppointmentSetterCommission =
                  appointmentSetterPersionArray[0]?.commission?.filter(
                    (appt) => appt.code === "appointment-setter"
                  )[0]?.commission;
                appointmentSetterPersion =
                  appointmentSetterPersionArray[0].first_name +
                  " " +
                  appointmentSetterPersionArray[0].last_name;
                appointmentSetterCommission = appointmentSetter[0].commission
                  ? appointmentSetter[0].commission
                  : finalAppointmentSetterCommission;
                appointmentSetterPrice =
                  (Number(value?.original_price) *
                    Number(appointmentSetterCommission)) /
                  100;
              }
            }
            let salesAdmin =
              value?.staffs.length > 0 &&
              value?.staffs.filter((item) => item.code === "sales-admin");
            if (salesAdmin && salesAdmin.length > 0) {
              salesAdminId = salesAdmin[0].staff_id;
              let salesAdminPersionArray = value?.staff_details.filter(
                (itm) => itm._id === salesAdminId
              );
              if (salesAdminPersionArray && salesAdminPersionArray.length > 0) {
                let finalSalesAdminCommission =
                  salesAdminPersionArray[0]?.commission?.filter(
                    (salesAdmin) => salesAdmin.code === "sales-admin"
                  )[0]?.commission;
                salesAdminPersion =
                  salesAdminPersionArray[0].first_name +
                  " " +
                  salesAdminPersionArray[0].last_name;
                salesAdminCommission = salesAdmin[0].commission
                  ? salesAdmin[0].commission
                  : finalSalesAdminCommission;
                salesAdminPrice =
                  (Number(value?.original_price) *
                    Number(salesAdminCommission)) /
                  100;
              }
            }
            let salesCommissionPay =
              value?.staff_commission.length > 0 &&
              value?.staff_commission.filter(
                (item) => item.staff_code === "sales-staff"
              );
            let consultantCommissionPay =
              value?.staff_commission.length > 0 &&
              value?.staff_commission.filter(
                (item) => item.staff_code === "consultant"
              );
            let appointmentSetterCommissionPay =
              value?.staff_commission.length > 0 &&
              value?.staff_commission.filter(
                (item) => item.staff_code === "appointment-setter"
              );
            let salesAdminCommissionPay =
              value?.staff_commission.length > 0 &&
              value?.staff_commission.filter(
                (item) => item.staff_code === "sales-admin"
              );            

            let primaryId = "";
            let primaryName = "";
            let primaryPrice = 0;
            if (code && code === "sales-staff") {
              primaryId = salesId;
              primaryName = salesPersion ? salesPersion : "";
              primaryPrice = salesPersion ? salesPrice : 0;
            } else if (code && code === "consultant") {
              primaryId = consultantId;
              primaryName = consultantPersion ? consultantPersion : "";
              primaryPrice = consultantPersion ? consultantPrice : 0;
            } else if (code && code === "appointment-setter") {
              primaryId = appointmentSetterId;
              primaryName = appointmentSetterPersion
                ? appointmentSetterPersion
                : "";
              primaryPrice = appointmentSetterPersion
                ? appointmentSetterPrice
                : 0;
            } else if (code && code === "sales-admin") {
              primaryId = salesAdminId;
              primaryName = salesAdminPersion ? salesAdminPersion : "";
              primaryPrice = salesAdminPersion ? salesAdminPrice : 0;
            }

            if (
              moment(new Date()).format("YYYY-MM") ===
              moment(new Date(value?.created_at)).format("YYYY-MM")
            ) {
              cmExpenses = cmExpenses + 1;
              cmResult.push({
                _id: value?._id,
                rowData: [
                  {
                    _id: 1,
                    width: 15,
                    type: "text",
                    data: value?.company_name,
                    isColor: "",
                  },
                  {
                    _id: 2,
                    width: 15,
                    type: "text",
                    data:
                      value && value?.created_at
                        ? moment(new Date(value?.created_at)).format(
                            "MMM Do YYYY"
                          )
                        : "N/A",
                    isColor: "",
                  },
                  {
                    _id: 3,
                    width: 15,
                    type: "text",
                    data: "$" + Number(value?.original_price).toFixed(2),
                    isColor: "",
                  },
                  {
                    _id: 4,
                    width: 20,
                    type: "text",
                    data: salesPersion ? salesPersion : t("none"),
                    isColor: "",
                  },
                  {
                    _id: 5,
                    width: 20,
                    type: "text",
                    data: (salesCommission ? salesCommission : 0) + "%",
                    isColor: "",
                  },
                  {
                    _id: 6,
                    width: 20,
                    type: "text",
                    data: "$" + (salesPrice ? salesPrice : 0).toFixed(2),
                    isColor: "",
                  },
                  {
                    _id: 7,
                    width: 20,
                    type:
                      salesCommissionPay && salesCommissionPay.length > 0
                        ? "text"
                        : "checkbox",
                    data:
                      salesCommissionPay && salesCommissionPay.length > 0
                        ? t("paid")
                        : salesId
                        ? salesId
                        : "",
                    value:
                      salesCommissionPay && salesCommissionPay.length > 0
                        ? 1
                        : 2,
                    isColor:
                      salesCommissionPay && salesCommissionPay.length > 0
                        ? "!text-teal-500"
                        : "",
                    code: "sales-staff",
                  },
                  {
                    _id: 8,
                    width: 20,
                    type: "text",
                    data: consultantPersion ? consultantPersion : t("none"),
                    isColor: "",
                  },
                  {
                    _id: 9,
                    width: 20,
                    type: "text",
                    data:
                      (consultantCommission ? consultantCommission : 0) + "%",
                    isColor: "",
                  },
                  {
                    _id: 10,
                    width: 20,
                    type: "text",
                    data:
                      "$" + (consultantPrice ? consultantPrice : 0).toFixed(2),
                    isColor: "",
                  },
                  {
                    _id: 11,
                    width: 20,
                    type:
                      consultantCommissionPay &&
                      consultantCommissionPay.length > 0
                        ? "text"
                        : "checkbox",
                    data:
                      consultantCommissionPay &&
                      consultantCommissionPay.length > 0
                        ? t("paid")
                        : consultantId
                        ? consultantId
                        : "",
                    value:
                      consultantCommissionPay &&
                      consultantCommissionPay.length > 0
                        ? 1
                        : 2,
                    isColor:
                      consultantCommissionPay &&
                      consultantCommissionPay.length > 0
                        ? "!text-teal-500"
                        : "",
                    code: "consultant",
                  },
                  {
                    _id: 12,
                    width: 20,
                    type: "text",
                    data: appointmentSetterPersion
                      ? appointmentSetterPersion
                      : t("none"),
                    isColor: "",
                  },
                  {
                    _id: 13,
                    width: 20,
                    type: "text",
                    data:
                      (appointmentSetterCommission
                        ? appointmentSetterCommission
                        : 0) + "%",
                    isColor: "",
                  },
                  {
                    _id: 14,
                    width: 20,
                    type: "text",
                    data:
                      "$" +
                      (appointmentSetterPrice
                        ? appointmentSetterPrice
                        : 0
                      ).toFixed(2),
                    isColor: "",
                  },
                  {
                    _id: 15,
                    width: 20,
                    type:
                      appointmentSetterCommissionPay &&
                      appointmentSetterCommissionPay.length > 0
                        ? "text"
                        : "checkbox",
                    data:
                      appointmentSetterCommissionPay &&
                      appointmentSetterCommissionPay.length > 0
                        ? t("paid")
                        : appointmentSetterId
                        ? appointmentSetterId
                        : "",
                    value:
                      appointmentSetterCommissionPay &&
                      appointmentSetterCommissionPay.length > 0
                        ? 1
                        : 2,
                    isColor:
                      appointmentSetterCommissionPay &&
                      appointmentSetterCommissionPay.length > 0
                        ? "!text-teal-500"
                        : "",
                    code: "appointment-setter",
                  },
                  {
                    _id: 16,
                    width: 20,
                    type: "text",
                    data: salesAdminPersion ? salesAdminPersion : t("none"),
                    isColor: "",
                  },
                  {
                    _id: 17,
                    width: 20,
                    type: "text",
                    data:
                      (salesAdminCommission ? salesAdminCommission : 0) + "%",
                    isColor: "",
                  },
                  {
                    _id: 18,
                    width: 20,
                    type: "text",
                    data:
                      "$" + (salesAdminPrice ? salesAdminPrice : 0).toFixed(2),
                    isColor: "",
                  },
                  {
                    _id: 19,
                    width: 20,
                    type:
                      salesAdminCommissionPay &&
                      salesAdminCommissionPay.length > 0
                        ? "text"
                        : "checkbox",
                    data:
                      salesAdminCommissionPay &&
                      salesAdminCommissionPay.length > 0
                        ? t("paid")
                        : salesAdminId
                        ? salesAdminId
                        : "",
                    value:
                      salesAdminCommissionPay &&
                      salesAdminCommissionPay.length > 0
                        ? 1
                        : 2,
                    isColor:
                      salesAdminCommissionPay &&
                      salesAdminCommissionPay.length > 0
                        ? "!text-teal-500"
                        : "",
                    code: "sales-admin",
                  },
                  {
                    _id: 20,
                    width: 20,
                    type: "text",
                    data:
                      "$" +
                      Number(
                        (salesPrice ? salesPrice : 0) +
                          (consultantPrice ? consultantPrice : 0) +
                          (appointmentSetterPrice
                            ? appointmentSetterPrice
                            : 0) +
                          (salesAdminPrice ? salesAdminPrice : 0)
                      ).toFixed(2),
                    value: 1,
                    isColor: "!text-teal-500",
                    code: "tot-commission",
                  },
                ],
              });
              cmAvgResult.push({
                _id: primaryId,
                name: primaryName,
                total: Number(value?.original_price),
                price: Number(primaryPrice),
                qty: 1,
              });
              cmNRCResult.push({
                _id: value?._id,
                company_name : value?.company_name, 
                name: primaryName,     
                original_price: Number(value?.original_price),         
                price: Number(primaryPrice),
                date: value?.created_at,
                type: "Commission",
                salesCommissionPay :  salesCommissionPay,
                salesCommission : salesCommission,
                consultantCommissionPay :  consultantCommissionPay,
                consultantCommission : consultantCommission,
                appointmentSetterCommissionPay :  appointmentSetterCommissionPay,
                appointmentSetterCommission : appointmentSetterCommission,
                salesAdminCommissionPay :  salesAdminCommissionPay,
                salesAdminCommission: salesAdminCommission
              });
            }
            if (
              moment(new Date()).subtract(1, "month").format("YYYY-MM") ===
              moment(new Date(value?.created_at)).format("YYYY-MM")
            ) {
              lmExpenses = lmExpenses + 1;
              lmResult.push({
                _id: value?._id,
                rowData: [
                  {
                    _id: 1,
                    width: 15,
                    type: "text",
                    data: value?.company_name,
                    isColor: "",
                  },
                  {
                    _id: 2,
                    width: 15,
                    type: "text",
                    data:
                      value && value?.created_at
                        ? moment(new Date(value?.created_at)).format(
                            "MMM Do YYYY"
                          )
                        : "N/A",
                    isColor: "",
                  },
                  {
                    _id: 3,
                    width: 15,
                    type: "text",
                    data: "$" + Number(value?.original_price).toFixed(2),
                    isColor: "",
                  },
                  {
                    _id: 4,
                    width: 20,
                    type: "text",
                    data: salesPersion ? salesPersion : t("none"),
                    isColor: "",
                  },
                  {
                    _id: 5,
                    width: 20,
                    type: "text",
                    data: (salesCommission ? salesCommission : 0) + "%",
                    isColor: "",
                  },
                  {
                    _id: 6,
                    width: 20,
                    type: "text",
                    data: "$" + (salesPrice ? salesPrice : 0).toFixed(2),
                    isColor: "",
                  },
                  {
                    _id: 7,
                    width: 20,
                    type:
                      salesCommissionPay && salesCommissionPay.length > 0
                        ? "text"
                        : "checkbox",
                    data:
                      salesCommissionPay && salesCommissionPay.length > 0
                        ? t("paid")
                        : salesId
                        ? salesId
                        : "",
                    value:
                      salesCommissionPay && salesCommissionPay.length > 0
                        ? 1
                        : 2,
                    isColor:
                      salesCommissionPay && salesCommissionPay.length > 0
                        ? "!text-teal-500"
                        : "",
                    code: "sales-staff",
                  },
                  {
                    _id: 8,
                    width: 20,
                    type: "text",
                    data: consultantPersion ? consultantPersion : t("none"),
                    isColor: "",
                  },
                  {
                    _id: 9,
                    width: 20,
                    type: "text",
                    data:
                      (consultantCommission ? consultantCommission : 0) + "%",
                    isColor: "",
                  },
                  {
                    _id: 10,
                    width: 20,
                    type: "text",
                    data:
                      "$" + (consultantPrice ? consultantPrice : 0).toFixed(2),
                    isColor: "",
                  },
                  {
                    _id: 11,
                    width: 20,
                    type:
                      consultantCommissionPay &&
                      consultantCommissionPay.length > 0
                        ? "text"
                        : "checkbox",
                    data:
                      consultantCommissionPay &&
                      consultantCommissionPay.length > 0
                        ? t("paid")
                        : consultantId
                        ? consultantId
                        : "",
                    value:
                      consultantCommissionPay &&
                      consultantCommissionPay.length > 0
                        ? 1
                        : 2,
                    isColor:
                      consultantCommissionPay &&
                      consultantCommissionPay.length > 0
                        ? "!text-teal-500"
                        : "",
                    code: "consultant",
                  },
                  {
                    _id: 12,
                    width: 20,
                    type: "text",
                    data: appointmentSetterPersion
                      ? appointmentSetterPersion
                      : t("none"),
                    isColor: "",
                  },
                  {
                    _id: 13,
                    width: 20,
                    type: "text",
                    data:
                      (appointmentSetterCommission
                        ? appointmentSetterCommission
                        : 0) + "%",
                    isColor: "",
                  },
                  {
                    _id: 14,
                    width: 20,
                    type: "text",
                    data:
                      "$" +
                      (appointmentSetterPrice
                        ? appointmentSetterPrice
                        : 0
                      ).toFixed(2),
                    isColor: "",
                  },
                  {
                    _id: 15,
                    width: 20,
                    type:
                      appointmentSetterCommissionPay &&
                      appointmentSetterCommissionPay.length > 0
                        ? "text"
                        : "checkbox",
                    data:
                      appointmentSetterCommissionPay &&
                      appointmentSetterCommissionPay.length > 0
                        ? t("paid")
                        : appointmentSetterId
                        ? appointmentSetterId
                        : "",
                    value:
                      appointmentSetterCommissionPay &&
                      appointmentSetterCommissionPay.length > 0
                        ? 1
                        : 2,
                    isColor:
                      appointmentSetterCommissionPay &&
                      appointmentSetterCommissionPay.length > 0
                        ? "!text-teal-500"
                        : "",
                    code: "appointment-setter",
                  },
                  {
                    _id: 16,
                    width: 20,
                    type: "text",
                    data: salesAdminPersion ? salesAdminPersion : t("none"),
                    isColor: "",
                  },
                  {
                    _id: 17,
                    width: 20,
                    type: "text",
                    data:
                      (salesAdminCommission ? salesAdminCommission : 0) + "%",
                    isColor: "",
                  },
                  {
                    _id: 18,
                    width: 20,
                    type: "text",
                    data:
                      "$" + (salesAdminPrice ? salesAdminPrice : 0).toFixed(2),
                    isColor: "",
                  },
                  {
                    _id: 19,
                    width: 20,
                    type:
                      salesAdminCommissionPay &&
                      salesAdminCommissionPay.length > 0
                        ? "text"
                        : "checkbox",
                    data:
                      salesAdminCommissionPay &&
                      salesAdminCommissionPay.length > 0
                        ? t("paid")
                        : salesAdminId
                        ? salesAdminId
                        : "",
                    value:
                      salesAdminCommissionPay &&
                      salesAdminCommissionPay.length > 0
                        ? 1
                        : 2,
                    isColor:
                      salesAdminCommissionPay &&
                      salesAdminCommissionPay.length > 0
                        ? "!text-teal-500"
                        : "",
                    code: "sales-admin",
                  },
                  {
                    _id: 20,
                    width: 20,
                    type: "text",
                    data:
                      "$" +
                      Number(
                        (salesPrice ? salesPrice : 0) +
                          (consultantPrice ? consultantPrice : 0) +
                          (appointmentSetterPrice
                            ? appointmentSetterPrice
                            : 0) +
                          (salesAdminPrice ? salesAdminPrice : 0)
                      ).toFixed(2),
                    value: 1,
                    isColor: "!text-teal-500",
                    code: "tot-commission",
                  },
                ],
              });
              lmAvgResult.push({
                _id: primaryId,
                name: primaryName,
                total: Number(value?.original_price),
                price: Number(primaryPrice),
                qty: 1,
              });
              lmNRCResult.push({
                _id: value?._id,
                company_name : value?.company_name, 
                name: primaryName,     
                original_price: Number(value?.original_price),         
                price: Number(primaryPrice),
                date: value?.created_at,
                type: "Commission",
                salesCommissionPay :  salesCommissionPay,
                salesCommission : salesCommission,
                consultantCommissionPay :  consultantCommissionPay,
                consultantCommission : consultantCommission,
                appointmentSetterCommissionPay :  appointmentSetterCommissionPay,
                appointmentSetterCommission : appointmentSetterCommission,
                salesAdminCommissionPay :  salesAdminCommissionPay,
                salesAdminCommission: salesAdminCommission
              });
            }
            if (
              moment(new Date()).format("YYYY") ===
              moment(new Date(value?.created_at)).format("YYYY")
            ) {
              cyExpenses = cyExpenses + 1;
              cyResult.push({
                _id: value?._id,
                rowData: [
                  {
                    _id: 1,
                    width: 15,
                    type: "text",
                    data: value?.company_name,
                    isColor: "",
                  },
                  {
                    _id: 2,
                    width: 15,
                    type: "text",
                    data:
                      value && value?.created_at
                        ? moment(new Date(value?.created_at)).format(
                            "MMM Do YYYY"
                          )
                        : "N/A",
                    isColor: "",
                  },
                  {
                    _id: 3,
                    width: 15,
                    type: "text",
                    data: "$" + Number(value?.original_price).toFixed(2),
                    isColor: "",
                  },
                  {
                    _id: 4,
                    width: 20,
                    type: "text",
                    data: salesPersion ? salesPersion : t("none"),
                    isColor: "",
                  },
                  {
                    _id: 5,
                    width: 20,
                    type: "text",
                    data: (salesCommission ? salesCommission : 0) + "%",
                    isColor: "",
                  },
                  {
                    _id: 6,
                    width: 20,
                    type: "text",
                    data: "$" + (salesPrice ? salesPrice : 0).toFixed(2),
                    isColor: "",
                  },
                  {
                    _id: 7,
                    width: 20,
                    type:
                      salesCommissionPay && salesCommissionPay.length > 0
                        ? "text"
                        : "checkbox",
                    data:
                      salesCommissionPay && salesCommissionPay.length > 0
                        ? t("paid")
                        : salesId
                        ? salesId
                        : "",
                    value:
                      salesCommissionPay && salesCommissionPay.length > 0
                        ? 1
                        : 2,
                    isColor:
                      salesCommissionPay && salesCommissionPay.length > 0
                        ? "!text-teal-500"
                        : "",
                    code: "sales-staff",
                  },
                  {
                    _id: 8,
                    width: 20,
                    type: "text",
                    data: consultantPersion ? consultantPersion : t("none"),
                    isColor: "",
                  },
                  {
                    _id: 9,
                    width: 20,
                    type: "text",
                    data:
                      (consultantCommission ? consultantCommission : 0) + "%",
                    isColor: "",
                  },
                  {
                    _id: 10,
                    width: 20,
                    type: "text",
                    data:
                      "$" + (consultantPrice ? consultantPrice : 0).toFixed(2),
                    isColor: "",
                  },
                  {
                    _id: 11,
                    width: 20,
                    type:
                      consultantCommissionPay &&
                      consultantCommissionPay.length > 0
                        ? "text"
                        : "checkbox",
                    data:
                      consultantCommissionPay &&
                      consultantCommissionPay.length > 0
                        ? t("paid")
                        : consultantId
                        ? consultantId
                        : "",
                    value:
                      consultantCommissionPay &&
                      consultantCommissionPay.length > 0
                        ? 1
                        : 2,
                    isColor:
                      consultantCommissionPay &&
                      consultantCommissionPay.length > 0
                        ? "!text-teal-500"
                        : "",
                    code: "consultant",
                  },
                  {
                    _id: 12,
                    width: 20,
                    type: "text",
                    data: appointmentSetterPersion
                      ? appointmentSetterPersion
                      : t("none"),
                    isColor: "",
                  },
                  {
                    _id: 13,
                    width: 20,
                    type: "text",
                    data:
                      (appointmentSetterCommission
                        ? appointmentSetterCommission
                        : 0) + "%",
                    isColor: "",
                  },
                  {
                    _id: 14,
                    width: 20,
                    type: "text",
                    data:
                      "$" +
                      (appointmentSetterPrice
                        ? appointmentSetterPrice
                        : 0
                      ).toFixed(2),
                    isColor: "",
                  },
                  {
                    _id: 15,
                    width: 20,
                    type:
                      appointmentSetterCommissionPay &&
                      appointmentSetterCommissionPay.length > 0
                        ? "text"
                        : "checkbox",
                    data:
                      appointmentSetterCommissionPay &&
                      appointmentSetterCommissionPay.length > 0
                        ? t("paid")
                        : appointmentSetterId
                        ? appointmentSetterId
                        : "",
                    value:
                      appointmentSetterCommissionPay &&
                      appointmentSetterCommissionPay.length > 0
                        ? 1
                        : 2,
                    isColor:
                      appointmentSetterCommissionPay &&
                      appointmentSetterCommissionPay.length > 0
                        ? "!text-teal-500"
                        : "",
                    code: "appointment-setter",
                  },
                  {
                    _id: 16,
                    width: 20,
                    type: "text",
                    data: salesAdminPersion ? salesAdminPersion : t("none"),
                    isColor: "",
                  },
                  {
                    _id: 17,
                    width: 20,
                    type: "text",
                    data:
                      (salesAdminCommission ? salesAdminCommission : 0) + "%",
                    isColor: "",
                  },
                  {
                    _id: 18,
                    width: 20,
                    type: "text",
                    data:
                      "$" + (salesAdminPrice ? salesAdminPrice : 0).toFixed(2),
                    isColor: "",
                  },
                  {
                    _id: 19,
                    width: 20,
                    type:
                      salesAdminCommissionPay &&
                      salesAdminCommissionPay.length > 0
                        ? "text"
                        : "checkbox",
                    data:
                      salesAdminCommissionPay &&
                      salesAdminCommissionPay.length > 0
                        ? t("paid")
                        : salesAdminId
                        ? salesAdminId
                        : "",
                    value:
                      salesAdminCommissionPay &&
                      salesAdminCommissionPay.length > 0
                        ? 1
                        : 2,
                    isColor:
                      salesAdminCommissionPay &&
                      salesAdminCommissionPay.length > 0
                        ? "!text-teal-500"
                        : "",
                    code: "sales-admin",
                  },
                  {
                    _id: 20,
                    width: 20,
                    type: "text",
                    data:
                      "$" +
                      Number(
                        (salesPrice ? salesPrice : 0) +
                          (consultantPrice ? consultantPrice : 0) +
                          (appointmentSetterPrice
                            ? appointmentSetterPrice
                            : 0) +
                          (salesAdminPrice ? salesAdminPrice : 0)
                      ).toFixed(2),
                    value: 1,
                    isColor: "!text-teal-500",
                    code: "tot-commission",
                  },
                ],
              });
              cyAvgResult.push({
                _id: primaryId,
                name: primaryName,
                total: Number(value?.original_price),
                price: Number(primaryPrice),
                qty: 1,
              });
              cyNRCResult.push({
                _id: value?._id,
                company_name : value?.company_name, 
                name: primaryName,     
                original_price: Number(value?.original_price),         
                price: Number(primaryPrice),
                date: value?.created_at,
                type: "Commission",
                salesCommissionPay :  salesCommissionPay,
                salesCommission : salesCommission,
                consultantCommissionPay :  consultantCommissionPay,
                consultantCommission : consultantCommission,
                appointmentSetterCommissionPay :  appointmentSetterCommissionPay,
                appointmentSetterCommission : appointmentSetterCommission,
                salesAdminCommissionPay :  salesAdminCommissionPay,
                salesAdminCommission: salesAdminCommission
              });
            }
            if (
              moment(new Date()).subtract(1, "year").format("YYYY") ===
              moment(new Date(value?.created_at)).format("YYYY")
            ) {
              lyExpenses = lyExpenses + 1;
              lyResult.push({
                _id: value?._id,
                rowData: [
                  {
                    _id: 1,
                    width: 15,
                    type: "text",
                    data: value?.company_name,
                    isColor: "",
                  },
                  {
                    _id: 2,
                    width: 15,
                    type: "text",
                    data:
                      value && value?.created_at
                        ? moment(new Date(value?.created_at)).format(
                            "MMM Do YYYY"
                          )
                        : "N/A",
                    isColor: "",
                  },
                  {
                    _id: 3,
                    width: 15,
                    type: "text",
                    data: "$" + Number(value?.original_price).toFixed(2),
                    isColor: "",
                  },
                  {
                    _id: 4,
                    width: 20,
                    type: "text",
                    data: salesPersion ? salesPersion : "None",
                    isColor: "",
                  },
                  {
                    _id: 5,
                    width: 20,
                    type: "text",
                    data: (salesCommission ? salesCommission : 0) + "%",
                    isColor: "",
                  },
                  {
                    _id: 6,
                    width: 20,
                    type: "text",
                    data: "$" + (salesPrice ? salesPrice : 0).toFixed(2),
                    isColor: "",
                  },
                  {
                    _id: 7,
                    width: 20,
                    type:
                      salesCommissionPay && salesCommissionPay.length > 0
                        ? "text"
                        : "checkbox",
                    data:
                      salesCommissionPay && salesCommissionPay.length > 0
                        ? t("paid")
                        : salesId
                        ? salesId
                        : "",
                    value:
                      salesCommissionPay && salesCommissionPay.length > 0
                        ? 1
                        : 2,
                    isColor:
                      salesCommissionPay && salesCommissionPay.length > 0
                        ? "!text-teal-500"
                        : "",
                    code: "sales-staff",
                  },
                  {
                    _id: 8,
                    width: 20,
                    type: "text",
                    data: consultantPersion ? consultantPersion : "None",
                    isColor: "",
                  },
                  {
                    _id: 9,
                    width: 20,
                    type: "text",
                    data:
                      (consultantCommission ? consultantCommission : 0) + "%",
                    isColor: "",
                  },
                  {
                    _id: 10,
                    width: 20,
                    type: "text",
                    data:
                      "$" + (consultantPrice ? consultantPrice : 0).toFixed(2),
                    isColor: "",
                  },
                  {
                    _id: 11,
                    width: 20,
                    type:
                      consultantCommissionPay &&
                      consultantCommissionPay.length > 0
                        ? "text"
                        : "checkbox",
                    data:
                      consultantCommissionPay &&
                      consultantCommissionPay.length > 0
                        ? t("paid")
                        : consultantId
                        ? consultantId
                        : "",
                    value:
                      consultantCommissionPay &&
                      consultantCommissionPay.length > 0
                        ? 1
                        : 2,
                    isColor:
                      consultantCommissionPay &&
                      consultantCommissionPay.length > 0
                        ? "!text-teal-500"
                        : "",
                    code: "consultant",
                  },
                  {
                    _id: 12,
                    width: 20,
                    type: "text",
                    data: appointmentSetterPersion
                      ? appointmentSetterPersion
                      : "None",
                    isColor: "",
                  },
                  {
                    _id: 13,
                    width: 20,
                    type: "text",
                    data:
                      (appointmentSetterCommission
                        ? appointmentSetterCommission
                        : 0) + "%",
                    isColor: "",
                  },
                  {
                    _id: 14,
                    width: 20,
                    type: "text",
                    data:
                      "$" +
                      (appointmentSetterPrice
                        ? appointmentSetterPrice
                        : 0
                      ).toFixed(2),
                    isColor: "",
                  },
                  {
                    _id: 15,
                    width: 20,
                    type:
                      appointmentSetterCommissionPay &&
                      appointmentSetterCommissionPay.length > 0
                        ? "text"
                        : "checkbox",
                    data:
                      appointmentSetterCommissionPay &&
                      appointmentSetterCommissionPay.length > 0
                        ? t("paid")
                        : appointmentSetterId
                        ? appointmentSetterId
                        : "",
                    value:
                      appointmentSetterCommissionPay &&
                      appointmentSetterCommissionPay.length > 0
                        ? 1
                        : 2,
                    isColor:
                      appointmentSetterCommissionPay &&
                      appointmentSetterCommissionPay.length > 0
                        ? "!text-teal-500"
                        : "",
                    code: "appointment-setter",
                  },
                  {
                    _id: 16,
                    width: 20,
                    type: "text",
                    data: salesAdminPersion ? salesAdminPersion : "None",
                    isColor: "",
                  },
                  {
                    _id: 17,
                    width: 20,
                    type: "text",
                    data:
                      (salesAdminCommission ? salesAdminCommission : 0) + "%",
                    isColor: "",
                  },
                  {
                    _id: 18,
                    width: 20,
                    type: "text",
                    data:
                      "$" + (salesAdminPrice ? salesAdminPrice : 0).toFixed(2),
                    isColor: "",
                  },
                  {
                    _id: 19,
                    width: 20,
                    type:
                      salesAdminCommissionPay &&
                      salesAdminCommissionPay.length > 0
                        ? "text"
                        : "checkbox",
                    data:
                      salesAdminCommissionPay &&
                      salesAdminCommissionPay.length > 0
                        ? t("paid")
                        : salesAdminId
                        ? salesAdminId
                        : "",
                    value:
                      salesAdminCommissionPay &&
                      salesAdminCommissionPay.length > 0
                        ? 1
                        : 2,
                    isColor:
                      salesAdminCommissionPay &&
                      salesAdminCommissionPay.length > 0
                        ? "!text-teal-500"
                        : "",
                    code: "sales-admin",
                  },
                  {
                    _id: 20,
                    width: 20,
                    type: "text",
                    data:
                      "$" +
                      Number(
                        (salesPrice ? salesPrice : 0) +
                          (consultantPrice ? consultantPrice : 0) +
                          (appointmentSetterPrice
                            ? appointmentSetterPrice
                            : 0) +
                          (salesAdminPrice ? salesAdminPrice : 0)
                      ).toFixed(2),
                    value: 1,
                    isColor: "!text-teal-500",
                    code: "tot-commission",
                  },
                ],
              });
              lyAvgResult.push({
                _id: primaryId,
                name: primaryName,
                total: Number(value?.original_price),
                price: Number(primaryPrice),
                qty: 1,
              });
              lyNRCResult.push({
                _id: value?._id,
                company_name : value?.company_name, 
                name: primaryName,     
                original_price: Number(value?.original_price),         
                price: Number(primaryPrice),
                date: value?.created_at,
                type: "Commission",
                salesCommissionPay :  salesCommissionPay,
                salesCommission : salesCommission,
                consultantCommissionPay :  consultantCommissionPay,
                consultantCommission : consultantCommission,
                appointmentSetterCommissionPay :  appointmentSetterCommissionPay,
                appointmentSetterCommission : appointmentSetterCommission,
                salesAdminCommissionPay :  salesAdminCommissionPay,
                salesAdminCommission: salesAdminCommission
              });
            }
            totalResult.push({
              _id: value?._id,
              rowData: [
                {
                  _id: 1,
                  width: 15,
                  type: "text",
                  data: value?.company_name,
                  isColor: "",
                },
                {
                  _id: 2,
                  width: 15,
                  type: "text",
                  data:
                    value && value?.created_at
                      ? moment(new Date(value?.created_at)).format(
                          "MMM Do YYYY"
                        )
                      : "N/A",
                  isColor: "",
                },
                {
                  _id: 3,
                  width: 15,
                  type: "text",
                  data: "$" + Number(value?.original_price).toFixed(2),
                  isColor: "",
                },
                {
                  _id: 4,
                  width: 20,
                  type: "text",
                  data: salesPersion ? salesPersion : t("none"),
                  isColor: "",
                },
                {
                  _id: 5,
                  width: 20,
                  type: "text",
                  data: (salesCommission ? salesCommission : 0) + "%",
                  isColor: "",
                },
                {
                  _id: 6,
                  width: 20,
                  type: "text",
                  data: "$" + (salesPrice ? salesPrice : 0).toFixed(2),
                  isColor: "",
                },
                {
                  _id: 7,
                  width: 20,
                  type:
                    salesCommissionPay && salesCommissionPay.length > 0
                      ? "text"
                      : "checkbox",
                  data:
                    salesCommissionPay && salesCommissionPay.length > 0
                      ? t("paid")
                      : salesId
                      ? salesId
                      : "",
                  value:
                    salesCommissionPay && salesCommissionPay.length > 0 ? 1 : 2,
                  isColor:
                    salesCommissionPay && salesCommissionPay.length > 0
                      ? "!text-teal-500"
                      : "",
                  code: "sales-staff",
                },
                {
                  _id: 8,
                  width: 20,
                  type: "text",
                  data: consultantPersion ? consultantPersion : "None",
                  isColor: "",
                },
                {
                  _id: 9,
                  width: 20,
                  type: "text",
                  data: (consultantCommission ? consultantCommission : 0) + "%",
                  isColor: "",
                },
                {
                  _id: 10,
                  width: 20,
                  type: "text",
                  data:
                    "$" + (consultantPrice ? consultantPrice : 0).toFixed(2),
                  isColor: "",
                },
                {
                  _id: 11,
                  width: 20,
                  type:
                    consultantCommissionPay &&
                    consultantCommissionPay.length > 0
                      ? "text"
                      : "checkbox",
                  data:
                    consultantCommissionPay &&
                    consultantCommissionPay.length > 0
                      ? t("paid")
                      : consultantId
                      ? consultantId
                      : "",
                  value:
                    consultantCommissionPay &&
                    consultantCommissionPay.length > 0
                      ? 1
                      : 2,
                  isColor:
                    consultantCommissionPay &&
                    consultantCommissionPay.length > 0
                      ? "!text-teal-500"
                      : "",
                  code: "consultant",
                },
                {
                  _id: 12,
                  width: 20,
                  type: "text",
                  data: appointmentSetterPersion
                    ? appointmentSetterPersion
                    : "None",
                  isColor: "",
                },
                {
                  _id: 13,
                  width: 20,
                  type: "text",
                  data:
                    (appointmentSetterCommission
                      ? appointmentSetterCommission
                      : 0) + "%",
                  isColor: "",
                },
                {
                  _id: 14,
                  width: 20,
                  type: "text",
                  data:
                    "$" +
                    (appointmentSetterPrice
                      ? appointmentSetterPrice
                      : 0
                    ).toFixed(2),
                  isColor: "",
                },
                {
                  _id: 15,
                  width: 20,
                  type:
                    appointmentSetterCommissionPay &&
                    appointmentSetterCommissionPay.length > 0
                      ? "text"
                      : "checkbox",
                  data:
                    appointmentSetterCommissionPay &&
                    appointmentSetterCommissionPay.length > 0
                      ? t("paid")
                      : appointmentSetterId
                      ? appointmentSetterId
                      : "",
                  value:
                    appointmentSetterCommissionPay &&
                    appointmentSetterCommissionPay.length > 0
                      ? 1
                      : 2,
                  isColor:
                    appointmentSetterCommissionPay &&
                    appointmentSetterCommissionPay.length > 0
                      ? "!text-teal-500"
                      : "",
                  code: "appointment-setter",
                },
                {
                  _id: 16,
                  width: 20,
                  type: "text",
                  data: salesAdminPersion ? salesAdminPersion : t("none"),
                  isColor: "",
                },
                {
                  _id: 17,
                  width: 20,
                  type: "text",
                  data: (salesAdminCommission ? salesAdminCommission : 0) + "%",
                  isColor: "",
                },
                {
                  _id: 18,
                  width: 20,
                  type: "text",
                  data:
                    "$" + (salesAdminPrice ? salesAdminPrice : 0).toFixed(2),
                  isColor: "",
                },
                {
                  _id: 19,
                  width: 20,
                  type:
                    salesAdminCommissionPay &&
                    salesAdminCommissionPay.length > 0
                      ? "text"
                      : "checkbox",
                  data:
                    salesAdminCommissionPay &&
                    salesAdminCommissionPay.length > 0
                      ? t("paid")
                      : salesAdminId
                      ? salesAdminId
                      : "",
                  value:
                    salesAdminCommissionPay &&
                    salesAdminCommissionPay.length > 0
                      ? 1
                      : 2,
                  isColor:
                    salesAdminCommissionPay &&
                    salesAdminCommissionPay.length > 0
                      ? "!text-teal-500"
                      : "",
                  code: "sales-admin",
                },
                {
                  _id: 20,
                  width: 20,
                  type: "text",
                  data:
                    "$" +
                    Number(
                      (salesPrice ? salesPrice : 0) +
                        (consultantPrice ? consultantPrice : 0) +
                        (appointmentSetterPrice ? appointmentSetterPrice : 0) +
                        (salesAdminPrice ? salesAdminPrice : 0)
                    ).toFixed(2),
                  value: 1,
                  isColor: "!text-teal-500",
                  code: "tot-commission",
                },
              ],
            });            
            totalAvgResult.push({
              _id: primaryId,
              name: primaryName,
              total: Number(value?.original_price),
              price: Number(primaryPrice),
              qty: 1,
            });            
            totalNRCResult.push({
              _id: value?._id,
              company_name : value?.company_name, 
              name: primaryName,     
              original_price: Number(value?.original_price),         
              price: Number(primaryPrice),
              date: value?.created_at,
              type: "Commission",
              salesCommissionPay :  salesCommissionPay,
              salesCommission : salesCommission,
              consultantCommissionPay :  consultantCommissionPay,
              consultantCommission : consultantCommission,
              appointmentSetterCommissionPay :  appointmentSetterCommissionPay,
              appointmentSetterCommission : appointmentSetterCommission,
              salesAdminCommissionPay :  salesAdminCommissionPay,
              salesAdminCommission: salesAdminCommission
            });
          });

          if (type === "avg-sales") {
            if (subTypes && subTypes === "total") {
              setAvgResult(totalAvgResult);
            } else if (subTypes && subTypes === "current-year") {
              setAvgResult(cyAvgResult);
            } else if (subTypes && subTypes === "previous-year") {
              setAvgResult(lyAvgResult);
            } else if (subTypes && subTypes === "current-month") {
              setAvgResult(cmAvgResult);
            } else if (subTypes && subTypes === "previous-month") {
              setAvgResult(lmAvgResult);
            }
          } else if (type === "commissions") {
            if (subTypes && subTypes === "total") {
              setCountData(result.count);
              setResults(totalResult);
              setAvgResult(totalAvgResult);
              setTotalDataCount(result.count);
              //console.log(totalResult,'totalResult')
            } else if (subTypes && subTypes === "current-year") {
              setCountData(cyExpenses);
              setResults(cyResult);
              setAvgResult(cyAvgResult);
              setTotalDataCount(cyExpenses);
            } else if (subTypes && subTypes === "previous-year") {
              setCountData(lyExpenses);
              setResults(lyResult);
              setAvgResult(lyAvgResult);
              setTotalDataCount(lyExpenses);
            } else if (subTypes && subTypes === "current-month") {
              setCountData(cmExpenses);
              setResults(cmResult);
              setAvgResult(cmAvgResult);
              setTotalDataCount(cmExpenses);
            } else if (subTypes && subTypes === "previous-month") {
              setCountData(lmExpenses);
              setResults(lmResult);
              setAvgResult(lmAvgResult);
              setTotalDataCount(lmExpenses);
            }
          }else if (type === "net-revenue") {
            if (subTypes && subTypes === "total") {
              setNetRevenewCommissionResult(totalNRCResult);
            } else if (subTypes && subTypes === "current-year") {
              setNetRevenewCommissionResult(cyNRCResult);
            } else if (subTypes && subTypes === "previous-year") {
              setNetRevenewCommissionResult(lyNRCResult);
            } else if (subTypes && subTypes === "current-month") {
              setNetRevenewCommissionResult(cmNRCResult);
            } else if (subTypes && subTypes === "previous-month") {
              setNetRevenewCommissionResult(lmNRCResult);
            }
          }
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err.message);
      }
    };

    const loadLeadTrackingList = async () => {
      setLoading(true);
      try {
        const result = await postData("lead/tracking", {
          date: date,
        });
        if (result.status === 200) {
          let cmLeadTracking = 0;
          let lmLeadTracking = 0;
          let cyLeadTracking = 0;
          let lyLeadTracking = 0;
          let totalResult = [];
          let cmResult = [];
          let lmResult = [];
          let cyResult = [];
          let lyResult = [];
          result.data.map((value, key) => {
            totalResult.push({
              _id: value?._id,
              rowData: [
                {
                  _id: 1,
                  width: 15,
                  type: "text",
                  data: titleCase(value?._id),
                  isColor: "",
                },
                {
                  _id: 2,
                  width: 15,
                  type: "text",
                  data: value?.total,
                  isColor: "",
                },
                {
                  _id: 3,
                  width: 20,
                  type: "text",
                  data: "$" + Number(value?.price).toFixed(2),
                  isColor: "",
                },
                {
                  _id: 4,
                  width: 20,
                  type: "text",
                  data:
                    (Number(value?.total * 100) / result?.count).toFixed(2) +
                    "%",
                  isColor: "",
                },
                {
                  _id: 5,
                  width: 10,
                  type: "text",
                  data: Number(value?.price / value?.total).toFixed(2),
                  isColor: "",
                },
                {
                  _id: 6,
                  width: 10,
                  type: "text",
                  data: Number(value?.price / result?.count).toFixed(2),
                  isColor: "",
                },
              ],
            });
            if (
              moment(new Date()).format("YYYY-MM") ===
              moment(new Date(value?.created_at)).format("YYYY-MM")
            ) {
              cmLeadTracking = cmLeadTracking + 1;
              cmResult.push({
                _id: value?._id,
                rowData: [
                  {
                    _id: 1,
                    width: 15,
                    type: "text",
                    data: titleCase(value?._id),
                    isColor: "",
                  },
                  {
                    _id: 2,
                    width: 15,
                    type: "text",
                    data: value?.total,
                    isColor: "",
                  },
                  {
                    _id: 3,
                    width: 20,
                    type: "text",
                    data: "$" + Number(value?.price).toFixed(2),
                    isColor: "",
                  },
                  {
                    _id: 4,
                    width: 20,
                    type: "text",
                    data:
                      (Number(value?.total * 100) / result?.count).toFixed(2) +
                      "%",
                    isColor: "",
                  },
                  {
                    _id: 5,
                    width: 10,
                    type: "text",
                    data: Number(value?.price / value?.total).toFixed(2),
                    isColor: "",
                  },
                  {
                    _id: 6,
                    width: 10,
                    type: "text",
                    data: Number(value?.price / result?.count).toFixed(2),
                    isColor: "",
                  },
                ],
              });
            }
            if (
              moment(new Date()).subtract(1, "month").format("YYYY-MM") ===
              moment(new Date(value?.created_at)).format("YYYY-MM")
            ) {
              lmLeadTracking = lmLeadTracking + 1;
              lmResult.push({
                _id: value?._id,
                rowData: [
                  {
                    _id: 1,
                    width: 15,
                    type: "text",
                    data: titleCase(value?._id),
                    isColor: "",
                  },
                  {
                    _id: 2,
                    width: 15,
                    type: "text",
                    data: value?.total,
                    isColor: "",
                  },
                  {
                    _id: 3,
                    width: 20,
                    type: "text",
                    data: "$" + Number(value?.price).toFixed(2),
                    isColor: "",
                  },
                  {
                    _id: 4,
                    width: 20,
                    type: "text",
                    data:
                      (Number(value?.total * 100) / result?.count).toFixed(2) +
                      "%",
                    isColor: "",
                  },
                  {
                    _id: 5,
                    width: 10,
                    type: "text",
                    data: Number(value?.price / value?.total).toFixed(2),
                    isColor: "",
                  },
                  {
                    _id: 6,
                    width: 10,
                    type: "text",
                    data: Number(value?.price / result?.count).toFixed(2),
                    isColor: "",
                  },
                ],
              });
            }
            if (
              moment(new Date()).format("YYYY") ===
              moment(new Date(value?.created_at)).format("YYYY")
            ) {
              cyLeadTracking = cyLeadTracking + 1;
              cyResult.push({
                _id: value?._id,
                rowData: [
                  {
                    _id: 1,
                    width: 15,
                    type: "text",
                    data: titleCase(value?._id),
                    isColor: "",
                  },
                  {
                    _id: 2,
                    width: 15,
                    type: "text",
                    data: value?.total,
                    isColor: "",
                  },
                  {
                    _id: 3,
                    width: 20,
                    type: "text",
                    data: "$" + Number(value?.price).toFixed(2),
                    isColor: "",
                  },
                  {
                    _id: 4,
                    width: 20,
                    type: "text",
                    data:
                      (Number(value?.total * 100) / result?.count).toFixed(2) +
                      "%",
                    isColor: "",
                  },
                  {
                    _id: 5,
                    width: 10,
                    type: "text",
                    data: Number(value?.price / value?.total).toFixed(2),
                    isColor: "",
                  },
                  {
                    _id: 6,
                    width: 10,
                    type: "text",
                    data: Number(value?.price / result?.count).toFixed(2),
                    isColor: "",
                  },
                ],
              });
            }
            if (
              moment(new Date()).subtract(1, "year").format("YYYY") ===
              moment(new Date(value?.created_at)).format("YYYY")
            ) {
              lyLeadTracking = lyLeadTracking + 1;
              lyResult.push({
                _id: value?._id,
                rowData: [
                  {
                    _id: 1,
                    width: 15,
                    type: "text",
                    data: titleCase(value?._id),
                    isColor: "",
                  },
                  {
                    _id: 2,
                    width: 15,
                    type: "text",
                    data: value?.total,
                    isColor: "",
                  },
                  {
                    _id: 3,
                    width: 20,
                    type: "text",
                    data: "$" + Number(value?.price).toFixed(2),
                    isColor: "",
                  },
                  {
                    _id: 4,
                    width: 20,
                    type: "text",
                    data:
                      (Number(value?.total * 100) / result?.count).toFixed(2) +
                      "%",
                    isColor: "",
                  },
                  {
                    _id: 5,
                    width: 10,
                    type: "text",
                    data: Number(value?.price / value?.total).toFixed(2),
                    isColor: "",
                  },
                  {
                    _id: 6,
                    width: 10,
                    type: "text",
                    data: Number(value?.price / result?.count).toFixed(2),
                    isColor: "",
                  },
                ],
              });
            }
          });
          if (subTypes && subTypes === "total") {
            setCountData(result.count);
            setResults(totalResult);
          } else if (subTypes && subTypes === "current-year") {
            setCountData(cyLeadTracking);
            setResults(cyResult);
          } else if (subTypes && subTypes === "previous-year") {
            setCountData(lyLeadTracking);
            setResults(lyResult);
          } else if (subTypes && subTypes === "current-month") {
            setCountData(cmLeadTracking);
            setResults(cmResult);
          } else if (subTypes && subTypes === "previous-month") {
            setCountData(lmLeadTracking);
            setResults(lmResult);
          }
        }
        setLoading(false);
      } catch (err) {
        console.log(err.message);
        setLoading(false);
      }
    };

    const loadAvgCommissionList = async () => {
      setLoading(true);
      try {
        const result = await postData("usertype/count-sales", {
          date: date,
        });
        if (result.status === 200) {
          setAvgCommissionFinalResult(result?.data);
          let cmAvgCommission = 0;
          let lmAvgCommission = 0;
          let cyAvgCommission = 0;
          let lyAvgCommission = 0;
          let totalResult = [];
          let cmResult = [];
          let lmResult = [];
          let cyResult = [];
          let lyResult = [];
          result?.data &&
            result?.data?.map((value, key) => {
              let salesCommission = 0;
              let consultantCommission = 0;
              let appointmentSetterCommission = 0;
              let salesAdminCommission = 0;
              let salesPrice = 0;
              let consultantPrice = 0;
              let appointmentSetterPrice = 0;
              let salesAdminPrice = 0;
              if (
                value?.commission &&
                Object.keys(value?.commission).length > 0
              ) {
                let sales = value?.commission.filter(
                  (itm) => itm.code === "sales-staff"
                );
                if (sales && sales.length > 0) {
                  salesCommission = sales[0].commission
                    ? sales[0].commission
                    : 0;
                  salesPrice =
                    (Number(value?.original_price) * Number(salesCommission)) /
                    100;
                }
                let consultant = value?.commission.filter(
                  (item) => item.code === "consultant"
                );
                if (consultant && consultant.length > 0) {
                  consultantCommission = consultant[0].commission
                    ? consultant[0].commission
                    : 0;
                  consultantPrice =
                    (Number(value?.original_price) *
                      Number(consultantCommission)) /
                    100;
                }
                let appointmentSetter = value?.commission.filter(
                  (item) => item.code === "appointment-setter"
                );
                if (appointmentSetter && appointmentSetter.length > 0) {
                  appointmentSetterCommission = appointmentSetter[0].commission
                    ? appointmentSetter[0].commission
                    : 0;
                  appointmentSetterPrice =
                    (Number(value?.original_price) *
                      Number(appointmentSetterCommission)) /
                    100;
                }
                let salesAdmin = value?.commission.filter(
                  (item) => item.code === "sales-admin"
                );
                if (salesAdmin && salesAdmin.length > 0) {
                  salesAdminCommission = salesAdmin[0].commission
                    ? salesAdmin[0].commission
                    : 0;
                  salesAdminPrice =
                    (Number(value?.original_price) *
                      Number(salesAdminCommission)) /
                    100;
                }
              }
              let commission = 0;
              if (value?.staff_details && value?.staff_details.length > 0) {
                for (
                  let index = 0;
                  index < value?.staff_details.length;
                  index++
                ) {
                  let element = value?.staff_details[index];
                  let price = value?.staff_details[index]?.original_price;
                  let salesComm = 0;
                  let consultantComm = 0;
                  let appointmentSetterComm = 0;
                  let salesAdminComm = 0;
                  if (
                    element?.staffs &&
                    Object.keys(element?.staffs).length > 0
                  ) {
                    let sales1 = element?.staffs.filter(
                      (itm) => itm.code === "sales-staff"
                    );
                    if (sales1 && sales1.length > 0) {
                      salesComm = sales1[0].commission
                        ? sales1[0].commission
                        : 0;
                      commission =
                        commission + (Number(price) * Number(salesComm)) / 100;
                    }
                    let consultant1 = element?.staffs.filter(
                      (item) => item.code === "consultant"
                    );
                    if (consultant1 && consultant1.length > 0) {
                      consultantComm = consultant1[0].commission
                        ? consultant1[0].commission
                        : 0;
                      commission =
                        commission +
                        (Number(price) * Number(consultantComm)) / 100;
                    }
                    let appointmentSetter1 = element?.staffs.filter(
                      (item) => item.code === "appointment-setter"
                    );
                    if (appointmentSetter1 && appointmentSetter1.length > 0) {
                      appointmentSetterComm = appointmentSetter1[0].commission
                        ? appointmentSetter1[0].commission
                        : 0;
                      commission =
                        commission +
                        (Number(price) * Number(appointmentSetterComm)) / 100;
                    }
                    let salesAdmin1 = element?.staffs.filter(
                      (item) => item.code === "sales-admin"
                    );
                    if (salesAdmin1 && salesAdmin1.length > 0) {
                      salesAdminComm = salesAdmin1[0].commission
                        ? salesAdmin1[0].commission
                        : 0;
                      commission =
                        commission +
                        (Number(price) * Number(salesAdminComm)) / 100;
                    }
                  }
                }
              }
              let html = (
                <>
                  <div className="text-sm font-normal text-slate-600 leading-none flex justify-between mb-1">
                    <span className="font-semibold">Sales staff:</span>{" "}
                    {salesCommission + "%"}
                  </div>
                  <div className="text-sm font-normal text-slate-600 leading-none flex justify-between mb-1">
                    <span className="font-semibold">Consultant:</span>{" "}
                    {consultantCommission + "%"}
                  </div>
                  <div className="text-sm font-normal text-slate-600 leading-none flex justify-between mb-1">
                    <span className="font-semibold">Appointment Setter:</span>{" "}
                    {appointmentSetterCommission + "%"}
                  </div>
                  <div className="text-sm font-normal text-slate-600 leading-none flex justify-between mb-1">
                    <span className="font-semibold">Sales Admin:</span>{" "}
                    {salesAdminCommission + "%"}
                  </div>
                </>
              );
              totalResult.push({
                _id: value?._id,
                rowData: [
                  {
                    _id: 1,
                    width: 15,
                    type: "text",
                    data: value?.first_name + " " + value?.last_name,
                    isColor: "",
                  },
                  {
                    _id: 2,
                    width: 15,
                    type: "text",
                    data: value?.count_staff,
                    isColor: "",
                  },
                  { _id: 3, width: 20, type: "text", data: html, isColor: "" },
                  {
                    _id: 4,
                    width: 20,
                    type: "text",
                    data: "$" + Number(commission).toFixed(2),
                    isColor: "",
                  },
                ],
              });
              if (
                moment(new Date()).format("YYYY-MM") ===
                moment(new Date(value?.created_at)).format("YYYY-MM")
              ) {
                cmAvgCommission = cmAvgCommission + 1;
                cmResult.push({
                  _id: value?._id,
                  rowData: [
                    {
                      _id: 1,
                      width: 15,
                      type: "text",
                      data: value?.first_name + " " + value?.last_name,
                      isColor: "",
                    },
                    {
                      _id: 2,
                      width: 15,
                      type: "text",
                      data: value?.count_staff,
                      isColor: "",
                    },
                    {
                      _id: 3,
                      width: 20,
                      type: "text",
                      data: html,
                      isColor: "",
                    },
                    {
                      _id: 4,
                      width: 20,
                      type: "text",
                      data: "$" + Number(commission).toFixed(2),
                      isColor: "",
                    },
                  ],
                });
              }
              if (
                moment(new Date()).subtract(1, "month").format("YYYY-MM") ===
                moment(new Date(value?.created_at)).format("YYYY-MM")
              ) {
                lmAvgCommission = lmAvgCommission + 1;
                lmResult.push({
                  _id: value?._id,
                  rowData: [
                    {
                      _id: 1,
                      width: 15,
                      type: "text",
                      data: value?.first_name + " " + value?.last_name,
                      isColor: "",
                    },
                    {
                      _id: 2,
                      width: 15,
                      type: "text",
                      data: value?.count_staff,
                      isColor: "",
                    },
                    {
                      _id: 3,
                      width: 20,
                      type: "text",
                      data: html,
                      isColor: "",
                    },
                    {
                      _id: 4,
                      width: 20,
                      type: "text",
                      data: "$" + Number(commission).toFixed(2),
                      isColor: "",
                    },
                  ],
                });
              }
              if (
                moment(new Date()).format("YYYY") ===
                moment(new Date(value?.created_at)).format("YYYY")
              ) {
                cyAvgCommission = cyAvgCommission + 1;
                cyResult.push({
                  _id: value?._id,
                  rowData: [
                    {
                      _id: 1,
                      width: 15,
                      type: "text",
                      data: value?.first_name + " " + value?.last_name,
                      isColor: "",
                    },
                    {
                      _id: 2,
                      width: 15,
                      type: "text",
                      data: value?.count_staff,
                      isColor: "",
                    },
                    {
                      _id: 3,
                      width: 20,
                      type: "text",
                      data: html,
                      isColor: "",
                    },
                    {
                      _id: 4,
                      width: 20,
                      type: "text",
                      data: "$" + Number(commission).toFixed(2),
                      isColor: "",
                    },
                  ],
                });
              }
              if (
                moment(new Date()).subtract(1, "year").format("YYYY") ===
                moment(new Date(value?.created_at)).format("YYYY")
              ) {
                lyAvgCommission = lyAvgCommission + 1;
                lyResult.push({
                  _id: value?._id,
                  rowData: [
                    {
                      _id: 1,
                      width: 15,
                      type: "text",
                      data: value?.first_name + " " + value?.last_name,
                      isColor: "",
                    },
                    {
                      _id: 2,
                      width: 15,
                      type: "text",
                      data: value?.count_staff,
                      isColor: "",
                    },
                    {
                      _id: 3,
                      width: 20,
                      type: "text",
                      data: html,
                      isColor: "",
                    },
                    {
                      _id: 4,
                      width: 20,
                      type: "text",
                      data: "$" + Number(commission).toFixed(2),
                      isColor: "",
                    },
                  ],
                });
              }
            });
          if (subTypes && subTypes === "total") {
            setCountData(result.count);
            setResults(totalResult);
          } else if (subTypes && subTypes === "current-year") {
            setCountData(cyAvgCommission);
            setResults(cyResult);
          } else if (subTypes && subTypes === "previous-year") {
            setCountData(lyAvgCommission);
            setResults(lyResult);
          } else if (subTypes && subTypes === "current-month") {
            setCountData(cmAvgCommission);
            setResults(cmResult);
          } else if (subTypes && subTypes === "previous-month") {
            setCountData(lmAvgCommission);
            setResults(lmResult);
          }
        }
        setLoading(false);
      } catch (err) {
        console.log(err.message);
        setLoading(false);
      }
    };

    const loadRenewalPaymentList = async () => {
      setLoading(true);
      try {
        const result = await postData("subscription/renewal-list", {
          companyId: companyId,
          date: date,
        });
        if (result.status === 200) {
          let cmRenewalPayment = 0;
          let lmRenewalPayment = 0;
          let cyRenewalPayment = 0;
          let lyRenewalPayment = 0;
          let totalResult = [];
          let cmResult = [];
          let lmResult = [];
          let cyResult = [];
          let lyResult = [];
          result.data.map((value, key) => {
            totalResult.push({
              _id: value?._id,
              rowData: [
                {
                  _id: 1,
                  width: 15,
                  type: "text",
                  data: value?.company_name,
                  isColor: "",
                },
                {
                  _id: 2,
                  width: 15,
                  type: "text",
                  data: "$" + Number(value?.price).toFixed(2),
                  isColor: "",
                },
                {
                  _id: 3,
                  width: 20,
                  type: "text",
                  data:
                    value && value?.start_date
                      ? moment(new Date(value?.start_date)).format(
                          "MMM Do YYYY"
                        )
                      : "N/A",
                  isColor: "",
                },
                {
                  _id: 4,
                  width: 20,
                  type: "text",
                  data:
                    value && value?.end_date
                      ? moment(new Date(value?.end_date)).format("MMM Do YYYY")
                      : "N/A",
                  isColor: "",
                },
                {
                  _id: 5,
                  width: 15,
                  type: "status",
                  statusLabel:
                    value?.status && value?.status === 1
                      ? t("active")
                      : t("expire"),
                  statusType:
                    value?.status && value?.status === 1 ? "success" : "danger",
                  isColor: "",
                },
                {
                  _id: 6,
                  width: 10,
                  type: "text",
                  data: value?.account_type
                    ? capitalizeString(value?.account_type)
                    : "",
                  isColor: "",
                },
              ],
            });
            if (
              moment(new Date()).format("YYYY-MM") ===
              moment(new Date(value?.created_at)).format("YYYY-MM")
            ) {
              cmRenewalPayment = cmRenewalPayment + 1;
              cmResult.push({
                _id: value?._id,
                rowData: [
                  {
                    _id: 1,
                    width: 15,
                    type: "text",
                    data: value?.company_name,
                    isColor: "",
                  },
                  {
                    _id: 2,
                    width: 15,
                    type: "text",
                    data: "$" + Number(value?.price).toFixed(2),
                    isColor: "",
                  },
                  {
                    _id: 3,
                    width: 20,
                    type: "text",
                    data:
                      value && value?.start_date
                        ? moment(new Date(value?.start_date)).format(
                            "MMM Do YYYY"
                          )
                        : "N/A",
                    isColor: "",
                  },
                  {
                    _id: 4,
                    width: 20,
                    type: "text",
                    data:
                      value && value?.end_date
                        ? moment(new Date(value?.end_date)).format(
                            "MMM Do YYYY"
                          )
                        : "N/A",
                    isColor: "",
                  },
                  {
                    _id: 5,
                    width: 15,
                    type: "status",
                    statusLabel:
                      value?.status && value?.status === 1
                        ? t("active")
                        : t("expire"),
                    statusType:
                      value?.status && value?.status === 1
                        ? "success"
                        : "danger",
                    isColor: "",
                  },
                  {
                    _id: 6,
                    width: 10,
                    type: "text",
                    data: value?.account_type
                      ? capitalizeString(value?.account_type)
                      : "",
                    isColor: "",
                  },
                ],
              });
            }
            if (
              moment(new Date()).subtract(1, "month").format("YYYY-MM") ===
              moment(new Date(value?.created_at)).format("YYYY-MM")
            ) {
              lmRenewalPayment = lmRenewalPayment + 1;
              lmResult.push({
                _id: value?._id,
                rowData: [
                  {
                    _id: 1,
                    width: 15,
                    type: "text",
                    data: value?.company_name,
                    isColor: "",
                  },
                  {
                    _id: 2,
                    width: 15,
                    type: "text",
                    data: "$" + Number(value?.price).toFixed(2),
                    isColor: "",
                  },
                  {
                    _id: 3,
                    width: 20,
                    type: "text",
                    data:
                      value && value?.start_date
                        ? moment(new Date(value?.start_date)).format(
                            "MMM Do YYYY"
                          )
                        : "N/A",
                    isColor: "",
                  },
                  {
                    _id: 4,
                    width: 20,
                    type: "text",
                    data:
                      value && value?.end_date
                        ? moment(new Date(value?.end_date)).format(
                            "MMM Do YYYY"
                          )
                        : "N/A",
                    isColor: "",
                  },
                  {
                    _id: 5,
                    width: 15,
                    type: "status",
                    statusLabel:
                      value?.status && value?.status === 1
                        ? t("active")
                        : t("expire"),
                    statusType:
                      value?.status && value?.status === 1
                        ? "success"
                        : "danger",
                    isColor: "",
                  },
                  {
                    _id: 6,
                    width: 10,
                    type: "text",
                    data: value?.account_type
                      ? capitalizeString(value?.account_type)
                      : "",
                    isColor: "",
                  },
                ],
              });
            }
            if (
              moment(new Date()).format("YYYY") ===
              moment(new Date(value?.created_at)).format("YYYY")
            ) {
              cyRenewalPayment = cyRenewalPayment + 1;
              cyResult.push({
                _id: value?._id,
                rowData: [
                  {
                    _id: 1,
                    width: 15,
                    type: "text",
                    data: value?.company_name,
                    isColor: "",
                  },
                  {
                    _id: 2,
                    width: 15,
                    type: "text",
                    data: "$" + Number(value?.price).toFixed(2),
                    isColor: "",
                  },
                  {
                    _id: 3,
                    width: 20,
                    type: "text",
                    data:
                      value && value?.start_date
                        ? moment(new Date(value?.start_date)).format(
                            "MMM Do YYYY"
                          )
                        : "N/A",
                    isColor: "",
                  },
                  {
                    _id: 4,
                    width: 20,
                    type: "text",
                    data:
                      value && value?.end_date
                        ? moment(new Date(value?.end_date)).format(
                            "MMM Do YYYY"
                          )
                        : "N/A",
                    isColor: "",
                  },
                  {
                    _id: 5,
                    width: 15,
                    type: "status",
                    statusLabel:
                      value?.status && value?.status === 1
                        ? t("active")
                        : t("expire"),
                    statusType:
                      value?.status && value?.status === 1
                        ? "success"
                        : "danger",
                    isColor: "",
                  },
                  {
                    _id: 6,
                    width: 10,
                    type: "text",
                    data: value?.account_type
                      ? capitalizeString(value?.account_type)
                      : "",
                    isColor: "",
                  },
                ],
              });
            }
            if (
              moment(new Date()).subtract(1, "year").format("YYYY") ===
              moment(new Date(value?.created_at)).format("YYYY")
            ) {
              lyRenewalPayment = lyRenewalPayment + 1;
              lyResult.push({
                _id: value?._id,
                rowData: [
                  {
                    _id: 1,
                    width: 15,
                    type: "text",
                    data: value?.company_name,
                    isColor: "",
                  },
                  {
                    _id: 2,
                    width: 15,
                    type: "text",
                    data: "$" + Number(value?.price).toFixed(2),
                    isColor: "",
                  },
                  {
                    _id: 3,
                    width: 20,
                    type: "text",
                    data:
                      value && value?.start_date
                        ? moment(new Date(value?.start_date)).format(
                            "MMM Do YYYY"
                          )
                        : "N/A",
                    isColor: "",
                  },
                  {
                    _id: 4,
                    width: 20,
                    type: "text",
                    data:
                      value && value?.end_date
                        ? moment(new Date(value?.end_date)).format(
                            "MMM Do YYYY"
                          )
                        : "N/A",
                    isColor: "",
                  },
                  {
                    _id: 5,
                    width: 15,
                    type: "status",
                    statusLabel:
                      value?.status && value?.status === 1
                        ? t("active")
                        : t("expire"),
                    statusType:
                      value?.status && value?.status === 1
                        ? "success"
                        : "danger",
                    isColor: "",
                  },
                  {
                    _id: 6,
                    width: 10,
                    type: "text",
                    data: value?.account_type
                      ? capitalizeString(value?.account_type)
                      : "",
                    isColor: "",
                  },
                ],
              });
            }
          });
          if (subTypes && subTypes === "total") {
            setCountData(result.count);
            setResults(totalResult);
          } else if (subTypes && subTypes === "current-year") {
            setCountData(cyRenewalPayment);
            setResults(cyResult);
          } else if (subTypes && subTypes === "previous-year") {
            setCountData(lyRenewalPayment);
            setResults(lyResult);
          } else if (subTypes && subTypes === "current-month") {
            setCountData(cmRenewalPayment);
            setResults(cmResult);
          } else if (subTypes && subTypes === "previous-month") {
            setCountData(lmRenewalPayment);
            setResults(lmResult);
          }
        }
        setLoading(false);
      } catch (err) {
        console.log(err.message);
        setLoading(false);
      }
    };

    if (type && (type === "sales" || type === "cancellation")) {
      loadSubscriptionList();
    } else if (type && type === "revenue") {
      loadDashboardList();
    } else if (type && type === "succession-plan") {
      loadPlanList();
    } else if (type && type === "expenses") {
      loadExpensesList();
    } else if (type && (type === "commissions" || type === "avg-sales")) {
      loadCommissionsList();
    } else if (type && type === "net-revenue") {
      loadDashboardList();
      loadExpensesList();
      loadCommissionsList();
    } else if (type && type === "lead-tracking") {
      loadLeadTrackingList();
    } else if (type && type === "avg-commission") {
      loadAvgCommissionList();
    } else if (type && type === "renewals") {
      loadRenewalPaymentList();
    }
  }, [
    sortKey,
    sortValue,
    salesId,
    consultantId,
    date,
    appointmentSetterId,
    type,
    subtype,
    expenseType,
    actionValues,
    isUpdate,
    limit,
    offset,
    paymentStatus,
    code,
    companyId,
  ]);

  useEffect(() => {
    if (expensesResult.length > 0 || revenewResult.length > 0 || netRevenewCommissionResult.length >0 ) {
      setNetRevenew([...revenewResult, ...expensesResult, ...netRevenewCommissionResult]);
    }
  }, [revenewResult, expensesResult,netRevenewCommissionResult]);

  useEffect(() => {
    try {
      let totalResult = [];
      let total = 0;
      let totalRevenew = 0;
      let totalExpenses = 0;
      let totalCommission = 0;
      setResults([]);
      setCountData(0);
      setTotalRevenew(0);
      setTotalExpenses(0);
      setTotalCommission(0);
      if (netRevenew.length > 0) {
        netRevenew.sort((a, b) => new Date(b.date) - new Date(a.date)).map((item) => {
            if (item.type === "Revenue") {
              totalResult.push({
                _id: item?._id,
                rowData: [
                  {
                    _id: 1,
                    width: 15,
                    type: "text",
                    data: item?.type,
                    isColor: "",
                  },
                  {
                    _id: 2,
                    width: 20,
                    type: "text",
                    data: item?.company_name,
                    isColor: "",
                  },
                  { _id: 7, width: 20, type: "text", data: "--", isColor: "" },
                  { _id: 3, width: 20, type: "text", data: "--", isColor: "" },
                  {
                    _id: 4,
                    width: 15,
                    type: "text",
                    data:
                      "$" +
                      (item.original_price
                        ? Number(item?.original_price).toFixed(2)
                        : "0.00"),
                    isColor: "",
                  },
                  {
                    _id: 5,
                    width: 20,
                    type: "text",
                    data:
                      item && item?.date
                        ? moment(new Date(item?.date)).format("Do MMM YYYY")
                        : "N/A",
                    isColor: "",
                  },
                  { _id: 6, width: 15, type: "text", data: "--", isColor: "" },
                ],
              });
              total = total + Number(item?.price);
              totalRevenew = totalRevenew + Number(item?.price);
            } else if (item.type === "Expenses" && item?.isCommission === 3) {
              totalResult.push({
                _id: item?._id,
                rowData: [
                  {
                    _id: 1,
                    width: 15,
                    type: "text",
                    data: item?.type,
                    isColor: "",
                  },
                  { _id: 2, width: 20, type: "text", data: "--", isColor: "" },
                  { _id: 7, width: 20, type: "text", data: "--", isColor: "" },
                  {
                    _id: 3,
                    width: 20,
                    type: "text",
                    data: item?.type_name,
                    isColor: "",
                  },
                  {
                    _id: 4,
                    width: 15,
                    type: "text",
                    data:
                      "$" +
                      (item.amount ? Number(item?.amount).toFixed(2) : "0.00"),
                    isColor: "!text-rose-600",
                  },
                  {
                    _id: 5,
                    width: 20,
                    type: "text",
                    data:
                      item && item?.date
                        ? moment(new Date(item?.date)).format("Do MMM YYYY")
                        : "N/A",
                    isColor: "",
                  },
                  {
                    _id: 6,
                    width: 15,
                    type: "text",
                    data: item?.comments,
                    isColor: "",
                  },
                ],
              });
              total = total - Number(item?.amount);
              totalExpenses = totalExpenses + Number(item?.amount);
            }else if (item.type === "Commission") {
              let _salesPrice = (Number(item?.original_price) * Number((item?.salesCommissionPay && item?.salesCommissionPay.length>0)?item?.salesCommission:0)) / 100;
              let _consultantPrice = (Number(item?.original_price) * Number((item?.consultantCommissionPay && item?.consultantCommissionPay.length>0)?item?.consultantCommission:0))/100;
              let _appointmentSetterPrice = (Number(item?.original_price) * Number((item?.appointmentSetterCommissionPay && item?.appointmentSetterCommissionPay.length>0)?item?.appointmentSetterCommission:0)) /100;
              let _salesAdminPrice = (Number(item?.original_price) * Number((item?.salesAdminCommissionPay && item?.salesAdminCommissionPay.length>0)?item?.salesAdminCommission:0)) / 100;
              totalResult.push({
                _id: item?._id,
                rowData: [
                  {
                    _id: 1,
                    width: 15,
                    type: "text",
                    data: item?.type,
                    isColor: "",
                  },
                  { _id: 2, width: 20, type: "text", data: item?.company_name, isColor: "" },
                  { _id: 7, width: 20, type: "text", data: item?.name, isColor: "" },
                  {
                    _id: 3,
                    width: 20,
                    type: "text",
                    data: item?.type_name,
                    isColor: "",
                  },
                  {
                    _id: 4,
                    width: 15,
                    type: "text",
                    data:
                      "$" +
                      (item.price ? Number(item?.price).toFixed(2) : "0.00"),
                    isColor: "!text-rose-600",
                  },
                  {
                    _id: 5,
                    width: 20,
                    type: "text",
                    data:
                      item && item?.date
                        ? moment(new Date(item?.date)).format("Do MMM YYYY")
                        : "N/A",
                    isColor: "",
                  },
                  {
                    _id: 6,
                    width: 15,
                    type: "text",
                    data: item?.comments,
                    isColor: "",
                  },
                ],
              });
              let finalCommission = (Number(_salesPrice) +
              Number(_consultantPrice) +
              Number(_appointmentSetterPrice) +
              Number(_salesAdminPrice))
              total = total - Number(finalCommission);
              totalCommission = totalCommission + finalCommission;
            }
          });
        setResults(totalResult);
        setCountData(Number(total).toFixed(2));
        setTotalRevenew(Number(totalRevenew).toFixed(2));
        setTotalExpenses(Number(totalExpenses).toFixed(2));
        setTotalCommission(Number(totalCommission).toFixed(2));
      }
    } catch (err) {
      console.log(err.message);
    }
  }, [netRevenew]);

  const handleOnDragEnd = async (result) => {
    //console.log("result",result);
    const items = reorder(
      results,
      result.source.index,
      result.destination.index
    );
    setResults(items);

    const res = await postData("position/ordering", {
      items: items,
    });
    if (res.status && res.status === 200) {
      toastr.success(res.message);
    } else {
      toastr.error(res.message);
    }
  };

  let tableHeadData = [];
  if (type && type === "sales") {
    tableHeadData = [
      {
        _id: 1,
        width: 40,
        name: t("company_name"),
        value: "name",
        align: "left",
        isSort: false,
        isFilter: false,
      },
      {
        _id: 2,
        width: 15,
        name: t("price"),
        value: "price",
        align: "left",
        isSort: false,
        isFilter: false,
      },
      {
        _id: 3,
        width: 20,
        name: t("sales_staff"),
        value: "created_at",
        align: "left",
        isSort: false,
        isFilter: false,
      },
      {
        _id: 4,
        width: 15,
        name: t("appointment_setter"),
        value: "status",
        align: "left",
        isSort: false,
        isFilter: false,
      },
      {
        _id: 5,
        width: 10,
        name: t("consultant"),
        value: "",
        align: "left",
        isSort: false,
        isFilter: false,
      },
      {
        _id: 6,
        width: 10,
        name: t("account_status"),
        value: "",
        align: "left",
        isSort: false,
        isFilter: false,
      },
    ];
  } else if (type && type === "cancellation") {
    tableHeadData = [
      {
        _id: 1,
        width: 40,
        name: t("company_name"),
        value: "name",
        align: "left",
        isSort: false,
        isFilter: false,
      },
      {
        _id: 2,
        width: 15,
        name: t("price"),
        value: "price",
        align: "left",
        isSort: false,
        isFilter: false,
      },
      {
        _id: 3,
        width: 20,
        name: t("sales_staff"),
        value: "created_at",
        align: "left",
        isSort: false,
        isFilter: false,
      },
      {
        _id: 4,
        width: 15,
        name: t("appointment_setter"),
        value: "status",
        align: "left",
        isSort: false,
        isFilter: false,
      },
      {
        _id: 5,
        width: 10,
        name: t("consultant"),
        value: "",
        align: "left",
        isSort: false,
        isFilter: false,
      },
      {
        _id: 6,
        width: 10,
        name: t("of_succession_plans"),
        value: "number_of_succession_plan",
        align: "left",
        isSort: false,
        isFilter: false,
      },
    ];
  } else if (type && type === "revenue") {
    tableHeadData = [
      {
        _id: 1,
        width: 40,
        name: t("company_name"),
        value: "name",
        align: "left",
        isSort: false,
        isFilter: false,
      },
      {
        _id: 2,
        width: 15,
        name: t("price"),
        value: "price",
        align: "left",
        isSort: false,
        isFilter: false,
      },
      {
        _id: 3,
        width: 20,
        name: t("sales_staff"),
        value: "created_at",
        align: "left",
        isSort: false,
        isFilter: false,
      },
      {
        _id: 4,
        width: 15,
        name: t("appointment_setter"),
        value: "status",
        align: "left",
        isSort: false,
        isFilter: false,
      },
      {
        _id: 5,
        width: 10,
        name: t("consultant"),
        value: "",
        align: "left",
        isSort: false,
        isFilter: false,
      },
      {
        _id: 6,
        width: 10,
        name: t("account_status"),
        value: "number_of_succession_plan",
        align: "left",
        isSort: false,
        isFilter: false,
      },
    ];
  } else if (type && type === "succession-plan") {
    tableHeadData = [
      {
        _id: 1,
        width: 40,
        name: t("company_name"),
        value: "name",
        align: "left",
        isSort: false,
        isFilter: false,
      },
      {
        _id: 2,
        width: 10,
        name: t("of_completed_plans"),
        value: "number_of_succession_plan",
        align: "left",
        isSort: false,
        isFilter: false,
      },
      {
        _id: 3,
        width: 10,
        name: t("of_incompleted_plans"),
        value: "number_of_succession_plan",
        align: "left",
        isSort: false,
        isFilter: false,
      },
      {
        _id: 4,
        width: 15,
        name: t("price"),
        value: "price",
        align: "left",
        isSort: false,
        isFilter: false,
      },
      {
        _id: 5,
        width: 10,
        name: t("account_status"),
        value: "number_of_succession_plan",
        align: "left",
        isSort: false,
        isFilter: false,
      },
    ];
  } else if (type && type === "renewals") {
    tableHeadData = [
      {
        _id: 1,
        width: 40,
        name: t("company_name"),
        value: "company_name",
        align: "left",
        isSort: false,
        isFilter: false,
      },
      {
        _id: 2,
        width: 15,
        name: t("renewal_price"),
        value: "price",
        align: "left",
        isSort: false,
        isFilter: false,
      },
      {
        _id: 3,
        width: 20,
        name: t("start_date"),
        value: "start_date",
        align: "left",
        isSort: false,
        isFilter: false,
      },
      {
        _id: 4,
        width: 15,
        name: t("end_date"),
        value: "end_date",
        align: "left",
        isSort: false,
        isFilter: false,
      },
      {
        _id: 5,
        width: 10,
        name: t("status"),
        value: "status",
        align: "left",
        isSort: false,
        isFilter: false,
      },
      {
        _id: 5,
        width: 10,
        name: t("account_type"),
        value: "account_type",
        align: "left",
        isSort: false,
        isFilter: false,
      },
    ];
  } else if (type && type === "demos") {
    tableHeadData = [];
  } else if (type && type === "referrals") {
    tableHeadData = [];
  } else if (type && type === "appointments") {
    tableHeadData = [];
  } else if (type && type === "expenses") {
    tableHeadData = [
      {
        _id: 1,
        width: 40,
        name: t("type_of_expense"),
        value: "type",
        align: "left",
        isSort: false,
        isFilter: false,
      },
      {
        _id: 2,
        width: 15,
        name: t("price"),
        value: "amount",
        align: "left",
        isSort: false,
        isFilter: false,
      },
      {
        _id: 3,
        width: 20,
        name: t("date"),
        value: "date",
        align: "left",
        isSort: false,
        isFilter: false,
      },
      {
        _id: 4,
        width: 15,
        name: t("comments"),
        value: "status",
        align: "left",
        isSort: false,
        isFilter: false,
      },
      {
        _id: 5,
        width: 10,
        name: t("action"),
        value: "",
        align: "right",
        isSort: false,
        isFilter: false,
      },
    ];
  } else if (type && type === "commissions") {
    tableHeadData = [
      {
        _id: 1,
        width: 40,
        name: t("company"),
        value: "name",
        align: "left",
        isSort: false,
        isFilter: false,
      },
      {
        _id: 2,
        width: 15,
        name: t("start_date"),
        value: "price",
        align: "left",
        isSort: false,
        isFilter: false,
      },
      {
        _id: 3,
        width: 20,
        name: t("price"),
        value: "created_at",
        align: "left",
        isSort: false,
        isFilter: false,
      },
      {
        _id: 4,
        width: 15,
        name: t("salesperson"),
        value: "sales_id",
        align: "left",
        isSort: false,
        isFilter: false,
      },
      {
        _id: 5,
        width: 10,
        name: t("salesperson") + " %",
        value: "sales_percentage",
        align: "left",
        isSort: false,
        isFilter: false,
      },
      {
        _id: 6,
        width: 10,
        name: t("amount"),
        value: "salesperson_commission_amount",
        align: "left",
        isSort: false,
        isFilter: false,
      },
      {
        _id: 7,
        width: 10,
        name: t("payment_made"),
        value: "sales_commission_payment",
        align: "left",
        isSort: false,
        isFilter: false,
      },
      {
        _id: 8,
        width: 15,
        name: t("consultant"),
        value: "consultant_id",
        align: "left",
        isSort: false,
        isFilter: false,
      },
      {
        _id: 9,
        width: 10,
        name: t("consultant") + " %",
        value: "consultant_percentage",
        align: "left",
        isSort: false,
        isFilter: false,
      },
      {
        _id: 10,
        width: 10,
        name: t("amount"),
        value: "consultant_commission_amount",
        align: "left",
        isSort: false,
        isFilter: false,
      },
      {
        _id: 11,
        width: 10,
        name: t("payment_made"),
        value: "consultant_commission_payment",
        align: "left",
        isSort: false,
        isFilter: false,
      },
      {
        _id: 12,
        width: 15,
        name: t("appt_setter"),
        value: "appointment_setter_id",
        align: "left",
        isSort: false,
        isFilter: false,
      },
      {
        _id: 13,
        width: 10,
        name: t("appt_setter") + " %",
        value: "appointment_percentage",
        align: "left",
        isSort: false,
        isFilter: false,
      },
      {
        _id: 14,
        width: 10,
        name: t("amount"),
        value: "appt_setter_commission_amount",
        align: "left",
        isSort: false,
        isFilter: false,
      },
      {
        _id: 15,
        width: 10,
        name: t("payment_made"),
        value: "appt_commission_payment",
        align: "left",
        isSort: false,
        isFilter: false,
      },
      {
        _id: 16,
        width: 10,
        name: t("sales_admin"),
        value: "sales_admin_id",
        align: "left",
        isSort: false,
        isFilter: false,
      },
      {
        _id: 17,
        width: 10,
        name: t("sales_admin") + " %",
        value: "sales_admin_percentage",
        align: "left",
        isSort: false,
        isFilter: false,
      },
      {
        _id: 18,
        width: 10,
        name: t("amount"),
        value: "sales_admin_commission_amount",
        align: "left",
        isSort: false,
        isFilter: false,
      },
      {
        _id: 19,
        width: 10,
        name: t("payment_made"),
        value: "sales_admin_commission_payment",
        align: "left",
        isSort: false,
        isFilter: false,
      },
      {
        _id: 20,
        width: 10,
        name: t("total"),
        value: "total_commission",
        align: "left",
        isSort: false,
        isFilter: false,
      },
    ];
  } else if (type && type === "net-revenue") {
    tableHeadData = [
      {
        _id: 1,
        width: 40,
        name: t("type"),
        value: "type",
        align: "left",
        isSort: false,
        isFilter: false,
      },
      {
        _id: 2,
        width: 40,
        name: t("company"),
        value: "name",
        align: "left",
        isSort: false,
        isFilter: false,
      },
      {
        _id: 7,
        width: 40,
        name: t("commission_to"),
        value: "type",
        align: "left",
        isSort: false,
        isFilter: false,
      },
      {
        _id: 3,
        width: 40,
        name: t("type_of_expense"),
        value: "type",
        align: "left",
        isSort: false,
        isFilter: false,
      },
      {
        _id: 4,
        width: 15,
        name: t("price"),
        value: "amount",
        align: "left",
        isSort: false,
        isFilter: false,
      },
      {
        _id: 5,
        width: 20,
        name: t("date"),
        value: "date",
        align: "left",
        isSort: false,
        isFilter: false,
      },
      {
        _id: 6,
        width: 15,
        name: t("comments"),
        value: "status",
        align: "left",
        isSort: false,
        isFilter: false,
      },
    ];
  } else if (type && type === "lead-tracking") {
    tableHeadData = [
      {
        _id: 1,
        width: 40,
        name: "",
        value: "type",
        align: "left",
        isSort: false,
        isFilter: false,
      },
      {
        _id: 2,
        width: 40,
        name: t("leads"),
        value: "type",
        align: "left",
        isSort: false,
        isFilter: false,
      },
      {
        _id: 3,
        width: 40,
        name: t("sales") + "($$$)",
        value: "name",
        align: "left",
        isSort: false,
        isFilter: false,
      },
      {
        _id: 4,
        width: 40,
        name: t("conversion_rate"),
        value: "type",
        align: "left",
        isSort: false,
        isFilter: false,
      },
      {
        _id: 5,
        width: 15,
        name: t("cost_per_lead"),
        value: "amount",
        align: "left",
        isSort: false,
        isFilter: false,
      },
      {
        _id: 6,
        width: 20,
        name: t("cost_per_sale"),
        value: "date",
        align: "left",
        isSort: false,
        isFilter: false,
      },
    ];
  } else if (type && type === "avg-commission") {
    tableHeadData = [
      {
        _id: 1,
        width: 50,
        name: t("name"),
        value: "name",
        align: "left",
        isSort: false,
        isFilter: false,
      },
      {
        _id: 2,
        width: 40,
        name: t("no_of_sale"),
        value: "count_staff",
        align: "left",
        isSort: false,
        isFilter: false,
      },
      {
        _id: 3,
        width: 40,
        name: t("percentage"),
        value: "name",
        align: "left",
        isSort: false,
        isFilter: false,
      },
      {
        _id: 4,
        width: 40,
        name: t("total_commission"),
        value: "type",
        align: "left",
        isSort: false,
        isFilter: false,
      },
    ];
  } else if (type && type === "avg-sales") {
    tableHeadData = [
      {
        _id: 1,
        width: 50,
        name: t("name"),
        value: "name",
        align: "left",
        isSort: false,
        isFilter: false,
      },
      {
        _id: 2,
        width: 40,
        name: t("no_of_sale"),
        value: "count_staff",
        align: "left",
        isSort: false,
        isFilter: false,
      },
      {
        _id: 3,
        width: 40,
        name: t("total_sale_amount"),
        value: "name",
        align: "left",
        isSort: false,
        isFilter: false,
      },
      {
        _id: 4,
        width: 40,
        name: t("total_commission"),
        value: "type",
        align: "left",
        isSort: false,
        isFilter: false,
      },
    ];
  }

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
    const startItem = Number(totalDataCount > 0 ? 1 : 0);
    const endItem = Math.min(startItem + limit - 1, totalDataCount);
    setStartDataCount(startItem);
    setEndDataCount(endItem);
  }, [limit, totalDataCount]);

  const getDownload = (e) => {
    e.preventDefault();
    if (e.target.tagName.toLowerCase() === "a") {
      window.open(e.target.href, "_blank");
    }
  };

  return (
    <div className="border bg-white border-gray-200 p-4 shadow-md rounded-xl">
      <div className="relative ">
        <ReportTab
          xPlacement={"left"}
          type={type}
          subTypes={subtype}
          heading={heading}
          getChangeValue={(obj) => {
            setChangeValue(obj);
          }}
        />
      </div>

      <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-4">
        <div className="text-2xl font-bold">{heading}</div>
        <div className="sm:ml-auto flex justify-end items-center gap-3">
          <button
            type={"button"}
            className={
              "flex justify-center items-center gap-2 bg-teal-500 text-white rounded text-base sm:text-lg h-8 sm:h-10 py-0 px-2 sm:px-3 transition-all duration-200 cursor-pointer"
            }
            onClick={(e) => getDownload(e)}
          >
            <i className={"fa-solid fa-file-pdf"}></i>
            <div className={"text-xs sm:text-sm font-medium whitespace-nowrap"}>
              <PDFDownloadLink
                document={
                  type && type === "commissions" ? (
                    <CommissionReport
                      results={commissionFinalResult}
                      user={user}
                      code={code}
                      // heading = {heading}
                      // header = {tableHeadData}
                      type={type}
                    />
                  ) : type && type === "avg-commission" ? (
                    <AvgCommissionReport
                      results={avgCommissionFinalResult}
                      user={user}
                      code={code}
                      type={type}
                    />
                  ) : (
                    <DashboardReport
                      results={results}
                      user={user}
                      heading={heading}
                      header={tableHeadData}
                      type={type}
                    />
                  )
                }
                fileName="scorecard.pdf"
              >
                {({ blob, url, loading, error }) =>
                  loading ? t("loading_document") : t("download_pdf")
                }
              </PDFDownloadLink>
            </div>
          </button>
          {type && type === "expenses" && (
            <Button
              buttonType={"button"}
              buttonIcon={"fa-light fa-plus"}
              buttonIconPosition={"left"}
              buttonLabel={t("add")}
              buttonHasLink={false}
              functions={() => {
                setEnabledExpensesPopup(true);
                setExpensesInfo({});
                setActionValues({});
              }}
            />
          )}
        </div>
      </div>
      <div className="relative flex gap-3 items-center mb-3 flex-wrap">
        {type && type === "commissions" ? (
          <div className="relative">
            <Select
              xPlacement={"left"}
              dropdownClass={"!w-48"}
              dropdownButtonClass={"!bg-white !w-48"}
              selectedValue={limit}
              dropdownData={[
                { _id: 1000000, label: t("all_items"), value: 1000000 },
                ...[
                  { _id: 5, label: "5 " + t("items"), value: 5 },
                  { _id: 10, label: "10 " + t("items"), value: 10 },
                  { _id: 20, label: "20 " + t("items"), value: 20 },
                  { _id: 30, label: "30 " + t("items"), value: 30 },
                  { _id: 50, label: "50 " + t("items"), value: 50 },
                  { _id: 100, label: "100 " + t("items"), value: 100 },
                ],
              ]}
              getSelectedValue={(e) => setLimit(e.value)}
            />
          </div>
        ) : (
          <div className="relative bg-white border border-slate-200 rounded h-10 flex justify-start items-center px-3 text-[13px] text-slate-500 font-medium whitespace-nowrap truncate  w-48">
            {t("showing") + " : " + countData}
          </div>
        )}

        {type && type === "avg-sales" && (
          <div className="relative">
            <Select
              xPlacement={"right"}
              dropdownClass={"!w-48"}
              dropdownButtonClass={"!bg-white !w-48"}
              transitionClass={"!w-48"}
              selectedValue={code}
              search={true}
              dropdownData={[
                { _id: "", label: t("select") },
                ...staffArray.map((val) => ({
                  _id: val?.code,
                  label: val?.label,
                  value: val?.code,
                })),
              ]}
              getSelectedValue={(e) => setCode(e.value)}
            />
          </div>
        )}
        {type &&
          type !== "succession-plan" &&
          type !== "expenses" &&
          type !== "avg-commission" &&
          type !== "renewals" && (
            <>
              <div className="relative">
                <Select
                  xPlacement={"right"}
                  dropdownClass={"!w-48"}
                  dropdownButtonClass={"!bg-white !w-48"}
                  transitionClass={"!w-auto"}
                  selectedValue={salesId}
                  search={true}
                  dropdownData={[
                    { _id: "", label: t("all_staffs") },
                    ...sales.map((item) => ({
                      _id: item?._id,
                      label: item?.first_name + " " + item?.last_name,
                      value: item?._id,
                    })),
                  ]}
                  setSales
                  getSelectedValue={(e) => setSalesId(e._id)}
                />
              </div>
              {/* <div className="relative">
                  <Select 
                  xPlacement={'right'} 
                  dropdownButtonClass={'!bg-white'} 
                  transitionClass={'!w-auto'}
                  selectedValue={consultantId} 
                  dropdownData={[
                      { _id: '', label: t('all_consultant') },
                      ...consultants.map((item) => ({
                        _id: item?._id, 
                        label: item?.first_name+' '+item?.last_name,
                        value:item?._id
                      })),
                  ]}setSales
                  getSelectedValue={(e) => setConsultantId(e._id)}
                  />
                </div>
                <div className="relative">
                  <Select 
                  xPlacement={'right'} 
                  dropdownButtonClass={'!bg-white'} 
                  transitionClass={'!w-auto'}
                  selectedValue={appointmentSetterId} 
                  dropdownData={[
                      { _id: '', label: t('all_appointment_setter') },
                      ...appointmentSetter.map((item) => ({
                        _id: item?._id, 
                        label: item?.first_name+' '+item?.last_name,
                        value:item?._id
                      })),
                  ]}setSales
                  getSelectedValue={(e) => setAppointmentSetterId(e._id)}
                  />
                </div> */}
            </>
          )}
        {type && type === "renewals" && (
          <div className="relative">
            <Select
              xPlacement={"right"}
              dropdownClass={"!w-48"}
              dropdownButtonClass={"!bg-white !w-48"}
              transitionClass={"!w-auto"}
              selectedValue={companyId}
              search={true}
              dropdownData={[
                { _id: "", label: t("select_company") },
                ...companies.map((company) => ({
                  _id: company?._id,
                  label: company?.company_name,
                  value: company?._id,
                })),
              ]}
              getSelectedValue={(e) => setCompanyId(e.value)}
            />
          </div>
        )}
        {type && type === "expenses" && (
          <div className="relative">
            <Select
              xPlacement={"right"}
              dropdownClass={"!w-48"}
              dropdownButtonClass={"!bg-white !w-48"}
              transitionClass={"!w-auto"}
              selectedValue={expenseType}
              search={true}
              dropdownData={[
                { _id: "", label: t("all_expenses") },
                ...expenseTypes.map((item) => ({
                  _id: item?._id,
                  label: item?.name,
                  value: item?._id,
                })),
              ]}
              setSales
              getSelectedValue={(e) => setExpenseType(e._id)}
            />
          </div>
        )}
        {type && type === "commissions" && (
          <div className="relative">
            <Select
              xPlacement={"right"}
              dropdownClass={"!w-48"}
              dropdownButtonClass={"!bg-white !w-48"}
              transitionClass={"!w-auto"}
              selectedValue={paymentStatus}
              dropdownData={[
                { _id: "", label: t("all_payment_status") },
                { _id: 1, label: t("paid"), value: 1 },
                { _id: 2, label: t("not_paid"), value: 2 },
              ]}
              setSales
              getSelectedValue={(e) => setPaymentStatus(e._id)}
            />
          </div>
        )}
        <div className="relative">
          <Flatpickr
            ref={flatPicker}
            className="w-48 h-10 rounded-md bg-transparent border border-slate-200 px-3 text-[13px] text-slate-500 font-medium whitespace-nowrap truncate !ring-0 !outline-0 focus:border-gray-400 transition-all duration-200"
            placeholder={t("select_date")}
            value={date}
            options={{
              mode: "range",
              dateFormat: "Y-m-d",
              onClose: (selectedDates) => {
                setSelectedDates(selectedDates);
              },
            }}
            onChange={(selectedDates, dateStr, instance) => {
              setDate(dateStr);
            }}
            onReady={handleReady}
          />
        </div>
      </div>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <div className="w-full bg-white rounded-md shadow mb-4">
          <Table
            tableData={results}
            tableHeadData={tableHeadData}
            totalRecord={results.length}
            loading={loading}
            getSortValue={setSortValue}
            getSortKey={setSortKey}
            //editUrl={'/edit-position'}
            //isStatusUpdate={true}
            isDeleteEnabled={true}
            isDraggable={false}
            getActionValue={(obj) => {
              setActionValues(obj);
            }}
            tableImage={false}
            extraData={extraData}
            updateCheckboxHandler={(obj) => {
              //console.log(obj)
              setCheckboxValue(obj);
            }}
          />
        </div>
      </DragDropContext>
      {type && type === "commissions" && (
        <div className="flex justify-center sm:justify-between my-4">
          {totalDataCount > 0 ? (
            <div>
              <p>
                {t("showing")}: {startDataCount} to {endDataCount} of{" "}
                {totalDataCount}
              </p>
            </div>
          ) : (
            ""
          )}
        </div>
      )}
      {type && type === "net-revenue" && (
        <div className="relative grid grid-cols-2 sm:grid-cols-4 mb-4 gap-2">
          <div className="relative bg-white border border-slate-200 rounded py-2 sm:py-3 px-1 sm:px-2 flex justify-start sm:justify-center items-center text-xs sm:text-sm font-semibold text-teal-600">
            {t("revenew") + " : " + totalRevenew}
          </div>
          <div className="relative bg-white border border-slate-200 rounded py-2 sm:py-3 px-1 sm:px-2 flex justify-start sm:justify-center items-center text-xs sm:text-sm font-semibold text-rose-600">
            {t("commission") + " : " + totalCommission}
          </div>
          <div className="relative bg-white border border-slate-200 rounded py-2 sm:py-3 px-1 sm:px-2 flex justify-start sm:justify-center items-center text-xs sm:text-sm font-semibold text-rose-600">
            {t("operating_expenses") + " : " + totalExpenses}
          </div>
          <div className="relative bg-white border border-slate-200 rounded py-2 sm:py-3 px-1 sm:px-2 flex justify-start sm:justify-center items-center text-xs sm:text-sm font-semibold text-slate-600">
            {t("net_revenew") + " : " + countData}
          </div>
        </div>
      )}

      {enabledExpensesPopup && (
        <ExpensesPopup
          isOpen={enabledExpensesPopup}
          setIsOpen={(val) => {
            setEnabledExpensesPopup(val);
            setActionValues({});
          }}
          data={expenesInfo}
          getActionValue={(obj) => {
            setActionValues(obj);
            setEnabledExpensesPopup(false);
          }}
        />
      )}
    </div>
  );
};

export default Reports;

import React, { useState, useEffect, useCallback } from "react";
import { setTitle } from "../helpers/MetaTag";
import Select from "../components/form/Select";
import Button from "../components/form/Button";
import Search from "../components/form/Search";
import Table from "../components/elements/table/Table";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { reorder } from "../helpers";
import toastr from "toastr";
import CmsTab from "../shared/CmsTab";

import { postData } from "../services/api";
import { useTranslation } from "react-i18next";
import Pagination from "../components/Common/Pagination";
import { Tooltip } from "react-tooltip";
import AddEditLoyaltiesPopup from "../components/popup/AddEditLoyaltiesPopup";
import { getStatusFilter } from "../constant/Constant";
import { useDebounce } from "../helpers/useDebounce";

const Loyalties = () => {
  setTitle(
    "Succession Planning Software for your Business with Succession Now | Loyalty toward Company"
  );
  const { i18n, t } = useTranslation();
  const role = localStorage.getItem("role");
  const status_filter = getStatusFilter(t);
  const [loading, setLoading] = useState(true);
  const [keyword, setKeyword] = useState("");
  const search = useDebounce(keyword, 500);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [sortKey, setSortKey] = useState("point");
  const [sortValue, setSortValue] = useState(1);
  const [sortQuery, setSortQuery] = useState("");
  const [status, setStatus] = useState("");
  const [noOfPage, setNoOfPage] = useState(0);
  const [loyalty, setLoyalty] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [startDataCount, setStartDataCount] = useState(0);
  const [endDataCount, setEndDataCount] = useState(0);
  const [actionValue, setActionValue] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [isOpen, setIsopen] = useState({
    _id: null,
    open: false,
  });

  const extraData = [
    {
      _id: 1,
      label: t("edit"),
      icon: "fa-pen-to-square",
      link: "/",
      type: "button",
      standout: false,
      buttonType: "edit-user-info",
      isVisabled: 1,
      ordering: 1,
    },
  ];

  useEffect(() => {
    if (sortKey && sortValue) {
      setSortQuery({ [sortKey]: sortValue });
    } else {
      setSortQuery("");
    }
  }, [sortKey, sortValue]);

  useEffect(() => {
    if (actionValue && actionValue.button_type === "status") {
      const loadStatusChange = async () => {
        setIsUpdate(false);
        try {
          const statusData = await postData("loyalty/status-change", {
            id: actionValue?.row_id,
            status: actionValue?.current_status,
          });
          if (statusData.data) {
            setLoyalty(
              loyalty.map((it) => {
                if (it?._id === statusData.data?._id) {
                  return {
                    ...it,
                    status: statusData.data?.status,
                  };
                } else {
                  return it;
                }
              })
            );
            setIsUpdate(true);
          } else {
            toastr.error(statusData.message);
          }
        } catch (error) {
          toastr.error(error.message);
        }
      };
      loadStatusChange();
    } else if (actionValue && actionValue.button_type === "delete") {
      const loadDeleteRecord = async () => {
        setIsUpdate(false);
        try {
          const statusData = await postData("loyalty/delete", {
            id: actionValue?.row_id,
          });
          if (statusData.data) {
            const index = loyalty.findIndex(
              (item) => item._id === actionValue?.row_id
            );
            if (index !== -1) {
              loyalty.splice(index, 1);
              setLoyalty(
                loyalty.map((it) => {
                  return it;
                })
              );
            }
            setIsUpdate(true);
          } else {
            toastr.error(statusData.message);
          }
        } catch (error) {
          toastr.error(error.message);
        }
      };
      loadDeleteRecord();
    } else if (actionValue && actionValue.button_type === "edit-user-info") {
      setIsopen({ open: true, _id: actionValue?.row_id });
    }
    //console.log('actionValue',actionValue)
  }, [actionValue]);

  const loadList = useCallback(async () => {
    setLoading(true);
    try {
      const result = await postData("loyalty/list", {
        keyword: search,
        limit: limit,
        offset: offset,
        sortQuery: sortQuery,
        status: status,
      });
      if (result.data) {
        setLoyalty(
          result.data.map((value, key) => {
            return {
              _id: value?._id,
              rowData: [
                { _id: 1, type: "text", data: value?.name || "N/A" },
                {
                  _id: 2,
                  type: "status",
                  statusLabel: value.isPass ? "Pass" : "Fail",
                  statusType: value.isPass ? "success" : "danger",
                },
                {
                  _id: 3,
                  type: "text",
                  data:
                    value && value?.created_at
                      ? moment(new Date(value?.created_at)).format("ll")
                      : "N/A",
                },
                {
                  _id: 4,
                  type: "status",
                  statusLabel:
                    value?.status && value?.status === 1
                      ? t("active")
                      : t("deactivated"),
                  statusType:
                    value?.status && value?.status === 1
                      ? "success"
                      : "warning",
                },
                {
                  _id: 5,
                  type: "action",
                  statusLabel:
                    value?.status && value?.status === 1
                      ? t("active")
                      : t("deactivated"),
                  statusType:
                    value?.status && value?.status === 1
                      ? "success"
                      : "warning",
                  data: [{ "edit-user-info": 1 }],
                },
              ],
            };
          })
        );
      }
      setTotalDataCount(result.count);
      setLoading(false);
    } catch (err) {
      console.error(err.message);
    } finally {
      setLoading(false);
    }
  }, [search, limit, offset, sortQuery, status, isUpdate]);

  useEffect(() => {
    loadList();
  }, [loadList]);

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
    const startItem = Number(totalDataCount > 0 ? 1 : 0);
    const endItem = Math.min(startItem + limit - 1, totalDataCount);
    setStartDataCount(startItem);
    setEndDataCount(endItem);
  }, [limit, totalDataCount]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalDataCount;
    setOffset(newOffset);
    setCurrentPage(event.selected);
    const startItem = Number(newOffset + 1);
    const endItem = Math.min(startItem + limit - 1, totalDataCount);
    setStartDataCount(startItem);
    setEndDataCount(endItem);
  };

  const tableHeadData = [
    {
      _id: 1,
      name: t("loyalty_rating"),
      value: "name",
      align: "left",
      isSort: true,
      isFilter: false,
    },
    {
      _id: 2,
      name: t("pass") + "/" + t("fail"),
      value: "isPass",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 3,
      name: t("created"),
      value: "created_at",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 4,
      width: 15,
      name: t("status"),
      value: "status",
      align: "left",
      isSort: false,
      isFilter: true,
      filterData: status_filter || [],
      onFilter: (data) => {
        if (data && Array.isArray(data) && data.length === 1) {
          setStatus(data[0]?.value);
        } else {
          setStatus("");
        }
        setOffset(0);
      },
    },
    {
      _id: 5,
      name: t("action"),
      value: "",
      align: "right",
      isSort: false,
      isFilter: false,
    },
  ];

  const handleOnDragEnd = async (result) => {
    const items = reorder(
      loyalty,
      result.source.index,
      result.destination.index
    );
    setLoyalty(items);
    const res = await postData("loyalty/ordering", {
      items: items,
    });
    if (res.status && res.status === 200) {
      toastr.success(res.message);
    } else {
      toastr.error(res.message);
    }
  };

  return (
    <>
      <div className="border bg-white border-gray-200 p-4 shadow-md rounded-xl">
        {role && role !== "master-admin" && (
          <div className="relative ml-auto flex justify-start items-center">
            <CmsTab xPlacement={"left"} />
          </div>
        )}
        <div className="relative flex gap-3 items-center mb-3 flex-wrap sm:flex-nowrap gap-y-4">
          <div className="text-2xl font-bold">{t("loyalty")}</div>
          <div
            className="sm:ml-auto flex justify-end items-center"
            data-tooltip-id={`button${1}`}
            data-tooltip-place="right"
          >
            <Button
              buttonType={"button"}
              buttonIcon={"fa-light fa-plus"}
              buttonIconPosition={"left"}
              buttonLabel={t("add")}
              buttonHasLink={false}
              functions={() => setIsopen({ _id: null, open: true })}
            />
            <Tooltip
              id={`button${1}`}
              className="!text-xs !bg-dark-teal-600 z-50 rounded py-3 px-6"
              render={() => (
                <div className="gap-x-1 gap-y-1">
                  <div className="text-[13px] font-normal leading-none flex items-center">
                    <div className="font-semibold">Add New Loyalty</div>
                  </div>
                </div>
              )}
            />
          </div>
        </div>
        <div className="relative flex gap-3 items-center mb-3 flex-wrap">
          <div className="relative">
            <Select
              xPlacement={"left"}
              dropdownButtonClass={"!bg-white"}
              dropdownClass={"w-auto"}
              selectedValue={limit}
              dropdownData={[
                { _id: 1000000, label: t("all_items"), value: 1000000 },
                ...[
                  { _id: 10, label: "10 " + t("items"), value: 10 },
                  { _id: 20, label: "20 " + t("items"), value: 20 },
                  { _id: 30, label: "30 " + t("items"), value: 30 },
                  { _id: 50, label: "50 " + t("items"), value: 50 },
                  { _id: 100, label: "100 " + t("items"), value: 100 },
                ],
              ]}
              getSelectedValue={(e) => {
                setLimit(e.value);
                setOffset(0);
                setCurrentPage(0);
              }}
            />
          </div>
          <div className="relative w-48">
            <Search
              placeholder={t("search")}
              setInput={setKeyword}
              valueReset={() => {
                setOffset(0);
                setCurrentPage(0);
              }}
            />
          </div>
          {/* <div className="relative w-48">
            <Select
              xPlacement={"right"}
              dropdownButtonClass={"!bg-white"}
              transitionClass={"!w-auto"}
              dropdownClass={"w-48"}
              selectedValue={status}
              dropdownData={[
                { _id: "", label: t("all_status") },
                { _id: 1, label: t("active"), value: 1 },
                { _id: 2, label: t("deactivated"), value: 2 },
              ]}
              getSelectedValue={(e) => setStatus(e.value)}
            />
          </div> */}
        </div>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <div className="w-full bg-white rounded-md shadow overflow-hidden">
            <Table
              tableData={loyalty}
              tableHeadData={tableHeadData}
              totalRecord={totalDataCount}
              loading={loading}
              getSortValue={setSortValue}
              getSortKey={setSortKey}
              // editUrl={"/edit-loyalty"}
              extraData={extraData}
              isStatusUpdate={true}
              isDeleteEnabled={true}
              isDraggable={false}
              getActionValue={(obj) => setActionValue(obj)}
              tableImage={false}
            />
          </div>
        </DragDropContext>
        {noOfPage > 1 ? (
          <Pagination
            handlePageClick={handlePageClick}
            noOfPage={noOfPage}
            startDataCount={startDataCount}
            endDataCount={endDataCount}
            count={totalDataCount}
            forcePage={currentPage}
          />
        ) : (
          <div className="flex justify-center sm:justify-between my-4 text-sm text-black text-center sm:text-left">
            {totalDataCount > 0 ? (
              <div>
                <p>
                  {t("showing")}: {startDataCount} to {endDataCount} of{" "}
                  {totalDataCount}
                </p>
              </div>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
      {isOpen?.open && (
        <AddEditLoyaltiesPopup
          isOpen={isOpen?.open}
          id={isOpen?._id}
          setIsOpen={setIsopen}
          loadList={loadList}
        />
      )}
    </>
  );
};

export default Loyalties;

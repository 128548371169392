import React, { useEffect, useState } from "react";
import { setTitle } from "../helpers/MetaTag";
import Button from "../components/form/Button";
import Search from "../components/form/Search";
import Select from "../components/form/Select";
import Textarea from "../components/form/Textarea";
import Searching from "../components/Searching";
import NoDataFound from "../components/NoDataFound";
import ReactPaginate from "react-paginate";
import Image from "../components/elements/Image";
import toastr from "toastr";
import { useSelector } from "react-redux";
import DeletePopup from "../components/DeletePopup";
import SuccessorEditPopup from "../components/popup/SuccessorEditPopup";
import { SuccessorLimitMessage, NextStepMessage } from "../helpers";
import { postData } from "../services/api";
import { API_URL } from '../config/host';
import StepAlert from "../components/elements/alert/StepAlert";
import ManageTiersPopup from "../components/popup/ManageTiersPopup";
import { useTranslation } from "react-i18next";

const Profiles = ({ getIsChange = () => {} }) => {
  setTitle("Succession Planning Software for your Business with Succession Now | Potential Successors");
  const { i18n,t } = useTranslation();
  const user = useSelector((state) => state.auth?.user);
  const [experience, setExperience] = useState([]);
  const [creditUnion, setCreditUnion] = useState([]);
  const [education, setEducation] = useState([]);
  const [performance, setPerformance] = useState([]);
  const [loyalty, setLoyalty] = useState([]);
  const [desire, setDesire] = useState([]);
  const [retirementWindow, setRetirementWindow] = useState([]);

  const [loading, setLoading] = useState(true);
  const [keyword, setKeyword] = useState("");
  const [limit, setLimit] = useState(10000);
  const [offset, setOffset] = useState(0);
  const [noOfPage, setNoOfPage] = useState(0);
  const [successors, setSuccessors] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [industry, setIndustry] = useState("credit_union");

  const [enabledEditPopup, setEnabledEditPopup] = useState(false);
  const [editInfo,setEditInfo] = useState({});
  const [enabledDeletePopup, setenabledDeletePopup] = useState(false);
  const [currentId, setCurrentId] = useState("");
  const [actionValue, setActionValue] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  const [message, setMessage] = useState("");
  const [isNextStep, setIsNextStep] = useState(false);
  const [isChange, setIsChage] = useState(false); 

  const [enabledManageTiersPopup, setEnabledManageTiersPopup] = useState(false);
  
  const heading = [
    {
      name: "Senior Management Experience",
      option: "(Years of Senior Management Experience)",
    },
    {
      name:
        industry && industry === "credit_union"
          ? "Years with company"
          : "Years with company",
      option:
        industry && industry === "credit_union"
          ? "(Years with company)"
          : "(Years with company)",
    },
    {
      name: "Education",
      option:
        industry && industry === "credit_union"
          ? "(Highest level of Education)"
          : "(Specialized schools or courses)",
    },
    {
      name: "Performance",
      option: "(Recent Performance Review)",
    },
    {
      name: "Loyalty",
      option:
        industry && industry === "credit_union"
          ? "(Loyalty Toward Company)"
          : "(Company)",
    },
    {
      name: "Desire to Advance",
      option: "",
    },
    {
      name: "Retirement Window",
      option: "",
    },
    {
      name: "Additional Comments",
      option: "",
    },
  ];
  //const [comment,setComment] = useState([]);  

  useEffect(() => {
    async function experienceData() {
      try {
        const result = await postData("experience/list", {
          sortQuery: { ordering: -1 },
          type: 1,
          status: 1,
        });
        if (result.data) {
          setExperience(result.data);
        } else {
          console.log("Experience list message", result.message);
        }
      } catch (error) {
        console.log("Error Experience list catch", error.message);
      }
    }
    experienceData();

    async function creditUnionData() {
      try {
        const result = await postData("experience/list", {
          sortQuery: { ordering: -1 },
          type: 2,
          status: 1,
        });
        if (result.data) {
          setCreditUnion(result.data);
        } else {
          console.log("Company list message", result.message);
        }
      } catch (error) {
        console.log("Error Company list catch", error.message);
      }
    }
    creditUnionData();

    async function educationList() {
      try {
        const result = await postData("education/list", {
          sortQuery: { created_at: 1 },
          status: 1,
        });
        if (result.data) {
          setEducation(result.data);
        } else {
          console.log("Education message", result.message);
        }
      } catch (error) {
        console.log("Error education catch", error.message);
      }
    }
    educationList();

    async function performanceList() {
      try {
        const result = await postData("performance/list", {
          sortQuery: { created_at: 1 },
          status: 1,
        });
        if (result.data) {
          setPerformance(result.data);
        } else {
          console.log("Performance message", result.message);
        }
      } catch (error) {
        console.log("Error performance catch", error.message);
      }
    }
    performanceList();

    async function loyaltyList() {
      try {
        const result = await postData("loyalty/list", {
          sortQuery: { created_at: 1 },
          status: 1,
        });
        if (result.data) {
          setLoyalty(result.data);
        } else {
          console.log("Loyalty message", result.message);
        }
      } catch (error) {
        console.log("Error loyalty catch", error.message);
      }
    }
    loyaltyList();

    async function desireList() {
      try {
        const result = await postData("desire/list", {
          sortQuery: { created_at: 1 },
          status: 1,
        });
        if (result.data) {
          setDesire(result.data);
        } else {
          console.log("Desire message", result.message);
        }
      } catch (error) {
        console.log("Error desire catch", error.message);
      }
    }
    desireList();

    async function retirementWindowData() {
      try {
        const result = await postData("experience/list", {
          sortQuery: { created_at: 1 },
          type: 3,
          status: 1,
          is_visabled:1,
        });
        if (result.data) {
          setRetirementWindow(result.data);
        } else {
          console.log("Retirement window list message", result.message);
        }
      } catch (error) {
        console.log("Error retirement window list catch", error.message);
      }
    }
    retirementWindowData();
  }, []);

  useEffect(() => {
    // Profiles List
    const loadSuccessors = async () => {
      setLoading(true);
      try {
        const result = await postData("successor/list", {
          keyword: keyword,
          limit: limit,
          offset: offset,
          sortQuery: { created_at: 1 },
          is_interim:2,
        });
        if (result.data) {
          setSuccessors(result.data);
        }
        setTotalDataCount(result.count);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err.message);
      }
    };
    loadSuccessors();
  }, [keyword, limit, offset, isUpdate,actionValue]);

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
  }, [limit, totalDataCount]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalDataCount;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setOffset(newOffset);
  };

  const handelUpdate = async (id, val, field, index) => {
    try {
      setIsChage(false);
      getIsChange(false);
      setSuccessors(
        successors.map((it, idx) => {
          if (idx === index) {
            return { ...it, [field]: val };
          } else {
            return it;
          }
        })
      );
      const result = await postData("successor/update", {
        id: id,
        [field]: val !== "" ? val : null,
      });
      if (result.status && result.status === 200) {
        toastr.success(result.message);
        await postData("download-succession-plan/close-approval", {});
        setIsChage(true);
        getIsChange(true);
      } else {
        toastr.error(result.message);
        setIsChage(true);
        getIsChange(true);
      }
    } catch (error) {
      toastr.error(error.message);
      setIsChage(true);
      getIsChange(true);
    }
  };

  useEffect(() => {
    const loadDeleteRecord = async () => {
      setIsChage(false);
      setIsUpdate(false);
      getIsChange(false);
      try {
        const statusData = await postData("successor/delete", {
          id: actionValue?.row_id,
        });
        if (statusData.status === 200) {
          const index = successors.findIndex(
            (item) => item._id === actionValue?.row_id
          );
          if (index !== -1) {
            successors.splice(index, 1);
            setSuccessors(
              successors.map((it) => {
                return it;
              })
            );
          }
          setIsChage(true);
          setIsUpdate(true);
          getIsChange(true);
        } else {
          toastr.error(statusData.message);
        }
      } catch (error) {
        toastr.error(error.message);
      }
    };
    if (actionValue && actionValue.button_type === "delete") {
      loadDeleteRecord();
    }
  }, [actionValue]);

  useEffect(() => {
    if (successors && successors.length > 0) {
      let experience = successors.filter((item) => !item.experience);
      let with_credit_union = successors.filter(
        (item) => !item.with_credit_union
      );
      let education = successors.filter((item) => !item.education);
      let performance = successors.filter((item) => !item.performance);
      let loyalty = successors.filter((item) => !item.loyalty);
      let desire_to_advance = successors.filter(
        (item) => !item.desire_to_advance
      );
      let retirement_window = successors.filter(
        (item) => !item.retirement_window
      );
      if (
        experience.length === 0 &&
        with_credit_union.length === 0 &&
        education.length === 0 &&
        performance.length === 0 &&
        loyalty.length === 0 &&
        desire_to_advance.length === 0 &&
        retirement_window.length === 0
      ) {
        setIsNextStep(true);
      } else {
        setIsNextStep(false);
      }
    } else {
      setIsNextStep(false);
    }
  }, [isChange, successors]);    

  return (
    <>
      <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-4">
        <div className="text-xl md:text-3xl text-slate-800 font-bold">
          {t('succession_candidates_for')} {user?.title}
        </div>
        <div className="sm:ml-auto flex justify-end items-center space-x-2">
          {
            user && (user?.email === 'jd.test.nwa@gmail.com' || user?.email === 'sujaymondal9@gmail.com' || user?.email === 'pm@gmail.com') && 
            <Button
              buttonType={"button"}
              buttonIcon={"fa-light fa-list-check"}
              buttonIconPosition={"left"}
              buttonLabel={t('manage_tiers')}
              buttonHasLink={false}
              functions={() => setEnabledManageTiersPopup(true)}
            />
          }          
          {(totalDataCount && totalDataCount >= 5) ? (
            <Button
              buttonType={"button"}
              buttonIcon={"fa-light fa-plus"}
              buttonIconPosition={"left"}
              buttonLabel={t('add_successor')}
              buttonHasLink={false}
              functions={() => toastr.error(SuccessorLimitMessage)}
            />
          ) : (
            <Button
              buttonType={"button"}
              buttonIcon={"fa-light fa-plus"}
              buttonIconPosition={"left"}
              buttonLabel={t('add_successor')}
              buttonHasLink={true}
              buttonLink={"/add-successor"}
            />
          )}
          {isNextStep ? (
            <Button
              buttonType={"button"}
              buttonIcon={"fa-light fa-arrow-right-to-arc"}
              buttonIconPosition={"left"}
              buttonLabel={t('next_step')}
              buttonHasLink={true}
              buttonLink={"/concern"}
            />
          ) : (
            <Button
              buttonClasses={"!bg-rose-500"}
              buttonType={"button"}
              buttonIcon={"fa-light fa-arrow-right-to-arc"}
              buttonIconPosition={"left"}
              buttonLabel={t('next_step')}
              buttonHasLink={false}
              functions={() =>
                toastr.error(
                  successors && successors.length > 0
                    ? NextStepMessage
                    : t('please_add_successors_first')
                )
              }
            />
          )}
        </div>
      </div>
      <StepAlert/>
      <div className="relative flex justify-between items-center mb-4">
        {/* <div className="relative">
          <Select
            dropdownButtonClass={"!bg-white"}
            xPlacement={"left"}
            selectedValue={limit}
            dropdownData={[
              { _id: 1000000, label: "All Items", value: 1000000 },
              ...[
                { _id: 10, label: "10 Items", value: 10 },
                { _id: 20, label: "20 Items", value: 20 },
                { _id: 30, label: "30 Items", value: 30 },
                { _id: 50, label: "50 Items", value: 50 },
                { _id: 100, label: "100 Items", value: 100 },
              ],
            ]}
            getSelectedValue={(e) => setLimit(e.value)}
          />
        </div> */}
        {/* <div className="relative ml-auto flex justify-end items-center gap-3">
          <div className="relative">
            <Search placeholder={"Search"} setInput={setKeyword} />
          </div>
        </div> */}
      </div>

      <div className="w-full overflow-auto scroll-smooth scrollbar min-h-[480px] mb-4">
        <table className="min-w-max w-full table-auto">
          <thead>
            <tr className="text-white font-bold text-sm leading-none border-b border-slate-200">
              <th className="py-4 px-2 text-left max-w-[240px] align-top sticky -top-6 z-10 bg-slate-900 rounded-tl-md">
                {successors && successors.length > 0
                  ? t('succession_factors')
                  : t('welcome_to_your_successon_plan')}
              </th>
              {totalDataCount > 0
                ? successors.map((item, index0) => {
                    return (
                      <th className="py-4 px-2 max-w-[240px] align-top sticky -top-6 z-10 bg-slate-900 last:rounded-tr-md" key={index0}>
                        <div className="flex justify-center gap-1">
                          <div className="mx-auto flex justify-center gap-4 items-center">
                            <div className="w-8 h-8 min-w-[32px] min-h-[32px] overflow-hidden rounded-full relative">
                              <Image
                                src={API_URL+item?.image}
                                alt={"Admin"}
                                width={"100%"}
                                height={"100%"}
                                effect={"blur"}
                                classes={"object-cover cursor-pointer"}
                              />
                            </div>
                            <div className="font-semibold text-sm leading-none text-center">
                              {item.name}
                              <span className="block text-[11px] font-medium mt-1">
                                {item?.position_details
                                  ? item?.position_details[0]?.short_name
                                  : "N/A"}
                              </span>
                            </div>
                          </div>
                          <div className="flex justify-end  min-w-[30px]">
                            <Button
                              buttonClasses={"!p-0 !flex !items-center !justify-center !text-[10px] !bg-transparent !text-slate-400 !w-4 !h-4"}
                              buttonType={"button"}
                              buttonIcon={"fa-regular fa-pencil"}
                              buttonIconPosition={"left"}
                              buttonHasLink={false}
                              functions={() => {
                                setEnabledEditPopup(true);
                                setEditInfo({
                                  _id:item?._id,
                                  name : item?.name,
                                  designation : item?.designation,
                                  experience : item?.experience,
                                  with_credit_union: item?.with_credit_union,
                                  education: item?.education,
                                  performance : item?.performance,
                                  loyalty : item?.loyalty,
                                  desire_to_advance: item.desire_to_advance,
                                  retirement_window: item?.retirement_window,
                                  image : API_URL+item?.image
                                })
                              }}
                            />
                            <Button
                              buttonClasses={
                                "!p-0 !flex !items-center !justify-center !text-sm !bg-transparent !text-rose-500 !w-4 !h-4"
                              }
                              buttonType={"button"}
                              buttonIcon={"fa-regular fa-xmark"}
                              buttonIconPosition={"left"}
                              buttonHasLink={false}
                              functions={() => {
                                setenabledDeletePopup(true);
                                setCurrentId(item?._id);
                                setMessage(
                                  t('you_are_about_to_delete_the_successor') +
                                    item?.name +
                                    ". "+t('please_click_confirm_to_delete_this_successor')
                                );
                              }}
                            />
                          </div>
                        </div>
                      </th>
                    );
                  })
                : ""}
            </tr>
          </thead>
          {totalDataCount > 0 && (
            <tbody className="text-slate-700 text-sm font-light bg-white">
              {heading.map((items, index) => (
                <tr className="border-b border-slate-200 group" key={index}>
                  <td className="py-3 px-2 font-semibold text-sm text-left max-w-[240px] align-top" >
                    {items.name}
                    <span className="block text-xs font-normal leading-tight text-slate-500 my-1">
                      {items.option}
                    </span>
                  </td>
                  {totalDataCount > 0
                    ? successors.map((item, index1) => {
                        if (index === 0) {
                          return (
                            <td className="py-3 px-2 text-left max-w-[240px]" key={index1}>
                              <Select
                                xPlacement={"left"}
                                selectedValue={item?.experience}
                                dropdownClass={"!w-60"}
                                dropdownButtonLabelClass={"!font-bold"}
                                dropdownData={[
                                  { _id: "", label: t('select'), value: "" },
                                  ...experience.map((experience_item) => ({
                                    _id: experience_item?._id,
                                    label: experience_item?.name,
                                    value: experience_item?._id,
                                  })),
                                ]}
                                dropdownOptionBold={true}
                                getSelectedValue={(e) =>
                                  handelUpdate(
                                    item._id,
                                    e._id,
                                    "experience",
                                    index1
                                  )
                                }
                              />
                            </td>
                          );
                        } else if (index === 1) {
                          return (
                            <td className="py-3 px-2 text-left max-w-[240px]" key={index1}>
                              <Select
                                xPlacement={"left"}
                                selectedValue={item?.with_credit_union}
                                dropdownClass={"!w-60"}
                                dropdownButtonLabelClass={"!font-bold"}
                                dropdownData={[
                                  { _id: "", label: t('select'), value: "" },
                                  ...creditUnion.map((item) => ({
                                    _id: item?._id,
                                    label: item?.name,
                                    value: item?._id,
                                  })),
                                ]}
                                dropdownOptionBold={true}
                                getSelectedValue={(e) =>
                                  handelUpdate(
                                    item._id,
                                    e._id,
                                    "with_credit_union",
                                    index1
                                  )
                                }
                              />
                            </td>
                          );
                        } else if (index === 2) {
                          return (
                            <td className="py-3 px-2 text-left max-w-[240px]" key={index1}>
                              <Select
                                xPlacement={"left"}
                                selectedValue={item?.education}
                                dropdownClass={"!w-60"}
                                dropdownButtonLabelClass={"!font-bold"}
                                dropdownData={[
                                  { _id: "", label: t('select'), value: "" },
                                  ...education.map((item) => ({
                                    _id: item?._id,
                                    label: item?.name,
                                    value: item?._id,
                                  })),
                                ]}
                                dropdownOptionBold={true}
                                getSelectedValue={(e) =>
                                  handelUpdate(
                                    item._id,
                                    e._id,
                                    "education",
                                    index1
                                  )
                                }
                              />
                            </td>
                          );
                        } else if (index === 3) {
                          return (
                            <td className="py-3 px-2 text-left max-w-[240px]" key={index1}>
                              <Select
                                xPlacement={"left"}
                                selectedValue={item?.performance}
                                dropdownClass={"!w-60"}
                                dropdownButtonLabelClass={"!font-bold"}
                                dropdownData={[
                                  { _id: "", label: t('select'), value: "" },
                                  ...performance.map((item) => ({
                                    _id: item?._id,
                                    label:
                                      (item?.alt_name
                                        ? item?.alt_name
                                        : item?.name) +
                                      " " +
                                      (item.number
                                        ? "(" + item.number + ")"
                                        : ""),
                                    value: item?._id,
                                  })),
                                ]}
                                dropdownOptionBold={true}
                                getSelectedValue={(e) =>
                                  handelUpdate(
                                    item._id,
                                    e._id,
                                    "performance",
                                    index1
                                  )
                                }
                              />
                            </td>
                          );
                        } else if (index === 4) {
                          let key = loyalty.findIndex((itm) => itm._id === item?.loyalty);
                          let pass = true;
                          let isClass = null;
                          if(key !== -1){
                            pass = loyalty[key].isPass;
                            isClass = loyalty[key].isClass;
                          }   
                                               
                          return (
                            <td className="py-3 px-2 text-left max-w-[240px]" key={index1}>
                              <Select
                                xPlacement={"left"}
                                selectedValue={item?.loyalty}
                                dropdownClass={"!w-60"}
                                dropdownButtonLabelClass={(isClass && isClass === 'blue')?"!text-blue-500 !font-bold":(isClass && isClass === 'rose')?"!text-rose-500 !font-bold":"!font-bold"}
                                dropdownData={[
                                  { _id: "", label: t('select'), value: "" },
                                  ...loyalty.map((item) => ({
                                    _id: item?._id,
                                    label: item?.name,
                                    value: item?._id,
                                  })),
                                ]}
                                dropdownOptionBold={true}
                                getSelectedValue={(e) =>
                                  handelUpdate(
                                    item._id,
                                    e._id,
                                    "loyalty",
                                    index1
                                  )
                                }
                              />
                            </td>
                          );
                        } else if (index === 5) {
                          let desire_key = desire.findIndex((desire) => desire._id === item?.desire_to_advance);
                          let desire_pass = true;
                          let desire_isClass = null;
                          if(desire_key !== -1){
                            desire_pass = desire[desire_key].isPass;
                            desire_isClass = desire[desire_key].isClass;
                          }
                          //console.log('desire_isClass',(desire_isClass)?desire_isClass+" !font-bold":"!font-bold")
                          return (
                            <td className="py-3 px-2 text-left max-w-[240px]" key={index1}>
                              <Select
                                xPlacement={"left"}
                                selectedValue={item.desire_to_advance}
                                dropdownClass={"!w-60"}
                                dropdownButtonLabelClass={(desire_isClass && desire_isClass === 'blue')?"!text-blue-500 !font-bold":(desire_isClass && desire_isClass === 'rose')?"!text-rose-500 !font-bold":"!font-bold"}
                                dropdownData={[
                                  { _id: "", label: t('select'), value: "" },
                                  ...desire.map((item) => ({
                                    _id: item?._id,
                                    label: item?.name,
                                    value: item?._id,
                                  })),
                                ]}
                                dropdownOptionBold={true}
                                getSelectedValue={(e) =>
                                  handelUpdate(
                                    item._id,
                                    e._id,
                                    "desire_to_advance",
                                    index1
                                  )
                                }
                              />
                            </td>
                          );
                        } else if (index === 6) {
                          let retirement_key = retirementWindow.findIndex((retirement) => retirement._id === item?.retirement_window);
                          let retirement_pass = true;
                          let retirement_isClass = true;
                          if(retirement_key !== -1){
                            retirement_pass = retirementWindow[retirement_key].isPass;
                            retirement_isClass = retirementWindow[retirement_key].isClass;
                          }
                          return (
                            <td className="py-3 px-2 text-left max-w-[240px]" key={index1}>
                              <Select
                                xPlacement={"left"}
                                selectedValue={item?.retirement_window}
                                dropdownClass={"!w-60"}
                                dropdownButtonLabelClass={(retirement_isClass && retirement_isClass === 'blue')?"!text-blue-500 !font-bold":(retirement_isClass && retirement_isClass === 'rose')?"!text-rose-500 !font-bold":"!font-bold"}
                                dropdownData={[
                                  { _id: "", label: t('select'), value: "" },
                                  ...retirementWindow.map((item) => ({
                                    _id: item?._id,
                                    label: item?.name,
                                    value: item?._id,
                                  })),
                                ]}
                                dropdownOptionBold={true}
                                getSelectedValue={(e) =>
                                  handelUpdate(
                                    item._id,
                                    e._id,
                                    "retirement_window",
                                    index1
                                  )
                                }
                              />
                            </td>
                          );
                        } else if (index === 7) {
                          return (
                            <td className="py-3 px-2 text-left max-w-[240px]" key={index1}>
                              <Textarea
                                inputClasses={"!h-32"}
                                inputValue={item?.additional_comments}
                                setTextarea={(val) => {
                                  setSuccessors(
                                    successors.map((it, idx) => {
                                      if (idx === index1) {
                                        return {
                                          ...it,
                                          additional_comments: val,
                                        };
                                      } else {
                                        return it;
                                      }
                                    })
                                  );
                                }}
                                onBlur={(val) =>
                                  handelUpdate(
                                    item._id,
                                    val,
                                    "additional_comments",
                                    index1
                                  )
                                }
                              />
                            </td>
                          );
                        } else {
                          return (
                            <td className="py-3 px-2 text-left max-w-[240px] align-top" key={index1}>
                              <div className="relative flex justify-center text-xs font-medium text-slate-700 bg-white py-2 px-3 border border-slate-200 rounded"></div>
                            </td>
                          );
                        }
                      })
                    : ""}
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
      <div className="relative flex justify-between items-center">
        <div className="ml-auto flex justify-end items-center space-x-2">
          {isNextStep ? (
            <Button
              buttonType={"button"}
              buttonIcon={"fa-light fa-arrow-right-to-arc"}
              buttonIconPosition={"left"}
              buttonLabel={t('next_step')}
              buttonHasLink={true}
              buttonLink={"/concern"}
            />
          ) : (
            <Button
              buttonClasses={"!bg-rose-500"}
              buttonType={"button"}
              buttonIcon={"fa-light fa-arrow-right-to-arc"}
              buttonIconPosition={"left"}
              buttonLabel={t('next_step')}
              buttonHasLink={false}
              functions={() => toastr.error(NextStepMessage)}
            />
          )}
        </div>
      </div>
      {enabledDeletePopup && (
        <DeletePopup
          isOpen={enabledDeletePopup}
          setIsOpen={(val) => setenabledDeletePopup(val)}
          currentId={currentId}
          getActionValue={(obj) => {
            setActionValue(obj);
            setenabledDeletePopup(false);
            setCurrentId("");
          }}
          message={message}
        />
      )}
      {
        enabledEditPopup &&
        <SuccessorEditPopup
          isOpen={enabledEditPopup}
          setIsOpen={(val) => setEnabledEditPopup(val)}
          data={editInfo}
          getActionValue={(obj) => {
            setActionValue(obj);
            setEnabledEditPopup(false);
            setEditInfo({});
          }}
          message={message}
        />
      }
      {enabledManageTiersPopup && (
        <ManageTiersPopup
          isOpen={enabledManageTiersPopup}
          setIsOpen={(val) => setEnabledManageTiersPopup(val)}
          data={{
            user : user
          }}
          getActionValue={(obj) => {
            setActionValue(obj);
          }}
        ></ManageTiersPopup>
      )}
    </>
  );
};

export default Profiles;

import React from "react";
import { Page, Text, View, StyleSheet, Image, Font } from "@react-pdf/renderer";
import logo from "../../logo-succession-now.png";
import RobotoLight from "../../assets/font/Roboto-Light.ttf";
import RobotoRegular from "../../assets/font/Roboto-Regular.ttf";
import RobotoMedium from "../../assets/font/Roboto-Medium.ttf";
import RobotoBold from "../../assets/font/Roboto-Bold.ttf";
import moment from "moment/moment";

import { useTranslation } from "react-i18next";

Font.register({ family: "RobotoLight", src: RobotoLight });
Font.register({ family: "RobotoRegular", src: RobotoRegular });
Font.register({ family: "RobotoMedium", src: RobotoMedium });
Font.register({ family: "RobotoBold", src: RobotoBold });

const PerspectiveReport = ({
  history,
  reatingOptions,
  title,
  headingTitle,
  user,
  companyLogo,
  type,
}) => {
  const { t } = useTranslation();

  const styles = StyleSheet.create({
    page: {
      padding: 20,
      fontFamily: "RobotoRegular",
      lineHeight: 1.2,
      fontSize: 9,
      color: "#334155",
    },
    tableHeading: {
      fontSize: 12,
      width: "20%",
      maxWidth: "100%",
      padding: 8,
      backgroundColor: "#0f172a",
      color: "#ffffff",
    },
    tableData: {
      fontSize: 10,
      width: "20%",
      maxWidth: "100%",
      padding: 8,
    },

    image: {
      width: 150,
      height: 30,
      objectFit: "contain",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    tableOtherData: {
      fontSize: 10,
      color: "#334155",
    },
    imageSignature: {
      width: 100,
      height: 60,
      objectFit: "contain",
    },
    section: { textAlign: "center" },
    profileTableHeading: {
      fontSize: 8,
      width: "14%",
      paddingHorizontal: 4,
      paddingVertical: 8,
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    profileTableHeadingText: {
      width: "100%",
      fontFamily: "RobotoRegular",
      fontSize: 8,
      lineHeight: 1,
    },
    profileSubText: {
      fontSize: 8,
      marginTop: 2,
    },
    profileTableData: {
      fontSize: 9,
      width: "14%",
      padding: 4,
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    profileTableText: {
      fontSize: 7,
    },
    tablePart: {
      display: "flex",
      flexDirection: "row",
      overflow: "hidden",
    },
    developmentTableHeading: {
      fontSize: 8,
      width: "33.33%",
      paddingHorizontal: 8,
      paddingVertical: 8,
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    developmentTableData: {
      fontSize: 9,
      width: "33.33%",
      padding: 4,
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    developmentTableText: {
      fontSize: 7,
    },
    developmentTableHeadingText: {
      width: "100%",
      fontFamily: "RobotoRegular",
      fontSize: 10,
      lineHeight: 1,
    },
    readinessTableHeading: {
      fontSize: 10,
      width: "25%",
      padding: "8px 4px",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    readinessTableHeadingText: {
      width: "100%",
      fontFamily: "RobotoRegular",
      fontSize: 10,
      lineHeight: 1,
    },
    readinessTableData: {
      fontSize: 10,
      width: "25%",
      padding: 4,
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    readinessTableText: {
      fontSize: 9,
    },
    conclusionTableHeading: {
      fontSize: 12,
      width: "20%",
      padding: 5,
    },
    conclusionTableData: {
      fontSize: 10,
      width: "20%",
      padding: 5,
    },
    conclusionTableOtherData: {
      fontSize: 10,
    },
  });

  // const overallScoreDescription = (avgRating) => {
  //   if (avgRating && avgRating >= 9 && avgRating <= 10) {
  //     return "Highly Effective";
  //   } else if (avgRating && avgRating >= 8 && avgRating <= 8.9) {
  //     return "Very Effective";
  //   } else if (avgRating && avgRating >= 6 && avgRating <= 7.9) {
  //     return "Effective";
  //   } else if (avgRating && avgRating >= 5 && avgRating <= 5.9) {
  //     return "Somewhat Effective";
  //   } else if (avgRating && avgRating < 5) {
  //     return "Effectiveness Improvement Needed";
  //   }
  // };

  return (
    <>
      <Page
        size="A4"
        orientation="landscape"
        style={[
          styles.page,
          {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
          },
        ]}
      >
        <View style={[styles.section, { color: "white" }]}>
          <Text>Cover Page</Text>
        </View>
        <View
          style={{
            paddingBottom: 20,
            width: "100%",
            height: "90%",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <View
            style={{
              position: "absolute",
              right: 0,
              top: 0,
              width: 120,
              height: 24,
            }}
          >
            <Image style={{ objectFit: "contain" }} src={logo} />
          </View>
          <View
            style={{
              paddingBottom: 20,
              margin: "0 auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <View
              style={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                fontSize: 16,

                // alignItems:"center",
                // position:'relative',
                // width:'100%',
              }}
            >
              <Image
                style={[
                  {
                    width: "400",
                    height: "40",
                    objectFit: "contain",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  },
                ]}
                src={companyLogo}
                // src='https://successionnow.iosx.in/logo-succession-now.png'
              />
            </View>
            <View
              style={[
                styles.tableOther,
                { display: "flex", flexDirection: "row", marginTop: 15 },
              ]}
            >
              <Text
                style={[
                  styles.tableOtherData,
                  {
                    display: "flex",
                    flexDirection: "row",
                    fontFamily: "RobotoBold",
                    fontSize: 20,
                    textTransform: "capitalize",
                  },
                ]}
              >
                {user?.company_name}
              </Text>
            </View>
            <View
              style={[
                styles.tableOther,
                { display: "flex", flexDirection: "row", marginTop: 15 },
              ]}
            >
              <Text
                style={[
                  styles.tableOtherData,
                  {
                    display: "flex",
                    flexDirection: "row",
                    fontFamily: "RobotoMedium",
                    fontSize: 16,
                    textTransform: "capitalize",
                  },
                ]}
              >
                {user?.name}
              </Text>
            </View>
          </View>
          <View
            style={[
              {
                position: "absolute",
                bottom: "0",
                display: "flex",
                flexDirection: "row",
                textAlign: "center",
                justifyContent: "center",
                marginTop: "auto",
              },
            ]}
          >
            <View
              style={[
                styles.tableOther,
                {
                  display: "flex",
                  flexDirection: "row",
                  marginTop: 5,
                  textAlign: "center",
                  justifyContent: "center",
                  marginRight: 12,
                },
              ]}
            >
              <Text
                style={[
                  {
                    display: "flex",
                    flexDirection: "row",
                    fontFamily: "RobotoMedium",
                    fontSize: 10,
                    textAlign: "center",
                  },
                ]}
              >
                Created by :
              </Text>
              <Text style={{ fontWeight: 700, fontSize: 10, marginLeft: 2 }}>
                {history && history.length > 0
                  ? history[0].created_info?.name
                  : ""}
              </Text>
            </View>
            <View
              style={[
                styles.tableOther,
                { display: "flex", flexDirection: "row", marginTop: 5 },
              ]}
            >
              <Text
                style={[
                  styles.tableOtherData,
                  {
                    display: "flex",
                    flexDirection: "row",
                    fontFamily: "RobotoMedium",
                    fontSize: 10,
                  },
                ]}
              >
                Date:
              </Text>
              <Text style={{ fontWeight: 700, fontSize: 10, marginLeft: 2 }}>
                {moment(new Date()).format("MM/DD/YYYY")}
              </Text>
            </View>
          </View>
        </View>
      </Page>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <View
          style={{
            border: "1px solid #e2e8f0",
            borderRadius: 6,
            overflow: "hidden",
            marginBottom: 30,
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              backgroundColor: "#0f172a",
              color: "#ffffff",
              overflow: "hidden",
            }}
          >
            <View
              style={[
                styles.tableHeading,
                {
                  width: "25%",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                },
              ]}
            >
              <Text
                style={{
                  width: "100%",
                  fontFamily: "RobotoMedium",
                  fontSize: 10,
                }}
              >
                {t("proposedBoardMembers")}
              </Text>
            </View>
          </View>
          <View
            style={{
              widyth: "100%",
              borderRadius: 6,
              paddingHorizontal: 12,
              paddingVertical: 12,
              backgroundColor: "white",
            }}
          >
            {/* {reatingOptions &&
              reatingOptions.length > 0 &&
              reatingOptions.map((item, index) => {
                let ratingRowAnswers = item?.question?.answers;
                const ratedBoardMember = history
                  .filter((it) => it?.data?.ratingOptions)
                  .map((itm) =>
                    itm?.data?.ratingOptions.filter(
                      (row) => row.questionId === item?.question?._id
                    )
                  );
                let totalSum = 0;
                let _comments = [];
                let _ans = [];
                if (ratedBoardMember && ratedBoardMember.length > 0) {
                  ratedBoardMember.forEach((obj) => {
                    obj.forEach((ob) => {
                      totalSum += ob?.rating ? ob?.rating : 0;
                      if (ob?.comments) {
                        _comments.push(ob?.comments);
                      }
                      if (ob?.ids && ob?.ids?.length > 0) {
                        _ans.push(ob?.ids);
                      }
                    });
                  });
                }
                if (ratingRowAnswers && ratingRowAnswers.length > 0) {
                  ratingRowAnswers.forEach((obj) => {
                    if (obj?.company_id === user?._id) {
                      _ans.push(obj?._id);
                    }
                  });
                }
                let totalBoardMember = ratedBoardMember?.length;
                let avgRating =
                  Number(totalSum / totalBoardMember) > 0
                    ? Number(totalSum / totalBoardMember).toFixed(2)
                    : 0;
                let _score = "";
                if (avgRating && avgRating >= 9 && avgRating <= 10) {
                  _score = "Highly Effective";
                } else if (avgRating && avgRating >= 8 && avgRating <= 8.9) {
                  _score = "Very Effective";
                } else if (avgRating && avgRating >= 6 && avgRating <= 7.9) {
                  _score = "Effective";
                } else if (avgRating && avgRating >= 5 && avgRating <= 5.9) {
                  _score = "Somewhat Effective";
                } else if (avgRating && avgRating < 5) {
                  _score = "Effectiveness Improvement Needed";
                }
                let _uniqueArrayWithCount = uniqueArrayWithCount(_ans);
                return (
                  <>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                      }}
                    >
                      <View
                        key={index}
                        style={[
                          styles.tableData,
                          {
                            width: "50%",
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                          },
                        ]}
                      >
                        <Text
                          style={[
                            {
                              fontWeight: 500,
                              fontSize: 10,
                              lineHeight: 1,
                              whiteSpace: "nowrap",
                            },
                          ]}
                        >
                          {Number(index + 1) + " " + item?.question?.name}
                        </Text>
                      </View>
                      <View
                        key={index}
                        style={[
                          styles.tableData,
                          {
                            width: "10%",
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                          },
                        ]}
                      >
                        <Text
                          style={[
                            {
                              fontWeight: 500,
                              width: "100%",
                              fontSize: 10,
                              lineHeight: 1,
                            },
                          ]}
                        >
                          {avgRating}
                        </Text>
                      </View>
                      <View
                        key={index}
                        style={[
                          styles.tableData,
                          {
                            width: "20%",
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                          },
                        ]}
                      >
                        <Text
                          style={[
                            {
                              fontWeight: 500,
                              width: "100%",
                              fontSize: 10,
                              lineHeight: 1,
                            },
                          ]}
                        >
                          {_score}
                        </Text>
                      </View>
                    </View>
                    {_uniqueArrayWithCount &&
                      _uniqueArrayWithCount.length > 0 && (
                        <View className="relative">
                          {_uniqueArrayWithCount.map((an, i) => {
                            let rowData = ratingRowAnswers.filter(
                              (row) => row._id === an.id
                            );
                            let filterMatch = history.filter((user) => user?.user_id === rowData[0]?.created_by);
                            if (rowData && rowData.length > 0 && (filterMatch.length>0 || rowData[0]?.created_by === null)) {
                              return (
                                <View
                                  style={{
                                    paddingHorizontal: 16,
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    marginBottom: 4,
                                  }}
                                >
                                  <Text
                                    style={{
                                      backgroundColor: "rgba(0,0,0,1)",
                                      width: 4,
                                      height: 4,
                                      borderRadius: 9999,
                                      overflow: "hidden",
                                      flexShrink: 0,
                                    }}
                                  ></Text>
                                  <Text
                                    style={{
                                      fontSize: 10,
                                      flexShrink: 1,
                                      flexGrow: 1,
                                      marginHorizontal: 8,
                                    }}
                                  >
                                    {rowData[0]?.description}
                                  </Text>
                                  <Text
                                    style={{
                                      fontSize: 10,
                                      fontWeight: "semibold",
                                      marginLeft: "auto",
                                      flexShrink: 0,
                                    }}
                                  >
                                    {an.count}
                                  </Text>
                                </View>
                              );
                            }else{
                               return <></>
                            }
                          })}
                        </View>
                      )}
                    {_comments?.length > 0 && (
                      <View
                        style={{
                          paddingHorizontal: 12,
                          paddingVertical: 8,
                          width: "100%",
                          borderBottomWidth: 1,
                          borderColor: "rgba(100,116,139,1)",
                        }}
                      >
                        <Text
                          style={{
                            color: "#000",
                            fontSize: 10,
                            textTransform: "capitalize",
                            fontWeight: "bold",
                            marginBottom: 5,
                          }}
                        >
                          comments
                        </Text>
                        {_comments?.map((itm, idx) => (
                          <Text
                            style={{
                              fontSize: 10,
                              color: "rgb(100,116,139,1)",
                              paddingHorizontal: 8,
                              textTransform: "capitalize",
                              paddingVertical: 8,
                              fontWeight: 600,
                            }}
                            key={idx}
                          >
                            {itm}
                          </Text>
                        ))}
                      </View>
                    )}
                  </>
                );
              })} */}
          </View>
        </View>
      </Page>
    </>
  );
};

export default PerspectiveReport;

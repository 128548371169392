import React, { useState, useEffect } from "react";
import toastr from "toastr";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { setTitle } from "../../../helpers/MetaTag";
import BoardMemeberAlert from "../../elements/alert/BoardMemeberAlert";
import CardAction from "../../Common/CardAction";
import ManageInviteExecutivePopup from "../../popup/ManageInviteExecutivePopup";
import SurveyPopup from "../../popup/SurveyPopup";
import InvitationPopup from "../../popup/InvitationPopup";
import SurveyHistoryPopup from "../../popup/SurveyHistoryPopup";
import Button from "../../form/Button";
import { postData } from "../../../services/api";
import Searching from "../../Searching";
import NoDataFound from "../../NoDataFound";
import Indentification from "../../elements/Indentification";
import { initialsValue } from "../../../helpers";
import ProfileCardSkeleton from "../../loader/ProfileCardSkeleton";

const AllExecutivesList = ({ data }) => {
  setTitle("Succession Now | Executives");
  const { i18n, t } = useTranslation();
  const { id } = useParams();
  const role = localStorage.getItem("role");
  const user = useSelector((state) => state.auth?.user);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [keyword, setKeyword] = useState("");
  const [limit, setLimit] = useState(100);
  const [offset, setOffset] = useState(0);
  const [sortKey, setSortKey] = useState("created_at");
  const [sortValue, setSortValue] = useState(-1);
  const [sortQuery, setSortQuery] = useState("");
  const [noOfPage, setNoOfPage] = useState(0);
  const [executives, setExecutives] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [isUpdate, setIsUpdate] = useState(false);
  const [actionValue, setActionValue] = useState({});
  const [reatingOptions, setReatingOptions] = useState([]);
  const [enabledInviteExecutivePopup, setEnabledInviteExecutivePopup] =
    useState(false);
  const [inviteInfo, setInviteInfo] = useState({});
  const [enabledSurveyPopup, setEnabledSurveyPopup] = useState(false);
  const [detailsId, setDetailsId] = useState("");
  const [answers, setAnswers] = useState([]);
  const [enabledInvitationPopup, setEnabledInvitationPopup] = useState(false);
  const [details, setDetails] = useState({});
  const [enabledSurveyHistoryPopup, setEnabledSurveyHistoryPopup] =
    useState(false);

  // useEffect(() => {
  //   if (role && role !== "customer-admin") {
  //     return navigate("/dashboard");
  //   }
  // }, [role]);

  useEffect(() => {
    if (sortKey && sortValue) {
      setSortQuery({ [sortKey]: sortValue });
    } else {
      setSortQuery("");
    }
  }, [sortKey, sortValue]);

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
  }, [limit, totalDataCount]);

  useEffect(() => {
    async function employeeRatingOptionList() {
      try {
        const result = await postData("employee-rating-option/list", {
          sortQuery: { created_at: 1 },
          status: 1,
          limit: 50,
          type: "executive",
        });
        if (result.data) {
          setReatingOptions(result.data);
        } else {
          console.log("Advance desire message", result.message);
        }
      } catch (error) {
        console.log("Error advance desire catch", error.message);
      }
    }
    employeeRatingOptionList();
  }, []);

  useEffect(() => {
    // Executives List
    const loadExecutives = async () => {
      setLoading(true);
      try {
        const result = await postData("executive/list", {
          //keyword: keyword,
          limit: limit,
          offset: offset,
          sortQuery: { created_at: 1 },
          outside_candidate: 2,
          companyId: id,
        });
        if (result.data) {
          setExecutives(
            result.data?.sort((a, b) =>
              a.first_name.localeCompare(b.first_name)
            )
          );
        }
        setTotalDataCount(result.count);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err.message);
      }
    };
    loadExecutives();
  }, [keyword, limit, offset, isUpdate, actionValue, id]);

  const getInitials = function (string) {
    var names = string.split(" "),
      initials = names[0].substring(0, 1).toUpperCase();
    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  const getDownload = (e) => {
    e.preventDefault();
    if (e.target.tagName.toLowerCase() === "a") {
      window.open(e.target.href, "_blank");
    }
  };

  useEffect(() => {
    if (
      actionValue &&
      actionValue.button_type === "send-invitation-executive"
    ) {
      setEnabledInviteExecutivePopup(true);
      setInviteInfo({
        _id: actionValue.row_id,
      });
    } else if (
      actionValue &&
      actionValue.button_type === "resend-invitation-executive"
    ) {
      const loadResendInvitation = async () => {
        try {
          const othersData = await postData("executive/invitation-resend", {
            id: actionValue?.row_id,
          });
          if (othersData.data) {
            setActionValue({});
            toastr.success(
              "Email resent.Please make sure to check your spam email."
            );
          } else {
            toastr.error(othersData.message);
          }
        } catch (error) {
          toastr.error(error.message);
        }
      };
      loadResendInvitation();
    } else if (actionValue && actionValue.button_type === "delete") {
      const loadDeleteRecord = async () => {
        setIsUpdate(false);
        try {
          const statusData = await postData("executive/delete", {
            id: actionValue?.row_id,
          });
          if (statusData.data) {
            const index = executives.findIndex(
              (item) => item._id === actionValue?.row_id
            );
            if (index !== -1) {
              executives.splice(index, 1);
              setExecutives(
                executives.map((it) => {
                  return it;
                })
              );
            }
            setIsUpdate(true);
          } else {
            toastr.error(statusData.message);
          }
        } catch (error) {
          toastr.error(error.message);
        }
      };
      loadDeleteRecord();
    } else if (
      actionValue &&
      (actionValue.button_type === "survey" ||
        actionValue.button_type === "survey-history")
    ) {
      const loadExecutiveDetails = async () => {
        try {
          const result = await postData("executive/details", {
            _id: actionValue?.row_id,
          });
          if (result.status === 403) {
            return navigate("/executives");
          } else {
            if (result.data) {
              if (actionValue.button_type === "survey") {
                if (
                  result?.data?.executiveAudits &&
                  result?.data?.executiveAudits.length > 0
                ) {
                  setAnswers(
                    reatingOptions.map((value) => {
                      let _question = result?.data?.executiveAudits.filter(
                        (it) => it?.questionId === value?.question?._id
                      );
                      if (_question.length > 0) {
                        return {
                          ..._question[0],
                          ids: _question[0]?.ids,
                          questionInfo : value
                        };
                      } else {
                        return {
                          questionId: value?.question?._id,
                          answerId: null,
                          answerLabel: null,
                          rating: 0,
                          comments: null,
                          questionInfo : value
                        };
                      }
                    })
                  );
                } else {
                  setAnswers(
                    reatingOptions.map((value) => {
                      return {
                        questionId: value?.question?._id,
                        answerId: null,
                        answerLabel: null,
                        rating: 0,
                        comments: null,
                        questionInfo : value
                      };
                    })
                  );
                }
              } else if (actionValue.button_type === "survey-history") {
                setDetails(result.data);
              }
            }
            setDetailsId(actionValue?.row_id);
            if (actionValue.button_type === "survey") {
              setEnabledSurveyPopup(true);
            } else if (actionValue.button_type === "survey-history") {
              setEnabledSurveyHistoryPopup(true);
            }
          }
        } catch (err) {
          console.log(err.message);
        }
      };
      loadExecutiveDetails();
    } else if (actionValue && actionValue.button_type === "update-survey") {
      const onSubmit = async () => {
        try {
          const result = await postData("executive/update", {
            id: detailsId,
            ratingOptions: actionValue?.answers,
          });
          if (result.status && result.status === 200) {
            toastr.success(result.message);
            setDetailsId("");
          } else {
            toastr.error(result.message);
          }
        } catch (error) {
          toastr.error(error.message);
        }
      };
      onSubmit();
    }
  }, [actionValue]);

  return (
    <>
      <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-4">
        <div className="text-xl md:text-3xl text-slate-800 font-bold">
          {t("executives") + " - " + data?.company_name}
        </div>
      </div>
      <BoardMemeberAlert />
      <div className="relative flex justify-between items-center mb-4">
        &nbsp;
      </div>
      <div className="w-full overflow-auto scroll-smooth scrollbar min-h-[480px] mb-4">
        {totalDataCount > 0 ? (
          <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5 gap-2 sm:gap-3">
            {executives.length > 0 &&
              executives.map((item, index) => {
                let namelatter = getInitials(
                  item.first_name + " " + item.last_name
                );
                let totalQuestion = reatingOptions?.length;
                let filterAnswer =
                  item?.executiveAudits?.length > 0 &&
                  item?.executiveAudits.filter(
                    (it) => it?.answerId !== null || it?.ids?.length > 0
                  );
                let totalAnswer = filterAnswer?.length;
                return (
                  <div
                    className="relative w-full bg-white border border-gray-200 rounded-lg shadow overflow-hidden"
                    key={index}
                  >
                    {totalQuestion === totalAnswer ? (
                      <div className="absolute top-0 left-0">
                        <div className="text-white bg-green-500 rounded-br-xl py-2 px-3 !leading-none text-xs font-medium">
                          {t("completed")}
                        </div>
                      </div>
                    ) : (
                      <div className="absolute top-0 left-0">
                        <div className="text-white bg-rose-500 rounded-br-xl py-2 px-3 !leading-none text-xs font-medium">
                          {t("in_completed")}
                        </div>
                      </div>
                    )}
                    {/* <div className="flex justify-end px-2">
                      <CardAction
                        xPlacement={"right"}
                        dropdownData={[
                          // {
                          //   _id: 1,
                          //   label: t("edit"),
                          //   icon: "fa-pen-to-square",
                          //   link: "/executive/edit/" + item?._id,
                          //   type: "link",
                          //   standout: false,
                          //   value: item?._id,
                          //   buttonType: "",
                          //   ordering: 1,
                          // },
                          {
                            _id: 2,
                            label: t("start_survey"),
                            icon: "fa-clock",
                            link: "/",
                            type: "button",
                            standout: false,
                            value: item?._id,
                            buttonType: "survey",
                            ordering: 1,
                          },
                          {
                            _id: 2,
                            label: t("history"),
                            icon: "fa-rectangle-history",
                            link: "/",
                            type: "button",
                            standout: false,
                            value: item?._id,
                            buttonType: "survey-history",
                            ordering: 1,
                          },
                          // {
                          //   _id: 3,
                          //   label: t("details"),
                          //   icon: "fa-eye",
                          //   link: "/executive/details/" + item?._id,
                          //   type: "link",
                          //   standout: false,
                          //   value: item?._id,
                          //   buttonType: "",
                          //   ordering: 2,
                          // },
                          // (!item?.parent_id)?
                          // {
                          //   _id: 4,
                          //   label: t("send_invite"),
                          //   icon: "fa-envelope",
                          //   link: "/",
                          //   type: "button",
                          //   standout: false,
                          //   value: item?._id,
                          //   buttonType: "send-invitation-executive",
                          //   ordering: 3,
                          // }:'',
                          // (item?.parent_id && item?.email_verified !== 1)?
                          // {
                          //   _id: 4,
                          //   label: t("resend_invite"),
                          //   icon: "fa-envelope",
                          //   link: "/",
                          //   type: "button",
                          //   standout: false,
                          //   value: item?._id,
                          //   buttonType: "resend-invitation-executive",
                          //   ordering: 3,
                          // }:'',
                          // {
                          //   _id: 6,
                          //   label: t('delete'),
                          //   icon: 'fa-trash-can',
                          //   link: '/',
                          //   type: 'button',
                          //   standout: true,
                          //   value: item?._id,
                          //   buttonType: 'delete',
                          //   ordering : 10
                          // }
                        ]}
                        getActionValue={(obj) => setActionValue(obj)}
                      />
                    </div> */}
                    <div className="flex flex-col items-center pb-10 space-y-2 mt-6">
                      <Indentification
                        initial={initialsValue(
                          item?.first_name + " " + item?.last_name
                        )}
                        alt={"shortName"}
                        fill={true}
                        className={""}
                        size={"xl"}
                      />
                      <div className="text-center">
                        <h5 className="text-base sm:text-lg font-bold text-gray-900 capitalize">
                          {item?.first_name + " " + item?.last_name}
                        </h5>
                        <div className="text-xs sm:text-sm font-semibold sm:font-normal text-gray-600 capitalize">
                          {item?.position_details
                            ? item?.position_details?.name
                            : ""}
                        </div>
                        {/* <div className="flex justify-center mt-2">
                          {
                            (item?.parent_id)?
                            (item?.email_verified && item?.email_verified === 1)?
                            <div className="text-sm flex items-center space-x-1 text-green-500">
                              <i className="fa-regular fa-fw fa-check-circle"></i>
                              <span className="text-xs font-semibold">{t('invite_accecpted')}</span>
                            </div>:
                            <div className="text-sm flex items-center space-x-1 text-yellow-500">
                              <i className="fa-regular fa-fw fa-clock"></i>
                              <span className="text-xs font-semibold">{t('invited')}</span>
                            </div>:
                            <div className="text-sm flex items-center space-x-1 text-red-500">
                              <i className="fa-regular fa-fw fa-circle-xmark"></i>
                              <span className="text-xs font-semibold">{t('not_invited')}</span>
                            </div>
                          }                          
                        </div> */}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        ) : (
          <div className="relative bg-white shadow rounded">
            <div className="py-8 px-4 w-full">
              {loading ? (
                <div className="p-4 sm:p-6">
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
                    {Array(12)
                      .fill(null)
                      .map((_, index) => (
                        <ProfileCardSkeleton key={index} />
                      ))}
                  </div>
                </div>
              ) : (
                <NoDataFound label={t("no_executive_found")} />
              )}
            </div>
          </div>
        )}
      </div>
      {enabledInviteExecutivePopup && (
        <ManageInviteExecutivePopup
          isOpen={enabledInviteExecutivePopup}
          setIsOpen={(val) => setEnabledInviteExecutivePopup(val)}
          data={inviteInfo}
          getActionValue={(obj) => {
            setActionValue(obj);
            setEnabledInviteExecutivePopup(false);
          }}
        />
      )}
      {enabledSurveyPopup && (
        <SurveyPopup
          isOpen={enabledSurveyPopup}
          setIsOpen={(val) => setEnabledSurveyPopup(val)}
          data={answers}
          surveyFor={"executive"}
          isConfirmation={detailsId ? true : false}
          getActionValue={(obj) => {
            setActionValue(obj);
          }}
        ></SurveyPopup>
      )}
      {enabledInvitationPopup && (
        <InvitationPopup
          isOpen={enabledInvitationPopup}
          setIsOpen={(val) => setEnabledInvitationPopup(val)}
          data={answers}
          surveyFor={"executive"}
          isConfirmation={detailsId ? true : false}
          getActionValue={(obj) => {
            setActionValue(obj);
          }}
        ></InvitationPopup>
      )}
      {enabledSurveyHistoryPopup && (
        <SurveyHistoryPopup
          isOpen={enabledSurveyHistoryPopup}
          setIsOpen={(val) => setEnabledSurveyHistoryPopup(val)}
          data={details}
          surveyFor={"executive"}
          isConfirmation={detailsId ? true : false}
          getActionValue={(obj) => {
            setActionValue(obj);
          }}
        ></SurveyHistoryPopup>
      )}
    </>
  );
};

export default AllExecutivesList;

import React, { useState, useEffect } from "react";
import { setTitle } from "../helpers/MetaTag";
import Button from "../components/form/Button";
import { PDFDownloadLink, Document } from "@react-pdf/renderer";
import EmergencyReplacementPlanReport from "./pdf/EmergencyReplacementPlanReport";
import { postData } from "../services/api";
import { useSelector } from "react-redux";
import Searching from "../components/Searching";
import NoDataFound from "../components/NoDataFound";
import EmergencyReplacementPlanContent from "../components/EmergencyReplacement/EmergencyReplacementPlanContent";

import { useTranslation } from "react-i18next";
import { API_URL } from "../config/host";
import ReplacementSkeletonLoader from "../components/loader/ReplacementSkeletonLoader";

const EmergencyReplacementPlan = () => {
  setTitle("Succession Now | Emergency Replacement Plan");
  const { i18n, t } = useTranslation();
  const role = localStorage.getItem("role");
  const user = useSelector((state) => state.auth?.user);
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState({});
  const [entryDetails, setEntryDetails] = useState([]);

  useEffect(() => {
    const detailsFun = async () => {
      setLoading(true);
      try {
        const result = await postData("emergency-replacement/details", {});
        if (result.data) {
          setDetails(result.data);
          setLoading(false);
        } else {
          setDetails({});
          setLoading(false);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    detailsFun();

    async function loadDetails() {
      try {
        const result = await postData("emergency-replace/details", {});
        if (result.data) {
          setEntryDetails(result.data);
        } else {
          console.log("Details message", result.message);
        }
      } catch (error) {
        console.log("Error details catch", error.message);
      }
    }
    loadDetails();
  }, []);

  const getDownload = (e) => {
    e.preventDefault();
    if (e.target.tagName.toLowerCase() === "a") {
      window.open(e.target.href, "_blank");
    }
  };

  return (
    <>
      <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-4">
        <div className="text-xl md:text-3xl text-slate-800 font-bold">
          {t("emergency_interim_replacement_policy_&_plan") +
            " - " +
            user?.company_name}
        </div>
        <div className="sm:ml-auto flex justify-end items-center space-x-2">
          {role && role === "master-admin" && (
            <Button
              buttonType={"button"}
              buttonIcon={"fa-light fa-edit"}
              buttonIconPosition={"left"}
              buttonLabel={t("manage")}
              buttonHasLink={true}
              buttonLink={"/manage-emergency-replacement-plan"}
            />
          )}
          <button
            type={"button"}
            className={
              "flex justify-center items-center gap-2 bg-teal-500 text-white rounded text-base sm:text-lg h-8 sm:h-10 py-0 px-2 sm:px-3 transition-all duration-200 cursor-pointer"
            }
            onClick={(e) => getDownload(e)}
          >
            <i className={"fa-fw fa-light fa-download"}></i>
            <div className={"text-xs sm:text-sm font-medium whitespace-nowrap"}>
              <PDFDownloadLink
                document={
                  <Document>
                    <EmergencyReplacementPlanReport
                      data={details}
                      details={entryDetails}
                      user={user}
                      companyLogo={API_URL + user?.image}
                    />
                  </Document>
                }
                fileName="emergency_replacement_plan.pdf"
              >
                {({ blob, url, loading, error }) =>
                  loading ? t("loading_document") : t("export")
                }
              </PDFDownloadLink>
            </div>
          </button>
        </div>
      </div>
      <div className="relative bg-white shadow rounded">
        <div className="sm:py-8 sm:px-4 section-help">
          {Object.keys(details).length > 0 ? (
            <EmergencyReplacementPlanContent data={details} />
          ) : loading ? (
            <ReplacementSkeletonLoader />
          ) : (
            <NoDataFound label={t("no_data_found")} />
          )}
        </div>
      </div>
    </>
  );
};

export default EmergencyReplacementPlan;

import React from "react";

export const ManageDefaultConcernAndPredictorsSkleton = ({
  rowValue = "grid-cols-3",
}) => {
  return (
    <div
      className={`grid grid-cols-3 gap-4 p-4 overflow-auto scroll-smooth scrollbar max-h-[calc(100vh-240px)] ${rowValue}`}
    >
      {[...Array(18)].map((_, index) => (
        <div
          key={index}
          className="flex items-center justify-between gap-2 border border-dark-teal-200 rounded-md p-3 bg-gray-200 shimmer"
        >
          <div className="flex items-center space-x-3">
            <div className="w-5 h-5 bg-gray-300 rounded-full"></div>
            <div className="w-28 h-4 bg-gray-300 rounded"></div>
          </div>
          <div className="w-5 h-5 bg-gray-300 rounded-full"></div>
        </div>
      ))}
    </div>
  );
};

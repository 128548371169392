import React, { useState, useEffect } from "react";
import { setTitle } from "../helpers/MetaTag";
import Button from "../components/form/Button";
import Textarea from "../components/form/Textarea";
import Searching from "../components/Searching";
import NoDataFound from "../components/NoDataFound";
import toastr from "toastr";
import { useSelector } from "react-redux";
import Accordian from "../components/Common/Accordian";
import Search from "../components/form/Search";

import { postData } from "../services/api";
import { PDFDownloadLink } from "@react-pdf/renderer";
import CompetenciesReport from "./pdf/CompetenciesReport";
import { useTranslation } from "react-i18next";
import PositionCompetenciesSkelton from "../components/loader/PositionCompetenciesSkelton";

const Competencies = () => {
  setTitle(
    "Succession Planning Software for your Business with Succession Now | Succession Plan Position Competencies"
  );
  const { i18n, t } = useTranslation();
  const user = useSelector((state) => state.auth?.user);
  const [loading, setLoading] = useState(true);
  const [competenciesData, setCompetenciesData] = useState([]);
  const [competenciesResetData, setCompetenciesResetData] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const [openAccordionId, setOpenAccordionId] = useState(null);
  const [keyword, setKeyword] = useState("");
  const [allData, setAllData] = useState([]);

  useEffect(() => {
    async function positionListFun() {
      setLoading(true);
      try {
        const result = await postData("position/list", {
          sortQuery: { ordering: 1 },
          status: 1,
        });
        if (result.data) {
          setCompetenciesData(
            result.data.map((value, key) => {
              return {
                ...value,
                position_competencies: value?.position_competencies
                  ? value?.position_competencies
                  : "",
                different_competencies: value?.different_competencies
                  ? value?.different_competencies
                  : "",
              };
            })
          );
          setCompetenciesResetData(result.data);
          setLoading(false);
        } else {
          console.log("Position message", result.message);
          setLoading(false);
        }
      } catch (error) {
        console.log("Error role catch", error.message);
        setLoading(false);
      }
    }
    positionListFun();
  }, [isUpdate || ""]);

  const handelUpdate = async (id, index, value, field) => {
    let temp = [...competenciesData];
    if (field === "position_competencies") {
      temp[index].position_competencies = value;
      setCompetenciesData(temp);
    }
    if (field === "different_competencies") {
      temp[index].different_competencies = value;
      setCompetenciesData(temp);
    }
  };

  const onCancel = async (e) => {
    setIsCancel(true);
    setCompetenciesData(
      competenciesResetData.map((value, key) => {
        return {
          ...value,
          position_competencies: value?.position_competencies
            ? value?.position_competencies
            : "",
          different_competencies: value?.different_competencies
            ? value?.different_competencies
            : "",
        };
      })
    );
    setIsCancel(false);
  };

  const onUpdate = async (e) => {
    setIsUpdate(false);
    setIsSave(true);
    try {
      const result = await postData("position/competency-update", {
        competencies_data: competenciesData,
      });
      if (result.data && result.status === 200) {
        toastr.success(result.message);
        setIsUpdate(true);
        setIsSave(false);
      } else {
        toastr.error(result.message);
        setIsUpdate(false);
        setIsSave(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setIsUpdate(false);
      setIsSave(false);
    }
  };

  const getDownload = (e) => {
    console.log(e.target);
    e.preventDefault();
    if (e.target.tagName.toLowerCase() === "a") {
      window.open(e.target.href, "_blank");
    }
  };

  const toggleAccordion = (id) => {
    setOpenAccordionId(openAccordionId === id ? null : id);
  };

  useEffect(() => {
    if (keyword) {
      const filteredArray = competenciesData.filter((item) =>
        item?.name.toLowerCase().includes(keyword.toLowerCase())
      );
      setAllData(filteredArray.sort((a, b) => a.name.localeCompare(b.name)));
    } else {
      setAllData(competenciesData.sort((a, b) => a.name.localeCompare(b.name)));
    }
  }, [competenciesData, keyword]);

  return (
    <div className="border bg-white border-gray-200 p-4 shadow-md rounded-xl">
      <div className="relative flex gap-3 items-center mb-3 flex-wrap sm:flex-nowrap gap-y-4">
        <div className="text-2xl font-bold">
          {t("succession_plan_position_competencies")}
        </div>
        {/* <div className="ml-auto flex justify-end items-center space-x-2">
          <button 
            type={'button'} 
            className={"flex justify-center items-center gap-2 bg-teal-500 text-white rounded text-lg h-10 py-0 px-3 transition-all duration-200 cursor-pointer "} 
            onClick={(e) => getDownload(e)}
            >
            <i className={"fa-fw fa-light fa-download"}></i>  
            <PDFDownloadLink document={<CompetenciesReport data={competenciesData}/>} fileName="competencies.pdf">
              {({ blob, url, loading, error }) => (
                loading ? 'Loading document...' : 'Download PDF')}
            </PDFDownloadLink>
          </button>
        </div> */}
      </div>

      <div className="relative flex gap-3 items-center mb-3 flex-wrap">
        <div className="relative w-48">
          <Search placeholder={"Search"} setInput={setKeyword} />
        </div>
      </div>
      <div className="relative  rounded">
        <form className="relative space-y-2">
          {allData?.length > 0 ? (
            allData?.map((item, index) => (
              <Accordian
                key={index}
                id={index}
                title={item.name}
                isOpen={openAccordionId === index}
                toggleAccordion={toggleAccordion}
                divClass={"bg-slate-100 shadow-md rounded-md"}
                childClass={"!px-3 !pb-3 !pt-0"}
              >
                <div className="text-sm md:text-base font-Inter text-slate-700">
                  <div className="relative space-y-4">
                    <div className="relative">
                      <Textarea
                        inputClasses={"whitespace-pre-line !h-40 sm:!h-80"}
                        label={t(
                          "document_position_competencies_in_section_below"
                        )}
                        isAddBullet={true}
                        inputValue={item?.position_competencies}
                        setTextarea={(val) =>
                          handelUpdate(
                            item._id,
                            index,
                            val,
                            "position_competencies"
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              </Accordian>
              // <React.Fragment key={item._id}>
              //   <div className="px-4 py-5">
              //     <div className="text-xl text-slate-800 font-bold mb-2">
              //       {item.name}
              //     </div>
              //     <div className="relative space-y-4">
              //       <div className="relative">
              //         <Textarea
              //           inputClasses={"whitespace-pre-line"}
              //           label={
              //             t('document_position_competencies_in_section_below')
              //           }
              //           isAddBullet={true}
              //           inputValue={item?.position_competencies}
              //           setTextarea={(val) =>
              //             handelUpdate(
              //               item._id,
              //               index,
              //               val,
              //               "position_competencies"
              //             )
              //           }
              //         />
              //       </div>
              //       {/* <div className="relative">
              //         <Textarea
              //           inputClasses={"whitespace-pre-line"}
              //           label={
              //             "If desired, enter additional or different competencies below:"
              //           }
              //           inputValue={item?.different_competencies}
              //           setTextarea={(val) =>
              //             handelUpdate(
              //               item._id,
              //               index,
              //               val,
              //               "different_competencies"
              //             )
              //           }
              //         />
              //       </div> */}
              //     </div>
              //   </div>
              // </React.Fragment>
            ))
          ) : loading ? (
            <PositionCompetenciesSkelton />
          ) : (
            <NoDataFound label={t("no_data_found")} />
          )}
        </form>

        {competenciesData?.length > 0 && (
          <div className="py-3 px-3 border-t border-slate-200">
            <div className="flex items-center justify-end gap-4">
              <Button
                buttonType={"button"}
                buttonIcon={
                  isCancel ? "fa-light fa-spinner fa-spin" : "fa-light fa-times"
                }
                buttonIconPosition={"left"}
                buttonLabel={t("cancel")}
                buttonClasses={
                  "!bg-slate-200 !text-slate-600 border border-slate-300"
                }
                functions={onCancel}
                buttonHasLink={false}
                buttonDisabled={isCancel}
              />
              <Button
                buttonType={"button"}
                buttonIcon={
                  isSave ? "fa-light fa-spinner fa-spin" : "fa-light fa-check"
                }
                buttonIconPosition={"left"}
                buttonLabel={t("save")}
                functions={onUpdate}
                buttonHasLink={false}
                buttonDisabled={isSave}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Competencies;

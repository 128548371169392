import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import logo from "../../logo-succession-now.png";
import RobotoLight from "../../assets/font/Roboto-Light.ttf";
import RobotoRegular from "../../assets/font/Roboto-Regular.ttf";
import RobotoMedium from "../../assets/font/Roboto-Medium.ttf";
import RobotoBold from "../../assets/font/Roboto-Bold.ttf";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";

Font.register({ family: "RobotoLight", src: RobotoLight });
Font.register({ family: "RobotoRegular", src: RobotoRegular });
Font.register({ family: "RobotoMedium", src: RobotoMedium });
Font.register({ family: "RobotoBold", src: RobotoBold });

const InformationReport = ({ details, user, heading }) => {
  const { i18n, t } = useTranslation();
  const styles = StyleSheet.create({
    page: {
      padding: 20,
      fontFamily: "RobotoRegular",
      lineHeight: 1.2,
      fontSize: 9,
      color: "#334155",
    },
    tableHeading: {
      fontSize: 12,
      width: "30%",
      maxWidth: "100%",
      padding: 8,
    },
    tableData: {
      fontSize: 10,
      width: "40%",
      maxWidth: "100%",
      padding: 8,
    },

    image: {
      width: 200,
      height: 40,
      objectFit: "contain",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    tableOtherData: {
      fontSize: 10,
    },
    imageSignature: {
      width: 100,
      height: 60,
      objectFit: "contain",
    },
    section: { textAlign: "center" },
  });

  const convartString = (string) => {
    let wordsArray = string.split("_");
    let capitalizedWords = wordsArray.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    );
    let resultString = capitalizedWords.join(" ");
    return resultString;
  };

  return (
    <Document>
      <Page size="A4" orientation="portrait" style={styles.page}>
        <View style={[styles.section, { color: "white" }]}>
          <Text>Section #1</Text>
        </View>
        <View
          style={{
            paddingBottom: 20,
            width: "100%",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <View
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              fontSize: 16,
            }}
          >
            <Image
              style={styles.image}
              src={logo}
              // src='https://successionnow.iosx.in/logo-succession-now.png'
            />
          </View>
          <View
            style={[
              styles.tableOther,
              { display: "flex", flexDirection: "row", marginTop: 15 },
            ]}
          >
            <Text
              style={[
                styles.tableOtherData,
                {
                  display: "flex",
                  flexDirection: "row",
                  fontFamily: "RobotoBold",
                  fontSize: 20,
                  textTransform: "capitalize",
                },
              ]}
            >
              Sales Information
            </Text>
          </View>
          <View
            style={[
              {
                display: "flex",
                flexDirection: "row",
                marginTop: 5,
                textAlign: "center",
                justifyContent: "center",
              },
            ]}
          >
            {/* <View style={[styles.tableOther, { display: "flex", flexDirection: "row", marginTop: 5, textAlign: "center", justifyContent: "center",marginRight: 12 }]}>
              <Text style={[{ display: "flex", flexDirection: "row", fontFamily: "RobotoMedium", fontSize: 10, textAlign: "center", fontSize: 10 }]}>Created by:</Text>
              <Text style={{ fontWeight: 700, fontSize: 10, marginLeft: 2 }}>
                {user?.name}
              </Text>
            </View> */}
            <View
              style={[
                styles.tableOther,
                { display: "flex", flexDirection: "row", marginTop: 5 },
              ]}
            >
              <Text
                style={[
                  styles.tableOtherData,
                  {
                    display: "flex",
                    flexDirection: "row",
                    fontFamily: "RobotoMedium",
                    fontSize: 10,
                  },
                ]}
              >
                Date:
              </Text>
              <Text style={{ fontWeight: 700, fontSize: 10, marginLeft: 2 }}>
                {moment(new Date()).format("MM/DD/YYYY")}
              </Text>
            </View>
          </View>
        </View>
        {details && Object.keys(details).length > 0 && (
          <View
            style={{
              border: "1px solid #e2e8f0",
              borderRadius: 6,
              overflow: "hidden",
              marginBottom: 30,
            }}
            s
          >
            {heading.map((items, index) => (
              <View
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  borderTop: "1px solid #e2e8f0",
                }}
              >
                <View
                  style={[
                    styles.tableData,
                    { display: "flex", flexDirection: "row", flexWrap: "wrap" },
                  ]}
                >
                  <Text
                    style={[
                      {
                        whiteSpace: "nowrap",
                        fontWeight: 500,
                        width: "100%",
                        fontSize: 10,
                        lineHeight: 1,
                      },
                    ]}
                  >
                    {items.name}
                  </Text>
                </View>
                <View
                  key={index}
                  style={[
                    styles.tableData,
                    { display: "flex", flexDirection: "row", flexWrap: "wrap" },
                  ]}
                >
                  {index === 0 && (
                    <Text style={{ fontSize: 9 }}>
                      {details && details?.company_name
                        ? details?.company_name
                        : "N/A"}
                    </Text>
                  )}
                  {index === 1 && (
                    <Text style={{ fontSize: 9 }}>
                      {details && details?.comapny_administrator
                        ? details?.comapny_administrator
                        : "N/A"}
                    </Text>
                  )}
                  {index === 2 && (
                    <Text style={{ fontSize: 9 }}>
                      {details && details?.administrator_position
                        ? details?.administrator_position
                        : "N/A"}
                    </Text>
                  )}
                  {index === 3 && (
                    <Text style={{ fontSize: 9, whiteSpace: "nowrap" }}>
                      {details && details?.company_administrator_email
                        ? details?.company_administrator_email
                        : "N/A"}
                    </Text>
                  )}
                  {index === 4 && (
                    <Text style={{ fontSize: 9 }}>
                      {details && details?.company_administrator_phone
                        ? details?.company_administrator_phone
                        : "N/A"}
                    </Text>
                  )}
                  {index === 5 && (
                    <Text style={{ fontSize: 9 }}>
                      {details && details?.company_size_name
                        ? details?.company_size_name
                        : "N/A"}
                    </Text>
                  )}
                  {index === 6 && (
                    <Text style={{ fontSize: 9 }}>
                      {details && details?.price
                        ? "$" + details?.price.toFixed(2)
                        : "N/A"}
                    </Text>
                  )}
                  {index === 7 && (
                    <Text style={{ fontSize: 9 }}>
                      {details && details?.source
                        ? convartString(details?.source)
                        : "N/A"}
                    </Text>
                  )}
                  {index === 8 && (
                    <Text style={{ fontSize: 9 }}>
                      {details && details?.price_note
                        ? details?.price_note
                        : "N/A"}
                    </Text>
                  )}
                  {index === 9 && (
                    <Text style={{ fontSize: 9 }}>
                      {details && details?.salesperson
                        ? details?.salesperson
                        : "N/A"}
                    </Text>
                  )}
                  {index === 10 && (
                    <Text style={{ fontSize: 9 }}>
                      {details && details?.contract_length
                        ? Number(details?.contract_length) > 1
                          ? Number(details?.contract_length) + " Years"
                          : Number(details?.contract_length) + " Year"
                        : "N/A"}
                    </Text>
                  )}
                  {index === 11 && (
                    <Text style={{ fontSize: 9 }}>
                      {details && details?.payment_terms
                        ? Number(details?.payment_terms) === "monthly"
                          ? "Monthly"
                          : "Full Payment"
                        : "N/A"}
                    </Text>
                  )}
                  {index === 12 && (
                    <Text style={{ fontSize: 9 }}>
                      {details && details?.additional_note
                        ? details?.additional_note
                        : "N/A"}
                    </Text>
                  )}
                  {index === 13 && (
                    <Text style={{ fontSize: 9 }}>
                      {details && details?.purchase_agreement_received
                        ? details?.purchase_agreement_received === 1
                          ? t("received")
                          : t("not_received")
                        : "N/A"}
                    </Text>
                  )}
                  {index === 14 && (
                    <Text style={{ fontSize: 9 }}>
                      {details && details?.account_setup
                        ? details?.account_setup === 1
                          ? t("active")
                          : t("deactivate")
                        : "N/A"}
                    </Text>
                  )}
                  {index === 15 && (
                    <Text style={{ fontSize: 9 }}>
                      {details && details?.invoice_send
                        ? details?.invoice_send === 1
                          ? t("sent")
                          : t("not_sent")
                        : "N/A"}
                    </Text>
                  )}
                  {index === 16 && (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Text style={{ fontSize: 9 }}>
                        {details && details?.payment_received
                          ? details?.payment_received === 1
                            ? t("received")
                            : t("not_received")
                          : "N/A"}
                      </Text>
                      <Text style={{ fontSize: 9, marginLeft: 5 }}>
                        {details && details?.payment_received_date
                          ? `- ${moment(details?.payment_received_date).format(
                              "lll"
                            )}`
                          : ""}
                      </Text>
                    </View>
                  )}
                  {index === 17 && (
                    <Text style={{ fontSize: 9 }}>
                      {details && details?.traning_schedule
                        ? details?.traning_schedule === 1
                          ? t("start")
                          : t("pending")
                        : "N/A"}
                    </Text>
                  )}
                  {index === 18 && (
                    <Text style={{ fontSize: 9 }}>
                      {details && details?.traning_complete
                        ? details?.traning_complete === 1
                          ? t("complete")
                          : t("not_complete")
                        : "N/A"}
                    </Text>
                  )}
                  {index === 19 && (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Text style={{ fontSize: 9 }}>
                        {details?.plan_details?.name
                          ? `${details.plan_details.name} (${
                              details?.interval
                                ? details.interval.charAt(0).toUpperCase() +
                                  details.interval.slice(1)
                                : ""
                            }), `
                          : "N/A"}
                      </Text>
                      {details?.plan_details?.price && (
                        <Text style={{ fontSize: 9, marginLeft: 5 }}>
                          Price: ${details?.plan_details?.price || "0.0"}
                        </Text>
                      )}
                    </View>
                  )}
                </View>
              </View>
            ))}
          </View>
        )}
      </Page>
    </Document>
  );
};

export default InformationReport;

import React, { useState, useRef, useEffect, Fragment } from "react";
import toastr from "toastr";
import { useNavigate, useParams } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { postData } from "../../services/api";
import Input from "../form/Input";
import Button from "../form/Button";
import Select from "../form/Select";
import Textarea from "../form/Textarea";
import { decrypt } from "../../helpers";

const ApiKeySettingPopup = ({
  isOpen,
  setIsOpen = () => {},
  onSubmit = () => {},
  formData,
  setFormData = () => {},
}) => {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const editorRef = useRef(null);
  const [loaded, setLoaded] = useState(false);
  const [enabledConfirmationPopup, setEnabledConfirmationPopup] =
    useState(false);

  const updateField = (index, field, value) => {
    const updatedFormData = [...formData];
    updatedFormData[index][field] = value;
    setFormData(updatedFormData);
  };

  const generateJwtSecret = async (index) => {
    setLoaded(true);
    try {
      const res = await postData("generate/secret-key", {});
      if (res.status && res.status === 200) {
        updateField(index, "jwtSecretKey", res?.data);
        // setIsCopy({ show: true, index: index });
      } else {
        toastr.error(res.message);
      }
    } catch (err) {
      console.log(err.messege);
    }
    setLoaded(false);
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-xl transform rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900 text-center"
                >
                  {"Add New Site"}
                </Dialog.Title>
                <div className="absolute top-2 right-2 z-10">
                  <Button
                    buttonLabelClasses=""
                    buttonType={"button"}
                    buttonIcon={"fa-light fa-times"}
                    buttonIconPosition={"left"}
                    buttonLabel={""}
                    buttonClasses={
                      "!bg-transparent !text-slate-700 !w-10 !h-10 !p-0"
                    }
                    functions={() => {
                      setIsOpen({ _id: null, open: false });
                    }}
                    buttonHasLink={false}
                  />
                </div>
                <div className="grid grid-cols-12 gap-4">
                  <div className="col-span-12 lg:col-span-12 flex flex-col">
                    <div
                      className="grid grid-cols-1 gap-4 p-4 overflow-auto scroll-smooth scrollbar"
                      // max-h-[calc(100vh-50px)]"
                    >
                      {formData.map((data, index) => (
                        <div key={index} className="relative w-full space-y-2 ">
                          <div className="relative flex justify-between items-center mb-3 flex-wrap sm:flex-nowrap gap-y-4"></div>
                          <Input
                            label="Site Name"
                            labelClasses="!text-xs"
                            inputType="text"
                            inputPlaceholder="Enter Site Name"
                            inputValue={data.name}
                            setInput={(value) =>
                              updateField(index, "name", value)
                            }
                            requiredDisplay={true}
                          />
                          <Input
                            label="White Listed Domain"
                            labelClasses="!text-xs"
                            inputType="text"
                            inputPlaceholder="Enter Domain"
                            inputValue={data.whiteListedDomain}
                            setInput={(value) =>
                              updateField(index, "whiteListedDomain", value)
                            }
                            requiredDisplay={true}
                          />
                          <div className="relative flex items-end gap-3">
                            <div className="flex-grow flex-shrink">
                              <Textarea
                                label="Secret Key"
                                labelClasses="!text-xs"
                                inputClasses={"!h-10"}
                                inputPlaceholder="Generate Secret Key"
                                inputValue={data.jwtSecretKey}
                                setTextarea={(value) =>
                                  updateField(index, "jwtSecretKey", value)
                                }
                                requiredDisplay={true}
                                isDisable={true}
                              />
                            </div>
                          </div>
                          <div className="flex gap-2 mt-2">
                            <Button
                              buttonType="button"
                              buttonLabel="Generate Secret Key"
                              buttonIconPosition={"left"}
                              buttonIcon={
                                loaded
                                  ? "fa-light fa-spinner fa-spin"
                                  : "fa-solid fa-rotate"
                              }
                              buttonClasses="!bg-pink-500 text-white"
                              functions={() => {
                                generateJwtSecret(index);
                              }}
                              buttonHasLink={false}
                            />
                          </div>
                          <Select
                            label="Expire Days"
                            labelClasses={"!text-xs !font-semibold"}
                            xPlacement={"left"}
                            selectedValue={data.expireDay}
                            dropdownClass={"!w-60"}
                            dropdownData={[
                              {
                                _id: " ",
                                label: t("Select Expire Days"),
                                value: "",
                              },
                              ...Array.from({ length: 50 }).map((_, index) => {
                                const value = (index + 1) * 10;
                                return {
                                  _id: value,
                                  label: `${value} Days`,
                                  value: value,
                                };
                              }),
                            ].filter((itm) => itm)}
                            getSelectedValue={(value) =>
                              updateField(index, "expireDay", value._id)
                            }
                            requiredDisplay={true}
                          />
                        </div>
                      ))}
                    </div>
                    <div className="flex justify-end gap-4 mt-5">
                      <Button
                        buttonClasses=""
                        buttonLabelClasses=""
                        buttonType={"button"}
                        buttonIcon={
                          loaded
                            ? "fa-light fa-spinner fa-spin"
                            : "fa-light fa-floppy-disk"
                        }
                        buttonIconPosition={"left"}
                        buttonLabel={t("save")}
                        functions={onSubmit}
                        buttonHasLink={false}
                      />
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ApiKeySettingPopup;

import React, { useState, useEffect } from "react";
import { setTitle } from "../helpers/MetaTag";
import Button from "../components/form/Button";
import Select from "../components/form/Select";
import Input from "../components/form/Input";
import Textarea from "../components/form/Textarea";
import { Link } from "react-router-dom";
import { dayRemaining } from "./../helpers";
import toastr from "toastr";
import moment from "moment/moment";
import { postData } from "../services/api";

import { useTranslation } from "react-i18next";

const Salesteam = () => {
  setTitle(
    "Succession Planning Software for your Business with Succession Now | Sales team"
  );
  const { i18n, t } = useTranslation();
  const [sizes, setSizes] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [companyAdministrator, setCompanyAdministrator] = useState("");
  const [companyAdministratorEmail, setCompanyAdministratorEmail] =
    useState("");
  const [verifyCompanyAdministratorEmail, setVerifyCompanyAdministratorEmail] =
    useState("");
  const [companyAdministratorPhone, setCompanyAdministratorPhone] =
    useState("");
  const [companySize, setCompanySize] = useState("");
  const [price, setPrice] = useState("");
  const [priceNote, setPriceNote] = useState("");
  const [salesperson, setSalesperson] = useState("");
  const [additionalNotes, setAdditionalNotes] = useState("");
  const [administratorPosition, setAdministratorPosition] = useState("");
  const [source, setSource] = useState("");
  const [contractLength, setContractLength] = useState("");
  const [paymentTerms, setPaymentTerms] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [interval, setInterval] = useState("month");
  const [purchasePlans, setPurchasePlans] = useState([]);
  const [priceId, setPriceId] = useState("");

  const onCancel = async (e) => {
    setIsCancel(true);
    setIsCancel(false);
  };

  const onUpdate = async (e) => {
    setIsUpdate(true);
    try {
      const result = await postData("salesteam/create", {
        company_name: companyName,
        comapny_administrator: companyAdministrator,
        company_administrator_email: companyAdministratorEmail,
        verify_company_adminstrator_email: verifyCompanyAdministratorEmail,
        company_administrator_phone: companyAdministratorPhone,
        company_size: companySize,
        price: price,
        price_note: priceNote,
        salesperson: salesperson,
        additional_note: additionalNotes,
        administrator_position: administratorPosition,
        source: source,
        contract_length: contractLength,
        payment_terms: paymentTerms,
        plan_id: priceId,
        interval: interval,
      });
      if (result.status && result.status === 200) {
        toastr.success(result.message);
        setCompanyName("");
        setCompanyAdministrator("");
        setCompanyAdministratorEmail("");
        setVerifyCompanyAdministratorEmail("");
        setCompanyAdministratorPhone("");
        setCompanySize("");
        setPrice("");
        setPriceNote("");
        setSalesperson("");
        setAdditionalNotes("");
        setAdministratorPosition("");
        setSource("");
        setContractLength("");
        setPaymentTerms("");
        setIsUpdate(false);
      } else {
        toastr.error(result.message);
        setIsUpdate(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setIsUpdate(false);
    }
  };

  useEffect(() => {
    // Plan list
    const loadCompanySize = async () => {
      try {
        const result = await postData("filter-item/guest-list", {
          sortQuery: { ordering: 1 },
          type: "company-size",
        });
        if (result.data) {
          setSizes(result.data);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadCompanySize();
  }, []);

  useEffect(() => {
    const loadPurchasePlans = async () => {
      try {
        const result = await postData("plans/list", {
          sortQuery: { ordering: 1 },
          sortQuery: { ordering: 1 },
          isView: 1,
        });
        if (result.data) {
          setPurchasePlans(
            result.data.map((itm) => {
              let price;
              if (itm.pricing?.rates.length > 0) {
                price = itm.pricing?.rates?.filter(
                  (it) => it?.interval === interval
                )[0]?.amount;
              }
              return {
                _id: itm?._id,
                title: itm?.name,
                price: price,
              };
            })
          );
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadPurchasePlans();
  }, [interval]);

  return (
    <>
      {/* <div className="relative flex justify-between items-center mb-4">
        <div className="text-3xl text-slate-800 font-bold">Sales Team</div>
      </div> */}
      <div className="relative bg-white shadow rounded">
        <div className="py-10 px-3">
          <div className="relative space-y-10">
            <Link to={"/"}>
              <div className="h-10 w-full flex justify-center object-contain mb-5">
                <svg
                  className="h-full"
                  preserveAspectRatio="xMidYMid meet"
                  data-bbox="0 0 382.89 71.37"
                  viewBox="0 0 382.89 71.37"
                  xmlns="http://www.w3.org/2000/svg"
                  data-type="color"
                  role="img"
                  aria-labelledby="svgcid-iz595q-x7t6i4"
                >
                  <title id="svgcid-iz595q-x7t6i4">SuccessionNow Logo</title>
                  <g>
                    <path
                      d="m21.02 40.95-6.88-2.98c-3.78-1.61-4.59-3.29-4.59-6.01s1.05-5.15 5.08-5.15c3.41 0 4.84 2.42 4.84 6.57h8.56v-1.24c0-8.49-5.77-11.9-13.7-11.9-8.44 0-13.71 4.4-13.71 13.02 0 7.38 3.66 10.66 11.59 13.39 5.89 2.05 7.94 3.53 7.94 7.56 0 3.72-2.6 5.46-5.33 5.46-4.71 0-5.89-2.67-5.89-6.82v-1.3H0v1.8c0 8.18 3.97 12.9 14.07 12.9s15.38-4.22 15.38-13.58c0-5.39-1.74-8.8-8.43-11.72Z"
                      fill="#3a8078"
                      data-color="1"
                    ></path>
                    <path
                      d="M49.45 54.15c0 3.72-1.61 5.52-4.53 5.52-2.54 0-3.66-1.3-3.66-4.65v-23H32.7v24.74c0 6.57 3.16 9.49 8.49 9.49 3.6 0 6.7-1.49 8.49-4.77h.12v3.91h8.18V32.02h-8.56v22.13Z"
                      fill="#3a8078"
                      data-color="1"
                    ></path>
                    <path
                      d="M75.18 37.35c3.22 0 3.84 3.47 3.84 6.7h8.18c0-7.87-4.09-12.9-11.97-12.9s-13.52 3.84-13.52 17.54 4.22 17.55 12.77 17.55c9.05 0 12.71-4.9 12.71-13.64h-8.18c0 5.39-1.3 7.81-4.15 7.81-3.84 0-4.59-3.41-4.59-11.66 0-7.69.68-11.41 4.9-11.41Z"
                      fill="#3a8078"
                      data-color="1"
                    ></path>
                    <path
                      d="M103.19 37.35c3.22 0 3.84 3.47 3.84 6.7h8.18c0-7.87-4.09-12.9-11.97-12.9s-13.52 3.84-13.52 17.54 4.22 17.55 12.77 17.55c9.05 0 12.71-4.9 12.71-13.64h-8.18c0 5.39-1.3 7.81-4.15 7.81-3.84 0-4.59-3.41-4.59-11.66 0-7.69.68-11.41 4.9-11.41Z"
                      fill="#3a8078"
                      data-color="1"
                    ></path>
                    <path
                      d="M131.01 31.15c-11.53 0-13.27 8.31-13.27 17.24 0 8.31.5 17.85 12.9 17.85 8.06 0 11.78-4.34 12.09-12.09h-7.69c-.5 4.09-1.67 6.26-4.53 6.26-3.16 0-4.22-3.84-4.22-7.75v-2.67h16.92v-1.92c0-10.23-2.23-16.93-12.21-16.93Zm-4.71 13.76v-1.3c0-3.1.99-6.63 4.4-6.63s4.15 2.85 4.34 7.94h-8.74Z"
                      fill="#3a8078"
                      data-color="1"
                    ></path>
                    <path
                      d="m162.72 46.22-5.15-1.74c-2.85-.93-3.72-2.29-3.72-3.91 0-2.36 1.86-3.6 4.03-3.6 2.79 0 3.78 1.67 3.78 5.02h7.69v-1.36c0-6.01-3.66-9.49-11.41-9.49-8.8 0-12.28 4.53-12.28 10.04 0 4.71 2.42 7.63 7.13 9.3l6.01 2.17c1.67.62 3.1 1.61 3.1 3.78 0 2.67-1.74 3.97-4.34 3.97-2.91 0-4.46-1.92-4.46-4.84v-1.12h-7.69v1.18c0 6.39 2.67 10.6 12.28 10.6 7.19 0 12.4-3.47 12.4-10.48 0-5.02-1.98-7.75-7.38-9.55Z"
                      fill="#3a8078"
                      data-color="1"
                    ></path>
                    <path
                      d="m189.75 46.22-5.15-1.74c-2.85-.93-3.72-2.29-3.72-3.91 0-2.36 1.86-3.6 4.03-3.6 2.79 0 3.78 1.67 3.78 5.02h7.69v-1.36c0-6.01-3.66-9.49-11.41-9.49-8.8 0-12.28 4.53-12.28 10.04 0 4.71 2.42 7.63 7.13 9.3l6.01 2.17c1.67.62 3.1 1.61 3.1 3.78 0 2.67-1.74 3.97-4.34 3.97-2.91 0-4.46-1.92-4.46-4.84v-1.12h-7.69v1.18c0 6.39 2.67 10.6 12.28 10.6 7.19 0 12.4-3.47 12.4-10.48 0-5.02-1.98-7.75-7.38-9.55Z"
                      fill="#3a8078"
                      data-color="1"
                    ></path>
                    <path
                      fill="#3a8078"
                      d="M209.32 20.61v7.32h-8.56v-7.32h8.56z"
                      data-color="1"
                    ></path>
                    <path
                      fill="#3a8078"
                      d="M209.32 32.02v33.35h-8.56V32.02h8.56z"
                      data-color="1"
                    ></path>
                    <path
                      d="M226.36 31.15c-9.8 0-13.14 6.14-13.14 17.54s2.85 17.73 13.14 17.55c9.8 0 13.14-6.14 13.14-17.55s-2.85-17.73-13.14-17.54Zm0 29.26c-4.22 0-4.59-4.9-4.59-11.72s.37-11.72 4.59-11.72c3.47 0 4.59 3.41 4.59 11.72s-1.12 11.72-4.59 11.72Z"
                      fill="#3a8078"
                      data-color="1"
                    ></path>
                    <path
                      d="M260 31.15c-3.6 0-6.7 1.49-8.49 4.77h-.12v-3.91h-8.18v33.35h8.56V43.23c0-3.72 1.61-5.52 4.53-5.52 2.54 0 3.66 1.3 3.66 4.65v23h8.56V40.62c0-6.57-3.16-9.49-8.49-9.49Z"
                      fill="#3a8078"
                      data-color="1"
                    ></path>
                    <path
                      d="M325.74 31.15c-9.8 0-14.45 6.14-16.86 17.54-2.48 11.59-.93 17.73 9.42 17.55 9.79 0 14.45-6.14 16.86-17.55 2.48-11.59.93-17.73-9.42-17.54Zm.87 17.54c-1.74 8.31-3.6 11.72-7.07 11.72-4.21 0-3.53-4.9-2.11-11.72 1.49-6.82 2.85-11.72 7.07-11.72 3.47 0 3.91 3.41 2.11 11.72Z"
                      fill="#a7a9ac"
                      data-color="2"
                    ></path>
                    <path
                      fill="#a7a9ac"
                      d="m374.7 32.02-9.05 23.68h-.12l.62-23.68h-9.3l-9.18 23.68h-.12l1.11-23.68h-8.55l.18 33.35h9.92l9.18-23.68h.12l-.62 23.68h9.74l14.26-33.35h-8.19z"
                      data-color="2"
                    ></path>
                    <path
                      fill="#a7a9ac"
                      d="M321.29 12.82 313.02 0 300.2 8.27l6.29 1.36-11.16 41.79h-.13l-5.34-23.31h-10.29l-9.42 43.26h8.18l6.57-29.99h.13l5.34 23.99h10.16l14.2-53.96 6.56 1.41z"
                      data-color="2"
                    ></path>
                  </g>
                </svg>
              </div>
            </Link>
            <div className="mx-auto max-w-4xl space-y-4">
              <div className="grid grid-cols-3 gap-4">
                <div className="col-span-1 flex items-center justify-end">
                  <div className="text-sm font-medium text-slate-500">
                    Company Name
                    <span className="text-[8px] text-red-500 ml-0.5">
                      <i className="fa-solid fa-asterisk"></i>
                    </span>
                  </div>
                </div>
                <div className="col-span-2">
                  <Input
                    inputType={"text"}
                    inputValue={companyName}
                    setInput={setCompanyName}
                    isDisabled={false}
                  />
                </div>
              </div>
              <div className="grid grid-cols-3 gap-4">
                <div className="col-span-1 flex items-center justify-end">
                  <div className="text-sm font-medium text-slate-500">
                    Company Administrator
                    <span className="text-[8px] text-red-500 ml-0.5">
                      <i className="fa-solid fa-asterisk"></i>
                    </span>
                  </div>
                </div>
                <div className="col-span-2">
                  <Input
                    inputType={"text"}
                    inputValue={companyAdministrator}
                    setInput={setCompanyAdministrator}
                    isDisabled={false}
                  />
                </div>
              </div>
              <div className="grid grid-cols-3 gap-4">
                <div className="col-span-1 flex items-center justify-end">
                  <div className="text-sm font-medium text-slate-500">
                    Administrator Position
                    <span className="text-[8px] text-red-500 ml-0.5">
                      <i className="fa-solid fa-asterisk"></i>
                    </span>
                  </div>
                </div>
                <div className="col-span-2">
                  <Input
                    inputType={"text"}
                    inputValue={administratorPosition}
                    setInput={setAdministratorPosition}
                    isDisabled={false}
                  />
                </div>
              </div>
              <div className="grid grid-cols-3 gap-4">
                <div className="col-span-1 flex items-center justify-end">
                  <div className="text-sm font-medium text-slate-500">
                    Company Administrator Email
                    <span className="text-[8px] text-red-500 ml-0.5">
                      <i className="fa-solid fa-asterisk"></i>
                    </span>
                  </div>
                </div>
                <div className="col-span-2">
                  <Input
                    inputType={"text"}
                    inputValue={companyAdministratorEmail}
                    setInput={setCompanyAdministratorEmail}
                    isDisabled={false}
                  />
                </div>
              </div>
              <div className="grid grid-cols-3 gap-4">
                <div className="col-span-1 flex items-center justify-end">
                  <div className="text-sm font-medium text-slate-500">
                    Verify Company Administrator Email
                    <span className="text-[8px] text-red-500 ml-0.5">
                      <i className="fa-solid fa-asterisk"></i>
                    </span>
                  </div>
                </div>
                <div className="col-span-2">
                  <Input
                    inputType={"text"}
                    inputValue={verifyCompanyAdministratorEmail}
                    setInput={setVerifyCompanyAdministratorEmail}
                    isDisabled={false}
                  />
                </div>
              </div>
              <div className="grid grid-cols-3 gap-4">
                <div className="col-span-1 flex items-center justify-end">
                  <div className="text-sm font-medium text-slate-500">
                    Company Administrator Phone Number
                    <span className="text-[8px] text-red-500 ml-0.5">
                      <i className="fa-solid fa-asterisk"></i>
                    </span>
                  </div>
                </div>
                <div className="col-span-2">
                  <Input
                    inputType={"text"}
                    inputValue={companyAdministratorPhone}
                    setInput={setCompanyAdministratorPhone}
                    isDisabled={false}
                  />
                </div>
              </div>
              <div className="grid grid-cols-3 gap-4">
                <div className="col-span-1 flex items-center justify-end">
                  <div className="text-sm font-medium text-slate-500">
                    Interval
                    <span className="text-[8px] text-red-500 ml-0.5">
                      <i className="fa-solid fa-asterisk"></i>
                    </span>
                  </div>
                </div>
                <div className="col-span-2">
                  <Select
                    // label={t("interval")}
                    requiredDisplay={true}
                    labelClasses={"!text-xs"}
                    xPlacement={"bottomLeft"}
                    selectedValue={interval}
                    dropdownClass={"!w-60"}
                    dropdownData={[
                      { _id: " ", label: t("select_interval"), value: "" },
                      { _id: "month", label: t("month"), value: "month" },
                      { _id: "year", label: t("year"), value: "year" },
                    ]}
                    getSelectedValue={(e) => {
                      setInterval(e._id); 
                      setPriceId("");
                      setPrice("");
                    }}
                  />
                </div>
              </div>
              <div className="grid grid-cols-3 gap-4">
                <div className="col-span-1 flex items-center justify-end">
                  <div className="text-sm font-medium text-slate-500">
                    Plans
                    <span className="text-[8px] text-red-500 ml-0.5">
                      <i className="fa-solid fa-asterisk"></i>
                    </span>
                  </div>
                </div>
                <div className="col-span-2">
                  <Select
                    // label={t("plans")}
                    requiredDisplay={true}
                    labelClasses={"!text-xs"}
                    xPlacement={"bottomLeft"}
                    selectedValue={price}
                    dropdownClass={"!w-60"}
                    dropdownData={[
                      { _id: "", label: t("select_plans"), value: "" },
                      ...purchasePlans.map((item) => ({
                        _id: item?._id,
                        label:
                          item?.title + " - $" + Number(item?.price).toFixed(2),
                        value: item?.price,
                      })),
                    ]}
                    getSelectedValue={(e) => {
                      setPriceId(e._id);
                      setPrice(e.value);
                    }}
                  />
                </div>
              </div>
              <div className="grid grid-cols-3 gap-4">
                <div className="col-span-1 flex items-center justify-end">
                  <div className="text-sm font-medium text-slate-500">
                    Company Size
                    <span className="text-[8px] text-red-500 ml-0.5">
                      <i className="fa-solid fa-asterisk"></i>
                    </span>
                  </div>
                </div>
                <div className="col-span-2">
                  <Select
                    xPlacement={"left"}
                    selectedValue={companySize}
                    dropdownClass={"!w-60"}
                    //dropdownButtonLabelClass={"!font-bold"}
                    dropdownData={[
                      { _id: "", label: "Select Size", value: "" },
                      ...sizes.map((boardyear_item) => ({
                        _id: boardyear_item?._id,
                        label: boardyear_item?.name,
                        value: boardyear_item?._id,
                      })),
                    ]}
                    dropdownOptionBold={true}
                    getSelectedValue={(e) => setCompanySize(e._id)}
                  />
                </div>
              </div>
              <div className="grid grid-cols-3 gap-4">
                <div className="col-span-1 flex items-center justify-end">
                  <div className="text-sm font-medium text-slate-500">
                    Price
                    <span className="text-[8px] text-red-500 ml-0.5">
                      <i className="fa-solid fa-asterisk"></i>
                    </span>
                  </div>
                </div>
                <div className="col-span-2">
                  <Input
                    inputType={"number"}
                    inputValue={price}
                    setInput={setPrice}
                    isDisabled={false}
                  />
                </div>
              </div>
              <div className="grid grid-cols-3 gap-4">
                <div className="col-span-1 flex items-center justify-end">
                  <div className="text-sm font-medium text-slate-500">
                    Source
                    <span className="text-[8px] text-red-500 ml-0.5">
                      <i className="fa-solid fa-asterisk"></i>
                    </span>
                  </div>
                </div>
                <div className="col-span-2">
                  <Select
                    xPlacement={"left"}
                    selectedValue={source}
                    dropdownClass={"!w-60"}
                    //dropdownButtonLabelClass={"!font-bold"}
                    dropdownData={[
                      { _id: "", label: "Select Source", value: "" },
                      { _id: "linkedin", label: "LinkedIn", value: "linkedin" },
                      { _id: "growbots", label: "GrowBots", value: "growbots" },
                      { _id: "google", label: "Google", value: "google" },
                      { _id: "gartner", label: "Gartner", value: "gartner" },
                      { _id: "g2", label: "G2", value: "g2" },
                      { _id: "youtube", label: "YouTube", value: "youtube" },
                      {
                        _id: "website-organic",
                        label: "Website (Organic)",
                        value: "website-organic",
                      },
                      {
                        _id: "referral_email_nick",
                        label: "Referral, Email (Nick)",
                        value: "referral_email_nick",
                      },
                      {
                        _id: "hr_perfommance_solutions",
                        label: "HR Performance Solutions",
                        value: "hr_perfommance_solutions",
                      },
                      {
                        _id: "gac",
                        label: "GAC",
                        value: "gac",
                      },
                      {
                        _id: "shrm",
                        label: "SHRM",
                        value: "shrm",
                      },
                      { _id: "other", label: "Other", value: "other" },
                    ]}
                    dropdownOptionBold={true}
                    getSelectedValue={(e) => setSource(e._id)}
                  />
                </div>
              </div>
              <div className="grid grid-cols-3 gap-4">
                <div className="col-span-1 flex items-center justify-end">
                  <div className="text-sm font-medium text-slate-500">
                    Price Note
                  </div>
                </div>
                <div className="col-span-2">
                  <Textarea
                    inputClasses={"!h-32"}
                    inputValue={priceNote}
                    setTextarea={setPriceNote}
                  />
                </div>
              </div>
              <div className="grid grid-cols-3 gap-4">
                <div className="col-span-1 flex items-center justify-end">
                  <div className="text-sm font-medium text-slate-500">
                    Salesperson
                    <span className="text-[8px] text-red-500 ml-0.5">
                      <i className="fa-solid fa-asterisk"></i>
                    </span>
                  </div>
                </div>
                <div className="col-span-2">
                  <Input
                    inputType={"text"}
                    inputValue={salesperson}
                    setInput={setSalesperson}
                    isDisabled={false}
                  />
                </div>
              </div>
              <div className="grid grid-cols-3 gap-4">
                <div className="col-span-1 flex items-center justify-end">
                  <div className="text-sm font-medium text-slate-500">
                    Proposed Contract Length
                    <span className="text-[8px] text-red-500 ml-0.5">
                      <i className="fa-solid fa-asterisk"></i>
                    </span>
                  </div>
                </div>
                <div className="col-span-2">
                  <Select
                    xPlacement={"left"}
                    selectedValue={contractLength}
                    dropdownClass={"!w-60"}
                    dropdownData={[
                      { _id: "", label: "Select Contract Length", value: "" },
                      ...Array(3)
                        .fill()
                        .map((_, index) => ({
                          _id: Number(index + 1),
                          label:
                            Number(index + 1) > 1
                              ? Number(index + 1) + " Years"
                              : Number(index + 1) + " Year",
                          value: Number(index + 1),
                        })),
                    ]}
                    dropdownOptionBold={true}
                    getSelectedValue={(e) => setContractLength(e._id)}
                  />
                </div>
              </div>
              <div className="grid grid-cols-3 gap-4">
                <div className="col-span-1 flex items-center justify-end">
                  <div className="text-sm font-medium text-slate-500">
                    Payment Terms
                    <span className="text-[8px] text-red-500 ml-0.5">
                      <i className="fa-solid fa-asterisk"></i>
                    </span>
                  </div>
                </div>
                <div className="col-span-2">
                  <Select
                    xPlacement={"left"}
                    selectedValue={paymentTerms}
                    dropdownClass={"!w-60"}
                    dropdownData={[
                      { _id: "", label: "Select Payemnt Terms", value: "" },
                      { _id: "monthly", label: "Monthly", value: "monthly" },
                      {
                        _id: "full-payment",
                        label: "Full Payment",
                        value: "full-payment",
                      },
                    ]}
                    dropdownOptionBold={true}
                    getSelectedValue={(e) => setPaymentTerms(e._id)}
                  />
                </div>
              </div>
              <div className="grid grid-cols-3 gap-4">
                <div className="col-span-1 flex items-center justify-end">
                  <div className="text-sm font-medium text-slate-500">
                    Additional Notes
                  </div>
                </div>
                <div className="col-span-2">
                  <Textarea
                    inputClasses={"!h-32"}
                    inputValue={additionalNotes}
                    setTextarea={setAdditionalNotes}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="py-3 px-3 border-t border-slate-200">
          <div className="flex items-center justify-center gap-4">
            <Button
              buttonType={"button"}
              buttonIcon={
                isCancel ? "fa-light fa-spinner fa-spin" : "fa-light fa-times"
              }
              buttonIconPosition={"left"}
              buttonLabel={"Cancel"}
              buttonClasses={
                "!bg-slate-200 !text-slate-600 border border-slate-300"
              }
              functions={onCancel}
              buttonHasLink={false}
              buttonDisabled={isCancel}
            />
            <Button
              buttonType={"button"}
              buttonIcon={
                isUpdate ? "fa-light fa-spinner fa-spin" : "fa-light fa-check"
              }
              buttonIconPosition={"left"}
              buttonLabel={"Save"}
              functions={onUpdate}
              buttonHasLink={false}
              buttonDisabled={isUpdate}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Salesteam;

import React,{useEffect,useState} from "react";
import { setTitle } from "../helpers/MetaTag";
import { apiRequest } from "../services/api";


const Authentication = () => {
    setTitle("Succession Planning Software for your Business with Succession Now | Authentication");
    const [loading, setLoading] = useState(false);
    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    const token = params.get("token");
    const loaderStyles = {
        width: "fit-content",
        fontWeight: "bold",
        fontFamily: "monospace",
        fontSize: "30px",
        color: "#0000",
        background:
        "linear-gradient(90deg, #326E68 calc(50% + 0.5ch), #000 0) right/calc(200% + 1ch) 100%",
        WebkitBackgroundClip: "text",
        backgroundClip: "text",
        animation: "l7 2s infinite steps(11)",
    };

    const keyframes = `
        @keyframes l7 {
            to { background-position: left; }
        }
    `;

    useEffect(() => {
        const tokenVerification = async () => {
            setLoading(true);
        }
        tokenVerification();
    },[token])
    console.log('token',token)
  return (
    <>
    {
        loading ? 
        <div className="h-screen flex justify-center items-center">
            <style>{keyframes}</style>
            <div style={loaderStyles}>Loading...</div>
        </div>:
        <div className="h-screen flex justify-center items-center">
            hii
        </div>
    }
    </>    
  );
};

export default Authentication;

import React, { useRef } from "react";
import Td from "./Td";
import Searching from "../../Searching";
import NoDataFound from "../../NoDataFound";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import TableSkeleton from "../../loader/TableSkeleton";

const Tbody = ({
  tableData,
  totalRecord,
  tdClasses,
  loading,
  editUrl,
  isStatusUpdate,
  isDeleteEnabled,
  isDraggable,
  dropableRef,
  droppableProps = {},
  getActionValue = () => {},
  isUser,
  detailsUrl,
  othersAction = () => {},
  loginAs,
  extraData,
  updateCheckboxHandler = () => {},
  tableImage,
}) => {
  const dragRef = useRef(null);
  const { i18n, t } = useTranslation();

  return (
    <tbody
      className="text-slate-700 text-sm font-light bg-white"
      ref={isDraggable ? dropableRef : dragRef}
      {...droppableProps}
    >
      {totalRecord > 0 ? (
        tableData.map((item, index) => {
          return isDraggable ? (
            <Draggable draggableId={item._id} key={item._id} index={index}>
              {(provided, snapshot) => {
                return (
                  <tr
                    className={
                      "border-b border-slate-200 w-full " +
                      item?.isBackgroundColor
                        ? item?.isBackgroundColor
                        : ""
                    }
                    key={item._id}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                  >
                    {item.rowData.map((itemData) => {
                      return (
                        <Td
                          key={itemData?._id}
                          width={itemData?.width}
                          shortName={itemData?.shortName}
                          type={itemData?.type}
                          data={itemData?.data}
                          value={itemData?.value}
                          tdClasses={itemData?.tdClasses}
                          isDropdoen={itemData?.isDropdoen}
                          image={itemData?.image}
                          statusLabel={itemData?.statusLabel}
                          statusType={itemData?.statusType}
                          rowId={item?._id}
                          userIds={item?.userIds}
                          editUrl={editUrl}
                          isStatusUpdate={isStatusUpdate}
                          isDeleteEnabled={isDeleteEnabled}
                          dragHandleProps={provided.dragHandleProps}
                          getActionValue={(obj) => getActionValue(obj)}
                          isUser={isUser}
                          detailsUrl={detailsUrl}
                          othersAction={othersAction}
                          loginAs={loginAs}
                          isColor={itemData.isColor}
                          extraData={extraData}
                          ischeckboxData={itemData?.ischeckboxData}
                          checkboxData={itemData?.checkboxData}
                          updateCheckboxHandler={updateCheckboxHandler}
                          {...itemData}
                        />
                      );
                    })}
                  </tr>
                );
              }}
            </Draggable>
          ) : (
            <tr
              className={
                "border-b border-slate-200 w-full " + item?.isBackgroundColor
                  ? item?.isBackgroundColor
                  : ""
              }
              key={item._id}
            >
              {item.rowData.map((itemData) => {
                return (
                  <Td
                    key={itemData?._id}
                    width={itemData?.width}
                    toolTipData={itemData?.toolTipData}
                    type={itemData?.type}
                    data={itemData?.data}
                    isTooltip={itemData?.isTooltip}
                    value={itemData?.value}
                    tdClasses={tdClasses}
                    isDropdoen={itemData?.isDropdoen}
                    image={itemData?.image}
                    statusLabel={itemData?.statusLabel}
                    statusType={itemData?.statusType}
                    rowId={item?._id}
                    userIds={item?.userIds}
                    editUrl={editUrl}
                    isStatusUpdate={isStatusUpdate}
                    isDeleteEnabled={isDeleteEnabled}
                    getActionValue={(obj) => getActionValue(obj)}
                    isUser={isUser}
                    detailsUrl={detailsUrl}
                    othersAction={othersAction}
                    loginAs={loginAs}
                    isColor={itemData.isColor}
                    extraData={extraData}
                    ischeckboxData={itemData?.ischeckboxData}
                    checkboxData={itemData?.checkboxData}
                    updateCheckboxHandler={updateCheckboxHandler}
                    {...itemData}
                  />
                );
              })}
            </tr>
          );
        })
      ) : (
        <tr className="border-b border-slate-200 w-full">
          <td className="py-3 px-2 text-left" colSpan={20}>
            {loading ? (
              <TableSkeleton tableImage={tableImage} />
            ) : (
              <NoDataFound label={t("no_data_found")} />
            )}
          </td>
        </tr>
      )}
    </tbody>
  );
};

export default Tbody;

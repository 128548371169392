import React, { useState, useRef, useEffect, Fragment } from "react";
import toastr from "toastr";
import { useNavigate, useParams } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { postData } from "../../services/api";
import { allowedImageExtensions } from "../../helpers";
import ButtonFile from "../form/ButtonFile";
import Image from "../elements/Image";
import Input from "../form/Input";
import Checkbox from "../form/Checkbox";
import Button from "../form/Button";
import { setTitle } from "../../helpers/MetaTag";
import NoDataFound from "../NoDataFound";
import Searching from "../Searching";
import ConfirmationPopup from "./ConfirmationPopup";
import OtherPositionPopup from "./OtherPositionPopup";
import { useSelector } from "react-redux";
import Select from "../form/Select";

const EditUserPopup = ({
  isOpen,
  setIsOpen = () => {},
  data,
  loadList = () => {},
  id,
}) => {
  if (id) {
    setTitle(
      "Succession Planning Software for your Business with Succession Now | Edit Succession Plan"
    );
  } else {
    setTitle(
      "Succession Planning Software for your Business with Succession Now | Assign Succession Plans to Individuals"
    );
  }
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth?.user);
  const [position, setPosition] = useState([]);
  //const [retirementWindow,setRetirementWindow] = useState([]);

  const [details, setDetails] = useState({});
  const [isAdd, setIsAdd] = useState(false);
  const [name, setName] = useState("");
  //const [assignTitle,setAssignTitle] = useState('');
  const [email, setEmail] = useState("");
  const [designation, setDesignation] = useState("");
  //const [selectRetirementWindow,setSelectRetirementWindow] = useState('');
  const [enabledOtherPositionPopup, setEnabledOtherPositionPopup] =
    useState(false);
  const [enabledConfirmationPopup, setEnabledConfirmationPopup] =
    useState(false);
  const [actionValue, setActionValue] = useState({});
  const [isAdditionalAdministrator, setIsAdditionalAdministrator] = useState(2);
  const [message, setMessage] = useState("");
  const [isConfirm, setIsConfirm] = useState(false);
  useEffect(() => {
    // Position details
    const loadDetails = async () => {
      try {
        const result = await postData("assign/details", {
          id: id,
        });
        if (result.status === 403) {
          return navigate("/users");
        } else {
          if (result.data) {
            setDetails(result.data);
          }
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadDetails();
  }, [id]);

  useEffect(() => {
    if (details?.role_details && details?.role_details.length > 0) {
      let adminData = details?.role_details.filter(
        (item) => item?.code === "customer-subadmin"
      );
      if (adminData && adminData.length > 0) {
        setIsAdditionalAdministrator(1);
        setIsConfirm(true);
      } else {
        setIsAdditionalAdministrator(2);
        setIsConfirm(false);
      }
    }
    setName(details?.name);
    setEmail(details?.email);

    //setAssignTitle(details?.title);
    if (details.designation) {
      setDesignation(details.designation);
    } else {
      setDesignation(user?.designation);
    }
  }, [details]);

  useEffect(() => {
    async function positionData() {
      try {
        const result = await postData("position/list", {
          sortQuery: { created_at: 1 },
          status: 1,
        });
        if (result.data) {
          setPosition(result.data);
        } else {
          console.log("Position list message", result.message);
        }
      } catch (error) {
        console.log("Error position list catch", error.message);
      }
    }
    positionData();

    // async function retirementWindowData() {
    //   try {
    //     const result = await postData("experience/list", {
    //       sortQuery:{'created_at':1},
    //       type:3,
    //       status:1,
    //       is_visabled:1,
    //     });
    //     if (result.data) {
    //       setRetirementWindow(result.data);
    //     } else {
    //       console.log('Retirement window list message',result.message)
    //     }
    //   } catch (error) {
    //     console.log('Error retirement window list catch',error.message)
    //   }
    // }
    // retirementWindowData();
  }, []);

  const onSubmit = async (e) => {
    setIsAdd(true);
    try {
      let payload;
      let path;
      if (id) {
        path = "assign/update";
        payload = {
          id: id,
          name: name,
          //title:assignTitle,
          email: email,
          designation: designation,
          is_admin: isAdditionalAdministrator,
        };
      } else {
        path = "assign/create";
        payload = {
          name: name,
          //title:assignTitle,
          email: email,
          designation: designation,
          is_admin: isAdditionalAdministrator,
          //retirement_window: selectRetirementWindow
        };
      }
      const result = await postData(path, payload);
      if (result.status && result.status === 200) {
        toastr.success(result.message);
        setIsAdd(false);
        setName("");
        //setAssignTitle('')
        setEmail("");
        setDesignation("");
        loadList();
        setIsOpen({ open: false, _id: null });
        // return navigate("/users");
      } else {
        toastr.error(result.message);
        setIsAdd(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setIsAdd(false);
    }
  };

  useEffect(() => {
    if (actionValue && actionValue.button_type === "confirm") {
      if (isConfirm === true) {
        setIsAdditionalAdministrator(1);
      } else {
        setIsAdditionalAdministrator(2);
      }
      setEnabledConfirmationPopup(false);
    } else if (actionValue && actionValue.button_type === "close") {
    } else if (actionValue) {
      setPosition([...position, actionValue]);
      setDesignation(actionValue?._id);
    }
  }, [actionValue]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-xl transform rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900 text-center"
                >
                  Edit Succession Plan
                </Dialog.Title>
                <div className="absolute top-2 right-2 z-10">
                  <Button
                    buttonLabelClasses=""
                    buttonType={"button"}
                    buttonIcon={"fa-light fa-times"}
                    buttonIconPosition={"left"}
                    buttonLabel={""}
                    buttonClasses={
                      "!bg-transparent !text-slate-700 !w-10 !h-10 !p-0"
                    }
                    functions={() => {
                      setIsOpen({ _id: null, open: false });
                      setDetails({});
                    }}
                    buttonHasLink={false}
                  />
                </div>
                <div className="relative grid grid-cols-2 gap-4">
                  <div className="col-span-2">
                    <div className="relative mt-4 border bg-white border-gray-200 p-6 rounded-xl">
                      <div className="space-y-6">
                        <div className="grid sm:grid-cols-1 gap-4">
                          <div className="relative w-full">
                            <Input
                              label={t("full_name")}
                              requiredDisplay={true}
                              labelClasses="text-xs"
                              inputType="text"
                              inputPlaceholder={t("enter_full_name")}
                              inputValue={name}
                              setInput={setName}
                            />
                          </div>

                          <div className="relative w-full">
                            <Input
                              label={t("work_email")}
                              requiredDisplay={true}
                              labelClasses="text-xs"
                              inputType="email"
                              inputPlaceholder={t("work_email_address")}
                              inputValue={email}
                              setInput={setEmail}
                              isDisabled={!!id}
                            />
                          </div>

                          <div className="relative w-full">
                            <Select
                              label={t("position")}
                              requiredDisplay={true}
                              labelClasses="text-xs"
                              xPlacement="left"
                              selectedValue={designation}
                              dropdownClass="w-full"
                              dropdownData={[
                                {
                                  _id: " ",
                                  label: t("select_position"),
                                  value: "",
                                },
                                ...position.map((item) => ({
                                  _id: item?._id,
                                  label: item?.name,
                                  value: item?._id,
                                })),
                                {
                                  _id: "others",
                                  label: "Other",
                                  value: "others",
                                },
                              ]}
                              getSelectedValue={(e) => {
                                if (e._id === "others")
                                  setEnabledOtherPositionPopup(true);
                                setDesignation(e._id);
                              }}
                            />
                          </div>
                        </div>
                        {user?._id !== id && (
                          <div className="relative w-full">
                            <Checkbox
                              checkboxLabel={t(
                                "allow_user_administrator_access"
                              )}
                              checkboxLableClass={""}
                              isChecked={
                                isAdditionalAdministrator &&
                                isAdditionalAdministrator === 1
                                  ? true
                                  : false
                              }
                              checkboxValue={"confirm_additional_admin"}
                              getCheckboxValue={(code, event) => {
                                //console.log(event.target.checked);
                                setIsConfirm(event.target.checked);
                                if (event.target.checked === true) {
                                  setEnabledConfirmationPopup(true);
                                  setMessage(t("by_checking_this_box"));
                                } else {
                                  setEnabledConfirmationPopup(true);
                                  setMessage(
                                    t("by_checking_this_box_you_will")
                                  );
                                }
                              }}
                            />
                          </div>
                        )}
                      </div>

                      {/* Footer Section with Button */}
                      <div className="py-3 px-3 mt-4">
                        {!id && (
                          <div className="text-xs text-slate-800 font-bold flex justify-end">
                            {t("once_you_click_assign_this_person")}
                          </div>
                        )}
                        <div className="flex items-center justify-end gap-1 mt-1">
                          <Button
                            buttonClasses=""
                            buttonLabelClasses=""
                            buttonType="button"
                            buttonIcon={
                              isAdd
                                ? "fa-light fa-spinner fa-spin"
                                : "fa-light fa-check"
                            }
                            buttonIconPosition="left"
                            buttonLabel={id ? t("update") : t("assign")}
                            functions={onSubmit}
                            buttonHasLink={false}
                            buttonDisabled={isAdd}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {enabledOtherPositionPopup && (
                  <OtherPositionPopup
                    isOpen={enabledOtherPositionPopup}
                    setIsOpen={(val) => setEnabledOtherPositionPopup(val)}
                    getActionValue={(obj) => {
                      setActionValue(obj);
                    }}
                  ></OtherPositionPopup>
                )}
                {enabledConfirmationPopup && (
                  <ConfirmationPopup
                    isOpen={enabledConfirmationPopup}
                    title={t("confirmation")}
                    message={message}
                    confirmButtonLabel={t("confirm")}
                    cancelButtonLabel={t("cancel")}
                    setIsOpen={(val) => setEnabledConfirmationPopup(val)}
                    getActionValue={(obj) => {
                      //updateHandler(currentId,false)
                      setActionValue(obj);
                    }}
                  ></ConfirmationPopup>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default EditUserPopup;

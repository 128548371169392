import React from "react";

const ReplacementSkeletonLoader = () => {
  const rows = Array.from({ length: 10 });
  return (
    <div className="py-5 sm:py-10 px-4 sm:px-10 lg:px-20 relative w-full max-w-5xl mx-auto  shadow rounded-lg shimmer">
      <div className="flex flex-col items-center">
        <div className="flex items-center mb-10">
          <div className="w-full h-12 sm:h-16 min-w-[300px]">
            <div className="w-full h-full bg-gray-300 rounded"></div>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center">
        <div className="flex items-center">
          <div className="w-full h-4 sm:h-8 min-w-[700px]">
            <div className="w-full h-full bg-gray-300 rounded"></div>
          </div>
        </div>
      </div>
      <div className="p-4 space-y-4 ">
        <div className="h-6 bg-gray-300 rounded-md w-1/3"></div>

        <div className="space-y-2">
          <div className="h-4 bg-gray-300 rounded-md w-full"></div>
          <div className="h-4 bg-gray-300 rounded-md w-5/6"></div>
          <div className="h-4 bg-gray-300 rounded-md w-2/3"></div>
        </div>
        <ul className="space-y-3">
          {[...Array(4)].map((_, index) => (
            <li key={index} className="flex items-center space-x-3">
              <div className="h-4 w-4 bg-gray-300 rounded-full"></div>
              <div className="h-4 bg-gray-300 rounded-md w-1/2"></div>
            </li>
          ))}
        </ul>
        <div className="space-y-3">
          {[...Array(4)].map((_, index) => (
            <div
              key={index}
              className="h-10 bg-gray-300 rounded-md w-full"
            ></div>
          ))}
        </div>
        {/* <table className="table-auto w-full">
        <thead>
          <tr>
            <th className="h-6 bg-gray-300 rounded-md w-1/4"></th>
            <th className="h-6 bg-gray-300 rounded-md w-1/4"></th>
          </tr>
        </thead>
        <tbody>
          {[...Array(3)].map((_, index) => (
            <tr className="gap-2" key={index}>
              <td className="h-6 bg-gray-300 rounded-md w-1/4"></td>
              <td className="h-6 bg-gray-300 rounded-md w-1/4"></td>
            </tr>
          ))}
        </tbody>
      </table> */}

        <div className="overflow-x-auto">
          <table className="w-full table-auto border-collapse border border-gray-200">
            <thead>
              <tr>
                {Array.from({ length: 5 }).map((_, index) => (
                  <th key={index} className="p-2">
                    <div className="w-24 h-4 bg-gray-300 rounded animate-pulse"></div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rows.map((_, rowIndex) => (
                <tr key={rowIndex} className="border-b border-gray-200">
                  {Array.from({ length: 5 }).map((_, cellIndex) => (
                    <td key={cellIndex} className="p-2">
                      <div className="w-full h-4 bg-gray-300 rounded animate-pulse"></div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ReplacementSkeletonLoader;

import React, { useState, useEffect } from "react";
import { setTitle } from "../../helpers/MetaTag";
import Button from "../../components/form/Button";
import Select from "../../components/form/Select";
import Search from "../../components/form/Search";
import { postData } from "../../services/api";
import BuyPlanPopup from "../../components/popup/BuyPlanPopup";
import NoDataFound from "../../components/NoDataFound";
import moment from "moment";
import toastr from "toastr";
import { Tooltip } from "react-tooltip";
import MakePaymentPopup from "../../components/popup/MakePaymentPopup";
import MakeCreateAdditionalPaymentPopup from "../../components/popup/MakeCreateAdditionalPaymentPopup";
import { useTranslation } from "react-i18next";
import Pagination from "../../components/Common/Pagination";
import { useDebounce } from "../../helpers/useDebounce";
import Indentification from "../../components/elements/Indentification";
import { initialsValue } from "../../helpers";
import TableSkeleton from "../../components/loader/TableSkeleton";
import { capitalizeString } from "../../helpers";

const Payments = () => {
  setTitle(
    "Succession Planning Software for your Business with Succession Now | Payment"
  );
  const { i18n, t } = useTranslation();
  const role = localStorage.getItem("role");
  const [loading, setLoading] = useState(true);
  const [keyword, setKeyword] = useState("");
  const search = useDebounce(keyword, 500);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [sortKey, setSortKey] = useState("created_at");
  const [sortValue, setSortValue] = useState(-1);
  const [sortQuery, setSortQuery] = useState("");
  const [noOfPage, setNoOfPage] = useState(0);
  const [paymentList, setPaymentList] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [startDataCount, setStartDataCount] = useState(0);
  const [endDataCount, setEndDataCount] = useState(0);
  const [enabledBuyPlanPopup, setEnabledBuyPlanPopup] = useState(false);
  const [enabledMakePaymentPopup, setEnabledMakePaymentPopup] = useState(false);
  const [
    enabledCreateAdditionalPaymentPopup,
    setEnabledCreateAdditionalPaymentPopup,
  ] = useState(false);
  const [actionValue, setActionValue] = useState({});
  const [companyData, setCompanyData] = useState({});
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    if (sortKey && sortValue) {
      setSortQuery({ [sortKey]: sortValue });
    } else {
      setSortQuery("");
    }
  }, [sortKey, sortValue]);

  useEffect(() => {
    const loadPaymentList = async () => {
      setLoading(true);
      try {
        const result = await postData("usertype/payment-list", {
          keyword: search,
          limit: limit,
          offset: search ? 0 : offset,
          sortQuery: sortQuery,
          status: 1,
          code: "customer-admin",
        });
        if (result.data) {
          setPaymentList(result.data);
        }
        setTotalDataCount(result.count);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err.message);
      }
    };
    loadPaymentList();
  }, [limit, offset, search]);

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
    const startItem = Number(totalDataCount > 0 ? 1 : 0);
    const endItem = Math.min(startItem + limit - 1, totalDataCount);
    setStartDataCount(startItem);
    setEndDataCount(endItem);
    if (search) {
      setOffset(0);
    }
  }, [limit, totalDataCount, search]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalDataCount;
    setOffset(newOffset);
    setCurrentPage(event.selected);
    const startItem = Number(newOffset + 1);
    const endItem = Math.min(startItem + limit - 1, totalDataCount);
    setStartDataCount(startItem);
    setEndDataCount(endItem);
  };

  const onSubmit = async (plan_id, company_id) => {
    setLoading(true);
    try {
      const paymentData = await postData("stripe/plan-create", {
        planId: plan_id,
        company_id: company_id,
      });
      if (paymentData.status && paymentData.status === 200) {
        setLoading(false);
        window.location.href = paymentData?.data?.redirect_url;
      } else {
        toastr.error(paymentData.message);
        setLoading(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setLoading(false);
    }
  }; 

  return (
    <div className="border bg-white border-gray-200 p-4 shadow-md rounded-xl">
      <div className="relative flex justify-between items-center mb-3">
        <div className="text-2xl font-bold">{t("payment")}</div>
        <div
          className="sm:ml-auto flex justify-end items-center"
          data-tooltip-id={`button${1}`}
          data-tooltip-place="right"
        >
          <Button
            //buttonClasses={'!bg-fuchsia-500'}
            buttonType={"button"}
            buttonIcon={"fa-regular fa-hand-holding-dollar"}
            buttonIconPosition={"left"}
            buttonLabel={t("payment_request")}
            buttonHasLink={false}
            functions={() => setEnabledCreateAdditionalPaymentPopup(true)}
          />

          <Tooltip
            id={`button${1}`}
            className="!text-xs !bg-dark-teal-600 z-50 rounded py-3 px-6"
            render={() => (
              <div className="gap-x-1 gap-y-1">
                <div className="text-[13px] font-normal leading-none flex items-center">
                  <div className="font-semibold">
                    {" "}
                    Request For Additional Payment
                  </div>
                </div>
              </div>
            )}
          />
        </div>
      </div>
      <div className="relative flex gap-3 items-center mb-3 flex-wrap">
        <div className="relative">
          <Select
            xPlacement={"left"}
            dropdownButtonClass={"!bg-white"}
            selectedValue={limit}
            dropdownData={[
              { _id: 1000000, label: t("all_items"), value: 1000000 },
              ...[
                { _id: 10, label: "10 " + t("items"), value: 10 },
                { _id: 20, label: "20 " + t("items"), value: 20 },
                { _id: 30, label: "30 " + t("items"), value: 30 },
                { _id: 50, label: "50 " + t("items"), value: 50 },
                { _id: 100, label: "100 " + t("items"), value: 100 },
              ],
            ]}
            getSelectedValue={(e) => {
              setLimit(e.value);
              setOffset(0);
              setCurrentPage(0);
            }}
          />
        </div>
        <div className="relative w-48">
          <Search
            placeholder={t("search")}
            setInput={setKeyword}
            valueReset={() => {
              setOffset(0);
              setCurrentPage(0);
            }}
          />
        </div>
      </div>
      <div className="w-full overflow-auto scroll-smooth scrollbar bg-white rounded-md shadow min-h-[480px]">
        <table className="min-w-max w-full table-auto">
          <thead>
            <tr className="text-white font-bold text-xs leading-none border-b border-slate-200">
              <th className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900 rounded-tl-md">
                <div className="text-xs uppercase font-semibold text-white">
                  {t("company")}
                </div>
              </th>
              <th className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900">
                <div className="text-xs uppercase font-semibold text-white">
                  {t("administrator_email")}
                </div>
              </th>
              <th className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900">
                <div className="text-xs uppercase font-semibold text-white">
                  {t("date")}
                </div>
              </th>
              <th className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900">
                <div className="text-xs uppercase font-semibold text-white">
                  {t("price")}
                </div>
              </th>
              <th className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900">
                <div className="text-xs uppercase font-semibold text-white">
                  {t("payment_mode")}
                </div>
              </th>
              <th className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900">
                <div className="text-xs uppercase font-semibold text-white">
                  {t("payment")}
                </div>
              </th>
              <th className="py-4 px-2 text-right max-w-[240px] sticky -top-6 z-10 bg-slate-900 rounded-tr-md">
                <div className="text-xs uppercase font-semibold text-white">
                  {t("action")}
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="text-slate-700 text-sm font-light bg-white">
            {totalDataCount > 0 ? (
              paymentList.map((item, index) => (
                <tr className="border-b border-slate-200" key={index}>
                  <td className="py-2 px-2 text-left max-w-[240px] border-b border-slate-200 break-all">
                    <div className="block">
                      <div
                        className="relative inline-flex items-center"
                        data-tooltip-id={`textTooltip${item?._id}`}
                        data-tooltip-place="right"
                      >
                        <Indentification
                          initial={initialsValue(item?.company_name)}
                          image={item?.image}
                          alt={item?.company_name}
                          fill={true}
                          className={""}
                          size={"sm"}
                        />
                        <div
                          className={"text-sm font-normal text-slate-600 mx-2 "}
                        >
                          {item?.company_name}
                        </div>
                        <Tooltip
                          id={`textTooltip${item?._id}`}
                          className="!text-sm !bg-dark-teal-600 !bg-opacity-95 z-50 shadow-lg rounded-lg p-4"
                          render={() => (
                            <div className="flex flex-col items-start justify-center text-white gap-1">
                              {item?.name && (
                                <h4 className="font-semibold">
                                  <span className="text-slate-200">
                                    Administrator Name:{" "}
                                  </span>
                                  {item?.name}
                                </h4>
                              )}

                              {item?.email && (
                                <h4 className="font-medium">
                                  <span className="text-slate-200">
                                    Administrator Email:{" "}
                                  </span>
                                  {item?.email}
                                </h4>
                              )}

                              <h4 className="font-medium">
                                <span className="text-slate-200">Price: </span>
                                {item?.price
                                  ? "$" + item?.price.toFixed(2)
                                  : "$0.00"}
                              </h4>
                              <h4 className="font-medium">
                                <span className="text-slate-200">
                                  Payment Mode:{" "}
                                </span>
                                {item?.type ? item?.type : "N/A"}
                              </h4>

                              {item?.account_type && (
                                <h4 className="flex font-medium space-x-2">
                                  <span className="text-slate-200">
                                    Account Type:{" "}
                                  </span>
                                  <div className="flex items-center">
                                    <div
                                      className={`text-[10px] font-medium leading-tight rounded py-1 px-2  ${
                                        item?.account_type === "business"
                                          ? "bg-violet-100 text-violet-600"
                                          : "bg-orange-100 text-orange-600"
                                      }`}
                                    >
                                      {capitalizeString(item?.account_type)}
                                    </div>
                                  </div>
                                </h4>
                              )}
                              <h4 className="flex font-medium space-x-2">
                                <span className="text-slate-200">
                                  Subscription Details:{" "}
                                </span>
                                <div className="flex items-center">
                                  <div
                                    className={`text-[10px] font-medium leading-tight rounded py-1 px-2  ${
                                      item.payment_status === 1
                                        ? "bg-green-100 text-green-600"
                                        : item && item.payment_status === 3
                                        ? "bg-amber-100 text-amber-600"
                                        : "bg-rose-100 text-rose-600"
                                    }`}
                                  >
                                    {item && item.payment_status === 1
                                      ? "Paid"
                                      : item && item.payment_status === 3
                                      ? t("unpaid")
                                      : t("cancel")}
                                  </div>
                                </div>
                              </h4>
                            </div>
                          )}
                        />
                      </div>
                    </div>
                  </td>
                  <td className="py-2 px-2 text-left max-w-[240px] border-b border-slate-200 break-all">
                    <div className="block">
                      <div className="text-sm font-normal text-slate-600">
                        {item && item?.email ? item?.email : "N/A"}
                      </div>
                    </div>
                  </td>
                  <td className="py-2 px-2 text-left max-w-[240px] border-b border-slate-200 break-all">
                    <div className="block">
                      <div className="text-sm font-normal text-slate-600">
                        {item && item?.created_at
                          ? moment(new Date(item?.created_at)).format("ll")
                          : "N/A"}
                      </div>
                    </div>
                  </td>
                  <td className="py-2 px-2 text-left max-w-[240px] border-b border-slate-200 break-all">
                    <div className="block">
                      <div className="text-sm font-normal text-slate-600">
                        {item && item?.price
                          ? "$" + item?.price.toFixed(2)
                          : "$0.00"}
                      </div>
                    </div>
                  </td>
                  <td className="py-2 px-2 text-left max-w-[240px] border-b border-slate-200 break-all">
                    <div className="block">
                      <div className="text-sm font-normal text-slate-600">
                        {item && item?.type ? item?.type : "N/A"}
                      </div>
                    </div>
                  </td>
                  <td className="py-2 px-2 text-left max-w-[240px] border-b border-slate-200 break-all">
                    <div className="flex items-center">
                      <div
                        className={
                          "text-[10px] font-medium leading-tight rounded py-1 px-2 " +
                          (item && item?.payment_status === 1
                            ? "bg-green-100 text-green-600"
                            : item && item?.payment_status === 3
                            ? "bg-amber-100 text-amber-600"
                            : "bg-rose-100 text-rose-600")
                        }
                      >
                        {item && item?.payment_status === 1
                          ? "Paid"
                          : item && item?.payment_status === 3
                          ? t("unpaid")
                          : t("cancel")}
                      </div>
                    </div>
                  </td>
                  <td className="py-3 px-2 text-right max-w-[240px]">
                    {item && item.payment_status === 3 ? (
                      <div className="flex items-center justify-end gap-2">
                        <Button
                          buttonClasses={"!h-7 !px-2 !text-xs !bg-slate-400"}
                          buttonLabel={""}
                          buttonLabelClasses={"text-xs"}
                          buttonType={"button"}
                          buttonIcon={"fa-regular fa-credit-card"}
                          buttonIconPosition={"left"}
                          buttonHasLink={false}
                          functions={() => {
                            setEnabledBuyPlanPopup(true);
                            setCompanyData(item);
                          }}
                          tooltipContent={t("manage_payment")}
                        />
                        <Tooltip id="button-tooltip" place="left"></Tooltip>
                        <Button
                          buttonClasses={"!h-7 !px-2 !text-xs"}
                          buttonLabel={""}
                          buttonLabelClasses={"text-xs"}
                          buttonType={"button"}
                          buttonIcon={"fa-regular fa-arrow-right-to-arc"}
                          buttonIconPosition={"left"}
                          buttonHasLink={false}
                          functions={() => onSubmit(item?.plan_id, item?._id)}
                          tooltipContent={t("make_payment")}
                        />
                      </div>
                    ) : (
                      <div
                        className="inline-flex items-center justify-end gap-2"
                        data-tooltip-id={`button_make_payment${1}`}
                        data-tooltip-place="right"
                      >
                        <Button
                          buttonClasses={"!h-7 !px-2 !text-xs !bg-fuchsia-400"}
                          buttonLabel={""}
                          buttonLabelClasses={"text-xs"}
                          buttonType={"button"}
                          buttonIcon={"fa-regular fa-arrow-right-to-arc"}
                          buttonIconPosition={"left"}
                          buttonHasLink={false}
                          functions={() => {
                            setEnabledMakePaymentPopup(true);
                            setCompanyData(item);
                          }}
                        />
                        <Tooltip
                          id={`button_make_payment${1}`}
                          className="!text-xs !bg-dark-teal-600 z-50 rounded py-3 px-6"
                          render={() => (
                            <div className="gap-x-1">
                              <div className="text-[13px] font-normal leading-none flex items-center">
                                <div className="font-semibold">
                                  {t("make_payment")}
                                </div>
                              </div>
                            </div>
                          )}
                        />
                      </div>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr className="border-b border-slate-200">
                <td className="py-3 px-2 text-left" colSpan={7}>
                  {loading ? (
                    <>
                      {/* <Searching label={t("searching")} /> */}
                      <TableSkeleton />
                    </>
                  ) : (
                    <NoDataFound label={t("no_data_found")} />
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {noOfPage > 1 ? (
        <Pagination
          handlePageClick={handlePageClick}
          noOfPage={noOfPage}
          startDataCount={startDataCount}
          endDataCount={endDataCount}
          count={totalDataCount}
          forcePage={currentPage}
        />
      ) : (
        <div className="flex justify-center sm:justify-between my-4 text-sm text-black text-center sm:text-left">
          {totalDataCount > 0 ? (
            <div>
              <p>
                {t("showing")}: {startDataCount} to {endDataCount} of{" "}
                {totalDataCount}
              </p>
            </div>
          ) : (
            ""
          )}
        </div>
      )}
      {enabledBuyPlanPopup && (
        <BuyPlanPopup
          isOpen={enabledBuyPlanPopup}
          setIsOpen={(val) => setEnabledBuyPlanPopup(val)}
          data={companyData}
          getActionValue={(obj) => {
            setActionValue(obj);
          }}
          type={"predictors"}
        ></BuyPlanPopup>
      )}
      {enabledMakePaymentPopup && (
        <MakePaymentPopup
          isOpen={enabledMakePaymentPopup}
          setIsOpen={(val) => setEnabledMakePaymentPopup(val)}
          data={companyData}
          getActionValue={(obj) => {
            setActionValue(obj);
          }}
        ></MakePaymentPopup>
      )}
      {enabledCreateAdditionalPaymentPopup && (
        <MakeCreateAdditionalPaymentPopup
          isOpen={enabledCreateAdditionalPaymentPopup}
          setIsOpen={(val) => setEnabledCreateAdditionalPaymentPopup(val)}
          data={""}
          getActionValue={(obj) => {
            setActionValue(obj);
          }}
        ></MakeCreateAdditionalPaymentPopup>
      )}
    </div>
  );
};

export default Payments;

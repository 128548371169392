import React, { useState, useEffect, useCallback } from "react";
import { setTitle } from "../helpers/MetaTag";
import Button from "../components/form/Button";
import TableSkeleton from "../components/loader/TableSkeleton";
import { Tooltip } from "react-tooltip";
import { postData } from "../services/api";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import NoDataFound from "../components/NoDataFound";
import ManageSurveyQuestionPopup from "../components/popup/ManageSurveyQuestionPopup";

const ExecutiveAuditSettings = () => {
  setTitle(
    "Succession Planning Software for your Business with Succession Now | Executive Audit Settings"
  );
  const { i18n, t } = useTranslation();
  const role = localStorage.getItem("role");
  const user = useSelector((state) => state.auth?.user);
  const [loading, setLoading] = useState(true);
  const [keyword, setKeyword] = useState("");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [sortKey, setSortKey] = useState("created_at");
  const [sortValue, setSortValue] = useState(1);
  const [sortQuery, setSortQuery] = useState("");
  const [noOfPage, setNoOfPage] = useState(0);
  const [surveyData, setSurveyData] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [actionValue, setActionValue] = useState({});
  const [enabledPopup, setEnabledPopup] = useState({
    data: null,
    isOpen: false,
    id: null,
  });
  const [editData, setEditData] = useState({});

  useEffect(() => {
    if (sortKey && sortValue) {
      setSortQuery({ [sortKey]: sortValue });
    } else {
      setSortQuery("");
    }
  }, [sortKey, sortValue]);

  //   useEffect(() => {
  //     const loadSurveyData = async () => {
  //       setLoading(true);
  //       try {
  //         const result = await postData("employee-rating-option/list", {
  //           keyword: keyword,
  //           //   limit: limit,
  //           //   offset: offset,
  //           sortQuery: { created_at: 1 },
  //           status: 1,
  //           type: "executive",
  //         });
  //         if (result.data) {
  //           setSurveyData(result.data);
  //         }
  //         setTotalDataCount(result.count);
  //         setLoading(false);
  //       } catch (err) {
  //         setLoading(false);
  //         console.log(err.message);
  //       }
  //     };
  //     loadSurveyData();
  //   }, [actionValue]);

  const loadList = useCallback(async () => {
    setLoading(true);
    try {
      const result = await postData("employee-rating-option/list", {
        keyword: keyword,
        //   limit: limit,
        //   offset: offset,
        sortQuery: { created_at: 1 },
        status: 1,
        type: "executive",
      });
      if (result.data) {
        setSurveyData(result.data);
      }
      setTotalDataCount(result.count);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  }, [actionValue]);

  useEffect(() => {
    loadList();
  }, [loadList]);

  return (
    <>
      <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-4">
        <div className="text-xl md:text-3xl text-slate-800 font-bold">
          {t("executive_audit_settings") + " - " + user?.company_name}
        </div>
      </div>
      <div className="w-full overflow-auto scroll-smooth scrollbar bg-white rounded-md shadow min-h-[480px]">
        <table className="min-w-max w-full table-auto">
          <thead>
            <tr className="text-white font-bold text-xs leading-none border-b border-slate-200">
              <th className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900 rounded-tl-md">
                {t("name")}
              </th>
              <th className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900 rounded-tr-md">
                {t("action")}
              </th>
            </tr>
          </thead>
          <tbody className="text-slate-700 text-sm font-light bg-white">
            {totalDataCount > 0 ? (
              surveyData.map((item, index) => (
                <tr className="border-b border-slate-200" key={index}>
                  <td className="py-3 px-2 text-left max-w-[240px]">
                    <div className="block">
                      <div className="text-sm font-semibold text-slate-600">
                        {item?.question?.name}
                      </div>
                      <small className="block">
                        <div className="text-xs text-slate-400">
                          {item?.question?.description}
                        </div>
                      </small>
                    </div>
                  </td>
                  <td className="py-3 px-2 text-left max-w-[240px]">
                    <div
                      className=""
                      data-tooltip-id={`editButton${item?._id}`}
                      data-tooltip-place="right"
                    >
                      <Button
                        buttonClasses={
                          "!p-0 !flex !items-center !justify-center !text-[14px] !bg-transparent !text-dark-teal-600 !w-8 !h-8 !font-bold"
                        }
                        buttonType={"button"}
                        buttonIcon={"fa-regular fa-pencil"}
                        buttonIconPosition={"left"}
                        buttonHasLink={false}
                        functions={() => {
                          setEnabledPopup({
                            id: item?._id,
                            data: item,
                            isOpen: true,
                          });
                        }}
                      />
                      <Tooltip
                        id={`editButton${item?._id}`}
                        className="!text-xs !bg-dark-teal-600 z-50 rounded py-3 px-6"
                        render={() => (
                          <div className="gap-x-1 gap-y-1">
                            <div className="text-[13px] font-normal leading-none flex items-center">
                              <div className="font-semibold">
                                {`Click To Edit Executive Audit Settings`}
                              </div>
                            </div>
                          </div>
                        )}
                      />
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr className="border-b border-slate-200">
                <td className="py-3 px-2 text-left" colSpan={3}>
                  {loading ? (
                    <TableSkeleton tableImage={false} />
                  ) : (
                    <NoDataFound label={t("no_data_found")} />
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {enabledPopup?.isOpen && (
        <ManageSurveyQuestionPopup
          isOpen={enabledPopup?.isOpen}
          setIsOpen={setEnabledPopup}
          data={enabledPopup?.data}
          getActionValue={(obj) => {
            setActionValue(obj);
          }}
          type={"executive"}
          id={enabledPopup?.id}
          loadList={loadList}
        />
      )}
    </>
  );
};

export default ExecutiveAuditSettings;

import React from "react";
import Th from "./Th";

const Thead = ({
  tableHeadData,
  getSortValue = () => {},
  getSortKey = () => {},
  totalDataCount
}) => {
  return (
    <>
      <thead>
        <tr className="text-white text-xs leading-none border-b border-slate-200">
          {tableHeadData.map((item) => (
            <Th
              key={item?._id}
              width={item?.width}
              name={item?.name}
              value={item?.value}
              nameAlign={item?.align}
              isSort={item?.isSort}
              isFilter={item?.isFilter}
              getSortValue={getSortValue}
              functions={(val) => getSortKey(val)}
              dropdownData={item?.filterData}
              onFilter={item?.onFilter}
              isFilterSearch={item.isFilterSearch}
              totalDataCount={totalDataCount}
            />
          ))}
        </tr>
      </thead>
    </>
  );
};

export default Thead;

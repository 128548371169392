import React, { createContext, useContext, useEffect, useState } from "react";
import WIFI from "../assets/image/wifi.svg";

// Create the context
const NetworkStatusContext = createContext();

// Create a provider component
export const NetworkStatusProvider = ({ children }) => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    // Cleanup event listeners on unmount
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return (
    <NetworkStatusContext.Provider value={isOnline}>
      {isOnline ? (
        children
      ) : (
        <div className="flex flex-col justify-center items-center h-screen bg-gradient-to-b from-white to-teal-100 text-dark-teal-800 p-8">
          <div className=" flex mb-8 space-x-4">
            <h1 className="text-4xl sm:text-5xl font-extrabold mb-4">
              😕 Oops!
            </h1>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              width="64"
              height="64"
              viewBox="0 0 510 510"
              style={{ enableBackground: "new 0 0 512 512" }}
            >
              <defs>
                <linearGradient id="a">
                  <stop offset="0" stopColor="#eaf9fa"></stop>
                  <stop offset="1" stopColor="#b3dafe"></stop>
                </linearGradient>
                <linearGradient
                  xlinkHref="#a"
                  id="d"
                  x1="377.502"
                  x2="260.492"
                  y1="132.502"
                  y2="249.511"
                  gradientUnits="userSpaceOnUse"
                ></linearGradient>
                <linearGradient
                  id="e"
                  x1="413.343"
                  x2="413.343"
                  y1="253"
                  y2="142"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0" stopColor="#7bacdf" stopOpacity="0"></stop>
                  <stop offset="1" stopColor="#7bacdf"></stop>
                </linearGradient>
                <linearGradient
                  xlinkHref="#a"
                  id="f"
                  x1="293.501"
                  x2="191.996"
                  y1="216.501"
                  y2="318.006"
                  gradientUnits="userSpaceOnUse"
                ></linearGradient>
                <linearGradient
                  xlinkHref="#a"
                  id="g"
                  x1="211.585"
                  x2="148.585"
                  y1="295.922"
                  y2="361.922"
                  gradientUnits="userSpaceOnUse"
                ></linearGradient>
                <linearGradient
                  xlinkHref="#a"
                  id="h"
                  x1="154.5"
                  x2="105.246"
                  y1="355.5"
                  y2="404.754"
                  gradientUnits="userSpaceOnUse"
                ></linearGradient>
                <linearGradient id="b">
                  <stop offset="0" stopColor="#fe0364"></stop>
                  <stop offset=".257" stopColor="#f3035f"></stop>
                  <stop offset=".701" stopColor="#d70252"></stop>
                  <stop offset="1" stopColor="#c00148"></stop>
                </linearGradient>
                <linearGradient
                  xlinkHref="#b"
                  id="i"
                  x1="60"
                  x2="60"
                  y1="398"
                  y2="506.06"
                  gradientUnits="userSpaceOnUse"
                ></linearGradient>
                <linearGradient
                  xlinkHref="#b"
                  id="j"
                  x1="343"
                  x2="458.039"
                  y1="31"
                  y2="146.039"
                  gradientUnits="userSpaceOnUse"
                ></linearGradient>
                <linearGradient id="c">
                  <stop offset="0" stopColor="#ca2e43" stopOpacity="0"></stop>
                  <stop
                    offset=".19"
                    stopColor="#be2c40"
                    stopOpacity=".19"
                  ></stop>
                  <stop
                    offset=".703"
                    stopColor="#a22639"
                    stopOpacity=".703"
                  ></stop>
                  <stop offset="1" stopColor="#972437"></stop>
                </linearGradient>
                <linearGradient
                  xlinkHref="#c"
                  id="k"
                  x1="411"
                  x2="411"
                  y1="119"
                  y2="196.007"
                  gradientUnits="userSpaceOnUse"
                ></linearGradient>
                <linearGradient
                  xlinkHref="#c"
                  id="l"
                  x1="468.525"
                  x2="422.193"
                  y1="156.475"
                  y2="110.143"
                  gradientUnits="userSpaceOnUse"
                ></linearGradient>
                <linearGradient
                  xlinkHref="#a"
                  id="m"
                  x1="377.026"
                  x2="431.694"
                  y1="64.974"
                  y2="119.643"
                  gradientUnits="userSpaceOnUse"
                ></linearGradient>
              </defs>
              <g>
                <path
                  fill="url(#d)"
                  d="M472.474 483.671c-20.033 0-36.652-15.834-37.445-36.029C427.13 246.566 263.434 82.869 62.358 74.971c-20.695-.813-36.812-18.248-36-38.943.813-20.695 18.262-36.805 38.943-36 116.706 4.584 226.54 52.67 309.27 135.399 82.729 82.73 130.815 192.564 135.399 309.27.812 20.694-15.305 38.13-36 38.943-.499.021-1 .031-1.496.031z"
                ></path>
                <path
                  fill="url(#e)"
                  d="M485.689 313.696c-22.628-66.513-60.346-127.495-111.118-178.268l-33.575 33.575z"
                ></path>
                <path
                  fill="url(#f)"
                  d="M358.431 478.671c-17.152 0-31.495-13.428-32.425-30.76-7.66-142.797-121.12-256.257-263.917-263.917-17.924-.961-31.674-16.271-30.712-34.194.961-17.923 16.281-31.673 34.194-30.712 84.836 4.55 164.715 40.214 224.921 100.421s95.871 140.085 100.421 224.921c.962 17.923-12.789 33.232-30.712 34.194a33.96 33.96 0 0 1-1.77.047z"
                ></path>
                <path
                  fill="url(#g)"
                  d="M254.555 468.671c-11.607 0-21.453-8.921-22.402-20.692-7.356-91.224-78.907-162.775-170.131-170.131-12.386-.999-21.617-11.85-20.619-24.236.999-12.386 11.854-21.611 24.236-20.619 54.438 4.39 105.753 28.141 144.49 66.878s62.488 90.052 66.878 144.49c.999 12.386-8.232 23.237-20.619 24.236-.615.05-1.227.074-1.833.074z"
                ></path>
                <path
                  fill="url(#h)"
                  d="M170.954 458.671c-6.158 0-11.521-4.55-12.371-10.822-6.78-49.998-46.435-89.653-96.433-96.433-6.841-.928-11.635-7.226-10.707-14.066.928-6.841 7.225-11.632 14.066-10.707 61.101 8.286 109.561 56.747 117.847 117.847.928 6.841-3.866 13.139-10.707 14.066-.569.078-1.136.115-1.695.115z"
                ></path>
                <circle cx="60" cy="450" r="60" fill="url(#i)"></circle>
                <circle cx="411" cy="99" r="99" fill="url(#j)"></circle>
                <path
                  fill="url(#k)"
                  d="M340.996 169.004c38.662 38.662 101.345 38.662 140.007 0 25.746-25.746 34.323-62.14 25.779-95.004H315.218c-8.545 32.864.032 69.257 25.778 95.004z"
                ></path>
                <path
                  fill="url(#l)"
                  d="m509.423 88.48-43.762-43.762-108.893 108.894 43.825 43.825c28.682 3.014 58.317-5.402 79.928-26.994 28.762-28.762 33.419-72.994 28.902-82.963z"
                ></path>
                <path
                  fill="url(#m)"
                  d="M380.354 56.647 360.011 77c-6.015 6.015-6.015 15.782 0 21.797 6.015 6.015 15.782 6.015 21.797 0l20.343-20.353c6.015-6.015 6.015-15.782 0-21.797-6.015-6.015-15.782-6.015-21.797 0z"
                ></path>
              </g>
            </svg>
          </div>
          <h1 className="text-4xl sm:text-4xl font-extrabold text-dark-teal-600 mb-4 animate-fade-in">
            No Internet Connection
          </h1>

          <p className="text-lg sm:text-xl text-dark-teal-500 mb-2 animate-fade-in-delayed">
            It seems like you're offline.
          </p>
          <p className="text-sm sm:text-base text-dark-teal-400 mb-8 animate-fade-in-delayed">
            Please check your connection and try again.
          </p>

          <button
            className="px-6 py-2 bg-gradient-to-r from-dark-teal-500 to-dark-teal-600 text-white text-lg font-semibold rounded-lg shadow-lg hover:shadow-xl hover:bg-dark-teal-700 focus:ring-4 focus:ring-teal-300 transition-all transform hover:scale-105 active:scale-95"
            onClick={() => window.location.reload()}
          >
            Retry
          </button>
        </div>
      )}
    </NetworkStatusContext.Provider>
  );
};

// Custom hook for consuming the context
export const useNetworkStatus = () => {
  return useContext(NetworkStatusContext);
};

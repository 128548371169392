import React, { useEffect, useState } from "react";
import Input from "../../form/Input";
import Button from "../../form/Button";
import { useTranslation } from "react-i18next";
import toastr from "toastr";
import Textarea from "../../form/Textarea";
import Select from "../../form/Select";
import { postData } from "../../../services/api";
import ConfirmationPopup from "../../popup/ConfirmationPopup";
import ApiKeySettingPopup from "../../popup/ApiKeySettingPopup";

const ApiKeySetting = ({ details, siteDetails = () => {} }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState([
    {
      whiteListedDomain: "",
      name: "",
      jwtSecretKey: "",
      expireDay: "",
    },
  ]);
  const [formDataAdd, setFormDataAdd] = useState([
    {
      whiteListedDomain: "",
      name: "",
      jwtSecretKey: "",
      expireDay: "",
    },
  ]);
  const [loaded, setLoaded] = useState(false);
  const [enabledConfirmationPopup, setEnabledConfirmationPopup] =
    useState(false);
  const [actionValue, setActionValue] = useState({});
  const [isCopy, setIsCopy] = useState(false);
  const [isOpen, setIsOpen] = useState({ _id: null, open: false });
  const [id, setId] = useState(null);

  const addField = () => {
    setFormData([
      ...formData,
      {
        whiteListedDomain: "",
        name: "",
        jwtSecretKey: "",
        expireDay: "",
      },
    ]);
  };

  useEffect(() => {
    if (details?._id) {
      const data =
        details &&
        Array?.isArray(details?.restApiInfo) &&
        details?.restApiInfo?.length > 0 &&
        details?.restApiInfo?.map((item) => ({
          name: item?.name,
          whiteListedDomain: item?.domain,
          jwtSecretKey: item?.secretKey,
          expireDay: item?.expire,
        }));
      setFormData(data);
    }
  }, [details]);

  const deleteField = (index) => {
    const updatedFormData = formData.filter((_, idx) => idx !== index);
    setFormData(updatedFormData);
  };

  const updateField = (index, field, value) => {
    const updatedFormData = [...formData];
    updatedFormData[index][field] = value;
    setFormData(updatedFormData);
  };

  const handleCopyKey = (key) => {
    navigator.clipboard.writeText(key).then(() => {
      toastr.success("Secret key copied successfully!");
    });
  };

  const validateFields = () => {
    let isValid = true;

    // Iterate over each site (index-wise validation)
    for (let index = 0; index < formDataAdd.length; index++) {
      const data = formDataAdd[index];

      if (!data.name.trim()) {
        toastr.error(`Site ${index + 1}: Site Name is required`);
        isValid = false;
        break;
      }

      if (!data.whiteListedDomain.trim()) {
        toastr.error(`Site ${index + 1}: White Listed Domain is required`);
        isValid = false;
        break;
      }

      if (!data.jwtSecretKey.trim()) {
        toastr.error(`Site ${index + 1}: JWT Secret Key is required`);
        isValid = false;
        break;
      }

      if (isNaN(data.expireDay) || data.expireDay <= 0) {
        toastr.error(
          `Site ${index + 1}: Expire Days must be a positive number`
        );
        isValid = false;
        break;
      }
    }

    return isValid;
  };

  const onSubmit = async (index) => {
    setLoaded(true);
    if (validateFields()) {
      try {
        const path = "setting/apikey-update";
        const payload = {};

        if (id) {
        } else {
          payload =
          formDataAdd &&
            Array?.isArray(formDataAdd) &&
            formDataAdd?.length > 0 &&
            formDataAdd?.map((item) => ({
              name: item?.name,
              domain: item?.whiteListedDomain,
              secretKey: item?.jwtSecretKey,
              expire: item?.expireDay,
            }));
        }
        // formData &&
        // Array?.isArray(formData) &&
        // formData?.length > 0 &&
        // formData?.map((item) => ({
        //   name: item?.name,
        //   domain: item?.whiteListedDomain,
        //   secretKey: item?.jwtSecretKey,
        //   expire: item?.expireDay,
        // }));

        const res = await postData(path, {
          restApiInfo: payload && payload?.length > 0 && payload[0],
        });
        if (res.status && res.status === 200) {
          toastr.success(res.message);
          console.log(res, "resss");
          setEnabledConfirmationPopup(false);
          setActionValue({});
          setIsCopy({ show: true, index: index });
          setIsOpen({ _id: null, open: false });
          siteDetails();
        } else {
          toastr.error(res.message);
          setEnabledConfirmationPopup(false);
          setActionValue({});
          setIsCopy({ show: false, index: null });
        }
      } catch (err) {
        console.log(err.messege);
      }
    } else {
      setEnabledConfirmationPopup(false);
      setActionValue({});
      setIsCopy({ show: false, index: null });
    }
    setLoaded(false);
  };

  const generateJwtSecret = async (index) => {
    setLoaded(true);
    try {
      const res = await postData("generate/secret-key", {});
      if (res.status && res.status === 200) {
        updateField(index, "jwtSecretKey", res?.data);
        setIsCopy({ show: true, index: index });
      } else {
        toastr.error(res.message);
      }
    } catch (err) {
      console.log(err.messege);
    }
    setLoaded(false);
  };

  useEffect(() => {
    if (actionValue && actionValue.button_type === "confirm") {
      const generateJwtSecret = async (index) => {
        setLoaded(true);
        try {
          const res = await postData("generate/secret-key", {});
          if (res.status && res.status === 200) {
            updateField(index, "jwtSecretKey", res?.data);
            onSubmit(index);
          } else {
            toastr.error(res.message);
          }
        } catch (err) {
          console.log(err.messege);
        }
        setLoaded(false);
      };
      generateJwtSecret(actionValue?.index);
    }
  }, [actionValue]);

  const getMaskedKey = (secretKey) => {
    const visibleStart = 10;
    const visibleEnd = 10;
    const start = secretKey.slice(0, visibleStart); // First few characters
    const end = secretKey.slice(-visibleEnd); // Last few characters
    const masked = "*".repeat(secretKey.length - visibleStart - visibleEnd); // Mask middle characters
    return `${start}${masked}${end}`;
  };

  return (
    <div>
      <div className="relative flex justify-between items-center mb-3 flex-wrap sm:flex-nowrap gap-y-4">
        <div className="text-2xl font-bold">Api Key Setting</div>
        {/* <Button
          buttonClasses=""
          buttonLabelClasses=""
          buttonIcon={"fa-solid fa-plus"}
          buttonIconPosition={"left"}
          buttonType={"button"}
          buttonLabel={t("Add More Site")}
          functions={addField}
          buttonHasLink={false}
        /> */}
        <Button
          buttonClasses=""
          buttonLabelClasses=""
          buttonIcon={"fa-solid fa-plus"}
          buttonIconPosition={"left"}
          buttonType={"button"}
          buttonLabel={t("Add More Site")}
          functions={() => setIsOpen({ _id: null, open: true })}
          buttonHasLink={false}
        />
      </div>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 lg:col-span-12 flex flex-col">
          <div
            className="grid grid-cols-3 gap-4 p-4 overflow-auto scroll-smooth scrollbar"
            // max-h-[calc(100vh-50px)]"
          >
            {formData.map((data, index) => (
              <div
                key={index}
                className="relative w-full space-y-2 shadow-lg p-3 rounded-lg bg-white border border-slate-100"
              >
                <div className="relative flex justify-between items-center mb-3 flex-wrap sm:flex-nowrap gap-y-4">
                  <div className="text-xl font-semibold text-dark-teal-600">{`Site ${
                    index + 1
                  }`}</div>
                  {/* {formData.length > 1 && (
                    <button
                      onClick={() => deleteField(index)}
                      className="text-rose-500 text-lg"
                    >
                      <i className="fa-solid fa-trash"></i>
                    </button>
                  )} */}
                </div>
                <Input
                  label="Site Name"
                  labelClasses="!text-xs"
                  inputType="text"
                  inputPlaceholder="Enter Site Name"
                  inputValue={data.name}
                  setInput={(value) => updateField(index, "name", value)}
                  requiredDisplay={true}
                />
                <Input
                  label="White Listed Domain"
                  labelClasses="!text-xs"
                  inputType="text"
                  inputPlaceholder="Enter Domain"
                  inputValue={data.whiteListedDomain}
                  setInput={(value) =>
                    updateField(index, "whiteListedDomain", value)
                  }
                  requiredDisplay={true}
                />
                <div className="relative flex items-end gap-3">
                  <div className="flex-grow flex-shrink">
                    <Textarea
                      label="Secret Key"
                      labelClasses="!text-xs"
                      inputClasses={"!h-10"}
                      inputPlaceholder="Generate Secret Key"
                      inputValue={
                        data.jwtSecretKey
                          ? "***************************"
                          : data.jwtSecretKey
                      }
                      setTextarea={(value) =>
                        updateField(index, "jwtSecretKey", value)
                      }
                      requiredDisplay={true}
                      isDisable={true}
                    />
                  </div>
                  {isCopy?.show && isCopy?.index === index && (
                    <>
                      <Button
                        buttonType="button"
                        buttonIconPosition={"left"}
                        buttonIcon={"fa-regular fa-copy text-gray-500"}
                        buttonClasses="!bg-white shadow border border-slate-200 !px-0 aspect-[1/1] !flex-shrink-0"
                        functions={() => handleCopyKey(data.jwtSecretKey)}
                        buttonHasLink={false}
                      />
                    </>
                  )}
                </div>
                {isCopy?.show && isCopy?.index === index && (
                  <>
                    <div className="text-[13px] font-medium text-rose-700">
                      Please copy the secret key now, as it will not be
                      accessible again.
                    </div>
                  </>
                )}

                <div className="flex gap-2 mt-2">
                  <Button
                    buttonType="button"
                    buttonLabel="Generate Secret Key"
                    buttonIconPosition={"left"}
                    buttonIcon={
                      loaded
                        ? "fa-light fa-spinner fa-spin"
                        : "fa-solid fa-rotate"
                    }
                    buttonClasses="!bg-pink-500 text-white"
                    functions={() => {
                      if (data.jwtSecretKey) {
                        setEnabledConfirmationPopup(true);
                        setActionValue((pre) => ({
                          ...pre,
                          index: index,
                          item: data,
                        }));
                      } else {
                        generateJwtSecret(index);
                      }
                    }}
                    buttonHasLink={false}
                  />
                </div>
                <Select
                  label="Expire Days"
                  labelClasses={"!text-xs !font-semibold"}
                  xPlacement={"left"}
                  selectedValue={data.expireDay}
                  dropdownClass={"!w-60"}
                  dropdownData={[
                    { _id: " ", label: t("Select Expire Days"), value: "" },
                    ...Array.from({ length: 50 }).map((_, index) => {
                      const value = (index + 1) * 10;
                      return {
                        _id: value,
                        label: `${value} Days`,
                        value: value,
                      };
                    }),
                  ].filter((itm) => itm)}
                  getSelectedValue={(value) =>
                    updateField(index, "expireDay", value._id)
                  }
                  requiredDisplay={true}
                />
              </div>
            ))}
          </div>
          <div className="flex justify-end gap-4 mt-5">
            <Button
              buttonClasses=""
              buttonLabelClasses=""
              buttonType={"button"}
              buttonIcon={
                loaded
                  ? "fa-light fa-spinner fa-spin"
                  : "fa-light fa-floppy-disk"
              }
              buttonIconPosition={"left"}
              buttonLabel={t("save")}
              functions={onSubmit}
              buttonHasLink={false}
            />
          </div>
        </div>
      </div>
      {enabledConfirmationPopup && (
        <ConfirmationPopup
          isOpen={enabledConfirmationPopup}
          title={t("confirmation")}
          message={`Would you like to generate a new secret key? If you confirm, the key will be sent to the ${
            (actionValue && actionValue?.item?.name) || " "
          } 
          (${
            (actionValue && actionValue?.item?.whiteListedDomain) || " "
          }) domain. Please click 'Confirm' to proceed.`}
          confirmButtonLabel={t("confirm")}
          cancelButtonLabel={t("cancel")}
          setIsOpen={(val) => setEnabledConfirmationPopup(val)}
          getActionValue={(obj) => {
            setActionValue((pre) => ({ ...pre, ...obj }));
          }}
        />
      )}
      {isOpen?.open && (
        <ApiKeySettingPopup
          setIsOpen={setIsOpen}
          isOpen={isOpen?.open}
          onSubmit={onSubmit}
          formData={formDataAdd}
          setFormData={setFormDataAdd}
        />
      )}
    </div>
  );
};

export default ApiKeySetting;
